import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../context/SessionContext";
import axios from "axios";
import { Button } from "flowbite-react";
import {useGoogleLogin} from "@react-oauth/google";

function ForgetMeLink() {
    const { session, setSession } = useSession();
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                const accessToken = codeResponse.access_token;
                // Send the access token to the backend to authenticate the user
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth?access_token=${accessToken}`);
                const sessionData = await response.data // get user session

                // Now you can manage the user session on the client-side
                setSession(sessionData);
            } catch (error) {
                console.error("Error during authentication:", error);
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    const handleDeleteAccount = async () => {
        if (!confirm) {
            alert('Please confirm that you really want to delete your account.');
            return;
        }

        if (!window.confirm('Are you absolutely sure you want to delete your account? This action cannot be undone.')) {
            return;
        }

        // TODO: Delete local storage too!

        setLoading(true);
        setError(null);

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/account`, {
                headers: { Authorization: `Bearer ${session.token}` },
            });
            // Clear session
            setSession(null);
            alert('Your account has been deleted successfully.');
            navigate('/goodbye'); // Redirect to a goodbye page or home
        } catch (err) {
            console.error('Error deleting account:', err);
            setError('Failed to delete account. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoBack = async () => {
        navigate('/account'); // Redirect to the account page
    };

    // If the user is not logged in, redirect them to the login page
    if (!session) {
        return (
            <div className="flex justify-center items-start min-h-screen pt-10">
                <div className="text-center">
                    <h1 className="text-2xl font-bold mb-4">Delete Account - (Forget Me)</h1>
                    <p className="text-red-600 mb-4">You must be signed in to delete your account.</p>
                    <div className="mt-8 flex justify-center">
                        <Button
                            onClick={login}
                            color={'gray'}
                        >
                            Sign In
                        </Button>
                    </div>
                </div>
            </div>

        );
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Delete Account - (Forget Me)</h1>
            <div className="mb-4">
                <p className="text-red-600">Warning: Deleting your account is irreversible. All your data will be permanently removed.</p>
            </div>
            <div className="mb-4">
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        checked={confirm}
                        onChange={(e) => setConfirm(e.target.checked)}
                        className="mr-2"
                    />
                    I understand that deleting my account is permanent.
                </label>
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className={'flex space-x-4'}>
                <button
                    onClick={handleDeleteAccount}
                    disabled={!confirm || loading}
                    className={`bg-red-600 text-white px-4 py-2 rounded ${(!confirm || loading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {loading ? 'Deleting...' : 'Yes, Delete My Account'}
                </button>
                <Button
                    onClick={handleGoBack}
                    color={'gray'}
                >
                    No, Return To My Account
                </Button>
            </div>
        </div>
    );
}

export default ForgetMeLink;
