import React from 'react';

const ListBarSuggestions = ({ suggestions, handleSuggestion }) => {
    if (!suggestions.length) return null;

    return (
        <div className="bg-white shadow rounded-lg p-3 mb-2">
            {suggestions.map((suggestion, index) => (
                <div
                    key={index}
                    className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                    onClick={() => handleSuggestion(suggestion)}
                >
                    {suggestion.text}
                </div>
            ))}
        </div>
    );
};

export default ListBarSuggestions;
