import React, {useContext, useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {Button, Card} from 'flowbite-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import DarkModeContext from "../../context/DarkModeContext";
import MarkdownRenderer from "../../components/common/MarkdownRenderer";

function NewsArticlePage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const { darkMode } = useContext(DarkModeContext);

    const fetchArticle = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/${id}`);
            setArticle(response.data);
        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    const thumbsUp = async () => {
        try {

        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    const thumpsDown = async () => {
        try {

        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        fetchArticle();
    }, [id]);

    if (!article) {
        return <p>Loading article...</p>;
    }

    return (
        <div className="p-6 pb-8 mx-auto max-w-7xl">
            <h1 className="text-4xl font-bold mb-1 text-center">{article.name}</h1>
            <p className="text-lg mb-1 text-center">{article.description}</p>
            <p className="text-gray-500 mb-6 text-center">{formatDate(article.createdAt)}</p>

            {article.segments.map((segment, index) => (
                <div key={index} className="mx-auto max-w-4xl mb-8">  {/* Constrain Card max-width*/}
                    <Card theme={cardTheme}>
                        <p className="text-2xl font-semibold dark:text-gray-300">{segment.title}</p>
                        {segment.by ? <p className="text-sm text-gray-500 mb-6">By: {segment.by}</p> :
                            <div className={"mb-4"}></div>}
                        <div className="content markdown ">
                            <MarkdownRenderer darkMode={darkMode} content={segment.content}/>
                        </div>
                        {/*<div className="flex mt-4">*/}
                        {/*    <Button size="xs" className="mr-2">👍 {segment.engagement.likes}</Button>*/}
                        {/*    <Button size="xs">👎 {segment.engagement.dislikes}</Button>*/}
                        {/*</div>*/}
                    </Card>
                </div>
            ))}
        </div>
    );
}

export default NewsArticlePage;

const cardTheme = {
    "root": {
        "base": "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-900",
        "children": "flex h-full flex-col justify-center gap-1 p-4",
        "horizontal": {
            "off": "flex-col",
            "on": "flex-col md:max-w-xl md:flex-row"
        },
        "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
    },
    "img": {
        "base": "",
        "horizontal": {
            "off": "rounded-t-lg",
            "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        }
    }
}