import React from "react";
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import {ItemTypes} from "./ItemTypes";

// Group Drop Zone Component
export const GroupDropZone = React.memo(({ group, tileImages, onTileDrop }) => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ItemTypes.TILE,
        drop: (item) => {
            onTileDrop(group._id, item.tileIndices); // Now tileIndices is an array
        },
        canDrop: (item) => group.tileIndices.length + (item?.tileIndices?.length || 1) <= 9,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }), [group._id, group.tileIndices.length, onTileDrop]);

    return (
        <div
            ref={drop}
            className={`relative w-28 h-28 border rounded cursor-pointer flex flex-wrap items-center justify-center 
                ${isOver && canDrop ? 'bg-blue-100 dark:bg-blue-950' : 'bg-gray-100 dark:bg-gray-900'}`}
        >
            {group.tileIndices.map((tileIndex, idx) => (
                <img
                    key={idx}
                    src={tileImages[tileIndex]}
                    alt={`Tile ${tileIndex}`}
                    className="w-8 h-8 object-cover rounded m-0.5"
                />
            ))}
            {group.tileIndices.length < 9 && (
                <div className="w-8 h-8 flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded m-0.5 cursor-pointer">
                    +
                </div>
            )}
            {isOver && canDrop && (
                <div className="absolute inset-0 bg-blue-200 opacity-50 rounded"></div>
            )}
        </div>
    );
});

GroupDropZone.propTypes = {
    group: PropTypes.object.isRequired,
    tileImages: PropTypes.array.isRequired,
    onTileDrop: PropTypes.func.isRequired,
};