// src/components/StoryCard.js

import React from 'react';
import PropTypes from 'prop-types';

const StoryCard = ({ story, onClick }) => {
    const imageUrl = `${process.env.REACT_APP_API_URL}/image/attached/${story._id}?d=2`;

    // Placeholder values. Replace with actual data as needed.
    const averageRating = story.averageRating || 'N/A';
    const totalAdventures = story.totalAdventures || 0;
    const authorName = story.author?.alias || story.author?.name || 'Anonymous';

    return (
        <div
            className="bg-white dark:bg-gray-900 rounded-lg shadow-md min-w-60 cursor-pointer hover:shadow-2xl transition-shadow duration-300 flex flex-col"
            onClick={onClick}
        >
            <img
                src={imageUrl}
                alt={story.name}
                className="w-full h-40 object-cover rounded-t-lg"
            />
            <div className="p-4 flex flex-col flex-grow">
                <h3 className="text-lg font-semibold mb-1 truncate text-gray-800 dark:text-gray-400">{story.name}</h3>
                <p className="text-sm text-gray-600">Rating: {averageRating}</p>
                <p className="text-sm text-gray-600">Adventures: {totalAdventures}</p>
                <p className="text-sm text-gray-600">Author: {authorName}</p>
                {/*<div className="flex flex-wrap mt-2">*/}
                {/*    {story.tags.map((tag) => (*/}
                {/*        <span*/}
                {/*            key={tag}*/}
                {/*            className="text-xs bg-gray-200 rounded-full px-2 py-1 mr-2 mb-2"*/}
                {/*        >*/}
                {/*            {tag}*/}
                {/*        </span>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

StoryCard.propTypes = {
    story: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default StoryCard;
