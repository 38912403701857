// Example: Conditionally include Step6StoryArcs based on a previous selection
import Step_Format from "./steps/Step_Format";
import Step_Genre from "./steps/Step_Genre";
import Step_Themes from "./steps/Step_Themes";
import Step_Location from "./steps/Step_Location";
import Step_Review from "./steps/Step_Review";
import Step_Conflict from "./steps/Step_Conflict";
import Step_Incident from "./steps/Step_Incident";
import Step_Tone from "./steps/Step_Tone";
import Step_Symbolism from "./steps/Step_Symbolism";
import Step_Obstacle from "./steps/Step_Obstacle";

export const StepsConfig = [
    { id: 1, component: Step_Format, title: 'Adventure Format' },
    { id: 2, component: Step_Genre, title: 'Story Genre' },
    { id: 3, component: Step_Themes, title: 'Story Themes' },
    { id: 4, component: Step_Conflict, title: 'Central Conflict' },
    // { id: 4, component: Step_Conflict, title: 'Protagonist' },
    { id: 5, component: Step_Incident, title: 'Inciting Incident' },
    // { id: 6, component: Step_Obstacle, title: 'Obstacles' },
    // { id: 4, component: Step_Conflict, title: 'Antagonist' },
    { id: 6, component: Step_Tone, title: 'Narrative Tone' },
    { id: 7, component: Step_Symbolism, title: 'Symbolism' },
    { id: 8, component: Step_Location, title: 'Starting Location' },
    { id: 9, component: Step_Review, title: 'Review & Generate' },
];

export default StepsConfig;