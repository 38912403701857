import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google"
import {SessionProvider} from "./context/SessionContext";
import {DarkModeProvider} from "./context/DarkModeContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId='334919657600-357mnhmn58mm67oeub94meghcmilh3rm.apps.googleusercontent.com'>
        <React.StrictMode>
            <SessionProvider>
                <DarkModeProvider>
                    <App />
                </DarkModeProvider>
            </SessionProvider>
        </React.StrictMode>
    </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
