// src/components/CustomShelf.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'flowbite-react';
import StoryCard from './StoryCard';
import StoryDetailModal from './StoryDetailModal';
import SearchBar from '../common/SearchBar';
import ScrollableRow from '../common/ScrollableRow'; // Ensure the path is correct
import PropTypes from 'prop-types';

const CustomShelf = ({
                         worldId = null,
                         expandedView = false,
                         session,
                         includePublic = false,
                         autoSearch = false,
                     }) => {
    const [customStories, setCustomStories] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        genre: '',
        theme: '',
        tag: '',
        sortBy: '',
        sortOrder: 'asc',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // Error state

    // Fetch all stories on component mount
    useEffect(() => {
        if (autoSearch) handleSearch(worldId);
    }, [session, autoSearch]);

    const handleSearch = async (query = '') => {
        try {
            setLoading(true);
            setError(null); // Reset error state
            let response = {};

            if (includePublic) {
                if (session) {
                    const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/story`, {
                        params: query ? { query } : {},
                    });

                    const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/story`, {
                        params: query ? { query } : {},
                        headers: {
                            Authorization: `Bearer ${session.token}`,
                        },
                    });

                    const [response1, response2] = await Promise.all([promise1, promise2]);

                    const combinedData = [...response1.data, ...response2.data];

                    const storiesMap = new Map();

                    combinedData.forEach((item) => {
                        const existingStory = storiesMap.get(item.story._id);

                        if (existingStory) {
                            storiesMap.set(item.story._id, { ...existingStory, ...item });
                        } else {
                            storiesMap.set(item.story._id, item);
                        }
                    });

                    response.data = Array.from(storiesMap.values());
                } else {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/public/story`, {
                        params: query ? { query } : {},
                    });
                }
            } else if (session) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/entitlement/story`, {
                    params: query ? { query } : {},
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });
            }

            setCustomStories(response.data || []);
        } catch (error) {
            console.error('Error fetching stories:', error);
            setError('Failed to load stories. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFiltersAndSort = (stories) => {
        let filtered = [...stories];

        // Apply genre filter
        if (filters.genre) {
            filtered = filtered.filter((story) => story?.story?.genre?.includes(filters.genre));
        }

        // Apply theme filter
        if (filters.theme) {
            filtered = filtered.filter((story) => story?.story?.themes?.includes(filters.theme));
        }

        // Apply tag filter
        if (filters.tag) {
            filtered = filtered.filter((story) => story?.story?.tags?.includes(filters.tag));
        }

        // Apply sorting
        if (filters.sortBy) {
            filtered.sort((a, b) => {
                if (!a.story || !b.story) {
                    console.error("Missing 'story' property in one of objects!");
                    return 0;
                }
                const fieldA = a.story[filters.sortBy];
                const fieldB = b.story[filters.sortBy];
                if (fieldA === undefined || fieldB === undefined) {
                    console.error(`Missing '${filters.sortBy}' property in 'story' object!`);
                    return 0;
                }
                if (typeof fieldA === 'string') {
                    if (filters.sortOrder === 'asc') {
                        return fieldA.localeCompare(fieldB);
                    } else {
                        return fieldB.localeCompare(fieldA);
                    }
                } else if (typeof fieldA === 'number' || fieldA instanceof Date) {
                    if (filters.sortOrder === 'asc') {
                        return new Date(fieldA) - new Date(fieldB);
                    } else {
                        return new Date(fieldB) - new Date(fieldA);
                    }
                } else {
                    return 0;
                }
            });
        }

        return filtered;
    };

    const handleStoryClick = (story) => {
        setSelectedStory(story);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedStory(null);
    };

    // Extract unique genres, themes, and tags for filter options
    const genres = Array.from(
        new Set(customStories.map((story) => story.story.genre))
    ).filter(Boolean);
    const themes = Array.from(
        new Set(customStories.flatMap((story) => story.story.themes))
    ).filter(Boolean);
    const tags = Array.from(
        new Set(customStories.flatMap((story) => story.story.tags))
    ).filter(Boolean);

    const sortedAndFilteredStories = applyFiltersAndSort(customStories);

    return (
        <div className="mb-8">
            {/* Search Bar */}
            <SearchBar onSearch={handleSearch} placeholder={"Search stories..."} allowEmpty={autoSearch} />

            {/* Filters */}
            {customStories?.length > 0 && (
                <div className="flex flex-wrap gap-4 mb-4">
                    <Select
                        name="genre"
                        value={filters.genre}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">All Genres</option>
                        {genres.map((genre) => (
                            <option key={genre} value={genre}>
                                {genre}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="theme"
                        value={filters.theme}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">All Themes</option>
                        {themes.map((theme) => (
                            <option key={theme} value={theme}>
                                {theme}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="tag"
                        value={filters.tag}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">All Tags</option>
                        {tags.map((tag) => (
                            <option key={tag} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="sortBy"
                        value={filters.sortBy}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">Sort By</option>
                        <option value="createdAt">Created At</option>
                        <option value="updatedAt">Updated At</option>
                        <option value="averageRating">Average Rating</option>
                        <option value="popularity">Popularity</option>
                        {/* Add more sorting options as needed */}
                    </Select>

                    <Select
                        name="sortOrder"
                        value={filters.sortOrder}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </Select>
                </div>
            )}

            {/* Loading Indicator */}
            {loading && <p>Loading...</p>}

            {/* Error Message */}
            {error && <p className="text-red-500">{error}</p>}

            {/* Custom Shelf Display */}
            {!loading && sortedAndFilteredStories.length > 0 ? (
                expandedView ? (
                    // Expanded Grid View
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">
                        {sortedAndFilteredStories.map((storyEntitlement) => (
                            <StoryCard
                                key={storyEntitlement._id}
                                story={storyEntitlement.story}
                                onClick={() => handleStoryClick(storyEntitlement)}
                            />
                        ))}
                    </div>
                ) : (
                    // Single Scrollable Row with Arrow Buttons
                    <ScrollableRow className="py-2">
                        {sortedAndFilteredStories.map((storyEntitlement) => (
                            <StoryCard
                                key={storyEntitlement._id}
                                story={storyEntitlement.story}
                                onClick={() => handleStoryClick(storyEntitlement)}
                            />
                        ))}
                    </ScrollableRow>
                )
            ) : (
                !loading &&
                autoSearch && <p>No stories found.</p>
            )}

            {/* Story Modal */}
            {selectedStory && (
                <StoryDetailModal
                    story={selectedStory.story}
                    isOpen={isModalOpen}
                    session={session}
                    grantLevel={selectedStory.grantLevel}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );

};

CustomShelf.propTypes = {
    worldId: PropTypes.string,
    expandedView: PropTypes.bool, // If true, display in expanded grid view; otherwise, single scrollable row
    session: PropTypes.object, // Session object
    includePublic: PropTypes.bool, // Include public stories
    autoSearch: PropTypes.bool, // Automatically perform search on mount
};

CustomShelf.defaultProps = {
    expandedView: false,
    includePublic: false,
    autoSearch: false,
};

export default CustomShelf;
