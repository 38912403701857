// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {predefinedFormats, predefinedLocations, predefinedStoryTypes} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_StartLocation = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select a Story Type (Genre)"
            subTitle="(Pick One or Two)"
            predefinedList={predefinedStoryTypes}
            formDataField="genres" // Ensure this matches the key in formData
            selectionLimit={2} // Example: limit to 3 selections, set to null for no limit
            minSelection={1}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_StartLocation;
