// src/components/LocationFormModal.jsx
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Label, Modal, TextInput, Textarea, Button} from 'flowbite-react';
import PropertiesManager from "../../common/PropertiesManager";
import PropTypes from "prop-types";
import {TextAreaTheme, TextAreaThemeReadOnly} from "../../../themes/TextAreaTheme";
import {ModalTheme} from "../../../themes/ModalTheme";
import {ButtonTheme} from "../../../themes/ButtonTheme";
import {LabelTheme} from "../../../themes/LabelTheme";
import AddEditImage from "../../common/AddEditImage";
import {GiQuillInk} from "react-icons/gi";
import { GrEdit } from "react-icons/gr";
import { FaMapMarkerAlt } from "react-icons/fa";
import {TextInputTheme, TextInputThemeReadOnly} from "../../../themes/TextInputTheme";

const LocationFormModal = ({ isOpen, onClose, locationToEdit, story, onSuccess, viewOnly }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [labels, setLabels] = useState(locationToEdit ? locationToEdit.labels : []);
    const [properties, setProperties] = useState(locationToEdit ? locationToEdit.properties : []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const addEditImageRef = useRef();

    useEffect(() => {
        if (locationToEdit) {
            setName(locationToEdit.name);
            setDescription(locationToEdit.description);
            setProperties(locationToEdit.properties || []);
            setLabels(locationToEdit.labels || []);
        } else {
            setName('');
            setDescription('');
            setProperties([]);
            setLabels([]);
        }
        setIsViewOnly(viewOnly);
    }, [isOpen, locationToEdit, viewOnly]);

    const handleImageSave = async () => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving Image:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            await handleImageSave();
            const data = {
                name,
                description,
                labels,
                properties,
            };
            if (locationToEdit) {
                // Update existing location
                await axios.put(`${process.env.REACT_APP_API_URL}/location/${locationToEdit._id}`, data);
            } else {
                // Create new location
                await axios.post(`${process.env.REACT_APP_API_URL}/location`, {
                    ...data,
                    story: story._id,
                });
            }
            onSuccess();
            onClose();
        } catch (err) {
            setError('Failed to save location.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsViewOnly(false);
    };

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size="4xl">
            <Modal.Header>
                {isViewOnly ? (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <FaMapMarkerAlt/>
                        <span>View Location</span>
                        <GrEdit
                            className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110"
                            onClick={handleEdit}
                            title="Edit Location"
                            aria-label="Edit Location"
                        />
                    </div>
                ) : (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <FaMapMarkerAlt/>
                        <span>{locationToEdit ? 'Edit Location' : 'Add Location'}</span>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body>
                {error && <Label theme={LabelTheme} color={'failure'} value={error}/>}
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="mb-4 space-y-1">
                            <Label theme={LabelTheme} htmlFor="name" value="Name"/>
                            <TextInput
                                id="name"
                                type="text"
                                placeholder="Name"
                                theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={isViewOnly}
                            />
                        </div>
                        <div className="mb-4 space-y-1">
                            <Label theme={LabelTheme} htmlFor="description" value="Description"/>
                            <Textarea
                                id="description"
                                placeholder="Description"
                                required
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows={4}
                                className="dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:border-blue-500 focus:ring-blue-500"
                                disabled={isViewOnly}
                            />
                        </div>
                        <div className="mb-4 space-y-1">
                            <Label theme={LabelTheme} htmlFor="image" value="Image"/>
                            <AddEditImage
                                parentData={locationToEdit}
                                placeholder={6}
                                ref={addEditImageRef}
                                disabled={isViewOnly}
                            />
                        </div>
                        {isViewOnly && (
                            <div className="mt-4">
                                <h3 className="text-lg font-semibold">Linked Documents</h3>
                                <div className="mt-2">
                                    <p><strong>Maps:</strong> {/* Render associated maps */}</p>
                                    <p><strong>Items:</strong> {/* Render associated items */}</p>
                                    <p><strong>Lore:</strong> {/* Render associated lore */}</p>
                                    <p><strong>Characters:</strong> {/* Render associated plot elements */}</p>
                                    <p><strong>Plot Elements:</strong> {/* Render associated plot elements */}</p>
                                </div>
                            </div>
                        )}
                        <div className="mb-4 space-y-1">
                            <Label theme={LabelTheme} className="block mb-2">Properties</Label>
                            <PropertiesManager
                                worldId={story.world._id}
                                labels={labels}
                                objectProperties={properties}
                                setObjectProperties={setProperties}
                                disabled={isViewOnly}
                            />
                        </div>
                    </div>
                </form>
            </Modal.Body>
            {!isViewOnly && (
                <Modal.Footer>
                    <div className="flex justify-between w-full">
                        <Button
                            theme={ButtonTheme}
                            color="blue"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : locationToEdit ? 'Save' : 'Add'}
                            {!locationToEdit && <><GiQuillInk size={16} className="ml-1 translate-x-1" />1</>}
                        </Button>
                        {locationToEdit && <Button
                            theme={ButtonTheme}
                            color={"red"}
                            disabled={loading}
                        >
                            Delete
                        </Button>}
                        <Button
                            theme={ButtonTheme}
                            onClick={() => {
                                if (locationToEdit === null) onClose();
                                else setIsViewOnly(true);
                            }}
                            color={"gray"}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            )}
            {isViewOnly && (
                <Modal.Footer>
                    <div className="flex justify-between w-full">
                        {locationToEdit && <Button
                            theme={ButtonTheme}
                            color={"red"}
                            disabled={loading}
                        >
                            Delete
                        </Button>}
                        <Button
                            theme={ButtonTheme}
                            onClick={onClose}
                            color={"gray"}
                            disabled={loading}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            )}
        </Modal>
    );
};

LocationFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    locationToEdit: PropTypes.object,
    story: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool,
};

LocationFormModal.defaultProps = {
    viewOnly: false,
};

export default LocationFormModal;