// src/components/NewStory/CreateNewForm.js
import React, {useEffect, useRef, useState} from 'react';
import { Button, Label, Select, TextInput, Textarea } from 'flowbite-react';
import TagsInput from '../../components/common/TagsInput';
import {TextAreaTheme} from "../../themes/TextAreaTheme";
import {GiQuillInk} from "react-icons/gi";
import axios from "axios";
import {useSession} from "../../context/SessionContext";
import {useNavigate} from "react-router-dom";

function StoryNew() {
    const { session } = useSession();
    const navigate = useNavigate();
    const initialState = {
        name: '',
        description: '',
        tags: [],
        world: '',
        premise: '',
        genre: [],
        themes: [],
        introduction: ''
    };
    const [newStory, setNewStory] = useState(initialState);
    const addEditImageRef = useRef();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [entitledWorlds, setEntitledWorlds] = useState(null);

    // Fetch worlds the user is entitled to inherit from
    useEffect(() => {
        async function fetchEntitledWorlds() {
            let response = {};
            try {
                const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/world`, {
                });
                const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world`, {
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });
                const [response1, response2] = await Promise.all([promise1, promise2]);
                const combinedData = [...response1.data, ...response2.data];
                const storiesMap = new Map();
                combinedData.forEach((item) => {
                    const existingStory = storiesMap.get(item.world._id);

                    if (existingStory) {
                        storiesMap.set(item.world._id, { ...existingStory, ...item });
                    } else {
                        storiesMap.set(item.world._id, item);
                    }
                });
                response.data = Array.from(storiesMap.values());
                setEntitledWorlds(response.data);
                initialState.world = response.data[0].world._id;
                setNewStory(initialState);
            } catch (error) {
                console.error('Failed to fetch entitled worlds', error);
            }
        }
        fetchEntitledWorlds();

    }, []);

    if (!entitledWorlds) {
        return <div className={"p-6"}>Loading worlds...</div>;
    }

    if (entitledWorlds && entitledWorlds.length === 0) {
        return <div className={"p-6 text-red-500"}>Creating a new story requires access to at least one world!</div>;
    }

    const handleImageSave = async () => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving:', err);
            //setError('Failed to save changes.');
        }
    };

    const handleChange = (field, value) => {
        setNewStory((prev) => ({ ...prev, [field]: value }));
    };

    // Handle story submission
    const handleSubmit = async () => {
        setIsProcessing(true);
        await handleImageSave();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/story`, newStory);
        setIsProcessing(false);
        navigate(`/story/${response.data._id}`);
    };

    return (
        <div className="p-6 space-y-4">
            <div>
                <Label htmlFor="name" value="Title" />
                <TextInput
                    id="name"
                    placeholder="Story title"
                    value={newStory.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    required
                />
            </div>
            {/*<div>*/}
            {/*    <Label htmlFor="description" value="Describe the story you want" />*/}
            {/*    <Textarea*/}
            {/*        id="description"*/}
            {/*        placeholder="Story description"*/}
            {/*        theme={TextAreaTheme}*/}
            {/*        value={newStory.description}*/}
            {/*        onChange={(e) => handleChange('description', e.target.value)}*/}
            {/*        required*/}
            {/*        rows={4}*/}
            {/*    />*/}
            {/*</div>*/}
            <div>
                <Label htmlFor="premise" value="Premise" />
                <Textarea
                    id="premise"
                    placeholder="Story premise"
                    theme={TextAreaTheme}
                    value={newStory.premise}
                    onChange={(e) => handleChange('premise', e.target.value)}
                    required
                    rows={4}
                />
            </div>
            <div>
                <Label htmlFor="introduction" value="Introduction" />
                <Textarea
                    id="introduction"
                    placeholder="Introduction to start the story"
                    theme={TextAreaTheme}
                    value={newStory.introduction}
                    onChange={(e) => handleChange('introduction', e.target.value)}
                    required
                    rows={4}
                />
            </div>
            <div>
                <Label htmlFor="genre" value="Genre" />
                <TagsInput
                    id="genre"
                    placeholder="Story genre"
                    tags={newStory.genre}
                    onChange={(tags) => handleChange('genre', tags)}
                    required
                />
            </div>
            {/*<div>*/}
            {/*    <Label htmlFor="tags" value="Tags" />*/}
            {/*    <TagsInput*/}
            {/*        tags={newStory.tags}*/}
            {/*        onChange={(tags) => handleChange('tags', tags)}*/}
            {/*        placeholder="Add tags"*/}
            {/*    />*/}
            {/*</div>*/}
            <div >
                <Label htmlFor="themes" value="Themes" />
                <TagsInput
                    tags={newStory.themes}
                    onChange={(tags) => handleChange('themes', tags)}
                    placeholder="Add themes"
                />
            </div>

                <div>
                    <Label htmlFor="world" value="World" />
                    <Select
                        id="world"
                        value={newStory.world}
                        onChange={(e) => handleChange('world', e.target.value)}
                        required
                    >
                        {entitledWorlds.map((entitlement, index) => (
                            <option key={index} value={entitlement.world._id}>
                                {entitlement.world.name}
                            </option>
                        ))}
                    </Select>
                </div>

            <div className="w-full">
                <Button
                    onClick={handleSubmit}
                    disabled={isProcessing} // Disable button while processing
                    className="flex items-center justify-center space-x-2"
                >
                    {isProcessing ? (
                        <>
                            <svg
                                aria-hidden="true"
                                className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5918C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5918C0 22.9785 22.3858 0.591797 50 0.591797C77.6142 0.591797 100 22.9785 100 50.5918ZM9.08164 50.5918C9.08164 74.62 27.5618 93.1003 51.59 93.1003C75.6182 93.1003 94.0984 74.62 94.0984 50.5918C94.0984 26.5636 75.6182 8.08337 51.59 8.08337C27.5618 8.08337 9.08164 26.5636 9.08164 50.5918Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 96.8946 33.5533C95.1184 29.2475 92.8619 25.2956 89.5156 21.9493C85.1192 17.5529 79.4858 14.816 73.3133 13.1722C67.1408 11.5285 60.676 11.0711 54.4114 11.8499C51.9793 12.1848 50.4714 14.6233 51.1085 17.0487C51.7456 19.474 54.1879 20.9073 56.621 20.5724C61.3495 19.9503 66.1517 20.2727 70.7538 21.5158C75.3559 22.7589 79.6393 24.8935 83.2636 27.7871C86.8878 30.6808 89.7449 34.2607 91.6542 38.239C92.6209 40.5974 95.0423 41.6781 97.4677 41.0409Z"
                                    fill="currentFill"
                                />
                            </svg>
                            <span>Creating...</span>
                        </>
                    ) : (
                        <>
                            Create New Story
                            <GiQuillInk size={16} className={"ml-1 translate-x-1"} />
                        </>
                    )}
                </Button>
            </div>
        </div>
    );
}

export default StoryNew;
