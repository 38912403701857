// src/components/edit/TilesetFormModal.js

import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    TextInput,
    Label,
    Modal,
    Alert,
    Spinner,
    ModalFooter,
    Checkbox
} from "flowbite-react";
import { useForm } from 'react-hook-form';
import axios from "axios";
import PropTypes from 'prop-types';
import { ModalTheme } from "../../../themes/ModalTheme";
import { FiPlus, FiTrash2, FiUpload } from "react-icons/fi";
import ObjectId from 'bson-objectid';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ItemTypes } from "./utils/ItemTypes";
import { DraggableTile } from "./utils/DraggableTile";
import { DraggableGroup } from "./utils/DraggableGroup";
import { GroupDropZone } from "./utils/GroupDropZone";
import { RuleSelectionModal } from "./utils/RuleSelectionModal";
import { DropCell } from "./utils/DropCell";
import { GiQuillInk } from "react-icons/gi";
import { GroupSelectionModal } from "./utils/GroupSelectionModal"; // Import the new modal

// Main Component
function TilesetFormModal({ tilesetData = null, onSubmit, world }) {
    // State Management
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(null);
    const [tileGroups, setTileGroups] = useState(tilesetData ? tilesetData.tileGroups : []);
    const [currentGroup, setCurrentGroup] = useState({ name: '', probability: 1, isMain: false });
    const [edgeRules, setEdgeRules] = useState(tilesetData ? tilesetData.edgeRules : []);
    const [currentEdgeRule, setCurrentEdgeRule] = useState({ _id: ObjectId().toString(), name: '', grid: Array(9).fill(null) });
    const [tileImages, setTileImages] = useState([]); // Array of data URLs
    const [tilesheetLoaded, setTilesheetLoaded] = useState(false);
    const [tilesheetWidth, setTilesheetWidth] = useState(1024);
    const [uploading, setUploading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [selectedTiles, setSelectedTiles] = useState(new Set());
    const [selectionModal, setSelectionModal] = useState({ show: false, ruleId: null, index: null });
    const [groupSelectionModal, setGroupSelectionModal] = useState({ show: false, group: null }); // New state for group selection

    // Form Handling
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: tilesetData ? tilesetData.name : '',
            imageSrc: tilesetData ? tilesetData.imageSrc : '',
            tileSize: tilesetData ? tilesetData.tileSize : 16,
        },
    });

    // Load Tile Images
    const loadTileImages = useCallback(async (tileset) => {
        if (!tileset || !tileset.imageSrc || !tileset.tileSize) {
            setTileImages([]);
            setTilesheetLoaded(false);
            return;
        }
        try {
            const response = await axios.get(tileset.imageSrc, { responseType: 'blob' });
            const imageURL = URL.createObjectURL(response.data);
            const image = new Image();
            image.src = imageURL;
            image.crossOrigin = 'Anonymous'; // Ensure CORS is handled

            image.onload = () => {
                const tiles = [];
                const tileDataURLs = [];
                const columns = Math.floor(image.width / tileset.tileSize);
                const rows = Math.floor(image.height / tileset.tileSize);
                setTilesheetWidth(image.width);
                for (let y = 0; y < rows; y++) {
                    for (let x = 0; x < columns; x++) {
                        const canvas = document.createElement('canvas');
                        canvas.width = tileset.tileSize;
                        canvas.height = tileset.tileSize;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(
                            image,
                            x * tileset.tileSize,
                            y * tileset.tileSize,
                            tileset.tileSize,
                            tileset.tileSize,
                            0,
                            0,
                            tileset.tileSize,
                            tileset.tileSize
                        );
                        tiles.push(canvas);
                        tileDataURLs.push(canvas.toDataURL());
                    }
                }
                tileset.tiles = tiles;
                setTileImages(tileDataURLs);
                setTilesheetLoaded(true);
            };

            image.onerror = () => {
                console.error('Failed to load the tilesheet image.');
                setError('Failed to load the tilesheet image. Please check the URL and ensure CORS is properly configured.');
                setTileImages([]);
                setTilesheetLoaded(false);
            };
        } catch (err) {
            console.error('Error fetching the tilesheet image:', err);
            setError('Failed to fetch the tilesheet image. Please ensure the URL is correct and accessible.');
            setTileImages([]);
            setTilesheetLoaded(false);
        }
    }, []);

    // Watchers
    const imageSrc = watch('imageSrc');
    const tileSize = watch('tileSize');

    // Initialize Modal
    useEffect(() => {
        if (isOpen) {
            reset({
                name: tilesetData ? tilesetData.name : '',
                imageSrc: tilesetData ? tilesetData.imageSrc : '',
                tileSize: tilesetData ? tilesetData.tileSize : 16,
            });
            setError(null);
            setTileGroups(tilesetData ? tilesetData.tileGroups : []);
            setEdgeRules(tilesetData ? tilesetData.edgeRules : []);
            setCurrentGroup({ name: '', probability: 1, isMain: false });
            setCurrentEdgeRule({ _id: ObjectId().toString(), name: '', grid: Array(9).fill(null) });
            setTileImages([]);
            setTilesheetLoaded(false);
            setUploadedImage(null);
            setSelectedTiles(new Set());
            setSelectionModal({ show: false, ruleId: null, index: null });
            setGroupSelectionModal({ show: false, group: null });
            if (tilesetData && tilesetData.imageSrc && tilesetData.tileSize) {
                loadTileImages(tilesetData);
            }
        }
    }, [isOpen, tilesetData, reset, loadTileImages]);

    // Load images when imageSrc or tileSize changes
    useEffect(() => {
        if (isOpen) {
            if (imageSrc && tileSize >= 8) {
                loadTileImages({ imageSrc, tileSize: parseInt(tileSize) });
            } else {
                setTileImages([]);
                setTilesheetLoaded(false);
            }
        }
    }, [imageSrc, tileSize, isOpen, loadTileImages]);

    // Close Modal
    const onCloseModal = useCallback(() => {
        setIsOpen(false);
        reset();
        setError(null);
        setTileGroups([]);
        setEdgeRules([]);
        setTileImages([]);
        setTilesheetLoaded(false);
        setUploadedImage(null);
        setSelectedTiles(new Set());
        setSelectionModal({ show: false, ruleId: null, index: null });
        setGroupSelectionModal({ show: false, group: null });
    }, [reset]);

    // Add Tile Group
    const addTileGroup = useCallback(() => {
        const { name, probability } = currentGroup;
        if (!name.trim()) {
            alert('Group Name is required.');
            return;
        }

        const newGroup = {
            _id: ObjectId().toString(),
            name: name.trim(),
            tileIndices: [],
            probabilities: [],
            isMain: currentGroup.isMain,
            inPalette: false, // Initialize inPalette
        };

        setTileGroups(prevGroups => [...prevGroups, newGroup]);
        setCurrentGroup({ name: '', probability: 1, isMain: false });
    }, [currentGroup]);

    // Remove Tile Group
    const removeTileGroup = useCallback((id) => {
        setTileGroups(prevGroups => prevGroups.filter(group => group._id !== id));

        // Remove group references from edge rules
        setEdgeRules(prevRules => prevRules.map(rule => ({
            ...rule,
            grid: rule.grid.map(cell => (cell?.groupId === id ? null : cell)),
        })));
    }, []);

    const toggleGroupInPalette = useCallback((id, isChecked) => {
        setTileGroups(prevGroups => prevGroups.map(group => {
            if(group._id === id) {
                return { ...group, inPalette: isChecked };
            }
            return group;
        }));
    }, []);

    // Add Edge Rule
    const addEdgeRule = useCallback(() => {
        const { name } = currentEdgeRule;
        if (!name.trim()) {
            alert('Edge Rule Name is required.');
            return;
        }

        const newEdgeRule = {
            _id: ObjectId().toString(),
            name: name.trim(),
            grid: Array(9).fill(null),
            inPalette: true, // Initialize inPalette
        };

        setEdgeRules(prevRules => [...prevRules, newEdgeRule]);
        setCurrentEdgeRule({ _id: ObjectId().toString(), name: '', grid: Array(9).fill(null) });
    }, [currentEdgeRule]);

    // Remove Edge Rule
    const removeEdgeRule = useCallback((id) => {
        setEdgeRules(prevRules => prevRules.filter(rule => rule._id !== id));
    }, []);

    // Toggle inPalette for Edge Rule
    const toggleRuleInPalette = useCallback((id) => {
        setEdgeRules(prevRules => prevRules.map(rule => {
            if (rule._id === id) {
                return { ...rule, inPalette: !rule.inPalette };
            }
            return rule;
        }));
    }, []);

    // Handle Drop into Grid Cell
    const handleDrop = useCallback((ruleId, index, item, isClick = false) => {
        if (isClick) {
            // Open Selection Modal
            setSelectionModal({ show: true, ruleId, index });
            return;
        }

        if (item.type === ItemTypes.GROUP) {
            const selectedGroup = tileGroups.find(g => g._id === item.groupId);
            if (selectedGroup) {
                setEdgeRules(prevRules => prevRules.map(rule => {
                    if (rule._id === ruleId) {
                        const newGrid = [...rule.grid];
                        newGrid[index] = {
                            type: 'group',
                            groupId: selectedGroup._id,
                            label: selectedGroup.name,
                            imageSrc: tileImages[selectedGroup.tileIndices[0]] || '',
                        };
                        return { ...rule, grid: newGrid };
                    }
                    return rule;
                }));
            } else {
                alert('Group not found.');
            }
        } else if (item.type === ItemTypes.TILE) {
            const tileIndices = item.tileIndices; // Array of tile indices
            if (Array.isArray(tileIndices)) {
                setEdgeRules(prevRules => prevRules.map(rule => {
                    if (rule._id === ruleId) {
                        const newGrid = [...rule.grid];
                        newGrid[index] = tileIndices.map(tileIndex => ({
                            type: 'tile',
                            tileIndex,
                            imgSrc: tileImages[tileIndex],
                        }));
                        return { ...rule, grid: newGrid };
                    }
                    return rule;
                }));
            } else {
                alert('Invalid tile indices.');
            }
        }
    }, [tileGroups, tileImages]);

    // Handle Clear Grid Cell
    const handleClear = useCallback((ruleId, index) => {
        setEdgeRules(prevRules => prevRules.map(rule => {
            if (rule._id === ruleId) {
                const newGrid = [...rule.grid];
                newGrid[index] = null;
                return { ...rule, grid: newGrid };
            }
            return rule;
        }));
    }, []);

    // Handle Tile Drop into Group
    const handleTileDropToGroup = useCallback((groupId, tileIndices) => {
        setTileGroups(prevGroups => prevGroups.map(group => {
            if (group._id === groupId) {
                const newTileIndices = [...group.tileIndices];
                tileIndices.forEach(tileIndex => {
                    if (!newTileIndices.includes(tileIndex) && newTileIndices.length < 9) {
                        newTileIndices.push(tileIndex);
                    }
                });
                return { ...group, tileIndices: newTileIndices };
            }
            return group;
        }));
    }, []);

    // Handle Selection from Rule Selection Modal
    const handleRuleSelection = useCallback((type, selection) => {
        const { ruleId, index } = selectionModal;
        if (type === 'group') {
            const selectedGroup = tileGroups.find(g => g._id === selection);
            if (selectedGroup) {
                setEdgeRules(prevRules => prevRules.map(rule => {
                    if (rule._id === ruleId) {
                        const newGrid = [...rule.grid];
                        newGrid[index] = {
                            type: 'group',
                            groupId: selectedGroup._id,
                            label: selectedGroup.name,
                            imageSrc: tileImages[selectedGroup.tileIndices[0]] || '',
                        };
                        return { ...rule, grid: newGrid };
                    }
                    return rule;
                }));
            }
        } else if (type === 'tile') {
            const tileIndex = selection;
            if (!isNaN(tileIndex) && tileIndex >= 0 && tileIndex < tileImages.length) {
                setEdgeRules(prevRules => prevRules.map(rule => {
                    if (rule._id === ruleId) {
                        const newGrid = [...rule.grid];
                        newGrid[index] = {
                            type: 'tile',
                            tileIndex,
                            imgSrc: tileImages[tileIndex],
                        };
                        return { ...rule, grid: newGrid };
                    }
                    return rule;
                }));
            }
        }
    }, [tileGroups, tileImages, selectionModal]);

    // Handle Selection from Group Selection Modal
    const handleGroupSelection = useCallback((updatedGroup) => {
        setTileGroups(prevGroups => prevGroups.map(group => {
            if (group._id === updatedGroup._id) {
                return updatedGroup;
            }
            return group;
        }));
    }, []);

    // Handle Form Submission
    const handleFormSubmit = useCallback(async (data) => {
        try {
            const payload = {
                name: data.name.trim(),
                imageSrc: data.imageSrc.trim(),
                tileSize: parseInt(data.tileSize),
                tileGroups: tileGroups.map(group => ({
                    _id: group._id,
                    name: group.name,
                    tileIndices: group.tileIndices,
                    probabilities: group.probabilities,
                    inPalette: group.inPalette,
                })),
                edgeRules: edgeRules.map(rule => ({
                    _id: rule._id,
                    name: rule.name,
                    grid: rule.grid,
                    repeatMask: 255,  // ok to repeat in all directions
                    enforce: true, // change adjacent tiles to meet rule
                    inPalette: rule.inPalette,
                })),
                world: world._id,
            };

            let updatedTileset;
            if (tilesetData) {
                // Update existing tileset
                updatedTileset = (await axios.put(`${process.env.REACT_APP_API_URL}/tileset/${tilesetData._id}`, payload)).data;
            } else {
                // Create new tileset
                updatedTileset = (await axios.post(`${process.env.REACT_APP_API_URL}/tileset`, payload)).data;
            }

            if (uploadedImage) {
                if (tilesetData) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/image/release/${updatedTileset._id}`);
                }
                await axios.put(`${process.env.REACT_APP_API_URL}/image/attach/${updatedTileset._id}/${uploadedImage._id}`);
            }

            if (onSubmit) onSubmit(updatedTileset);
            onCloseModal();
        } catch (err) {
            console.error("Error saving tileset:", err);
            setError('Failed to save tileset. Please ensure all fields are correctly filled and try again.');
        }
    }, [tileGroups, edgeRules, tilesetData, onSubmit, world, uploadedImage, onCloseModal]);

    // Handle Image Upload
    const handleImageUpload = useCallback(async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Client-side validation
        if (!file.type.startsWith('image/')) {
            setError('Only image files are allowed.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) { // 5MB limit
            setError('Image size should be less than 5MB.');
            return;
        }

        try {
            setUploading(true);
            setError(null);
            const formData = new FormData();
            formData.append('image', file);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/image/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { mediaType: 'image/png' } // Adjust if needed
            });
            console.log('Image uploaded successfully:', response.data);
            setUploadedImage(response.data); // Assume response.data contains the image object with _id

            // Set imageSrc to the API endpoint using the uploaded image's _id
            setValue('imageSrc', `${process.env.REACT_APP_API_URL}/image/${response.data._id}`);
        } catch (err) {
            console.error('Error uploading image:', err);
            if (err.response) {
                setError(`Failed to upload image: ${err.response.data.message || err.response.statusText}`);
            } else if (err.request) {
                setError('Failed to upload image: No response from server.');
            } else {
                setError(`Failed to upload image: ${err.message}`);
            }
        } finally {
            setUploading(false);
        }
    }, [setValue]);

    // Toggle Tile Selection
    const toggleTileSelection = useCallback((tileIndex) => {
        setSelectedTiles(prev => {
            const newSet = new Set(prev);
            if (newSet.has(tileIndex)) {
                newSet.delete(tileIndex);
            } else {
                newSet.add(tileIndex);
            }
            return newSet;
        });
    }, []);

    return (
        <>
            {tilesetData ? (
                <Button
                    size="sm"
                    color="gray"
                    onClick={() => setIsOpen(true)}
                >
                    Edit
                </Button>
            ) : (
                <Button onClick={() => setIsOpen(true)}>New Tileset</Button>
            )}

            <Modal theme={ModalTheme} show={isOpen} size="5xl" onClose={onCloseModal}>
                <Modal.Header>
                    <div className={"flex flex-row items-center"}>
                        {tilesetData ? "Edit Tileset" : "Add New Tileset"}

                        {/* Error Message */}
                        {error && (
                            <div className={"pl-4"}>
                                <Alert color="failure">
                                    <span>
                                        <span className="font-medium">Error!</span> {error}
                                    </span>
                                </Alert>
                            </div>
                        )}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <DndProvider backend={HTML5Backend}>
                        <div className="flex flex-col md:flex-row h-[70vh] overflow-hidden">
                            {/* Main Form */}
                            <div className="flex-1 pr-4 overflow-y-auto">
                                <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
                                    {/* Name Field */}
                                    <div>
                                        <Label htmlFor="name">Name</Label>
                                        <TextInput
                                            id="name"
                                            placeholder="Tileset name"
                                            {...register('name', { required: 'Name is required' })}
                                            className="mt-1 block w-full"
                                        />
                                        {errors.name && (
                                            <span className="text-red-500 text-sm">
                                                {errors.name.message}
                                            </span>
                                        )}
                                    </div>

                                    <div className={"flex justify-between space-x-4"}>
                                        {/* Image Source Field with Upload Option */}
                                        <div>
                                            <Label htmlFor="imageSrc">Image Source</Label>
                                            <div className="flex items-center space-x-4">
                                                {/* Upload Button */}
                                                <label className="flex items-center space-x-1 cursor-pointer">
                                                    <FiUpload/>
                                                    <span>Upload Image</span>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        className="hidden"
                                                        onChange={handleImageUpload}
                                                    />
                                                </label>
                                            </div>
                                            {errors.imageSrc && (
                                                <span className="text-red-500 text-sm">
                                                    {errors.imageSrc.message}
                                                </span>
                                            )}
                                            {uploading && (
                                                <div className="flex items-center space-x-2 mt-2">
                                                    <Spinner size="sm"/>
                                                    <span>Uploading...</span>
                                                </div>
                                            )}
                                        </div>

                                        {/* Tile Size Field */}
                                        <div>
                                            <Label htmlFor="tileSize">Tile Size (px)</Label>
                                            <TextInput
                                                id="tileSize"
                                                type="number"
                                                min="16"
                                                {...register('tileSize', {
                                                    required: 'Tile size is required',
                                                    valueAsNumber: true,
                                                    min: { value: 16, message: 'Minimum tile size is 16px' },
                                                })}
                                                className="mt-1 block w-full"
                                            />
                                            {errors.tileSize && (
                                                <span className="text-red-500 text-sm">
                                                    {errors.tileSize.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {/* Tile Groups Management */}
                                    <div className="mb-2">
                                        <Label>Tile Groups</Label>
                                        <div className="flex items-center space-x-2 mb-4">
                                            <TextInput
                                                placeholder="Group Name"
                                                value={currentGroup.name}
                                                onChange={(e) => setCurrentGroup(prev => ({
                                                    ...prev,
                                                    name: e.target.value
                                                }))}
                                                className="flex-1"
                                            />
                                            <Button type="button" size="sm" onClick={addTileGroup}
                                                    disabled={!currentGroup.name.trim()}>
                                                <FiPlus/>
                                            </Button>
                                        </div>
                                        {/* List of Existing Groups */}
                                        {tileGroups.length > 0 && (
                                            <div className="space-y-2">
                                                {tileGroups.map(group => (
                                                    <div key={group._id} className="flex items-center justify-between p-2 border border-gray-300 rounded">
                                                        <div className="flex items-center space-x-2">
                                                            <img
                                                                src={group.tileIndices.length > 0 ? tileImages[group.tileIndices[0]] : ''}
                                                                alt={group.name}
                                                                className="w-8 h-8 object-cover border"
                                                            />
                                                            <span>{group.name}</span>
                                                            <Checkbox
                                                                id={`inPalette-${group._id}`}
                                                                checked={group.inPalette}
                                                                onChange={(e) => toggleGroupInPalette(group._id, e.target.checked)}
                                                            />
                                                            <Label htmlFor={`inPalette-${group._id}`}>In Palette</Label>
                                                        </div>
                                                        <div className="flex items-center space-x-2">
                                                            <Button
                                                                size="xs"
                                                                onClick={() => {
                                                                    if (group) { // Ensure group is not null
                                                                        setGroupSelectionModal({ show: true, group });
                                                                    } else {
                                                                        console.error('Cannot edit a null group');
                                                                    }
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                size="xs"
                                                                color="failure"
                                                                onClick={() => removeTileGroup(group._id)}
                                                            >
                                                                <FiTrash2 />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    {/* Edge Rules Management */}
                                    <div className="mb-4">
                                        <Label>Tile Rules</Label>
                                        {/* Add New Edge Rule */}
                                        <div>
                                            <div className="flex items-center space-x-2 mb-5">
                                                <TextInput
                                                    placeholder="Edge Rule Name"
                                                    value={currentEdgeRule.name}
                                                    onChange={(e) => setCurrentEdgeRule(prev => ({
                                                        ...prev,
                                                        name: e.target.value
                                                    }))}
                                                    className="flex-1"
                                                />
                                                <Button type="button" size="sm" onClick={addEdgeRule}
                                                        disabled={!currentEdgeRule.name.trim()}>
                                                    <FiPlus/>
                                                </Button>
                                            </div>
                                        </div>

                                        {/* List of Existing Edge Rules */}
                                        {edgeRules.length > 0 && (
                                            <div className="space-y-4">
                                                {edgeRules.map(rule => (
                                                    <div key={rule._id} className="p-4 border border-gray-300 rounded">
                                                        <div className="flex justify-between items-center mb-2">
                                                            <h4 className="font-semibold">{rule.name}</h4>
                                                            <div className="flex items-center space-x-2">
                                                                <Checkbox
                                                                    id={`ruleInPalette-${rule._id}`}
                                                                    checked={rule.inPalette}
                                                                    onChange={() => toggleRuleInPalette(rule._id)}
                                                                />
                                                                <Label htmlFor={`ruleInPalette-${rule._id}`}>In Palette</Label>
                                                                <Button type="button" color="failure" size="sm"
                                                                        onClick={() => removeEdgeRule(rule._id)}>
                                                                    <FiTrash2/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {/* 3x3 Grid Display */}
                                                        <div className="grid grid-cols-3 gap-2 pl-1.5">
                                                            {rule.grid.map((cell, index) => (
                                                                <DropCell
                                                                    key={index}
                                                                    ruleId={rule._id}
                                                                    index={index}
                                                                    cellData={cell}
                                                                    onDrop={handleDrop}
                                                                    onClear={handleClear}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>


                            {/* Sidebar for Tile Images and Groups */}
                            {tilesheetLoaded && tileImages.length > 0 && (
                                <div className="w-full md:w-1/3 pl-2 pr-4 overflow-y-auto h-full">
                                    <Label className="mb-2">Tile Images</Label>
                                    <div className="grid grid-cols-5 gap-1">
                                        {tileImages.map((imgSrc, index) => (
                                            <DraggableTile
                                                key={index}
                                                tileIndex={index}
                                                imgSrc={imgSrc}
                                                isSelected={selectedTiles.has(index)}
                                                toggleSelect={toggleTileSelection} // Correctly passing the function
                                            />
                                        ))}
                                    </div>

                                </div>
                            )}

                            {/* Tile Groups Section */}
                            <div className="w-full md:w-[calc(25%+20px)] px-2 overflow-y-auto h-full">
                                <Label className="mb-2">Tile Groups</Label>
                                <div className="grid grid-cols-2 gap-1">
                                    {tileGroups.map(group => {
                                        const groupImageSrc = group.tileIndices.length > 0 ? tileImages[group.tileIndices[0]] : '';
                                        return (
                                            <div key={group._id} className="flex flex-col items-center">
                                                <div className="flex items-center space-x-2">
                                                    <DraggableGroup
                                                        group={group}
                                                        imgSrc={groupImageSrc}
                                                    />
                                                </div>
                                                <GroupDropZone
                                                    group={group}
                                                    tileImages={tileImages}
                                                    onTileDrop={handleTileDropToGroup}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                        </div>
                    </DndProvider>
                </Modal.Body>
                <ModalFooter>
                    {/* Submit Button */}
                    <div className="text-right">
                        <Button onClick={handleSubmit(handleFormSubmit)}>
                            {tilesetData
                                ? "Save Changes"
                                : (
                                    <>
                                        Add Tileset <GiQuillInk size={16} className="ml-1 translate-x-1"/>
                                    </>
                                )}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>

            {/* Selection Modal for Rules */}
            <RuleSelectionModal
                show={selectionModal.show}
                onClose={() => setSelectionModal({ show: false, ruleId: null, index: null })}
                onSelect={(type, selection) => {
                    handleRuleSelection(type, selection);
                }}
                tileGroups={tileGroups}
                tileImages={tileImages}
            />

            {/* Group Selection Modal */}
            <GroupSelectionModal
                show={groupSelectionModal.show}
                onClose={() => setGroupSelectionModal({ show: false, group: null })}
                onSave={handleGroupSelection}
                group={groupSelectionModal.group}
                tileImages={tileImages}
                tileGroups={tileGroups}
            />
        </>
    )
}

TilesetFormModal.propTypes = {
    tilesetData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    world: PropTypes.object.isRequired,
};

export default TilesetFormModal;
