// pages/DebugScenePage.jsx
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MarkdownRenderer from '../../components/common/MarkdownRenderer';
import { Spinner, Badge } from 'flowbite-react';
import { useSession } from '../../context/SessionContext';
import DarkModeContext from "../../context/DarkModeContext"; // Ensure you have this context set up

const DebugScenePage = () => {
    const { id } = useParams(); // Extract the scene ID from the URL
    const { session } = useSession(); // Access token from context
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { darkMode } = useContext(DarkModeContext);

    useEffect(() => {
        const fetchDebugData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/scene/${id}/debug`,
                    {
                        headers: {
                            Authorization: `Bearer ${session.token}`,
                        },
                    }
                );
                setData(response.data);
                console.log('Fetched data:', response.data); // For debugging
            } catch (err) {
                console.error('Error fetching debug data:', err);
                setError('Failed to fetch debug data.');
            } finally {
                setLoading(false);
            }
        };

        if (session.token) { // Ensure token is present
            fetchDebugData();
        } else {
            setError('No session token found.');
            setLoading(false);
        }
    }, [id, session.token]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner size="xl" />
                <span className="ml-2">Loading...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    // Helper function to render JSON content, excluding specified fields
    const renderContent = (content, excludeFields = []) => {
        if (!content) {
            return <p className="italic text-gray-500">No data available.</p>;
        }

        if (typeof content === 'string') {
            return <MarkdownRenderer content={content} darkMode={darkMode} />;
        } else if (typeof content === 'object') {
            // Deep clone the object to avoid mutating the original data
            const filteredContent = JSON.parse(JSON.stringify(content));
            excludeFields.forEach((field) => delete filteredContent[field]);

            // If after exclusion, the object is empty, return a placeholder
            if (Object.keys(filteredContent).length === 0) {
                return <p className="italic text-gray-500">No relevant data to display.</p>;
            }

            return (
                <pre className="bg-gray-100 dark:bg-gray-800 p-4 rounded overflow-auto">
                    {JSON.stringify(filteredContent, null, 2)}
                </pre>
            );
        } else {
            return <span>{String(content)}</span>;
        }
    };

    // Function to render characters without embedding
    const renderCharacters = (characters) => {
        if (!characters || characters.length === 0) {
            return <p className="italic text-gray-500">No characters to display.</p>;
        }

        return (
            <div className="space-y-4">
                {characters.map((char) => (
                    <div key={char._id} className="p-4 bg-gray-200 dark:bg-gray-800 rounded">
                        <h3 className="text-xl font-semibold">{char.name || 'Unnamed Character'}</h3>
                        <p>
                            <strong>Affiliation:</strong> {char.affiliation}
                        </p>
                        <p>
                            <strong>Description:</strong> {char.description || 'N/A'}
                        </p>
                        {/* Add more fields as necessary, excluding 'embedding' */}
                    </div>
                ))}
            </div>
        );
    };

    // Function to render plotProgress
    const renderPlotProgress = (plotProgress) => {
        if (!plotProgress || plotProgress.length === 0) {
            return <p className="italic text-gray-500">No plot progress to display.</p>;
        }

        return (
            <div className="space-y-4">
                {plotProgress.map((progress, index) => (
                    <div key={index} className="p-4 bg-gray-200 dark:bg-gray-800 rounded">
                        <p>
                            <strong>Plot Stage:</strong> {progress.plotStage}
                        </p>
                        <p>
                            <strong>Is Active:</strong> {progress.isActive ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Completed Elements:</strong>{' '}
                            {progress.completedElements.length > 0
                                ? progress.completedElements.join(', ')
                                : 'None'}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    // Function to render logs with distinct panels
    const renderLogs = (logs) => {
        if (!logs || logs.length === 0) {
            return <p className="italic text-gray-500">No logs to display.</p>;
        }

        const logComponents = ['analyzeScene', 'checkPlot', 'actScene'];

        return (
            <div className="space-y-6">
                {logs.map((logEntry, index) => {
                    return (
                        <div
                            key={index}
                            className="p-6 bg-gray-300 dark:bg-gray-700 rounded-lg shadow-lg space-y-4"
                        >
                            <h3 className="text-2xl font-semibold mb-4">Log Entry {index + 1}</h3>

                            {/* RagData Section */}
                            {logEntry.ragData && (
                                <div className="p-4 bg-gray-200 dark:bg-gray-800 rounded shadow-inner">
                                    <h4 className="text-lg font-semibold mb-2">RagData:</h4>
                                    <div className="ml-4">
                                        <pre className="bg-gray-100 dark:bg-gray-800 p-2 rounded overflow-auto">
                                            {JSON.stringify(logEntry.ragData, null, 2)}
                                        </pre>
                                    </div>
                                </div>
                            )}

                            {logComponents.map((component) => {
                                const componentData = logEntry[component];
                                if (!componentData) {
                                    return (
                                        <div key={component} className="mb-4">
                                            <h4 className="text-xl font-semibold mb-2 capitalize">
                                                {component}:
                                            </h4>
                                            <p className="italic text-gray-600 dark:text-gray-300">
                                                No {component} data available.
                                            </p>
                                        </div>
                                    );
                                }

                                const requestMessages = componentData.request?.messages;
                                const requestContent =
                                    requestMessages && requestMessages.length > 0
                                        ? requestMessages.map((msg) => msg.content).join('\n')
                                        : 'No prompt content available.';
                                const usageRequest = componentData.request?.usage; // Usage in Request
                                const usageResponse = componentData.response?.usage; // Usage in Response

                                return (
                                    <div
                                        key={component}
                                        className="p-4 bg-gray-200 dark:bg-gray-800 rounded shadow-inner"
                                    >
                                        {/* Request Section */}
                                        <h4 className="text-lg font-semibold mb-2 capitalize">
                                            {component} Request:
                                        </h4>
                                        <div className="ml-4 mb-4">
                                            <MarkdownRenderer
                                                content={requestContent}
                                                darkMode={darkMode}
                                            />
                                        </div>

                                        {/* Usage Table in Request */}
                                        {usageRequest && (
                                            <div className="ml-4 mb-4">
                                                <h5 className="font-semibold mb-2">Usage (Request):</h5>
                                                <table className="min-w-full bg-white dark:bg-gray-600">
                                                    <thead>
                                                    <tr>
                                                        {Object.keys(usageRequest).map((key) => (
                                                            <th
                                                                key={key}
                                                                scope="col"
                                                                className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 dark:text-gray-200"
                                                            >
                                                                {key
                                                                    .replace(/([A-Z])/g, ' $1')
                                                                    .trim()
                                                                    .toUpperCase()}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        {Object.entries(usageRequest).map(
                                                            ([key, value]) => (
                                                                <td
                                                                    key={key}
                                                                    className="py-2 px-4 border-b text-sm text-gray-700 dark:text-gray-200"
                                                                >
                                                                    {typeof value === 'object' && value !== null
                                                                        ? JSON.stringify(value, null, 2)
                                                                        : value}
                                                                </td>
                                                            )
                                                        )}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}

                                        {/* Response Section */}
                                        {usageResponse && (
                                            <div>
                                                <h4 className="text-lg font-semibold mb-2 capitalize">
                                                    {component} Response:
                                                </h4>
                                                <div className="ml-4">
                                                    <div>
                                                        <h5 className="font-semibold mb-2">
                                                            Usage (Response):
                                                        </h5>
                                                        <table className="min-w-full bg-white dark:bg-gray-600">
                                                            <thead>
                                                            <tr>
                                                                {Object.keys(usageResponse).map((key) => (
                                                                    <th
                                                                        key={key}
                                                                        scope="col"
                                                                        className="py-2 px-4 border-b text-left text-sm font-medium text-gray-700 dark:text-gray-200"
                                                                    >
                                                                        {key
                                                                            .replace(/([A-Z])/g, ' $1')
                                                                            .trim()
                                                                            .toUpperCase()}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                {Object.entries(usageResponse).map(
                                                                    ([key, value]) => (
                                                                        <td
                                                                            key={key}
                                                                            className="py-2 px-4 border-b text-sm text-gray-700 dark:text-gray-200"
                                                                        >
                                                                            {typeof value === 'object' && value !== null
                                                                                ? JSON.stringify(value, null, 2)
                                                                                : value}
                                                                        </td>
                                                                    )
                                                                )}
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )
                })}
            </div>
        );
    };

    // Function to render tags
    const renderTags = (tags) => {
        if (!tags || tags.length === 0) {
            return <p className="italic text-gray-500">No tags available.</p>;
        }

        return (
            <div className="flex flex-wrap">
                {tags.map((tag, index) => (
                    <Badge key={index} color="info" className="mr-2 mb-2">
                        {tag}
                    </Badge>
                ))}
            </div>
        );
    };

    // Function to render paragraphs
    const renderParagraphs = (paragraphs) => {
        if (!paragraphs || paragraphs.length === 0) {
            return <p className="italic text-gray-500">No paragraphs to display.</p>;
        }

        return (
            <div className="space-y-4">
                {paragraphs.map((para, index) => (
                    <div key={index} className="p-4 bg-gray-200 dark:bg-gray-800 rounded">
                        <p>
                            <strong>{para.by}:</strong> {para.speech}
                        </p>
                        <p>
                            <em>Action:</em> {para.action}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    // Function to render story details
    const renderStoryDetails = (story) => {
        if (!story) {
            return <p className="italic text-gray-500">No story details available.</p>;
        }

        return (
            <div className="space-y-4">
                <p>
                    <strong>Name:</strong> {story.name}
                </p>
                <p>
                    <strong>Description:</strong> {story.description || 'N/A'}
                </p>
                <p>
                    <strong>Premise:</strong> {story.premise || 'N/A'}
                </p>
                <p>
                    <strong>Genre:</strong> {story.genre.join(', ')}
                </p>
                <MarkdownRenderer content={story.preamble || ''} darkMode={darkMode} />
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-8">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-bold mb-6">Debug Scene: {id}</h1>

                {/* Adventure Details */}
                {data.adventure && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Adventure Details</h2>
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-xl font-semibold">Name: {data.adventure.name}</h3>
                                <p>
                                    <strong>Adventure ID:</strong> {data.adventure._id}
                                </p>
                                <p>
                                    <strong>User ID:</strong> {data.adventure.user}
                                </p>
                                <p>
                                    <strong>Created At:</strong>{' '}
                                    {new Date(data.adventure.createdAt).toLocaleString()}
                                </p>
                                <p>
                                    <strong>Updated At:</strong>{' '}
                                    {new Date(data.adventure.updatedAt).toLocaleString()}
                                </p>
                            </div>

                            {/* Story Details */}
                            {data.adventure.story && (
                                <div>
                                <h3 className="text-xl font-semibold">Story: {data.adventure.story.name}</h3>
                                    <p>
                                        <strong>Story ID:</strong> {data.adventure.story._id}
                                    </p>
                                    <p>
                                        <strong>Premise:</strong> {data.adventure.story.premise || 'N/A'}
                                    </p>
                                    <p>
                                        <strong>Genre:</strong> {data.adventure.story.genre.join(', ')}
                                    </p>
                                    <MarkdownRenderer
                                        content={data.adventure.story.preamble || ''}
                                        darkMode={darkMode}
                                    />
                                </div>
                            )}

                            {/* Plot Lines */}
                            {data.adventure.story?.plotLines && data.adventure.story.plotLines.length > 0 && (
                                <div>
                                    <h3 className="text-xl font-semibold">Plot Lines</h3>
                                    <div className="space-y-2 mt-2">
                                        {data.adventure.story.plotLines.map((plot, index) => (
                                            <div key={index} className="p-2 bg-gray-300 dark:bg-gray-700 rounded">
                                                <p>
                                                    <strong>{plot.index}) {plot.name}:</strong>  {plot.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Plot Progress */}
                            {data.adventure.plotProgress && data.adventure.plotProgress.length > 0 && (
                                <div>
                                    <h3 className="text-xl font-semibold">Plot Progress</h3>
                                    {renderPlotProgress(data.adventure.plotProgress)}
                                </div>
                            )}
                        </div>
                    </section>
                )}

                {/* Characters Section */}
                {data.characters && data.characters.length > 0 && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Characters</h2>
                        {renderCharacters(data.characters)}
                    </section>
                )}

                {/* Story Details Section */}
                {data.story && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Story Details</h2>
                        {renderStoryDetails(data.story)}
                    </section>
                )}

                {/* Paragraphs Section */}
                {data.paragraphs && data.paragraphs.length > 0 && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Paragraphs</h2>
                        {renderParagraphs(data.paragraphs)}
                    </section>
                )}

                {/* Location Details Section */}
                {data.locationName && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Location Details</h2>
                        <div className="space-y-2">
                            <p>
                                <strong>Name:</strong> {data.locationName}
                            </p>
                            <p>
                                <strong>ID:</strong> {data.location}
                            </p>
                            {/* Add more location details if available */}
                        </div>
                    </section>
                )}

                {/* Tags Section */}
                {data.tags && data.tags.length > 0 && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Tags</h2>
                        {renderTags(data.tags)}
                    </section>
                )}

                {/* Logs Section */}
                {data.log && data.log.length > 0 && (
                    <section className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Logs</h2>
                        {renderLogs(data.log)}
                    </section>
                )}
            </div>
        </div>
    )
};

export default DebugScenePage;
