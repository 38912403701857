// src/components/labels/ListLabels.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Badge,
    Button,
    Pagination,
    Spinner,
    Alert,
} from 'flowbite-react';
import axios from 'axios';
import AddEditLabel from './AddEditLabel';
import { useSession } from '../../../context/SessionContext'; // Assuming you have a session context
import ReactTooltip from 'react-tooltip';
import AddEditLore from "../lore/AddEditLore"; // For tooltips if needed

// Memoized Table Row Component
const LabelTableRow = React.memo(({ labelList, label, isInherited, onDelete, onEdit }) => {
    return (
        <TableRow
            className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                isInherited ? 'opacity-65' : ''
            }`}
        >
            {/* Name Column with Badge */}
            <TableCell className="whitespace-nowrap">
                <Badge color={label.color} className="p-2">
                    {label.name}
                </Badge>
            </TableCell>

            {/* Description Column */}
            <TableCell>
                {label.description}
            </TableCell>

            {/* Options Column */}
            <TableCell>
                {!isInherited && (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <AddEditLabel labelList={labelList} labelData={label} onSubmit={onEdit} worldId={label.world}/>
                        <Button
                            color="failure"
                            size="xs"
                            onClick={(e) => onDelete(e, label._id)}
                            className="ml-2"
                        >
                            Delete
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
});

function ListLabels({ world }) {
    const [labelList, setLabelList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [labelsPerPage] = useState(10); // Number of labels per page
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { session } = useSession(); // Assuming you use a session context

    const fetchLabels = useCallback(async () => {
        if (!world) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/label`, {
                params: { worldId: world._id },
                headers: { Authorization: `Bearer ${session.token}` }, // Adjust based on your auth setup
            });
            const labelData = response.data;
            setLabelList(labelData);
        } catch (err) {
            console.error('Failed to fetch labels:', err);
            setError('Failed to fetch labels. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [world, session.token]);

    useEffect(() => {
        fetchLabels();
    }, [fetchLabels]);

    const handleLabelDelete = useCallback(
        async (event, labelId) => {
            event.preventDefault();
            if (!window.confirm('Are you sure you want to delete this label?')) return;
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/label/${labelId}`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                fetchLabels();
            } catch (error) {
                console.error('Error deleting label:', error);
                setError('Failed to delete label. Please try again.');
            }
        },
        [fetchLabels, session.token]
    );

    // Pagination Logic
    const indexOfLastLabel = currentPage * labelsPerPage;
    const indexOfFirstLabel = indexOfLastLabel - labelsPerPage;
    const currentLabels = labelList.slice(indexOfFirstLabel, indexOfLastLabel);
    const totalPages = Math.ceil(labelList.length / labelsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (!world) {
        return <div>Loading...</div>;
    }

    return (
        <div className="overflow-x-auto">
            <div className='ml-4 mb-4 flex justify-between items-center'>
                {/* Add Label Modal */}
                <AddEditLabel labelList={labelList} onSubmit={fetchLabels} worldId={world._id} />

                {/* Optionally, add search or filter functionality here */}
            </div>

            {loading ? (
                <div className="flex justify-center items-center py-10">
                    <Spinner aria-label="Loading" />
                </div>
            ) : error ? (
                <Alert color="failure">
                    <span>
                        <span className="font-medium">Error!</span> {error}
                    </span>
                </Alert>
            ) : (
                <>
                    <Table striped>
                        <TableHead>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Description</TableHeadCell>
                            <TableHeadCell>Options</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y">
                            {currentLabels.map(label => {
                                const isInherited = label.world !== world._id;
                                return (
                                    <LabelTableRow
                                        key={label._id}
                                        labelList={labelList}
                                        label={label}
                                        isInherited={isInherited}
                                        onDelete={handleLabelDelete}
                                        onEdit={fetchLabels}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <div className="flex justify-center mt-4">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                                layout="navigation"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ListLabels;
