// src/components/NewStory/StoryWizardForm/WizardContext.js

import React, { createContext, useContext, useState } from 'react';

// Create the context
const WizardContext = createContext();

// Custom hook to use the Wizard context
export const useWizard = () => {
    return useContext(WizardContext);
};

// Wizard Provider component
export const WizardProvider = ({ children, initialData = {} }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        storyPrompt: '',
        genres: [],
        format: '',
        themes: [],
        location: '',
        conflict: '',
        tone: '',
        obstacles: [],
        incident: '',
        symbolism: [],
        protagonist: '',
        antagonist: '',
        ...initialData
    });

    // To store displayed items per field
    const [displayedItemsMap, setDisplayedItemsMap] = useState({});

    // Function to update form data
    const updateFormData = (newData) => {
        setFormData((prev) => ({ ...prev, ...newData }));
    };

    // Function to set displayed items for a field
    const setDisplayedItems = (field, items) => {
        setDisplayedItemsMap((prev) => ({ ...prev, [field]: items }));
    };

    // Function to get displayed items for a field
    const getDisplayedItems = (field) => {
        return displayedItemsMap[field] || [];
    };

    // Function to go to the next step
    const nextStep = () => setCurrentStep((prev) => prev + 1);

    // Function to go to the previous step
    const prevStep = () => setCurrentStep((prev) => prev - 1);

    // Function to go to a specific step
    const goToStep = (step) => setCurrentStep(step);

    // Function to reset the wizard
    const resetWizard = () => {
        setCurrentStep(0);
        setFormData({
            storyPrompt: '',
            genres: [],
            format: '',
            themes: [],
            location: '',
            conflict: '',
            tone: '',
            obstacles: [],
            incident: '',
            symbolism: [],
            protagonist: '',
            antagonist: '',
            ...initialData
        });
        setDisplayedItemsMap({});
    };

    return (
        <WizardContext.Provider
            value={{
                currentStep,
                formData,
                updateFormData,
                nextStep,
                prevStep,
                goToStep,
                resetWizard,
                setDisplayedItems,
                getDisplayedItems,
            }}
        >
            {children}
        </WizardContext.Provider>
    );
};
