// src/components/lore/ListLore.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Badge,
    TextInput,
    Button,
    Pagination,
    Spinner,
    Alert,
} from 'flowbite-react';
import axios from 'axios';
import AddEditLore from './AddEditLore'; // Add/Edit Lore Modal
import NewLoreWizard from '../../../oldComponents/new/NewLoreWizard'; // AI Lore Generation Modal
import debounce from 'lodash.debounce';

// Memoized Table Row Component
const LoreTableRow = React.memo(({ lore, isInherited, onDelete, onEdit }) => {
    return (
        <TableRow
            className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                isInherited ? 'opacity-65' : ''
            }`}
        >
            <TableCell>{lore.name}</TableCell>
            <TableCell>{lore.description}</TableCell>
            <TableCell className="flex flex-wrap gap-2">
                {(lore.tags || []).map((tag, index) => (
                    <Badge key={index} color="gray" className="p-2">
                        {tag}
                    </Badge>
                ))}
            </TableCell>
            <TableCell>
                {!isInherited && (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <AddEditLore loreData={lore} onSubmit={onEdit} worldId={lore.world}/>
                        <Button
                            color="failure"
                            size="xs"
                            onClick={(e) => onDelete(e, lore._id)}
                            className="ml-2"
                        >
                            Delete
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
});

function ListLore({world}) {
    const [loreList, setLoreList] = useState([]);
    const [filteredLoreList, setFilteredLoreList] = useState([]);
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lorePerPage] = useState(10); // Number of lore items per page
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchLore = useCallback(async () => {
        if (!world) return;
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/lore`, {
                params: { worldId: world._id },
            });
            const loreData = response.data;
            setLoreList(loreData);
            setFilteredLoreList(loreData);
        } catch (err) {
            console.error('Failed to fetch lore:', err);
            setError('Failed to fetch lore. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [world]);

    useEffect(() => {
        fetchLore();
    }, [fetchLore]);

    // Debounced filter to improve performance
    const debouncedFilter = useMemo(
        () =>
            debounce((value) => {
                const lowerCaseFilter = value.toLowerCase();
                const filtered = loreList.filter(
                    (lore) =>
                        lore.name.toLowerCase().includes(lowerCaseFilter) ||
                        (lore.tags || []).some((tag) =>
                            tag.toLowerCase().includes(lowerCaseFilter)
                        )
                );
                setFilteredLoreList(filtered);
                setCurrentPage(1); // Reset to first page on filter
            }, 300),
        [loreList]
    );

    useEffect(() => {
        debouncedFilter(filter);
        return () => {
            debouncedFilter.cancel();
        };
    }, [filter, debouncedFilter]);

    const handleLoreDelete = useCallback(
        async (event, loreId) => {
            event.preventDefault();
            if (!window.confirm('Are you sure you want to delete this lore?')) return;
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/lore/${loreId}`);
                fetchLore();
            } catch (error) {
                console.error('Error deleting lore:', error);
                setError('Failed to delete lore. Please try again.');
            }
        },
        [fetchLore]
    );

    // Pagination Logic
    const indexOfLastLore = currentPage * lorePerPage;
    const indexOfFirstLore = indexOfLastLore - lorePerPage;
    const currentLore = filteredLoreList.slice(indexOfFirstLore, indexOfLastLore);
    const totalPages = Math.ceil(filteredLoreList.length / lorePerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (!world) {
        return <div>Loading...</div>;
    }

    return (
        <div className="overflow-x-auto">
            <div className="flex items-center ml-4 mb-4 space-x-4">
                {/* Add Lore Modal */}
                {/*<NewLoreWizard worldId={world._id} onSubmit={fetchLore} />*/}

                {/* Add/Edit Lore Modal */}
                <AddEditLore onSubmit={fetchLore} worldId={world._id} />

                {/* Search Input */}
                <div className="flex-grow pr-4">
                    <TextInput
                        placeholder="Filter by name or tags..."
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="w-full"
                    />
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center py-10">
                    <Spinner aria-label="Loading" />
                </div>
            ) : error ? (
                <Alert color="failure">
                    <span>
                        <span className="font-medium">Error!</span> {error}
                    </span>
                </Alert>
            ) : (
                <>
                    <Table striped>
                        <TableHead>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Description</TableHeadCell>
                            <TableHeadCell>Tags</TableHeadCell>
                            <TableHeadCell>Options</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y">
                            {currentLore.map((lore) => {
                                const isInherited = lore.world !== world._id;
                                return (
                                    <LoreTableRow
                                        key={lore._id}
                                        lore={lore}
                                        isInherited={isInherited}
                                        onDelete={handleLoreDelete}
                                        onEdit={fetchLore}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <div className="flex justify-center mt-4">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ListLore;
