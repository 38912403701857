import {Button, Dropdown, Input, Card, TextInput} from "flowbite-react";
import Breadcrumbs from "../../components/header/Breadcrumbs";
import NewAdventure from "../../oldComponents/menuItems/new/NewAdventure";
import AdventureCard from "../../components/adventure/AdventureCard";
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useSession } from "../../context/SessionContext";
import { debounce } from "lodash";
import { IoBookSharp, IoHomeSharp } from "react-icons/io5";
import { PiBookmarksSimpleFill, PiBooksFill } from "react-icons/pi";
import {useNavigate, useParams} from "react-router-dom";

function MyAdventuresPage({story}) {
    const { id } = useParams();
    const [adventures, setAdventures] = useState([]);
    const [filteredAdventures, setFilteredAdventures] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOption, setSortOption] = useState("createdAt_desc");
    const { session } = useSession();
    const navigate = useNavigate();

    const handleResize = debounce(() => {
        // Handle resize logic if necessary
    }, 100); // Debounce by 100ms

    const fetchAdventures = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/adventure`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                },
                params: {
                    story: story || id || undefined // Assuming you have a current story context
                }
            });

            setAdventures(response.data);
        } catch (error) {
            console.error('Failed to fetch adventures:', error);
        }
    };

    const handleStart = async () => {
        // Implement logic to start a new adventure
        try {
            const newAdventure = {story: (story?._id || id), name:"New Adventure"};
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/adventure`, newAdventure, {
                headers: { 'Authorization': `Bearer ${session.token}` }
            });
            //setAdventures(response.data);
            navigate(`/adventure/${result.data._id}`);
        } catch (error) {
            console.error('Error creating adventure:', error);
        }
    };


    useEffect(() => {
        if (session?.token) fetchAdventures();
    }, [session?.token]);

    // Filtering and Sorting
    useEffect(() => {
        let filtered = adventures.filter(adventure =>
            adventure.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Add additional filters here if needed

        // Sorting
        switch (sortOption) {
            case "createdAt_asc":
                filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                break;
            case "createdAt_desc":
                filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                break;
            case "tokens_asc":
                filtered.sort((a, b) => a.tokensUsed - b.tokensUsed);
                break;
            case "tokens_desc":
                filtered.sort((a, b) => b.tokensUsed - a.tokensUsed);
                break;
            default:
                break;
        }

        setFilteredAdventures(filtered);
    }, [adventures, searchTerm, sortOption]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (option) => {
        setSortOption(option);
    };

    if (!session) return <div className="text-center mt-8 text-red-500">No user session!</div>;

    return (
        <div className="p-6 ">

            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <div className={"text-2xl font-bold pr-6"}>My Adventures</div>
                <a onClick={handleStart} href={"#"} className="text-blue-500 hover:underline">New Adventure</a>
            </div>

            {(!story && !id) &&
                <div className="flex flex-col md:flex-row justify-between items-center ">
                    <TextInput
                        type="text"
                        placeholder="Search Adventures..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full md:w-1/3 mb-2 md:mb-0"
                    />
                    <Dropdown
                        label="Sort By"
                        inline={true}
                        className="w-full md:w-auto"
                    >
                        <Dropdown.Item onClick={() => handleSortChange("createdAt_desc")}>
                            Newest First
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSortChange("createdAt_asc")}>
                            Oldest First
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => handleSortChange("tokens_desc")}>
                            Most Tokens Used
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSortChange("tokens_asc")}>
                            Least Tokens Used
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            }

            <div className={"pt-6"}>
                {filteredAdventures.length === 0 ? (
                    <div className="text-center text-gray-500">No adventures found.</div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
                        {filteredAdventures.map((adventure) => (
                            <AdventureCard
                                key={adventure._id}
                                adventure={adventure}
                                fetchAdventures={fetchAdventures}
                            />
                        ))}
                    </div>
                )}
            </div>

        </div>
    );
}

export default MyAdventuresPage;
