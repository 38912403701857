// src/components/ConnectionModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Label, TextInput } from 'flowbite-react';

const ConnectionModal = ({
                             isOpen,
                             onClose,
                             selectedConnection,
                             setSelectedConnection,
                             connections,
                             setConnections,
                             onConnectionsUpdate,
                         }) => (
    <Modal show={isOpen} onClose={onClose}>
        <Modal.Header>Edit Connection</Modal.Header>
        <Modal.Body>
            <div>
                <Label htmlFor="editConnectionName" value="Connection Name" />
                <TextInput
                    id="editConnectionName"
                    type="text"
                    value={selectedConnection.name}
                    onChange={(e) => {
                        const updatedConnection = { ...selectedConnection, name: e.target.value };
                        setSelectedConnection(updatedConnection);
                    }}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={() => {
                    // Update connection
                    const updatedConnections = connections.map((connection) =>
                        connection._id === selectedConnection._id ? selectedConnection : connection
                    );
                    setConnections(updatedConnections);
                    onConnectionsUpdate(updatedConnections);
                    onClose();
                }}
            >
                Save
            </Button>
            <Button
                color="failure"
                onClick={() => {
                    // Remove connection
                    const updatedConnections = connections.filter(
                        (connection) => connection._id !== selectedConnection._id
                    );
                    setConnections(updatedConnections);
                    onConnectionsUpdate(updatedConnections);
                    onClose();
                }}
            >
                Delete
            </Button>
        </Modal.Footer>
    </Modal>
);

ConnectionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedConnection: PropTypes.object.isRequired,
    setSelectedConnection: PropTypes.func.isRequired,
    connections: PropTypes.array.isRequired,
    setConnections: PropTypes.func.isRequired,
    onConnectionsUpdate: PropTypes.func.isRequired,
};

export default ConnectionModal;
