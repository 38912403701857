// src/components/story/plot/graph/Toolbar.jsx

import React from 'react';
import { FaPlus } from 'react-icons/fa';

const Toolbar = () => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className="toolbar flex space-x-2 p-2">
            {/* PlotElement Tool */}
            <div
                className="toolbar-item flex items-center justify-center p-2 bg-blue-100 border-blue-500 border rounded cursor-pointer dark:bg-gray-900 dark:border-gray-700"
                onDragStart={(event) => onDragStart(event, 'plotElement')}
                draggable
            >
                <FaPlus className="mr-1" /> PlotElement
            </div>

            {/* PlotLine Tool */}
            <div
                className="toolbar-item flex items-center justify-center p-2 bg-yellow-100 border-yellow-500 border rounded cursor-pointer dark:text-gray-900 dark:bg-yellow-600 dark:border-yellow-700"
                onDragStart={(event) => onDragStart(event, 'plotLine')}
                draggable
            >
                <FaPlus className="mr-1" /> PlotLine
            </div>
        </div>
    );
};

export default Toolbar;
