// src/components/adventure/SceneLoader.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import SceneView from './SceneView';
import axios from 'axios';
import UserInput from "./UserInput";
import useSceneStreaming from "../hooks/useSceneStreaming";
import useScenes from "../hooks/useScenes";

const SceneLoader = ({ session, setSession, adventureId = '', startAt = 'end' }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreTop, setHasMoreTop] = useState(true);
    const [hasMoreBottom, setHasMoreBottom] = useState(true);
    const [minNarrativePosition, setMinNarrativePosition] = useState(null);
    const [maxNarrativePosition, setMaxNarrativePosition] = useState(null);
    const virtuosoRef = useRef(null); // Ref for Virtuoso
    const triggerInitialStream = useRef(false);


    const {
        scenes,
        setScenes,
        appendPartialData,
        appendCompletedParagraphs,
        clearPartialData,
        updateSceneParagraph,
        rejectParagraph,
    } = useScenes();


    // Initialize the streaming hook
    const { startStreaming } = useSceneStreaming({
        sessionToken: session.token,
        onAppendCompletedParagraphs: appendCompletedParagraphs,
        onAppendPartialData: appendPartialData,
        onClearPartialData: clearPartialData,
    });


    // Fetch initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                if (!adventureId) return;
                let direction, current;
                if (startAt === 'start') {
                    direction = 'down';
                    current = 'first';
                } else {
                    direction = 'up';
                    current = 'last';
                }
                // Fetch scenes
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/scene`,
                    {
                        params: {
                            adventureId: adventureId,
                            direction: direction,
                            current: current,
                            limit: 5,
                        },
                        headers: { Authorization: `Bearer ${session.token}` },
                    }
                );

                const fetchedScenes = response.data.scenes;

                console.log('Fetched Scenes:', fetchedScenes); // Debugging

                if (fetchedScenes.length > 0) {
                    setScenes(fetchedScenes);
                    setMinNarrativePosition(fetchedScenes[0].narrativeOrder);
                    setMaxNarrativePosition(fetchedScenes[fetchedScenes.length - 1].narrativeOrder);

                    // If this is the only scene and it has no paragraphs, initiate streaming
                    if (!triggerInitialStream.current && fetchedScenes.length === 1 && (!fetchedScenes[0].paragraphs || fetchedScenes[0].paragraphs.length === 0)) {
                        triggerInitialStream.current = true;
                    }

                } else {
                    setHasMoreTop(false);
                    setHasMoreBottom(false);
                }

            } catch (error) {
                console.error('Error fetching initial scenes:', error);
            }
        };

        fetchInitialData();
    }, [startAt, adventureId, setScenes, session.token]);

    // Scroll to the last scene when scenes are updated and start streaming if needed
    useEffect(() => {
        const lastScene = scenes[scenes.length - 1];

        if (lastScene) {
            if (virtuosoRef.current) {
                virtuosoRef.current.scrollToIndex({ index: scenes.length - 1, align: 'end' });
            }
        }

        if (triggerInitialStream.current && lastScene) {
            triggerInitialStream.current = false;
            startStreaming(lastScene._id); // Pass the correct sceneId
        }
    }, [scenes, startStreaming, triggerInitialStream]);

    // Load more scenes at the top
    const loadMoreTop = useCallback(async () => {
        if (!adventureId) return;
        if (!hasMoreTop || isLoading) return;
        setIsLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/scene`,
                {
                    params: {
                        adventureId: adventureId,
                        direction: 'up',
                        current: minNarrativePosition,
                        limit: 5,
                    },
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            const newScenes = response.data.scenes;

            console.log('Loaded More Top Scenes:', newScenes); // Debugging

            if (newScenes.length === 0) {
                setHasMoreTop(false);
            } else {
                setScenes((prev) => [...newScenes, ...prev]);
                setMinNarrativePosition(newScenes[0].narrativeOrder);

                // Adjust scroll position to maintain the current view
                if (virtuosoRef.current) {
                    virtuosoRef.current.adjustForPrependedItems(newScenes.length);
                }
            }
        } catch (error) {
            console.error('Error loading top scenes:', error);
        } finally {
            setIsLoading(false);
        }
    }, [adventureId, hasMoreTop, isLoading, minNarrativePosition, setScenes, session.token]);

    // Load more scenes at the bottom
    const loadMoreBottom = useCallback(async () => {
        if (!adventureId) return;
        if (!hasMoreBottom || isLoading) return;
        setIsLoading(true);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/scene`,
                {
                    params: {
                        adventureId: adventureId,
                        direction: 'down',
                        current: maxNarrativePosition,
                        limit: 5,
                    },
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            const newScenes = response.data.scenes;

            console.log('Loaded More Bottom Scenes:', newScenes); // Debugging

            if (newScenes.length === 0) {
                setHasMoreBottom(false);
            } else {
                setScenes((prev) => [...prev, ...newScenes]);
                setMaxNarrativePosition(newScenes[newScenes.length - 1].narrativeOrder);
            }
        } catch (error) {
            console.error('Error loading bottom scenes:', error);
        } finally {
            setIsLoading(false);
        }
    }, [adventureId, hasMoreBottom, isLoading, maxNarrativePosition, setScenes, session.token]);

    // Handler Functions (Memoized)

    const handleAddPicture = useCallback(async (sceneId, paragraphIndex) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/scene/${sceneId}/picture/${paragraphIndex}`,
                {},
                { headers: { Authorization: `Bearer ${session.token}` } }
            );
            // Update the specific paragraph with the new imageId
            updateSceneParagraph(sceneId, paragraphIndex, { image: response.data.imageId });

            console.log(`Picture added to scene ${sceneId}, paragraph ${paragraphIndex}`); // Debugging
        } catch (error) {
            console.error('Error updating scene:', error);
        }
    }, [updateSceneParagraph, session.token]);

    const handleRejectParagraph = useCallback(async (sceneId, paragraphIndex) => {
        // Mark the paragraph as rejected
        rejectParagraph(sceneId, paragraphIndex);

        // Optionally, persist the rejection to the backend
        try {
            const updatedScene = scenes.find(scene => scene._id === sceneId);
            if (!updatedScene) throw new Error('Scene not found');

            const updatedParagraphs = updatedScene.paragraphs.map((para, idx) =>
                idx === paragraphIndex ? { ...para, rejected: true } : para
            );

            await axios.put(
                `${process.env.REACT_APP_API_URL}/scene/${sceneId}`,
                { paragraphs: updatedParagraphs },
                {
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            console.log(`Paragraph ${paragraphIndex} in scene ${sceneId} rejected`); // Debugging
        } catch (error) {
            console.error('Error rejecting paragraph:', error);
        }
    }, [rejectParagraph, scenes, session.token]);

    const handleCreateMemory = useCallback(async (sceneId, paragraphIndex) => {
        try {
            // Implement memory creation logic here
            // For example, send a POST request to create a memory
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/memory`,
                {
                    sceneId,
                    paragraphIndex,
                },
                {
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            console.log('Memory created:', response.data); // Debugging
        } catch (error) {
            console.error('Error creating memory:', error);
        }
    }, [session.token]);

    const handleRemovePicture = useCallback(async (sceneId, paragraphIndex) => {
        // Remove the image from the specific paragraph
        updateSceneParagraph(sceneId, paragraphIndex, { image: null });

        // Optionally, persist the change to the backend
        try {
            const updatedScene = scenes.find(scene => scene._id === sceneId);
            if (!updatedScene) throw new Error('Scene not found');

            const updatedParagraphs = updatedScene.paragraphs.map((para, idx) =>
                idx === paragraphIndex ? { ...para, image: null } : para
            );

            await axios.put(
                `${process.env.REACT_APP_API_URL}/scene/${sceneId}`,
                { paragraphs: updatedParagraphs },
                {
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            console.log(`Picture removed from scene ${sceneId}, paragraph ${paragraphIndex}`); // Debugging
        } catch (error) {
            console.error('Error removing picture:', error);
        }
    }, [updateSceneParagraph, scenes, session.token]);

    if (!session) {
        return (<>No Session!</>);
    }

    const initialTopMostItemIndex = startAt === 'end' ? scenes.length - 1 : 0;

    return (
        <div className="flex flex-col h-full">
            {/* Optional: If you still need the button */}
            {minNarrativePosition > 1 &&
                <button
                    onClick={loadMoreTop}
                    className="p-1 text-white"
                >
                    Load More Top
                </button>
            }

            {/* Virtuoso container */}
            <div className="flex-grow h-0">
                <Virtuoso
                    ref={virtuosoRef}
                    data={scenes}
                    initialTopMostItemIndex={initialTopMostItemIndex}
                    followOutput={(isAtBottom) => isAtBottom}
                    startReached={loadMoreTop}
                    endReached={loadMoreBottom}
                    itemContent={(index, scene) => (
                        <SceneView
                            key={scene._id}
                            scene={scene}
                            onAddPicture={handleAddPicture}
                            onRejectParagraph={handleRejectParagraph}
                            onCreateMemory={handleCreateMemory}
                            onRemovePicture={handleRemovePicture}
                        />
                    )}
                    components={{
                        Header: () =>
                            isLoading && hasMoreTop ? (
                                <div className="flex justify-center items-center p-2">Loading...</div>
                            ) : null,
                        Footer: () =>
                            isLoading && hasMoreBottom ? (
                                <div className="flex justify-center items-center p-2">Loading...</div>
                            ) : null,
                    }}
                />
            </div>

            {/* UserInput component */}
            <div className="bg-white dark:bg-gray-800">
                <UserInput
                    scene={scenes[scenes.length - 1]}
                    session={session}
                    setSession={setSession}
                    startStreaming={() => {
                        const lastScene = scenes[scenes.length - 1];
                        if (lastScene) {
                            startStreaming(lastScene._id);
                        } else {
                            console.error('No scene available to start streaming.');
                        }
                    }}
                />
            </div>
        </div>
    );
};

// Optionally, memoize SceneLoader to prevent unnecessary re-renders
export default React.memo(SceneLoader);
