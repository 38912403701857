import React, {useContext, useEffect, useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {Button, Card} from 'flowbite-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import DarkModeContext from "../../context/DarkModeContext";
import MarkdownRenderer from "../../components/common/MarkdownRenderer";
import {GrEdit} from "react-icons/gr";
import AddEditNewsModal from "../../components/admin/news/AddEditNewsModal";
import {useSession} from "../../context/SessionContext";

function NewsArticlePage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const { darkMode } = useContext(DarkModeContext);
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [articleToEdit, setArticleToEdit] = useState(null);
    const [previousArticle, setPreviousArticle] = useState(null);
    const [nextArticle, setNextArticle] = useState(null);
    const { session } = useSession();


    const fetchArticle = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/${id}/reader`);
            setArticle(response.data.article);
            setPreviousArticle(response.data.previousArticle);
            setNextArticle(response.data.nextArticle);
        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    const handleEditArticle = (article) => {
        setArticleToEdit(article);
        setIsAddEditModalOpen(true);
    };

    const handleAddEditSuccess = () => {
        fetchArticle();
        setIsAddEditModalOpen(false);
        setArticleToEdit(null);
    };

    const thumbsUp = async () => {
        try {

        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    const thumpsDown = async () => {
        try {

        } catch (error) {
            console.error('Failed to fetch article:', error);
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        fetchArticle();
    }, [id]);

    if (!article) {
        return <p>Loading article...</p>;
    }

    const showEdit = (session?.user?.permissionLevel === 5);

    return (
        <div className="p-6 pb-8 mx-auto max-w-7xl">
            <div className="flex justify-between items-center">
                {previousArticle && (
                    <Button onClick={() => navigate(`/news/article/${previousArticle._id}`)} size="sm">
                        ← {previousArticle.name}
                    </Button>
                )}

                <Button color={'dark'} onClick={() => navigate(`/news`)} size="sm">
                    Other News
                </Button>

                {nextArticle && (
                    <Button onClick={() => navigate(`/news/article/${nextArticle._id}`)} size="sm">
                        {nextArticle.name} →
                    </Button>
                )}
            </div>

            <div className="flex justify-center mb-1">
                <h1 className="flex text-4xl font-bold  text-center">{article.name}</h1>
                {showEdit && (
                    <GrEdit
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditArticle(article)
                        }}
                        size={20}
                        title="Edit Article"
                        className="flex ml-3 mt-4 text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                        aria-label={`Edit ${article.name}`}
                    />
                )}
            </div>

            <p className="text-lg mb-1 text-center">{article.description}</p>
            <p className="text-gray-500 mb-6 text-center">{formatDate(article.publishedAt || article.createdAt)}</p>

            {article.segments.map((segment, index) => (
                <div key={index} className="mx-auto max-w-4xl mb-8">
                    <Card theme={cardTheme}>
                        <p className="text-2xl font-semibold dark:text-gray-300">{segment.title}</p>
                        {segment.by ? <p className="text-sm text-gray-500 mb-6">By: {segment.by}</p> : <div className="mb-4"></div>}
                        <div className="content markdown">
                            <MarkdownRenderer darkMode={darkMode} content={segment.content} />
                        </div>
                    </Card>
                </div>
            ))}

            {/* Add/Edit News Modal */}
            {isAddEditModalOpen && (
                <AddEditNewsModal
                    isOpen={isAddEditModalOpen}
                    onClose={() => {
                        setIsAddEditModalOpen(false);
                        setArticleToEdit(null);
                    }}
                    articleToEdit={articleToEdit}
                    onSuccess={handleAddEditSuccess}
                    session={session}
                />
            )}
        </div>
    );

}

export default NewsArticlePage;

const cardTheme = {
    "root": {
        "base": "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-900",
        "children": "flex h-full flex-col justify-center gap-1 p-4",
        "horizontal": {
            "off": "flex-col",
            "on": "flex-col md:max-w-xl md:flex-row"
        },
        "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
    },
    "img": {
        "base": "",
        "horizontal": {
            "off": "rounded-t-lg",
            "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        }
    }
}