import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Button, Tabs} from 'flowbite-react';
import {useSession} from "../../context/SessionContext";
import {SlPeople, SlPicture} from "react-icons/sl";
import {BsJournalBookmark} from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { GrHelpBook } from "react-icons/gr";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { GrUserAdmin } from "react-icons/gr";
import { VscGraphLine } from "react-icons/vsc";

import ListNewsArticles from "../../components/admin/news/ListNewsArticles";

function AdminPage() {
    const { type } = useParams(); // Fetch the route parameter
    const navigate = useNavigate();
    const { session } = useSession();

    if (!session || session.user.permissionLevel < 5) return null
    else return (
        <Tabs aria-label="Default tabs" style="underline">
            <Tabs.Item title="Reports" icon={VscGraphLine}>

            </Tabs.Item>
            <Tabs.Item title="Maintenance" icon={HiOutlineWrenchScrewdriver}>
                <div className={'flex items-center p-4'}>
                    <Button>Clean Up Database</Button>
                </div>
            </Tabs.Item>
            <Tabs.Item title="News" icon={IoNewspaperOutline}>
                <ListNewsArticles></ListNewsArticles>
            </Tabs.Item>
            <Tabs.Item title="Documentation" icon={GrHelpBook}>

            </Tabs.Item>
            <Tabs.Item title="Price List" icon={MdOutlineLocalGroceryStore}>

            </Tabs.Item>
            <Tabs.Item title="Moderation" icon={GrUserAdmin}>

            </Tabs.Item>
        </Tabs>
    );
}

export default AdminPage;
