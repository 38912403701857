// src/components/news/ListNewsArticles.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Badge,
    TextInput,
    Button,
    Pagination,
    Spinner,
    Alert,
} from 'flowbite-react';
import axios from 'axios';
import AddEditNewsModal from './AddEditNewsModal'; // Add/Edit News Modal
import debounce from 'lodash.debounce';
import ArticleTableRow from './ArticleTableRow';
import {useSession} from "../../../context/SessionContext";
import { TableTheme } from "../../../themes/TableTheme";

function ListNewsArticles() {
    const [articleList, setArticleList] = useState([]);
    const [filteredArticleList, setFilteredArticleList] = useState([]);
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 10; // Number of articles per page
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const [articleToEdit, setArticleToEdit] = useState(null);
    const { session } = useSession();

    const fetchNews = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/list/admin`, {
                headers: {
                    'Authorization': `Bearer ${session ? session.token : ''}`
                }
            });
            const newsData = response.data;
            setArticleList(newsData);
            setFilteredArticleList(newsData);
        } catch (err) {
            console.error('Failed to fetch news articles:', err);
            setError('Failed to fetch news articles. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [session]);

    useEffect(() => {
        fetchNews();
    }, [fetchNews]);

    // Debounced filter to improve performance
    const debouncedFilter = useMemo(
        () =>
            debounce((value) => {
                const lowerCaseFilter = value.toLowerCase();
                const filtered = articleList.filter(
                    (article) =>
                        article.name.toLowerCase().includes(lowerCaseFilter) ||
                        (article.tags || []).some((tag) =>
                            tag.toLowerCase().includes(lowerCaseFilter)
                        )
                );
                setFilteredArticleList(filtered);
                setCurrentPage(1); // Reset to first page on filter
            }, 300),
        [articleList]
    );

    useEffect(() => {
        debouncedFilter(filter);
        return () => {
            debouncedFilter.cancel();
        };
    }, [filter, debouncedFilter]);

    const handleNewsArticleDelete = useCallback(
        async (event, newsId) => {
            event.preventDefault();
            if (!window.confirm('Are you sure you want to delete this news article?')) return;
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/news/${newsId}`, {
                    headers: {
                        'Authorization': `Bearer ${session ? session.token : ''}`
                    }
                });
                fetchNews();
            } catch (error) {
                console.error('Error deleting news article:', error);
                setError('Failed to delete news article. Please try again.');
            }
        },
        [fetchNews, session]
    );

    const handleEditArticle = (article) => {
        setArticleToEdit(article);
        setIsAddEditModalOpen(true);
    };

    // Pagination Logic
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticleList.slice(indexOfFirstArticle, indexOfLastArticle);
    const totalPages = Math.ceil(filteredArticleList.length / articlesPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAddEditSuccess = () => {
        fetchNews();
        setIsAddEditModalOpen(false);
        setArticleToEdit(null);
    };

    if (!session) return null;

    return (
        <div className="overflow-x-auto">
            <div className="flex items-center ml-4 mb-4 space-x-4">
                {/* Add News Button */}
                <Button
                    onClick={() => {
                        setArticleToEdit(null); // Ensure no article is set for adding
                        setIsAddEditModalOpen(true);
                    }}
                    color="green"
                >
                    Add News Article
                </Button>
                {/* Search Input */}
                <div className="flex-grow pr-4">
                    <TextInput
                        placeholder="Filter by name or tags..."
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="w-full"
                    />
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center py-10">
                    <Spinner aria-label="Loading" />
                </div>
            ) : error ? (
                <Alert color="failure">
                    <span>
                        <span className="font-medium">Error!</span> {error}
                    </span>
                </Alert>
            ) : (
                <>
                    <Table striped theme={TableTheme}>
                        <TableHead>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Description</TableHeadCell>
                            <TableHeadCell>Image</TableHeadCell>
                            <TableHeadCell>Status</TableHeadCell>
                            <TableHeadCell>Tags</TableHeadCell>
                            <TableHeadCell>Segments</TableHeadCell>
                            <TableHeadCell>Category</TableHeadCell>
                            <TableHeadCell>Options</TableHeadCell>
                        </TableHead>
                        <TableBody className="divide-y">
                            {currentArticles.map((article) => (
                                <ArticleTableRow
                                    key={article._id}
                                    article={article}
                                    onDelete={handleNewsArticleDelete}
                                    onEdit={handleEditArticle}
                                />
                            ))}
                        </TableBody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <div className="flex justify-center mt-4">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </>
            )}

            {/* Add/Edit News Modal */}
            {isAddEditModalOpen && (
                <AddEditNewsModal
                    isOpen={isAddEditModalOpen}
                    onClose={() => {
                        setIsAddEditModalOpen(false);
                        setArticleToEdit(null);
                    }}
                    articleToEdit={articleToEdit}
                    onSuccess={handleAddEditSuccess}
                    session={session}
                />
            )}
        </div>
    );
}

export default ListNewsArticles;
