
import {Label, Modal, TextInput, Textarea, Badge} from "flowbite-react";
import React, {useEffect, useState} from "react";
import {CgAdd, CgAddR, CgRemove, CgRemoveR} from "react-icons/cg";


function ViewProperties({ properties, onPropertyUpdate }) {
    const handleChangeAttribute = (propertyIndex, attrIndex, key, value) => {
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex].attributes[attrIndex][key] = value;
        if (onPropertyUpdate) onPropertyUpdate(updatedProperties);
    };

    if (!properties) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-64">
            {properties.map((property, propertyIndex) => (
                <div key={propertyIndex} className="border py-1 px-2 mb-0.5 rounded-lg">
                    <div className='flex justify-between mb-0.5'>
                        <Label
                            type="text"
                            sizing="sm"
                            className="flex-initial w-1/2 font-bold"
                            theme={labelTheme}
                            value={property.name}
                        />
                    </div>

                    {property.attributes.map((attribute, attrIndex) => (
                        <div key={attrIndex} className="flex justify-between mb-0.5">
                            <Label
                                type="text"
                                sizing="xs"
                                className="flex-initial w-1/2"
                                theme={labelTheme}
                                value={attribute.key}
                            />
                            <TextInput
                                type="text"
                                sizing="xs"
                                className="flex-initial w-1/2 pl-1"
                                theme={textInputTheme}
                                value={attribute.value}
                                readOnly={!onPropertyUpdate}
                                onChange={(e) => handleChangeAttribute(propertyIndex, attrIndex, 'value', e.target.value)}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ViewProperties;


const textInputTheme = {
    "base": "flex",
    "addon": "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
    "field": {
        "base": "relative w-full",
        "icon": {
            "base": "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3",
            "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "rightIcon": {
            "base": "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3",
            "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "input": {
            "base": "block w-full border disabled:cursor-not-allowed disabled:opacity-50",
            "sizes": {
                "xs": "p-0.5 pl-1.5 sm:text-xs",
                "sm": "p-2 sm:text-xs",
                "md": "p-2.5 text-sm",
                "lg": "p-4 sm:text-base"
            },
            "colors": {
                "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
                "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
                "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
                "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
                "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
            },
            "withRightIcon": {
                "on": "pr-10",
                "off": ""
            },
            "withIcon": {
                "on": "pl-10",
                "off": ""
            },
            "withAddon": {
                "on": "rounded-r-md",
                "off": "rounded-md"
            },
            "withShadow": {
                "on": "shadow-sm dark:shadow-sm-light",
                "off": ""
            }
        }
    }
}
const labelTheme={
    "root": {
        "base": "text-xs font-small mt-0.5",
        "disabled": "opacity-50",
        "colors": {
            "default": "text-gray-700 dark:text-white",
            "info": "text-cyan-500 dark:text-cyan-600",
            "failure": "text-red-700 dark:text-red-500",
            "warning": "text-yellow-500 dark:text-yellow-600",
            "success": "text-green-700 dark:text-green-500"
        }
    }
}