// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {predefinedFormats, predefinedLocations, predefinedTone} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_Tone = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select a Tone or Mood for Your Story"
            subTitle="(Pick One or None)"
            predefinedList={predefinedTone}
            formDataField="tone" // Ensure this matches the key in formData
            isSingleSelect={true}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_Tone;
