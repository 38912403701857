// import {Button, Checkbox, Label, Modal, Select, TextInput} from "flowbite-react";
// import React, {useEffect, useState} from "react";
// import WorldPage from "../../../pages/private/WorldPage";
// import axios from "axios";
// import {useSession} from "../../../context/SessionContext";
// import {GiQuillInk} from "react-icons/gi";
// import {ModalTheme} from "../../../themes/ModalTheme";
//
//
// function NewAdventure({handleAdventureSubmit, story}) {
//     const { session } = useSession();
//     const [openModal, setOpenModal] = useState(false);
//     //const [stories, setStories] = useState([]);
//     const [newAdventure, setNewAdventure] = useState(null);
//     const [entitlements, setEntitlements] = useState([]);
//
//     useEffect(() => {
//         const fetchStories = async () => {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/entitlement/story`, {
//                 headers: {
//                     'Authorization': `Bearer ${session.token}` // Set header directly
//                 }
//             });
//
//             if (response.data && response.data.length > 0) {
//                 setEntitlements(response.data);
//                 setNewAdventure({story: response.data[0].story._id, name: ''}); // initialize using the first story in case the user doesn't change the selection dropdown
//             }
//         }
//         if (!story) fetchStories();
//         else { setNewAdventure({story: story._id, name: ''}); }
//     }, [session?.token])
//
//
//     function onCloseModal() {
//         setOpenModal(false);
//     }
//
//     if (!newAdventure || (!story && (!entitlements || entitlements.length === 0))) {
//         return <div>Please create a story first...</div>;
//     }
//     else if (story) {
//         return (
//             <>
//                 <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
//                    onClick={() => setOpenModal(true)}>
//                     Create New Adventure
//                 </a>
//                 <Modal theme={ModalTheme} show={openModal} size="md" onClose={onCloseModal} popup>
//                     <Modal.Header>Start New Adventure</Modal.Header>
//                     <Modal.Body>
//                         <div className="space-y-6">
//                             <div>
//                                 <div className="mb-2 block">
//                                     <Label htmlFor="name" value="Name"/>
//                                 </div>
//                                 <TextInput
//                                     id="name"
//                                     placeholder="name"
//                                     value={newAdventure.name}
//                                     onChange={(event) => setNewAdventure({...newAdventure, name: event.target.value})}
//                                     required
//                                 />
//                             </div>
//                             <div>
//                                 <div className="mb-2 block">
//                                     <Label htmlFor="story" value="Story"/>
//                                 </div>
//                                     <p>{story.name}</p>
//                             </div>
//
//                             <div className="w-full">
//                                 <Button onClick={() => {
//                                     handleAdventureSubmit(newAdventure);
//                                     setOpenModal(false);
//                                 }}>Create<GiQuillInk size={16} className={"ml-1 translate-x-1"}/>1</Button>
//                             </div>
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </>
//         );
//     };
//
//     return (
//         <>
//             <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
//                onClick={() => setOpenModal(true)}>
//                 Create New Adventure
//             </a>
//             <Modal theme={ModalTheme} show={openModal} size="md" onClose={onCloseModal} popup>
//                 <Modal.Header>Start New Adventure</Modal.Header>
//                 <Modal.Body>
//                     <div className="space-y-6">
//                         <div>
//                             <div className="mb-2 block">
//                                 <Label htmlFor="name" value="Name"/>
//                             </div>
//                             <TextInput
//                                 id="name"
//                                 placeholder="name"
//                                 value={newAdventure.name}
//                                 onChange={(event) => setNewAdventure({...newAdventure, name: event.target.value})}
//                                 required
//                             />
//                         </div>
//                         <div>
//                             <div className="mb-2 block">
//                                 <Label htmlFor="story" value="Story"/>
//                             </div>
//                                 <Select
//                                     theme={TextInputTheme}
//                                     id="type"
//                                     placeholder="story"
//                                     value={newAdventure._id}
//                                     onChange={(e) => setNewAdventure({...newAdventure, story: e.target.value})}
//                                     required
//                                 >
//                                     {entitlements.map((entitlement, index) => (
//                                         <option key={index} value={entitlement.story._id}>
//                                             {entitlement.story.name}
//                                         </option>
//                                     ))}
//                                 </Select>
//                         </div>
//
//                         <div className="w-full">
//                             <Button onClick={() => {
//                                 handleAdventureSubmit(newAdventure);
//                                 setOpenModal(false);
//                             }}>Create<GiQuillInk size={16} className={"ml-1 translate-x-1"}/>1</Button>
//                         </div>
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// }
//
// export default NewAdventure;
//
// const TextInputTheme={
//     "base": "flex",
//     "addon": "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400",
//     "field": {
//         "base": "relative w-full",
//         "icon": {
//             "base": "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3",
//             "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
//         },
//         "rightIcon": {
//             "base": "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3",
//             "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
//         },
//         "input": {
//             "base": "block w-full border disabled:cursor-not-allowed disabled:opacity-50",
//             "sizes": {
//                 "sm": "p-2 sm:text-xs",
//                 "md": "p-2.5 text-sm",
//                 "lg": "p-4 sm:text-base"
//             },
//             "colors": {
//                 "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
//                 "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
//                 "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
//                 "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
//                 "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
//             },
//             "withRightIcon": {
//                 "on": "pr-10",
//                 "off": ""
//             },
//             "withIcon": {
//                 "on": "pl-10",
//                 "off": ""
//             },
//             "withAddon": {
//                 "on": "rounded-r-lg",
//                 "off": "rounded-lg"
//             },
//             "withShadow": {
//                 "on": "shadow-sm dark:shadow-sm-light",
//                 "off": ""
//             }
//         }
//     }
// }
// const LabelTheme={
//     "root": {
//         "base": "text-sm font-medium",
//         "disabled": "opacity-50",
//         "colors": {
//             "default": "text-gray-900 dark:text-white",
//             "info": "text-cyan-500 dark:text-cyan-600",
//             "failure": "text-red-700 dark:text-red-500",
//             "warning": "text-yellow-500 dark:text-yellow-600",
//             "success": "text-green-700 dark:text-green-500"
//         }
//     }
// }