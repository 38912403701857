// src/hooks/useScenes.js
import { useState, useCallback } from 'react';
import axios from 'axios';

const useScenes = () => {
    const [scenes, setScenes] = useState([]);

    // Append partial data to the last scene
    const appendPartialData = useCallback((partialData) => {
        setScenes((prevScenes) =>
            prevScenes.map((scene, index) => {
                if (index === prevScenes.length - 1) {
                    return { ...scene, currentPartial: partialData };
                }
                return scene;
            })
        );
    }, []);

    // Append completed paragraphs to the last scene
    const appendCompletedParagraphs = useCallback((newParagraphs) => {
        setScenes((prevScenes) =>
            prevScenes.map((scene, index) => {
                if (index === prevScenes.length - 1) {
                    return {
                        ...scene,
                        paragraphs: [...(scene.paragraphs || []), ...newParagraphs],
                        currentPartial: null,
                    };
                }
                return scene;
            })
        );
    }, []);

    // Clear partial data from the last scene
    const clearPartialData = useCallback(() => {
        setScenes((prevScenes) =>
            prevScenes.map((scene, index) => {
                if (index === prevScenes.length - 1) {
                    return { ...scene, currentPartial: null };
                }
                return scene;
            })
        );
    }, []);

    // Update a specific paragraph's data in a specific scene
    const updateSceneParagraph = useCallback((sceneId, paragraphIndex, newParagraphData) => {
        setScenes((prevScenes) =>
            prevScenes.map((scene) => {
                if (scene._id === sceneId) {
                    const updatedParagraphs = scene.paragraphs.map((para, idx) =>
                        idx === paragraphIndex ? { ...para, ...newParagraphData } : para
                    );
                    return { ...scene, paragraphs: updatedParagraphs };
                }
                return scene;
            })
        );
    }, []);

    // Reject a specific paragraph in a specific scene
    const rejectParagraph = useCallback((sceneId, paragraphIndex) => {
        setScenes((prevScenes) => {
            return prevScenes.map(scene => {
                if (scene._id === sceneId) {
                    const updatedParagraphs = scene.paragraphs.map((para, idx) => {
                        if (idx === paragraphIndex) {
                            return { ...para, rejected: true };
                        }
                        return para;
                    });
                    return { ...scene, paragraphs: updatedParagraphs };
                }
                return scene;
            });
        });
    }, []);

    return {
        scenes,
        setScenes,
        appendPartialData,
        appendCompletedParagraphs,
        clearPartialData,
        updateSceneParagraph,
        rejectParagraph,
    };
};

export default useScenes;
