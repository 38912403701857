import React, { useEffect, useRef, useState } from 'react';
import { Label, TextInput, Textarea, Badge, Button, Progress, Select } from "flowbite-react";
import axios from 'axios';
import AddEditImage from "../common/AddEditImage";
// import TagSelector from "../common/TagSelector";
import { useForm } from "react-hook-form";
import TagsInput from "../common/TagsInput";
import { useNavigate } from "react-router-dom";
import { TextAreaTheme } from "../../themes/TextAreaTheme";
import {useSession} from "../../context/SessionContext";

function StoryOverview({ story, onSubmit, isViewOnly = false }) {
    const [updatedStory, setUpdatedStory] = useState(story);
    const navigate = useNavigate();
    const { session } = useSession();
    const addEditImageRef = useRef();
    const [isProcessing, setIsProcessing] = useState(false);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(story.startingLocations || []);

    // **Fetch Prerequisite Lists**
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                // Fetch Locations
                const locResponse = await axios.get(`${process.env.REACT_APP_API_URL}/location/list`, {
                    params: {
                        storyId: story._id,
                        limit: 100,
                        search: '',
                    },
                });
                setAvailableLocations(locResponse.data.locations || []);
            } catch (error) {
                console.error("Failed to fetch prerequisites:", error);
            }
        };

        fetchLocations();
    }, [story._id]);

    if (!story) {
        return <div>Loading...</div>;
    }

    const handleImageSave = async () => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving:', err);
            //setError('Failed to save changes.');
        }
    };

    const handleChange = (field, value) => {
        setUpdatedStory((prev) => ({ ...prev, [field]: value }));
    };

    // Handle starting locations change
    const handleStartingLocationsChange = (selected) => {
        setSelectedLocations(selected);
        handleChange('startingLocations', selected);
    };

    // Handle story submission
    const handleSubmit = async () => {
        setIsProcessing(true);
        await handleImageSave();
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/story/${updatedStory._id}`, updatedStory);
            if (onSubmit) onSubmit(updatedStory);
        } catch (error) {
            console.error("Failed to update story:", error);
            // Optionally handle the error (e.g., display a notification)
        }
        setIsProcessing(false);
    };

    // Handle story submission
    const handlePublish = async () => {
        setIsProcessing(true);
        await handleImageSave();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/story/publish/${updatedStory._id}`, updatedStory, {params:{ clone: false, copyEntitlements: false, deepClone: false}});
        setUpdatedStory(response.data);
        setIsProcessing(false);
    };

    // Handle story deletion
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this?')) {
            setIsProcessing(true);
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/story/${updatedStory._id}`);
                navigate('/stories');
            } catch (error) {
                console.error("Failed to delete story:", error);
                // Optionally handle the error
            }
            setIsProcessing(false);
        }
    };

    return (
        <div className="p-6 pb-12">
            {isProcessing && (
                <div className="flex items-center space-x-2 mb-4">
                    <span className="text-sm text-gray-600">Processing your story...</span>
                </div>
            )}

            <div className="flex justify-between mb-4">
                {/* Save Button */}
                <Button onClick={handleSubmit} disabled={isProcessing || isViewOnly}>
                    Save Updated Story
                </Button>
                {!story.isPublished && session.user.permissionLevel > 4 &&
                    <Button color={"gray"} onClick={handlePublish} disabled={isProcessing}>Publish Story</Button>
                }
                {/* Delete Button */}
                <Button color="red" onClick={handleDelete} disabled={isProcessing || isViewOnly}>
                    Delete Story
                </Button>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Left Column */}
                <div className="space-y-4">
                    {/* Story Name */}
                    <div>
                        <Label htmlFor="name" value="Name" />
                        <TextInput
                            id="name"
                            placeholder="Name of story"
                            value={updatedStory.name}
                            onChange={(event) => handleChange('name', event.target.value)}
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Story Genre (treated as tags) */}
                    <div>
                        <Label htmlFor="genre" value="Genre" />
                        <TagsInput
                            tags={updatedStory.genre || []}
                            onChange={(genre) => handleChange('genre', genre)}
                            placeholder="Add a genre and press Enter"
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Story Themes (treated as tags) */}
                    <div>
                        <Label htmlFor="themes" value="Themes" />
                        <TagsInput
                            tags={updatedStory.themes || []}
                            onChange={(themes) => handleChange('themes', themes)}
                            placeholder="Add a theme and press Enter"
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Story Tags */}
                    <div>
                        <Label htmlFor="tags" value="Tags" />
                        <TagsInput
                            tags={updatedStory.tags || []}
                            onChange={(tags) => handleChange('tags', tags)}
                            placeholder="Add a tag and press Enter"
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Starting Locations */}
                    <div>
                        <Label htmlFor="startingLocations" value="Starting Locations" />
                        <Select
                            id="startingLocations"
                            multiple
                            size="8"
                            value={selectedLocations}
                            onChange={(e) => {
                                const options = e.target.options;
                                const selected = [];
                                for (let i = 0; i < options.length; i++) {
                                    if (options[i].selected) {
                                        selected.push(options[i].value);
                                    }
                                }
                                handleStartingLocationsChange(selected);
                            }}
                            disabled={isViewOnly}
                            // className="h-48" // Adjust height as needed
                        >
                            {availableLocations.map((loc) => (
                                <option key={loc._id} value={loc._id}>
                                    {loc.name}
                                </option>
                            ))}
                        </Select>
                    </div>

                    {/* Initial Scene Preamble */}
                    <div>
                        <Label htmlFor="preamble" value="Initial Scene Preamble" />
                        <Textarea
                            id="preamble"
                            placeholder="How does this story start?"
                            theme={TextAreaTheme}
                            value={updatedStory.preamble}
                            rows={14}
                            onChange={(event) => handleChange('preamble', event.target.value)}
                            disabled={isViewOnly}
                        />
                    </div>
                </div>

                {/* Right Column */}
                <div className="space-y-4">
                    {/* Description */}
                    <div>
                        <Label htmlFor="description" value="Description" />
                        <Textarea
                            id="description"
                            placeholder="Whats this story about?"
                            theme={TextAreaTheme}
                            value={updatedStory.description}
                            rows={6}
                            onChange={(event) => handleChange('description', event.target.value)}
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Story Premise */}
                    <div>
                        <Label htmlFor="premise" value="Premise" />
                        <Textarea
                            id="premise"
                            placeholder="What is the underlying premise behind the plot for this story?"
                            theme={TextAreaTheme}
                            value={updatedStory.premise}
                            rows={6}
                            onChange={(event) => handleChange('premise', event.target.value)}
                            disabled={isViewOnly}
                        />
                    </div>

                    {/* Story Cover Art */}
                    <div>
                        <Label value="Cover Art" />
                        <AddEditImage
                            ref={addEditImageRef}
                            parentData={updatedStory}
                            placeholder={2}
                            disabled={isViewOnly}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoryOverview;
