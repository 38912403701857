// src/components/story/plot/PlotElementFormModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Modal,
    Button,
    TextInput,
    Textarea,
    Label,
    Select,
    Checkbox,
} from 'flowbite-react';
import { GiQuillInk } from "react-icons/gi";
import { GrEdit } from "react-icons/gr";
import { TbAlertHexagonFilled } from "react-icons/tb";
import AddEditImage from "../../common/AddEditImage"; // Ensure correct path
import AddEditProperties from "../../common/AddEditProperties"; // Ensure correct path
import { TextAreaTheme, TextAreaThemeReadOnly } from "../../../themes/TextAreaTheme";
import { ModalTheme } from "../../../themes/ModalTheme";
import { ButtonTheme } from "../../../themes/ButtonTheme";
import { TextInputTheme, TextInputThemeReadOnly } from "../../../themes/TextInputTheme";
import useSelectThemeStyle from "../../../themes/SelectTheme"; // Ensure correct path

const PlotElementFormModal = ({
                                  isOpen,
                                  onClose,
                                  plotElementToEdit,
                                  story,
                                  viewOnly,
                                  onSuccess,
                              }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [name, setName] = useState('');
    const [mainPlotLine, setMainPlotLine] = useState('');
    const [plotStage, setPlotStage] = useState(0);
    const [nextStage, setNextStage] = useState(1);
    const [isRequiredForStage, setIsRequiredForStage] = useState(false);
    const [advanceStageOnTrigger, setAdvanceStageOnTrigger] = useState(false);
    const [unlockPlotLines, setUnlockPlotLines] = useState([]);
    const [trigger, setTrigger] = useState('');
    const [hint, setHint] = useState('');
    const [description, setDescription] = useState('');
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // **Prerequisite States**
    const [availableLocations, setAvailableLocations] = useState([]);
    const [availableCharacters, setAvailableCharacters] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);
    const [availablePlotLines, setAvailablePlotLines] = useState([]);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedCharacters, setSelectedCharacters] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [requiredPlotLines, setRequiredPlotLines] = useState([]);

    // **Fetch Prerequisite Lists**
    useEffect(() => {
        const fetchPrerequisites = async () => {
            try {
                // Fetch Locations
                const locResponse = await axios.get(`${process.env.REACT_APP_API_URL}/location/list`, {
                    params: {
                        storyId: story._id,
                        limit: 100,
                        search: '',
                    },
                });
                setAvailableLocations(locResponse.data.locations || []);

                // Fetch Characters
                const charResponse = await axios.get(`${process.env.REACT_APP_API_URL}/character/list`, {
                    params: {
                        storyId: story._id,
                        limit: 100,
                        search: '',
                    },
                });
                setAvailableCharacters(charResponse.data.characters || []);

                // Fetch Items
                const itemResponse = await axios.get(`${process.env.REACT_APP_API_URL}/item/list`, {
                    params: {
                        storyId: story._id,
                        limit: 100,
                        search: '',
                    },
                });
                setAvailableItems(itemResponse.data.items || []);

                // Fetch PlotLines for Prerequisites and Main PlotLine Selection
                const plotlineResponse = await axios.get(`${process.env.REACT_APP_API_URL}/plotline/list`, {
                    params: {
                        storyId: story._id,
                        limit: 100,
                        search: '',
                    },
                });
                setAvailablePlotLines(plotlineResponse.data.plotLines || []);
            } catch (error) {
                console.error("Failed to fetch prerequisites:", error);
            }
        };

        fetchPrerequisites();
    }, [story._id]);

// Good practice to put async in useEffect function
    useEffect(() => {
        const fetchData = async () => {
            if (plotElementToEdit) {
                try {
                    // Get full copy of document to edit
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/plot/${plotElementToEdit._id}`);
                    const plotElement = response.data;
                    setName(plotElement.name || '');
                    setMainPlotLine(plotElement.plotLine || '');
                    setPlotStage(plotElement.plotStage || 0);
                    setNextStage(plotElement.nextStage || plotElement.plotStage + 1 || 1);
                    setIsRequiredForStage(plotElement.isRequiredForStage || false);
                    setAdvanceStageOnTrigger(plotElement.advanceStageOnTrigger || false);
                    setUnlockPlotLines(plotElement.unlockPlotLines || []);
                    setTrigger(plotElement.trigger || '');
                    setHint(plotElement.hint || '');
                    setDescription(plotElement.description || '');
                    setProperties(plotElement.properties || []);

                    // **Initialize Prerequisites**
                    setSelectedLocations(plotElement.prerequisites?.locations?.map(id => id.toString()) || []);
                    setSelectedCharacters(plotElement.prerequisites?.characters?.map(id => id.toString()) || []);
                    setSelectedItems(plotElement.prerequisites?.items?.map(id => id.toString()) || []);
                    setRequiredPlotLines(plotElement.prerequisites?.plotLines?.map(pl => pl.toString()) || []);
                } catch(error) {
                    console.error("Error retrieving the plot Element", error);
                }
            } else {
                // Reset form fields for new plot element
                setName('');
                setMainPlotLine('');
                setPlotStage(0);
                setNextStage(1);
                setIsRequiredForStage(false);
                setAdvanceStageOnTrigger(false);
                setUnlockPlotLines([]);
                setTrigger('');
                setHint('');
                setDescription('');
                setProperties([]);

                // **Reset Prerequisites**
                setSelectedLocations([]);
                setSelectedCharacters([]);
                setSelectedItems([]);
                setRequiredPlotLines([]);
            }
            setIsViewOnly(viewOnly);
            setError('');
        }
        fetchData();
    }, [plotElementToEdit, availablePlotLines, viewOnly]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            // Validate required fields
            if (!name || !mainPlotLine || !trigger || !description) {
                setError('Please fill in all required fields.');
                setLoading(false);
                return;
            }

            // Prepare the updated plot element with the correct numeric plotLine
            const updatedPlotElement = {
                name,
                plotLine: Number(mainPlotLine), // Ensure mainPlotLine is a number
                plotStage,
                nextStage,
                isRequiredForStage,
                advanceStageOnTrigger,
                unlockPlotLines: unlockPlotLines.map(pl => Number(pl)), // Ensure unlockPlotLines are numbers
                trigger,
                hint,
                description,
                properties,
                story: story._id,
                prerequisites: {
                    locations: selectedLocations,
                    characters: selectedCharacters,
                    items: selectedItems,
                    plotLines: requiredPlotLines.map(pl => Number(pl)), // Renamed
                },
                // Include image data if necessary (handled by AddEditImage)
            };

            let response;
            if (plotElementToEdit) {
                // Update existing plot element
                response = await axios.put(`${process.env.REACT_APP_API_URL}/plot/${plotElementToEdit._id}`, updatedPlotElement);
            } else {
                // Create new plot element
                response = await axios.post(`${process.env.REACT_APP_API_URL}/plot`, updatedPlotElement);
            }

            // Update state and notify parent component
            if (onSuccess) onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving plot element:', err);
            setError('Failed to save plot element.');
        } finally {
            setLoading(false);
        }
    };

    // Handle deletion of plot element
    const handleDelete = async () => {
        if (!plotElementToEdit) return;
        if (!window.confirm('Are you sure you want to delete this plot element?')) return;

        setLoading(true);
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/plot/${plotElementToEdit._id}`);
            if (onSuccess) onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting plot element:', err);
            setError('Failed to delete plot element.');
        } finally {
            setLoading(false);
        }
    };

    // Handle edit toggle
    const handleEdit = () => {
        setIsViewOnly(false);
    };

    return (
        <Modal
            theme={ModalTheme}
            dismissible
            show={isOpen}
            size="5xl"
            onClose={onClose}
        >
            <Modal.Header>
                {isViewOnly ? (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <TbAlertHexagonFilled/>
                        <span className="text-2xl font-bold text-gray-900 dark:text-white">
                            {plotElementToEdit ? 'View Plot Element' : 'Add Plot Element'}
                        </span>
                        {plotElementToEdit && (
                            <GrEdit
                                className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110"
                                onClick={handleEdit}
                                title="Edit Plot Element"
                                aria-label="Edit Plot Element"
                            />
                        )}
                    </div>
                ) : (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <TbAlertHexagonFilled/>
                        <span className="text-2xl font-bold text-gray-900 dark:text-white">
                            {plotElementToEdit ? 'Edit Plot Element' : 'Add Plot Element'}
                        </span>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="mb-4">
                        <Label color="failure" value={error}/>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        {/* Left Column */}
                        <div className="space-y-6">
                            {/* Main Plot Line Field */}
                            <div>
                                <Label htmlFor="mainPlotLine" value="Plot Line"/>
                                <Select
                                    id="mainPlotLine"
                                    value={mainPlotLine}
                                    onChange={(e) => setMainPlotLine(e.target.value)}
                                    required
                                    disabled={isViewOnly}
                                >
                                    <option value="">--Select A Plot Line--</option>
                                    {availablePlotLines.map((line) => (
                                        <option key={line.index} value={line.index}>
                                            {line.name} ({line.index})
                                        </option>
                                    ))}
                                </Select>
                            </div>

                            {/* Name Field */}
                            <div>
                                <Label htmlFor="name" value="Name"/>
                                <TextInput
                                    id="name"
                                    type="text"
                                    placeholder="Enter plot element name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    disabled={isViewOnly}
                                    theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                                />
                            </div>

                            {/* Hint Field */}
                            <div>
                                <Label htmlFor="hint" value="Hint"/>
                                <Textarea
                                    id="hint"
                                    placeholder="Enter hint"
                                    theme={TextAreaTheme}
                                    value={hint}
                                    onChange={(e) => setHint(e.target.value)}
                                    required
                                    rows={3}
                                    disabled={isViewOnly}
                                />
                            </div>

                            {/* Description Field */}
                            <div>
                                <Label htmlFor="description" value="Description (Event)"/>
                                <Textarea
                                    id="description"
                                    placeholder="Enter plot element description"
                                    theme={TextAreaTheme}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                    rows={9}
                                    disabled={isViewOnly}
                                />
                            </div>

                            <div className="flex gap-4">
                                {/* Plot Stage Field */}
                                <div>
                                    <Label htmlFor="plotStage" value="This Stage"/>
                                    <TextInput
                                        id="plotStage"
                                        type="number"
                                        placeholder="Enter plot stage"
                                        value={plotStage}
                                        onChange={(e) => setPlotStage(Math.max(0, parseInt(e.target.value, 10) || 0))}
                                        required
                                        min={0}
                                        disabled={isViewOnly}
                                        theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                                    />
                                </div>

                                {/* Next Stage Field */}
                                <div>
                                    <Label htmlFor="nextStage" value="Next Stage"/>
                                    <TextInput
                                        id="nextStage"
                                        type="number"
                                        placeholder="Enter next stage"
                                        value={nextStage}
                                        onChange={(e) => setNextStage(Math.max(0, parseInt(e.target.value, 10) || 0))}
                                        required
                                        min={0}
                                        disabled={isViewOnly}
                                        theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                                    />
                                </div>
                            </div>

                            <div className="flex gap-4">
                                {/* Required for Stage Completion Checkbox */}
                                <div className="flex items-center">
                                    <Checkbox
                                        id="isRequiredForStage"
                                        checked={isRequiredForStage}
                                        onChange={(e) => setIsRequiredForStage(e.target.checked)}
                                        disabled={isViewOnly}
                                    />
                                    <Label htmlFor="isRequiredForStage" className="ml-2">
                                        Required For Next Stage
                                    </Label>
                                </div>

                                {/* Advance Stage on Trigger Checkbox */}
                                <div className="flex items-center">
                                    <Checkbox
                                        id="advanceStageOnTrigger"
                                        checked={advanceStageOnTrigger}
                                        onChange={(e) => setAdvanceStageOnTrigger(e.target.checked)}
                                        disabled={isViewOnly}
                                    />
                                    <Label htmlFor="advanceStageOnTrigger" className="ml-2">
                                        Advance Next Immediately
                                    </Label>
                                </div>
                            </div>

                            {/* Unlock Plot Lines Field */}
                            <div>
                                <Label htmlFor="unlockPlotLines" value="Unlocks Plot Lines"/>
                                <Select
                                    id="unlockPlotLines"
                                    multiple
                                    value={unlockPlotLines}
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selected = [];
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].selected) {
                                                selected.push(options[i].value);
                                            }
                                        }
                                        setUnlockPlotLines(selected);
                                    }}
                                    required
                                    disabled={isViewOnly}
                                >
                                    {availablePlotLines
                                        .filter((line) => line.index !== parseInt(mainPlotLine, 10)) // Filter out the current plot line
                                        .map((line) => (
                                            <option key={line.index} value={line.index}>
                                                {line.name} ({line.index})
                                            </option>
                                        ))}
                                </Select>
                                <small className="text-gray-500">
                                    Select one or more plot lines that will be unlocked upon completion of this plot element.
                                </small>
                            </div>
                        </div>

                        {/* Right Column - Prerequisites */}
                        <div className="space-y-6">
                            {/* Trigger Field */}
                            <div>
                                <Label htmlFor="trigger" value="Trigger (Criteria)"/>
                                <Textarea
                                    id="trigger"
                                    placeholder="Enter trigger condition or criteria"
                                    theme={TextAreaTheme}
                                    value={trigger}
                                    onChange={(e) => setTrigger(e.target.value)}
                                    required
                                    rows={3}
                                    disabled={isViewOnly}
                                />
                            </div>

                            {/* Prerequisite Locations */}
                            <div>
                                <Label htmlFor="prerequisiteLocations" value="Prerequisite Locations"/>
                                <Select
                                    id="prerequisiteLocations"
                                    multiple
                                    value={selectedLocations}
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selected = [];
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].selected) {
                                                selected.push(options[i].value);
                                            }
                                        }
                                        setSelectedLocations(selected);
                                    }}
                                    disabled={isViewOnly}
                                >
                                    {availableLocations.map((loc) => (
                                        <option key={loc._id} value={loc._id}>
                                            {loc.name}
                                        </option>
                                    ))}
                                </Select>
                                <small className="text-gray-500">
                                    Select locations that must be present for this plot element's trigger to be
                                    evaluated.
                                </small>
                            </div>

                            {/* Prerequisite Characters */}
                            <div>
                                <Label htmlFor="prerequisiteCharacters" value="Prerequisite Characters"/>
                                <Select
                                    id="prerequisiteCharacters"
                                    multiple
                                    value={selectedCharacters}
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selected = [];
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].selected) {
                                                selected.push(options[i].value);
                                            }
                                        }
                                        setSelectedCharacters(selected);
                                    }}
                                    disabled={isViewOnly}
                                >
                                    {availableCharacters.map((char) => (
                                        <option key={char._id} value={char._id}>
                                            {char.name}
                                        </option>
                                    ))}
                                </Select>
                                <small className="text-gray-500">
                                    Select characters that must be present for this plot element's trigger to be
                                    evaluated.
                                </small>
                            </div>

                            {/* Prerequisite Items */}
                            <div>
                                <Label htmlFor="prerequisiteItems" value="Prerequisite Items"/>
                                <Select
                                    id="prerequisiteItems"
                                    multiple
                                    value={selectedItems}
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selected = [];
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].selected) {
                                                selected.push(options[i].value);
                                            }
                                        }
                                        setSelectedItems(selected);
                                    }}
                                    disabled={isViewOnly}
                                >
                                    {availableItems.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Select>
                                <small className="text-gray-500">
                                    Select items that must be present for this plot element's trigger to be evaluated.
                                </small>
                            </div>

                            {/* Prerequisite Plot Lines */}
                            <div>
                                <Label htmlFor="requiredPlotLines" value="Prerequisite Plot Lines"/>
                                <Select
                                    id="requiredPlotLines"
                                    multiple
                                    value={requiredPlotLines}
                                    onChange={(e) => {
                                        const options = e.target.options;
                                        const selected = [];
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].selected) {
                                                selected.push(options[i].value);
                                            }
                                        }
                                        setRequiredPlotLines(selected);
                                    }}
                                    disabled={isViewOnly}
                                >
                                    {availablePlotLines
                                        .filter((line) => line.index !== parseInt(mainPlotLine, 10)) // Filter out the current plot line
                                        .map((pl) => (
                                            <option key={pl.index} value={pl.index}>
                                                {pl.name} ({pl.index})
                                            </option>
                                        ))}
                                </Select>
                                <small className="text-gray-500">
                                    Select plot lines that must be completed before this plot element's trigger condition is evaluated.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {/* Submit and Action Buttons */}
                <div className="flex justify-between w-full">
                    {!isViewOnly ? (
                        <>
                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={loading}
                                theme={ButtonTheme}
                                color="blue"
                            >
                                {loading ? 'Saving...' : plotElementToEdit ? 'Save' : 'Add'}
                                {!plotElementToEdit &&
                                    <><GiQuillInk size={16} className="ml-1 translate-x-1"/>1</>
                                }
                            </Button>
                            {plotElementToEdit && (
                                <Button
                                    color="red"
                                    onClick={handleDelete}
                                    disabled={loading}
                                    theme={ButtonTheme}
                                >
                                    Delete
                                </Button>
                            )}
                            <Button
                                color="gray"
                                onClick={() => {
                                    if (plotElementToEdit === null) onClose();
                                    else setIsViewOnly(true);
                                }}
                                disabled={loading}
                                theme={ButtonTheme}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            {plotElementToEdit && (
                                <Button
                                    color="red"
                                    onClick={handleDelete}
                                    disabled={loading}
                                    theme={ButtonTheme}
                                >
                                    Delete
                                </Button>
                            )}
                            <Button
                                color="gray"
                                onClick={onClose}
                                disabled={loading}
                                theme={ButtonTheme}
                            >
                                Close
                            </Button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

PlotElementFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    plotElementToEdit: PropTypes.object,
    story: PropTypes.object.isRequired,
    viewOnly: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
};

PlotElementFormModal.defaultProps = {
    plotElementToEdit: null,
    viewOnly: false,
};

export default PlotElementFormModal;
