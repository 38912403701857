import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {useSession} from "../../context/SessionContext";
import {useGoogleLogin} from "@react-oauth/google";
import axios from "axios";
import {Button} from "flowbite-react";

const stripePromise = loadStripe('pk_live_51PsruZP6sYclB5gVUz773o0qAGDR0lwURKtMSmh6fQNK2CMteysMWg2cWIBY3ql3KiaJWbKQyeReK8uma3y6S13g00yWTaJprX');

function CheckoutPage() {
    const location = useLocation();
    const { product } = location.state || {};
    const { session, setSession } = useSession();

    useEffect(() => {
        if (product && session) {
            // Call backend to create Checkout Session
            fetch(`${process.env.REACT_APP_API_URL}/payment/create`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${session.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product }),
            })
                .then(response => response.json())
                .then(async (session) => {
                    const stripe = await stripePromise;
                    // Redirect to Stripe Checkout
                    const { error } = await stripe.redirectToCheckout({ sessionId: session.id });
                    if (error) {
                        console.error('Stripe redirect error:', error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [product, session]);

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                const accessToken = codeResponse.access_token;
                // Send the access token to the backend to authenticate the user
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth?access_token=${accessToken}`);
                const sessionData = await response.data // get user session

                // Now you can manage the user session on the client-side
                setSession(sessionData);
            } catch (error) {
                console.error("Error during authentication:", error);
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    if (!product) {
        return <div className="text-center mt-10">No product selected.</div>;
    }

    if (!session) {
        return (
            <div className="flex justify-center items-start min-h-screen pt-10">
                <div className="text-center">
                    <div className=" text-2xl text-center mt-10">Please sign-in to checkout.</div>
                    <div className="mt-8 flex justify-center">
                        <Button
                            onClick={login}
                            color={'gray'}
                        >
                            Sign In
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="text-center mt-10">
            <h2 className="text-2xl font-bold">Redirecting to Checkout...</h2>
            <p>Please wait while we take you to the payment page.</p>
        </div>
    );
}

export default CheckoutPage;
