// src/components/tilesets/TilesetList.js

import React, { useState, useEffect } from 'react';
import { Button, Table, Spinner, Alert } from 'flowbite-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import TilesetFormModal from './TilesetFormModal';

const ListTilesets = ({ world }) => {
    const [tilesets, setTilesets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTilesets = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tileset/list`, {
                params: { worldId: world._id },
            });
            setTilesets(response.data);
            setError(null);
        } catch (err) {
            console.error('Error fetching tilesets:', err);
            setError('Failed to load tilesets.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (world) {
            fetchTilesets();
        }
    }, [world]);

    const handleTilesetSubmit = (updatedTileset) => {
        if (updatedTileset) {
            if (tilesets.some(ts => ts._id === updatedTileset._id)) {
                // Update existing tileset in the list
                setTilesets(tilesets.map(ts => ts._id === updatedTileset._id ? updatedTileset : ts));
            } else {
                // Add new tileset to the list
                setTilesets([...tilesets, updatedTileset]);
            }
        }
    };

    const handleDelete = async (tilesetId) => {
        if (!window.confirm('Are you sure you want to delete this tileset?')) return;

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/tileset/${tilesetId}`);
            setTilesets(tilesets.filter(ts => ts._id !== tilesetId));
        } catch (err) {
            console.error('Error deleting tileset:', err);
            alert('Failed to delete tileset. Please try again.');
        }
    };

    return (
        <div className="p-4 shadow-md rounded-md">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Tilesets</h2>
                <TilesetFormModal onSubmit={handleTilesetSubmit} world={world} />
            </div>

            {loading ? (
                <div className="flex justify-center">
                    <Spinner aria-label="Loading..." />
                </div>
            ) : error ? (
                <Alert color="failure">
                    <span>
                        <span className="font-medium">Error!</span> {error}
                    </span>
                </Alert>
            ) : (
                <Table striped>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Image</Table.HeadCell>
                        <Table.HeadCell>Tile Size</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {tilesets.map(tileset => (
                            <Table.Row key={tileset._id} className="bg-white">
                                <Table.Cell className="whitespace-nowrap font-medium">
                                    {tileset.name}
                                </Table.Cell>
                                <Table.Cell>
                                    <img src={tileset.imageSrc} alt={tileset.name} className="h-16 w-16 object-cover" />
                                </Table.Cell>
                                <Table.Cell>{tileset.tileSize}px</Table.Cell>
                                <Table.Cell>
                                    <div className={"flex flex-col"}>
                                        <TilesetFormModal
                                            tilesetData={tileset}
                                            onSubmit={handleTilesetSubmit}
                                            world={world}
                                        />
                                        <Button
                                            size="sm"
                                            color="failure"
                                            onClick={() => handleDelete(tileset._id)}
                                            className="ml-2 flex"
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
};

ListTilesets.propTypes = {
    world: PropTypes.object.isRequired,
};

export default ListTilesets;
