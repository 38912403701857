import React, {useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {Breadcrumb, List, ListItem, Tabs} from "flowbite-react";
import {SlMap, SlPicture} from "react-icons/sl";
import { RiGlobeFill } from "react-icons/ri";
import { GrMapLocation } from "react-icons/gr";
import { SlPeople } from "react-icons/sl";
import { TbMoneybag } from "react-icons/tb";
import {BsBackpack4Fill, BsJournalBookmark} from "react-icons/bs";
import {IoBookmarksOutline, IoBookmarksSharp, IoBookSharp, IoHomeSharp} from "react-icons/io5";
import ListPlot from "../../components/story/plot/ListPlot";
import StoryOverview from "../../components/story/StoryOverview";
import ListCharacters from "../../components/story/character/ListCharacters";
import { IoMdShare } from "react-icons/io";
import ShareStory from "../../components/share/ShareStory";
import {useSession} from "../../context/SessionContext";
import {debounce} from "lodash";
import MyAdventuresPage from "./MyAdventuresPage";
import LocationList from "../../components/story/location/LocationList";


function StoryPage() {
    const { id } = useParams();
    const [story, setStory] = useState(null);
    const { session, setSession } = useSession();
    const navigate = useNavigate(); // Initialize useNavigate


    const handleResize = debounce(() => {
        // handle resize logic
    }, 200); // Debounce by 100ms

    // fetch story data using the id, template is inside the backticks

    const fetchStory = async () => {
        if (!session.token) return;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/story/${id}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`, // Set header directly
                    //'Cache-Control': 'no-cache'
                }
            });
            setStory(response.data);
        } catch (error) {
            console.error('Failed to fetch story:', error);
        }
    };

    useEffect(() => {
        fetchStory();
    }, [id, session?.token]);

    const handleRefreshStory = () => {
        fetchStory();
    };


    if (!story) {
        return <div>Loading...</div>;
    }

    if (story.generationProgress) {
        axios.get(`${process.env.REACT_APP_API_URL}/story/${id}/status`, {
            headers: {
                'Authorization': `Bearer ${session.token}` // Set header directly
                //'Cache-Control': 'no-cache'
            }
        })
            .then(response => {
                if (response.data.generationProgress) {
                    navigate('/story/' + story._id + '/status');
                }
            })
            .catch(error => {
                console.error('Failed to fetch story status:', error);
            });
    }

    return (
        <div>

            {session.user.permissionLevel >= 3 &&
                <Link to={`/world/${story.world._id}`} className="flex flex-row  justify-center items-center mx-auto hover:text-blue-500">
                    <RiGlobeFill/>
                    <div className={"pl-1 pr-6"}>World - "{story.world.name}"</div>
                </Link>
            }
            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <IoBookSharp size={24} className={"mx-2"}/>
                <div className={"text-2xl font-bold pl-1 pr-6"}>Story - "{story.name}"</div>
                {
                    story.isPublished && <div
                        className={"text-lg font-semibold text-gray-700 dark:text-gray-900 bg-yellow-400 dark:bg-yellow-600 py-1 px-4 rounded-lg"}>
                        Published
                    </div>
                }
            </div>
            <Tabs aria-label="Default tabs" style="underline">
            <Tabs.Item title="Overview" icon={SlPicture}>
                    <StoryOverview story={story} session={session}/>
                </Tabs.Item>
                <Tabs.Item title="Plot" icon={BsJournalBookmark}>
                    <ListPlot story={story}/>
                </Tabs.Item>
                <Tabs.Item title="Locations" icon={GrMapLocation}>
                    <LocationList story={story}/>
                </Tabs.Item>
                {/*<Tabs.Item title="Items" icon={TbMoneybag}>*/}
                {/*    Coming Soon TM*/}
                {/*</Tabs.Item>*/}
                <Tabs.Item title="Characters" icon={SlPeople}>
                    <ListCharacters story={story} onRefreshStory={handleRefreshStory}/>
                </Tabs.Item>
                <Tabs.Item  title="Adventures" icon={IoBookmarksOutline}>
                    <MyAdventuresPage story={story}/>
                </Tabs.Item>
                {/*<Tabs.Item  title="Reviews" icon={TfiCommentAlt}>*/}
                {/*    <MyAdventures/>*/}
                {/*</Tabs.Item>*/}
                {(story.entitlement.grantLevel > 2 && session.user.permissionLevel > 4) && (
                    <Tabs.Item title="Sharing" icon={IoMdShare}>
                        <ShareStory story={story}/>
                    </Tabs.Item>
                )}
            </Tabs>
        </div>
    )
}

export default StoryPage;
