import { Card } from "flowbite-react";

function PriceCard({handleBuy, planName, period, price, saving, included, excluded, imageUrl, comingSoon = false }) {
    const [whole, decimal] = String(price.toFixed(2)).split('.');

    let displayText = "Choose plan";

    if (comingSoon) {
        displayText = "Coming Soon";
    } else if (period === null) {
        displayText = "Buy";
    }

    return (
        <Card theme={priceCardTheme} className="w-full dark:bg-gray-600" imgSrc={imageUrl}>
            <h5 className="text-xl font-medium text-gray-500 dark:text-gray-300">{planName}</h5>
            {saving && <div className={"font-bold"}> Save {saving} </div>}
            {!saving && <div> No Saving </div>}
            <div className="flex items-baseline text-gray-900 dark:text-gray-300">
                <span className="text-3xl font-semibold">$</span>
                <span className="text-5xl font-extrabold tracking-tight">{whole}</span>
                {decimal && <span className="text-3xl font-extrabold tracking-tight">.{decimal}</span>}
                <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-300">{period && `/${period}`}</span>
            </div>
            <ul className="my-4 space-y-4">
                {included.map((item, index) => (
                    <li key={index} className="flex space-x-3">
                        <svg
                            className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-300">
                            {item}
                        </span>
                    </li>
                ))}
                {excluded.map((item, index) => (
                    <li key={index} className="flex space-x-3 line-through decoration-gray-500">
                        <svg
                            className="h-5 w-5 shrink-0 text-gray-400 dark:text-gray-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="text-base font-normal leading-tight text-gray-500">
                            {item}
                        </span>
                    </li>
                ))}
            </ul>
            <button
                type="button"
                className={`inline-flex w-full justify-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4 ${comingSoon
                    ? "bg-gray-400 cursor-not-allowed dark:bg-gray-500"
                    : "bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-200 dark:focus:ring-cyan-900"
                }`}
                onClick={handleBuy}
                disabled={comingSoon}
            >
                {displayText}
            </button>
        </Card>
    );
}

export default PriceCard;

const priceCardTheme = {
    root: {
        base: "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
        children: "flex h-full flex-col justify-center gap-1 p-4",
        horizontal: {
            off: "flex-col",
            on: "flex-col md:max-w-xl md:flex-row",
        },
        href: "hover:bg-gray-100 dark:hover:bg-gray-700",
    },
    img: {
        base: "",
        horizontal: {
            off: "rounded-t-lg",
            on: "h-80 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
        },
    },
};
