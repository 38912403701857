import { Navigate } from "react-router-dom";
import { useSession } from './SessionContext';
import { useEffect } from 'react';
import axios from 'axios';

function PrivateRoute({ children }) {
    const { session } = useSession();

    // If no session token, redirect to the landing page or login
    if (!session?.token) {
        return <Navigate to="/" replace />;
    }
    // If session exists, render the children components
    return children;
}

export default PrivateRoute;
