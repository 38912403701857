// src/common/PropertiesManager.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const PropertiesManager = ({ labels, objectProperties, setObjectProperties }) => {
    const [aggregatedProperties, setAggregatedProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProperties = async () => {
            if (!labels || labels.length === 0) {
                setAggregatedProperties([]);
                return;
            }

            setLoading(true);
            setError(null);
            let allProperties = {};

            try {
                for (let labelId of labels) {
                    console.log(`Fetching properties for label ID: ${labelId}`);
                    try {
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/label/${labelId}`);
                        console.log(`Response for label ID ${labelId}:`, response.data);
                        if (response.data && response.data.properties) {
                            response.data.properties.forEach(prop => {
                                if (!allProperties[prop.name]) {
                                    allProperties[prop.name] = {};
                                }
                                prop.attributes.forEach(attr => {
                                    allProperties[prop.name][attr.name] = attr.value;
                                });
                            });
                        } else {
                            console.warn(`No properties found for label ID: ${labelId}`);
                        }
                    } catch (err) {
                        console.error(`Failed to fetch label with ID: ${labelId}`, err);
                        setError(`Failed to fetch properties for label ID: ${labelId}`);
                        return; // Exit if a label fetch fails
                    }
                }

                // Apply object-level overrides
                objectProperties.forEach(prop => {
                    if (!allProperties[prop.name]) {
                        allProperties[prop.name] = {};
                    }
                    prop.attributes.forEach(attr => {
                        allProperties[prop.name][attr.name] = attr.value;
                    });
                });

                // Convert to array for display
                const propsArray = Object.entries(allProperties).map(([name, attributes]) => ({
                    name,
                    attributes: Object.entries(attributes).map(([key, value]) => ({ name: key, value })),
                }));

                console.log('Aggregated Properties:', propsArray);
                setAggregatedProperties(propsArray);
            } catch (err) {
                console.error("Failed to aggregate properties", err);
                setError("Failed to aggregate properties.");
            } finally {
                setLoading(false);
            }
        };

        fetchProperties();
    }, [labels, objectProperties]);

    const handleAttributeChange = (propName, attrName, value) => {
        // Update objectProperties accordingly
        const updatedProperties = [...objectProperties];
        const propIndex = updatedProperties.findIndex(p => p.name === propName);
        if (propIndex === -1) {
            updatedProperties.push({ name: propName, attributes: [{ name: attrName, value }] });
        } else {
            const attrIndex = updatedProperties[propIndex].attributes.findIndex(a => a.name === attrName);
            if (attrIndex === -1) {
                updatedProperties[propIndex].attributes.push({ name: attrName, value });
            } else {
                updatedProperties[propIndex].attributes[attrIndex].value = value;
            }
        }
        setObjectProperties(updatedProperties);
    };

    if (loading) {
        return <div className="text-gray-500 dark:text-gray-400">Loading properties...</div>;
    }

    if (error) {
        return <div className="text-red-500 dark:text-red-400">Error: {error}</div>;
    }

    if (aggregatedProperties.length === 0) {
        return <div className="text-gray-500 dark:text-gray-400">No properties available.</div>;
    }

    return (
        <div>
            {aggregatedProperties.map(prop => (
                <div key={prop.name} className="mb-4">
                    <h3 className="font-bold">{prop.name}</h3>
                    <div className="ml-4">
                        {prop.attributes.map(attr => (
                            <div key={attr.name} className="flex items-center mb-2">
                                <label className="w-32">{attr.name}</label>
                                <input
                                    type="text"
                                    value={attr.value}
                                    onChange={(e) => handleAttributeChange(prop.name, attr.name, e.target.value)}
                                    className="border p-1 rounded w-32 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

PropertiesManager.propTypes = {
    labels: PropTypes.array.isRequired,
    objectProperties: PropTypes.array.isRequired,
    setObjectProperties: PropTypes.func.isRequired,
};

export default PropertiesManager;
