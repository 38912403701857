import {Button, Card} from "flowbite-react";
import ViewLore from "../../oldComponents/view/ViewLore";
import {Link} from "react-router-dom";
import {BsTrash} from "react-icons/bs";
import React from "react";
import axios from "axios";

function CharCard({ character, handleSubmit }) {
    return (
        <Card theme={storyCardTheme}
              key={character._id}
              imgAlt="Meaningful alt text for an image that is not purely decorative"
              imgSrc={`${process.env.REACT_APP_API_URL}/image/attached/${character._id}?d=4`}
        >
            <div className="flex flex-col h-full justify-between">
                <div>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-2">
                        {character.name}
                    </h5>
                    <p className="font-normal text-sm text-gray-700 dark:text-gray-400 mb-4">
                        {character.description}
                    </p>
                </div>
                <Button onClick={(event) => handleSubmit(character)} className="mt-auto">
                    Select
                </Button>
            </div>
        </Card>
    );
}
export default CharCard;


const storyCardTheme = {
    "root": {
        "base": "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-900 m-1 max-w-s mx-auto",
        "children": "flex h-full flex-col gap-4 p-4",
        "horizontal": {
            "off": "flex-col",
            "on": "flex-col md:flex-row"
        },
        "href": "hover:bg-gray-100 dark:hover:bg-gray-700"
    },
    "img": {
        "base": "",
        "horizontal": {
            "off": "rounded-t-lg",
            "on": "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-64 md:rounded-none md:rounded-l-lg"
        }
    }
}