// src/components/WorldModal.js

import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'flowbite-react';
import axios from 'axios';
import { FaStar, FaStarHalf } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import * as response from "autoprefixer";
import {ModalTheme} from "../../themes/ModalTheme";

const WorldDetailModal = ({ world, grantLevel, session, isOpen, onClose }) => {
    const [adventures, setAdventures] = useState([]);
    const [topReviews, setTopReviews] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen && world) {
            fetchTopReviews();
        }
    }, [isOpen, world]);

    // const fetchAdventures = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/adventure`, {
    //             params: { world: world._id, sortBy: 'updateAt', order: 'desc', limit: 5 },
    //             headers: { 'Authorization': `Bearer ${session.token}` }
    //         });
    //         setAdventures(response.data);
    //     } catch (error) {
    //         console.error('Error fetching adventures:', error);
    //     }
    // };

    const fetchTopReviews = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/review`, {
                params: { worldId: world._id, sortBy: 'rating', order: 'desc', limit: 5 },
                headers: { 'Authorization': `Bearer ${session.token}` }
            });
            setTopReviews(response.data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    // const handleContinue = async () => {
    //     // sort adventures by last updatedAt and pick the first/latest one to navigate to
    //     adventures.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    //     navigate(`/adventure/${adventures[0]._id}`);
    // };

    // const handleStart = async () => {
    //     // Implement logic to start a new adventure
    //     try {
    //         const newAdventure = {world: world._id, name:"New Adventure"};
    //         const result = await axios.post(`${process.env.REACT_APP_API_URL}/adventure`, newAdventure, {
    //             headers: { 'Authorization': `Bearer ${session.token}` }
    //         });
    //         //setAdventures(response.data);
    //         navigate(`/adventure/${result.data._id}`);
    //     } catch (error) {
    //         console.error('Error creating adventure:', error);
    //     }
    // };

    const handleEdit = async () => {
        // Implement logic to edit the world
        navigate(`/world/${world._id}`);
    };

    // const handleAdventures = async () => {
    //     // Implement logic to edit the world
    //     navigate(`/world/${world._id}/adventures`);
    // };

    const handleReview = async () => {
        // Implement logic to add a new review
    };

    function renderStars(score) {
        let fullStars = Math.floor(score);
        let halfStar = score % 1 !== 0;
        let stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<FaStar key={i} color="gold" />);
        }

        if (halfStar) {
            stars.push(<FaStarHalf key={fullStars} color="gold" />);
        }

        return stars;
    }
    const startNew = (!adventures || adventures.length === 0);

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size='5xl'>
            <Modal.Header>
                <div className={"flex items-center gap-3"}>
                    <div>{world.name}</div>
                    <div>{renderStars(world.rating)}</div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col md:flex-row">
                    <img
                        src={`${process.env.REACT_APP_API_URL}/image/attached/${world._id}?d=2`}
                        alt={world.name}
                        className="w-full md:w-60 h-auto object-cover rounded-lg"
                    />
                    <div className="mt-4 md:mt-0 md:ml-6 dark:text-gray-200">
                        <p className="">{world.description}</p>
                        <p className="mt-2"><strong>Setting:</strong> {world.setting}</p>
                        <p className="mt-2"><strong>Tags:</strong> {world.tags.join(', ')}</p>
                        <div className="mt-4">
                            <h4 className="text-lg font-semibold">Top Reviews</h4>
                            {topReviews.length > 0 ? (
                                topReviews.map(review => (
                                    <div key={review._id} className="mt-2 p-2 bg-gray-100 rounded">
                                        <p><strong>{review.user.name}</strong>: {review.message}</p>
                                        <p>Rating: {review.rating}/5</p>
                                    </div>
                                ))
                            ) : (
                                <p>No reviews yet.</p>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/*<Button onClick={startNew ? handleStart : handleContinue} color="success">*/}
                {/*    {startNew ? 'Start New Adventure' : 'Continue Adventure'}*/}
                {/*</Button>*/}
                {grantLevel >= 3 && (
                    <Button onClick={handleEdit} color="gray">Edit World</Button>
                )}
                {/*<Button onClick={handleReview} color="info">Review</Button>*/}

                {/*<Button onClick={onClose} color="gray">Close</Button>*/}
            </Modal.Footer>
        </Modal>
    );
};

export default WorldDetailModal;
