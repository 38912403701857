// src/components/news/AddEditNewsModal.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Modal,
    Button,
    TextInput,
    Textarea,
    Label, Checkbox,
} from 'flowbite-react';
import { GiQuillInk } from "react-icons/gi";
import { IoNewspaper } from "react-icons/io5";
import Select from "react-select";
import { TextAreaTheme } from "../../../themes/TextAreaTheme";
import SelectTheme from "../../../themes/SelectTheme";
import TagsInput from "../../common/TagsInput";
import useSelectThemeStyle from "../../../themes/SelectTheme";

const AddEditNewsModal = ({
                              isOpen,
                              onClose,
                              articleToEdit,
                              onSuccess,
                              session
                          }) => {
    const [name, setName] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [published, setPublished] = useState(false);
    const [description, setDescription] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);
    const [segments, setSegments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    useEffect(() => {
        if (articleToEdit) {
            setName(articleToEdit.name || '');
            setDescription(articleToEdit.description || '');
            setImgUrl(articleToEdit.imgUrl || '');
            setCategory(articleToEdit.category || '');
            setTags(articleToEdit.tags || []);
            setPublishDate(formatDate(articleToEdit.publishedAt || articleToEdit.createdAt || Date.now()));
            setPublished(articleToEdit.published || false);
            // Initialize segments with existing _id
            setSegments(
                (articleToEdit.segments || []).map(segment => ({
                    _id: segment._id, // Use _id from backend
                    title: segment.title,
                    content: segment.content,
                    by: segment.by,
                }))
            );
        } else {
            setName('');
            setDescription('');
            setImgUrl('');
            setCategory('');
            setTags([]);
            setSegments([]);
            setPublished(false);
            setPublishDate(formatDate(Date.now()));
        }
    }, [articleToEdit]);

    const handleSegmentChange = (id, field, value) => {
        setSegments(prevSegments =>
            prevSegments.map(segment =>
                segment._id === id ? {...segment, [field]: value} : segment
            )
        );
    };

    const addSegment = () => {
        setSegments(prevSegments => [
            ...prevSegments,
            {_id: `temp-${Date.now()}`, title: '', content: ''} // Temporary _id for new segments
        ]);
    };

    const removeSegment = (id) => {
        setSegments(prevSegments => prevSegments.filter(segment => segment._id !== id));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            // Validate segments
            for (let i = 0; i < segments.length; i++) {
                const seg = segments[i];
                if (!seg.title.trim() || !seg.content.trim()) {
                    setError(`Segment ${i + 1} requires both title and content.`);
                    setLoading(false);
                    return;
                }
            }

            const data = {
                name,
                description,
                imgUrl,
                category,
                tags,
                published,
                publishedAt: publishDate,
                segments: segments.map(seg => ({
                    // If segment has a real _id, include it for updating
                    ...(seg._id && !seg._id.startsWith('temp-') && {_id: seg._id}),
                    title: seg.title,
                    content: seg.content,
                    by: seg.by,
                })),
            };

            if (articleToEdit) {
                // Update existing article
                await axios.put(`${process.env.REACT_APP_API_URL}/news/${articleToEdit._id}`, data, {
                    headers: {
                        'Authorization': `Bearer ${session.token}`
                    }
                });
            } else {
                // Create new article
                await axios.post(`${process.env.REACT_APP_API_URL}/news`, data, {
                    headers: {
                        'Authorization': `Bearer ${session.token}`
                    }
                });
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving news article:', err);
            setError('Failed to save news article.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!articleToEdit) return;
        if (!window.confirm('Are you sure you want to delete this news article?')) return;

        setLoading(true);
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/news/${articleToEdit._id}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting news article:', err);
            setError('Failed to delete news article.');
        } finally {
            setLoading(false);
        }
    };

    const tagOptions = [
        {value: 'breaking', label: 'Breaking'},
        {value: 'announcement', label: 'Announcement'},
        {value: 'update', label: 'Update'},
        // Add more predefined tags if necessary
    ];

    const categoryOptions = [
        {value: 'sports', label: 'Sports'},
        {value: 'politics', label: 'Politics'},
        {value: 'entertainment', label: 'Entertainment'},
        {value: 'devblog', label: 'Dev Blog'},
        // Add more categories as needed
    ];

    // const handleTagChange = (selectedOptions) => {
    //     setTags(selectedOptions ? selectedOptions.map(option => option.value) : []);
    // };

    const handleCategoryChange = (selectedOption) => {
        setCategory(selectedOption ? selectedOption.value : '');
    };

    return (
        <Modal
            show={isOpen}
            onClose={onClose}
            size="4xl"
        >
            <Modal.Header>
                <div className="flex justify-between items-center w-full space-x-2">
                    <IoNewspaper size={24}/>
                    <span className="text-2xl font-bold">
                        {articleToEdit ? 'Edit News Article' : 'Add News Article'}
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="mb-4">
                        <Label color="failure" value={error}/>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                    {/* Name Field */}
                    <div>
                        <Label htmlFor="name" value="Name"/>
                        <TextInput
                            id="name"
                            type="text"
                            placeholder="Enter article name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    {/* Description Field */}
                    <div>
                        <Label htmlFor="description" value="Description"/>
                        <Textarea
                            theme={TextAreaTheme}
                            id="description"
                            placeholder="Enter article description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                            rows={2}
                        />
                    </div>
                    <div>
                        <Label htmlFor="date" value="Published Date"/>
                        <TextInput
                            id="date"
                            type="date"
                            value={publishDate}
                            onChange={(e) => setPublishDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className={'-translate-y-1 translate-x-1'}>
                        <Checkbox
                            id="published"
                            checked={published}
                            onChange={(e) => setPublished(e.target.checked)}
                        />
                        <Label className={'pl-2'} htmlFor="published" value="Is Published?"/>
                    </div>

                    {/* Image URL Field */}
                    <div>
                        <Label htmlFor="imgUrl" value="Image URL"/>
                        <TextInput
                            id="imgUrl"
                            type="url"
                            placeholder="Enter image URL"
                            value={imgUrl}
                            onChange={(e) => setImgUrl(e.target.value)}
                            required
                        />
                    </div>
                    {/* Category Field */}
                    <div>
                        <Label htmlFor="category" value="Category"/>
                        <Select
                            id="category"
                            styles={useSelectThemeStyle()}
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                            value={categoryOptions.find(option => option.value === category)}
                            placeholder="Select a category..."
                            isClearable
                            required
                        />
                    </div>
                    {/* Tags Field */}
                    {/*<div>*/}
                    {/*    <Label htmlFor="tags" value="Tags"/>*/}
                    {/*    <TagsInput*/}
                    {/*        id="tags"*/}
                    {/*        placeholder="Select or type to add tags..."*/}
                    {/*        tags={tagOptions.filter(option => tags.includes(option.value))}*/}
                    {/*        onChange={handleTagChange}*/}
                    {/*        required*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/* Segments Section */}
                    <div className="border-t pt-4">
                        <div className="flex justify-between items-center mb-2">
                            <Label value="Segments"/>
                            <Button
                                type="button"
                                onClick={addSegment}
                                color="green"
                                size="xs"
                            >
                                Add Segment
                            </Button>
                        </div>
                        {segments.length === 0 && (
                            <p className="text-gray-500">No segments added yet.</p>
                        )}
                        {segments.map((segment, index) => (
                            <div key={segment._id} className="mb-4 p-4 border rounded">
                                <div className="flex justify-between items-center mb-2">
                                    <span
                                        className="font-semibold text-gray-700 dark:text-gray-200">Segment {index + 1}</span>
                                    <Button
                                        type="button"
                                        onClick={() => removeSegment(segment._id)}
                                        color="failure"
                                        size="xs"
                                    >
                                        Remove
                                    </Button>
                                </div>
                                <div className="mb-2">
                                    <Label htmlFor={`segment-title-${segment._id}`} value="Title"/>
                                    <TextInput
                                        id={`segment-title-${segment._id}`}
                                        type="text"
                                        placeholder="Enter segment title"
                                        value={segment.title}
                                        onChange={(e) => handleSegmentChange(segment._id, 'title', e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <Label htmlFor={`segment-author-${segment._id}`} value="Author"/>
                                    <TextInput
                                        id={`segment-author-${segment._id}`}
                                        type="text"
                                        placeholder="Enter author"
                                        value={segment.by}
                                        onChange={(e) => handleSegmentChange(segment._id, 'by', e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <Label htmlFor={`segment-content-${segment._id}`} value="Content"/>
                                    <Textarea
                                        theme={TextAreaTheme}
                                        id={`segment-content-${segment._id}`}
                                        placeholder="Enter segment content"
                                        value={segment.content}
                                        onChange={(e) => handleSegmentChange(segment._id, 'content', e.target.value)}
                                        required
                                        rows={4}
                                    />
                                </div>
                                {/* Optional: Engagement Fields */}
                                {/* <div className="mt-2">
                                    <Label htmlFor={`segment-likes-${segment._id}`} value="Likes" />
                                    <TextInput
                                        id={`segment-likes-${segment._id}`}
                                        type="number"
                                        placeholder="Enter number of likes"
                                        value={segment.likes}
                                        onChange={(e) => handleSegmentChange(segment._id, 'likes', e.target.value)}
                                        min="0"
                                    />
                                </div>
                                <div className="mt-2">
                                    <Label htmlFor={`segment-dislikes-${segment._id}`} value="Dislikes" />
                                    <TextInput
                                        id={`segment-dislikes-${segment._id}`}
                                        type="number"
                                        placeholder="Enter number of dislikes"
                                        value={segment.dislikes}
                                        onChange={(e) => handleSegmentChange(segment._id, 'dislikes', e.target.value)}
                                        min="0"
                                    />
                                </div> */}
                            </div>
                        ))}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex justify-between w-full">
                    <div className="flex space-x-2">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                            color="blue"
                        >
                            {loading ? 'Saving...' : articleToEdit ? 'Save Changes' : 'Add Article'}
                            <GiQuillInk size={16} className="ml-2"/>
                        </Button>
                        {articleToEdit && (
                            <Button
                                color="failure"
                                onClick={handleDelete}
                                disabled={loading}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                    <Button
                        color="gray"
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

AddEditNewsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    articleToEdit: PropTypes.object, // Pass the article object when editing
    onSuccess: PropTypes.func.isRequired, // Callback after successful add/edit/delete
    session: PropTypes.object.isRequired, // Assuming session is required for auth
};

AddEditNewsModal.defaultProps = {
    articleToEdit: null,
};

export default AddEditNewsModal;
