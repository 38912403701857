// src/components/wizard/NewLoreWizard.js

import React, { useState } from 'react';
import {
    Button,
    Textarea,
    Label,
    Modal,
    Spinner,
    Alert,
} from 'flowbite-react';
import { PiMagicWandFill } from 'react-icons/pi';
import axios from 'axios';
import {GiQuillInk} from "react-icons/gi";
import {ModalTheme} from "../../themes/ModalTheme";

function NewLoreWizard({ worldId, onSubmit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [creationMethod, setCreationMethod] = useState(null); // 'manual' or 'ai'
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [generatedLore, setGeneratedLore] = useState(null);

    const onCloseModal = () => {
        setIsOpen(false);
        setCreationMethod(null);
        setInputValue('');
        setLoading(false);
        setError(null);
        setGeneratedLore(null);
    };

    // Function to handle AI-generated lore
    const handleAIGenerate = async () => {
        if (!inputValue.trim()) {
            setError('Please enter a prompt for lore generation.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/lore/generate`, {
                world: worldId,
                prompt: inputValue.trim(),
            });

            const newLore = response.data; // Assuming API returns the created lore object
            setGeneratedLore(newLore);
            setInputValue('');
        } catch (err) {
            console.error('Error generating lore:', err);
            setError('Failed to generate lore. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Function to handle submission of AI-generated lore
    const handleSubmitGeneratedLore = async () => {
        // try {
        //     await axios.post(`${process.env.REACT_APP_API_URL}/lore`, generatedLore);
        //     if (onSubmit) onSubmit();
        //     onCloseModal();
        // } catch (err) {
        //     console.error('Error saving generated lore:', err);
        //     setError('Failed to save generated lore. Please try again.');
        // }
    };

    // Function to handle manual lore creation choice
    const handleManualCreate = () => {
        onCloseModal();
        // Optionally, open the AddEditLore modal directly if needed
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Generate Lore</Button>
            <Modal theme={ModalTheme} show={isOpen} size="lg" onClose={onCloseModal} dismissible>
                <Modal.Header>Create New Lore</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div>
                            <Label htmlFor="aiPrompt" value="AI Generation Prompt" />
                            <Textarea
                                id="aiPrompt"
                                placeholder="Enter a prompt for AI-generated lore..."
                                rows={4}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                required
                            />
                        </div>

                        {error && (
                            <Alert color="failure">
                                <span>
                                    <span className="font-medium">Error!</span> {error}
                                </span>
                            </Alert>
                        )}

                        <div className="flex space-x-4">
                            <Button
                                onClick={handleAIGenerate}
                                disabled={loading}
                                className="flex-1"
                            >
                                {loading ? <Spinner aria-label="Loading" size="sm" /> : 'Generate'}<GiQuillInk size={16} className="ml-1 translate-x-1" />2
                            </Button>
                            <Button
                                onClick={onCloseModal}
                                color="gray"
                                className="flex-1"
                            >
                                Cancel
                            </Button>
                        </div>

                        {generatedLore && (
                            <div className="mt-6 p-4 bg-green-100 rounded">
                                <h4 className="font-bold">Generated Lore</h4>
                                <p><strong>Name:</strong> {generatedLore.name}</p>
                                <p><strong>Description:</strong> {generatedLore.description}</p>
                                <p><strong>Tags:</strong> {(generatedLore.tags || []).join(', ')}</p>
                                {/* Add more fields as necessary */}
                                <Button
                                    onClick={handleSubmitGeneratedLore}
                                    className="mt-4"
                                    color="success"
                                >
                                    Save Generated Lore
                                </Button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NewLoreWizard;
