import React, { useState, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setupAxiosInterceptors } from "./SetupAxios";
import axios from "axios";

// Create a new context
export const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(() => {
        const storedSession = localStorage.getItem('session');
        return storedSession ? JSON.parse(storedSession) : null;
    });

    // Create Axios instance
    const api = React.useMemo(() => {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
        });
        setupAxiosInterceptors(() => session, setSession, instance); // Setup interceptors with the instance
        return instance;
    }, [session]);

    // Sync session with localStorage
    useEffect(() => {
        if (session) {
            localStorage.setItem('session', JSON.stringify(session));
        } else {
            localStorage.removeItem('session');
        }
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession, api }}>
            {children}
        </SessionContext.Provider>
    );
};

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Create a custom hook for using the session state
export const useSession = () => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};