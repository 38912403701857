import axios from 'axios';
import { useSession } from './SessionContext';

export const setupAxiosInterceptors = (getSession, setSession) => {
    // Request interceptor
    axios.interceptors.request.use(
        (config) => {
            const session = getSession(); // Get the latest session
            if (session?.token) {
                config.headers['Authorization'] = `Bearer ${session.token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Response interceptor
    axios.interceptors.response.use(
        (response) => {
            const newInkStatus = response.headers['ink-status'];
            if (newInkStatus) {
                setSession((prevSession) => ({
                    ...prevSession,
                    ink: JSON.parse(newInkStatus),
                }));
            }
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                console.log("Session expired or unauthorized. Clearing session and redirecting to login.");

                // Clear the session
                setSession(null);

                // Set flag in local storage
                localStorage.setItem('LoggedOutBanner', 'true');

                // Redirect to lock screen
                window.location.href = '/lockscreen';
            }
            return Promise.reject(error);
        }
    );
};