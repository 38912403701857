// import React from 'react';
// //import { Breadcrumb } from "flowbite-react";
// import { Breadcrumb, BreadcrumbItem } from "flowbite-react";
// import { useLocation, useParams } from 'react-router-dom';
// import { IoHomeSharp, IoBookSharp } from 'react-icons/io5';
// import { PiBookmarksSimpleFill } from 'react-icons/pi';
// import { RiGlobeFill } from 'react-icons/ri';
// import { useSession } from "../../context/SessionContext";
// import {debounce} from "lodash";
//
// const Breadcrumbs = ({labels = true}) => {
//     const { session } = useSession();
//     const location = useLocation();
//     const { id } = useParams(); // Use if you need to get id from the URL
//     const breadcrumbs = [];
//
//     const handleResize = debounce(() => {
//         // handle resize logic
//     }, 100); // Debounce by 100ms
//
//     const isCurrentLocation = (path) => location.pathname === path;
//
//     // // Calculate the title of the page
//     // const pageTitle = (() => {
//     //     if (location.pathname.includes('/adventure') && session.lastAdventure) {
//     //         return  session.lastAdventure.character ? `Playing - ${session.lastAdventure.character.name || 'unknown'}` : `Adventure - ${session.lastAdventure.name || ''}`;
//     //     } else if (location.pathname.includes('/story') && session.lastStory) {
//     //         return `Story - ${session.lastStory.name}`;
//     //     } else if (location.pathname.includes('/world') && session.lastWorld) {
//     //         return `World - ${session.lastWorld.name}`;
//     //     } else if (location.pathname.includes('/home')) {
//     //         return "Home";
//     //     // } else if (location.pathname.includes('/guide')) {
//     //     //     return "Documentation";
//     //     } else {
//     //         return '';
//     //     }
//     // })();
//
//
//     // Home breadcrumb
//     breadcrumbs.push(
//         <BreadcrumbItem
//             key="home"
//             href={isCurrentLocation('/home') ? undefined : "/home"}
//             icon={IoHomeSharp}
//         >
//             {labels && 'Home'}
//         </BreadcrumbItem>
//     );
//
//     // World breadcrumb, handle the current one
//     let currentWorld = null;
//     if (location.pathname.includes('/home') && session.lastWorld) {
//         currentWorld = session.lastWorld;
//     } else if (location.pathname.includes('/world') && session.lastWorld) {
//         currentWorld = session.lastWorld;
//     } else if (location.pathname.includes('/story') && session.lastStory) {
//         currentWorld = session.lastStory.world;
//     }
//
//     if (currentWorld) {
//         breadcrumbs.push(
//             <BreadcrumbItem
//                 key="world"
//                 href={isCurrentLocation(`/world/${currentWorld._id}`) ? undefined : `/world/${currentWorld._id}`}
//                 icon={RiGlobeFill}
//             >
//                 {labels && currentWorld.name}
//             </BreadcrumbItem>
//         );
//     }
//
//     // Story breadcrumb, handle the current one
//     let currentStory = null;
//     if (location.pathname.includes('/world') && session.lastStory) {
//         currentStory = session.lastStory;
//     }else if (location.pathname.includes('/story') && session.lastStory) {
//         currentStory = session.lastStory;
//     } else if (location.pathname.includes('/adventure') && session.lastAdventure?.story) {
//         currentStory = session.lastAdventure.story;
//     }else if (location.pathname.includes('/test') && session.lastAdventure?.story) {
//         currentStory = session.lastAdventure.story;
//     }
//
//     if (currentStory && (!currentWorld || currentStory.world._id === currentWorld._id)) {
//         breadcrumbs.push(
//             <BreadcrumbItem
//                 key="story"
//                 href={isCurrentLocation(`/story/${currentStory._id}`) ? undefined : `/story/${currentStory._id}`}
//                 icon={IoBookSharp}
//             >
//                 {labels && currentStory.name}
//             </BreadcrumbItem>
//         );
//     }
//
//
//     // Adventure breadcrumb, handle the current one
//     let currentAdventure = null;
//     if (location.pathname.includes('/story') && session.lastAdventure) {
//         currentAdventure = session.lastAdventure;
//     } else if (location.pathname.includes('/adventure') && session.lastAdventure) {
//         currentAdventure = session.lastAdventure;
//     }
//
//     // Adventure breadcrumb, handle the current one
//     if (currentAdventure && (!currentStory || currentAdventure.story._id === currentStory._id)) {
//         breadcrumbs.push(
//             <BreadcrumbItem
//                 key="adventure"
//                 href={isCurrentLocation(`/adventure/${currentAdventure._id}`) ? undefined : `/adventure/${currentAdventure._id}`}
//                 icon={PiBookmarksSimpleFill}
//             >
//                 {labels && currentAdventure.name}
//             </BreadcrumbItem>
//         );
//     }
//
//     return (
//         <div>
//             <Breadcrumb theme={breadcrumbTheme} aria-label="Solid background breadcrumb example" className="bg-gray-50 px-6 py-3 dark:bg-gray-800">
//                 {breadcrumbs}
//             </Breadcrumb>
//             {/*<div className="flex justify-center z-0">*/}
//             {/*    <span className="text-2xl text-center">*/}
//             {/*        {pageTitle !== 'Home' && (*/}
//             {/*            <>{pageTitle}</>*/}
//             {/*        )}*/}
//             {/*        {pageTitle === 'Home' && (*/}
//             {/*            <>Welcome home, {session.user.name}!</>*/}
//             {/*        )}*/}
//             {/*    </span>*/}
//             {/*</div>*/}
//         </div>
//     );
//
//
// };
//
// export default Breadcrumbs;
//
// const breadcrumbTheme = {
//     "root": {
//         "base": "",
//         "list": "flex items-center"
//     },
//     "item": {
//         "base": "group flex items-center",
//         "chevron": "mx-1 h-4 w-4 text-gray-400 group-first:hidden md:mx-2",
//         "href": {
//             "off": "flex items-center text-sm font-medium text-gray-500 dark:text-gray-400",
//             "on": "flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
//         },
//         "icon": "mr-2 h-4 w-4"
//     }
// }
