// src/components/SearchBar.js

import React, { useState } from 'react';
import { TextInput, Button } from 'flowbite-react';
import {debounce} from "lodash";

const SearchBar = ({ onSearch, placeholder, allowEmpty = false }) => {
    const [query, setQuery] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (allowEmpty || query.trim() !== '') {
            onSearch(query);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex items-center mb-4">
            <TextInput
                placeholder={placeholder}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="flex-grow mr-2"
            />
            <Button type="submit">Search</Button>
        </form>
    );
};

export default SearchBar;
