// src/utils/eventSourceSingleton.js
let eventSourceInstance = null;

export const getEventSource = (url, listeners) => {
    if (!eventSourceInstance) {
        eventSourceInstance = new EventSource(url);
        Object.keys(listeners).forEach((eventType) => {
            eventSourceInstance.addEventListener(eventType, listeners[eventType]);
        });

        eventSourceInstance.onopen = () => {
            console.log("Streaming connection opened.");
        };

        eventSourceInstance.onerror = (error) => {
            console.error('Error occurred while streaming data:', error);
            // Implement reconnection logic if necessary
        };
    }

    return eventSourceInstance;
};

export const closeEventSource = () => {
    if (eventSourceInstance) {
        eventSourceInstance.close();
        console.log("Streaming connection closed.");
        eventSourceInstance = null;
    }
};
