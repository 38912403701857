// src/components/NewStory/StoryWizardForm/cards/SelectableCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';

const SelectableCard = ({ itemKey, itemData, isSelected, onSelect, isDisabled }) => {
    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ' ') && !isDisabled) {
            e.preventDefault();
            onSelect(itemKey);
        }
    };

    isDisabled = isDisabled || itemData.notAvailable;

    return (
        <div
            onClick={() => !isDisabled && onSelect(itemKey)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-pressed={isSelected}
            className={`relative cursor-pointer border rounded-lg shadow-md p-1 flex flex-col items-center transition-transform transform hover:scale-105 
            ${isSelected ? 'border-blue-500 ring-2 ring-blue-300' : 'border-gray-300 dark:border-gray-600'}
            ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}
            focus:outline-none focus:ring-2 focus:ring-blue-500`}
            style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}
        >
            {/* Diagonal Banner */}
            {itemData.notAvailable && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                    <div className="absolute transform -rotate-12 bg-red-500 text-white text-lg font-bold px-4 py-1 z-50">
                        {itemData.notAvailable}
                    </div>
                </div>
            )}

            <img
                src={itemData.imageUrl}
                alt={itemData.name}
                className="h-32 w-full object-cover rounded-t-md"
                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
            />
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-400">{itemData.name}</h3>
            <p className="text-md text-gray-600 dark:text-gray-500 text-center">{itemData.description}</p>
            {isSelected && (
                <FaCheckCircle className="absolute bottom-2 right-2 text-blue-500 text-xl" />
            )}
            {!itemData.notAvailable && isDisabled && !isSelected && (
                <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center text-gray-500 text-sm font-semibold">
                    Max Selected
                </div>
            )}
        </div>
    );
};

SelectableCard.propTypes = {
    itemKey: PropTypes.string.isRequired,
    itemData: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        notAvailable: PropTypes.string,
        imageUrl: PropTypes.string.isRequired,
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool, // New prop
};

SelectableCard.defaultProps = {
    isDisabled: false,
};

export default React.memo(SelectableCard);
