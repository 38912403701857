import React, { useEffect, useState } from 'react';
import { Banner, BannerCollapseButton } from 'flowbite-react';
import { MdAnnouncement } from 'react-icons/md';
import { HiX } from 'react-icons/hi';
import { useSession } from '../../context/SessionContext';

const BANNER_KEY = 'BetaBannerClosed';

export default function PrimaryBanner() {
    const { session } = useSession();
    const [closed, setClosed] = useState(localStorage.getItem(BANNER_KEY) === 'true');

    useEffect(() => {
        localStorage.setItem(BANNER_KEY, closed.toString());
    }, [closed]);

    const handleClose = () => {
        setClosed(true);
    };

    if (closed || session?.token) {
        return null;
    }

    return (
        <Banner>
            <div className="flex w-full justify-between border-b border-gray-600 bg-blue-400 p-4">
                <div className="mx-auto flex items-center">
                    <p className="flex items-center text-md font-bold text-gray-800 ">
                        <MdAnnouncement className="mr-4 h-5 w-5"/>
                        <span
                            className="[&_p]:inline">Welcome to MyAdventures... You get 200 ink free when you first sign-up!</span>
                    </p>
                </div>
                <BannerCollapseButton color="gray" className="border-0 bg-transparent text-gray-500 dark:text-gray-900" onClick={handleClose}>
                    <HiX className="h-4 w-4"/>
                </BannerCollapseButton>
            </div>
        </Banner>
    );
}