// src/components/CustomShelf.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select } from 'flowbite-react';
import WorldCard from './WorldCard';
import WorldDetailModal from './WorldDetailModal';
import SearchBar from '../common/SearchBar';
import ScrollableRow from '../common/ScrollableRow'; // Ensure the path is correct
import PropTypes from 'prop-types';

const CustomAtlas = ({
                         expandedView = false,
                         session,
                         includePublic = false,
                         autoSearch = false,
                     }) => {
    const [customWorlds, setCustomWorlds] = useState([]);
    const [selectedWorld, setSelectedWorld] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        genre: '',
        theme: '',
        tag: '',
        sortBy: '',
        sortOrder: 'asc',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); // Error state

    // Fetch all worlds on component mount
    useEffect(() => {
        if (autoSearch) handleSearch();
    }, [session, autoSearch]);

    const handleSearch = async (query = '') => {
        try {
            setLoading(true);
            setError(null); // Reset error state
            let response = {};

            if (includePublic) {
                if (session) {
                    const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/world`, {
                        params: query ? { query } : {},
                    });

                    const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world`, {
                        params: query ? { query } : {},
                        headers: {
                            Authorization: `Bearer ${session.token}`,
                        },
                    });

                    const [response1, response2] = await Promise.all([promise1, promise2]);

                    const combinedData = [...response1.data, ...response2.data];

                    const worldsMap = new Map();

                    combinedData.forEach((item) => {
                        const existingWorld = worldsMap.get(item.world._id);

                        if (existingWorld) {
                            worldsMap.set(item.world._id, { ...existingWorld, ...item });
                        } else {
                            worldsMap.set(item.world._id, item);
                        }
                    });

                    response.data = Array.from(worldsMap.values());
                } else {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/public/world`, {
                        params: query ? { query } : {},
                    });
                }
            } else if (session) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world`, {
                    params: query ? { query } : {},
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });
            }

            setCustomWorlds(response.data || []);
        } catch (error) {
            console.error('Error fetching worlds:', error);
            setError('Failed to load worlds. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFiltersAndSort = (worlds) => {
        let filtered = [...worlds];

        // Apply genre filter
        if (filters.genre) {
            filtered = filtered.filter((world) => world?.world?.genre?.includes(filters.genre));
        }

        // Apply theme filter
        if (filters.theme) {
            filtered = filtered.filter((world) => world?.world?.themes?.includes(filters.theme));
        }

        // Apply tag filter
        if (filters.tag) {
            filtered = filtered.filter((world) => world?.world?.tags?.includes(filters.tag));
        }

        // Apply sorting
        if (filters.sortBy) {
            filtered.sort((a, b) => {
                if (!a.world || !b.world) {
                    console.error("Missing 'world' property in one of objects!");
                    return 0;
                }
                const fieldA = a.world[filters.sortBy];
                const fieldB = b.world[filters.sortBy];
                if (fieldA === undefined || fieldB === undefined) {
                    console.error(`Missing '${filters.sortBy}' property in 'world' object!`);
                    return 0;
                }
                if (typeof fieldA === 'string') {
                    if (filters.sortOrder === 'asc') {
                        return fieldA.localeCompare(fieldB);
                    } else {
                        return fieldB.localeCompare(fieldA);
                    }
                } else if (typeof fieldA === 'number' || fieldA instanceof Date) {
                    if (filters.sortOrder === 'asc') {
                        return new Date(fieldA) - new Date(fieldB);
                    } else {
                        return new Date(fieldB) - new Date(fieldA);
                    }
                } else {
                    return 0;
                }
            });
        }

        return filtered;
    };

    const handleWorldClick = (world) => {
        setSelectedWorld(world);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedWorld(null);
    };

    // Extract unique genres, themes, and tags for filter options
    const genres = Array.from(
        new Set(customWorlds.map((world) => world.world.genre))
    ).filter(Boolean);
    const themes = Array.from(
        new Set(customWorlds.flatMap((world) => world.world.themes))
    ).filter(Boolean);
    const tags = Array.from(
        new Set(customWorlds.flatMap((world) => world.world.tags))
    ).filter(Boolean);

    const sortedAndFilteredWorlds = applyFiltersAndSort(customWorlds);

    return (
        <div className="mb-8">
            {/* Search Bar */}
            <SearchBar onSearch={handleSearch} placeholder={"Search worlds..."} allowEmpty={autoSearch} />

            {/* Filters */}
            {customWorlds?.length > 0 && (
                <div className="flex flex-wrap gap-4 mb-4">
                    <Select
                        name="tag"
                        value={filters.tag}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">All Tags</option>
                        {tags.map((tag) => (
                            <option key={tag} value={tag}>
                                {tag}
                            </option>
                        ))}
                    </Select>

                    <Select
                        name="sortBy"
                        value={filters.sortBy}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="">Sort By</option>
                        <option value="createdAt">Created At</option>
                        <option value="updatedAt">Updated At</option>
                        <option value="averageRating">Average Rating</option>
                        <option value="popularity">Popularity</option>
                        {/* Add more sorting options as needed */}
                    </Select>

                    <Select
                        name="sortOrder"
                        value={filters.sortOrder}
                        onChange={handleFilterChange}
                        className="min-w-[150px]"
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </Select>
                </div>
            )}

            {/* Loading Indicator */}
            {loading && <p>Loading...</p>}

            {/* Error Message */}
            {error && <p className="text-red-500">{error}</p>}

            {/* Custom Shelf Display */}
            {!loading && sortedAndFilteredWorlds.length > 0 ? (
                expandedView ? (
                    // Expanded Grid View
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4">
                        {sortedAndFilteredWorlds.map((worldEntitlement) => (
                            <WorldCard
                                key={worldEntitlement._id}
                                world={worldEntitlement.world}
                                onClick={() => handleWorldClick(worldEntitlement)}
                            />
                        ))}
                    </div>
                ) : (
                    // Single Scrollable Row with Arrow Buttons
                    <ScrollableRow className="py-2">
                        {sortedAndFilteredWorlds.map((worldEntitlement) => (
                            <WorldCard
                                key={worldEntitlement._id}
                                world={worldEntitlement.world}
                                onClick={() => handleWorldClick(worldEntitlement)}
                            />
                        ))}
                    </ScrollableRow>
                )
            ) : (
                !loading &&
                autoSearch && <p>No worlds found.</p>
            )}

            {/* World Modal */}
            {selectedWorld && (
                <WorldDetailModal
                    world={selectedWorld.world}
                    isOpen={isModalOpen}
                    session={session}
                    grantLevel={selectedWorld.grantLevel}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );

};

CustomAtlas.propTypes = {
    expandedView: PropTypes.bool, // If true, display in expanded grid view; otherwise, single scrollable row
    session: PropTypes.object, // Session object
    includePublic: PropTypes.bool, // Include public worlds
    autoSearch: PropTypes.bool, // Automatically perform search on mount
};

CustomAtlas.defaultProps = {
    expandedView: false,
    includePublic: false,
    autoSearch: false,
};

export default CustomAtlas;
