// src/components/ListCharacters.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Badge,
    TextInput,
} from 'flowbite-react';
import { BsTrash } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import CharacterFormModal from './CharacterFormModal'; // You'll need to create this component
import { TableTheme } from "../../../themes/TableTheme";
import { IoPerson } from "react-icons/io5";


const ListCharacters = ({ story }) => {
    const [characters, setCharacters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalViewOnly, setIsModalViewOnly] = useState(false);
    const [characterToEdit, setCharacterToEdit] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [labelFilter, setLabelFilter] = useState([]);
    const [activeLabelFilters, setActiveLabelFilters] = useState([]);

    /**
     * Fetch characters from the API with optional search and label filters.
     * @param {number} page - Current page number.
     * @param {string} search - Search term for character names.
     * @param {array} labels - Array of label IDs to filter characters.
     */
    const fetchCharacters = async (page, search = searchTerm, labels = labelFilter) => {
        setLoading(true);
        try {
            const params = { story: story._id, page, limit: 20 };

            if (search) {
                params.search = search;
            }

            if (labels.length > 0) {
                params.labels = labels.join(',');
                // Optional: Add 'match' parameter if backend supports it
                // params.match = 'any'; // or 'all'
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/character/list`, { params });
            setCharacters(response.data.characters);
            setTotalPages(response.data.totalPages);
            setPage(response.data.currentPage);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch characters.');
        } finally {
            setLoading(false);
        }
    };

    /**
     * Effect hook to fetch characters whenever page, searchTerm, or labelFilter changes.
     */
    useEffect(() => {
        fetchCharacters(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchTerm, labelFilter]);

    /**
     * Handle deletion of a character.
     * @param {string} id - ID of the character to delete.
     */
    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this character?')) return;
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/character/${id}`);
            fetchCharacters(page);
        } catch (err) {
            console.error(err);
            alert('Failed to delete the character.');
        }
    };

    /**
     * Open the modal for adding or editing a character.
     * @param {object|null} character - Character object to edit or null for adding.
     * @param {boolean} viewOnly - If true, opens the modal in view-only mode.
     */
    const openModal = (character = null, viewOnly = false) => {
        setCharacterToEdit(character);
        setIsModalViewOnly(viewOnly);
        setIsModalOpen(true);
    };

    /**
     * Close the character modal.
     */
    const closeModal = () => {
        setIsModalOpen(false);
        setCharacterToEdit(null);
    };

    /**
     * Callback function after successful add/edit/delete operations.
     */
    const handleSuccess = () => {
        fetchCharacters(page);
    };

    /**
     * Handle the submission of the search form.
     * @param {object} e - Event object.
     */
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setPage(1); // Reset to first page on new search
        fetchCharacters(1, searchTerm, labelFilter);
    };

    /**
     * Add a label to the active label filters.
     * @param {object} label - Label object to add.
     */
    const handleLabelClick = (label) => {
        if (!labelFilter.includes(label._id)) {
            setLabelFilter([...labelFilter, label._id]);
            setActiveLabelFilters([...activeLabelFilters, label]);
            setPage(1); // Reset to first page on filter change
        }
    };

    /**
     * Remove a label from the active label filters.
     * @param {string} labelId - ID of the label to remove.
     */
    const handleRemoveLabelFilter = (labelId) => {
        setLabelFilter(labelFilter.filter(id => id !== labelId));
        setActiveLabelFilters(activeLabelFilters.filter(label => label._id !== labelId));
        setPage(1); // Reset to first page on filter change
    };

    // Define affiliation labels (assuming similar to existing)
    const affiliationLabels = {
        '5': 'Confidant',
        '4': 'Friend',
        '3': 'Companion',
        '2': 'Acquaintance',
        '1': 'Favorable',
        '0': 'Neutral',
        '-1': 'Unfavorable',
        '-2': 'Distant',
        '-3': 'Rival',
        '-4': 'Enemy',
        '-5': 'Nemesis'
    };

    return (
        <div className="overflow-x-auto p-4 pt-0 pb-8">
            {/* Header Section: Buttons and Search Bar */}
            <div className="flex flex-col md:flex-row mb-6 justify-between w-full">
                <div className="flex space-x-2 mb-4 md:mb-0">
                    <Button onClick={() => openModal()} color="blue">
                        Add Character
                    </Button>
                    {/* You can add more buttons here if needed */}
                </div>
                <form onSubmit={handleSearchSubmit} className="flex items-center space-x-2">
                    <TextInput
                        type="text"
                        placeholder="Search by name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-64"
                        aria-label="Search Characters"
                    />
                    <Button type="submit" color="green">
                        Search
                    </Button>
                </form>
            </div>

            {/* Active Label Filters */}
            {activeLabelFilters.length > 0 && (
                <div className="flex items-center mb-4 space-x-2">
                    <span className="font-semibold">Active Filters:</span>
                    {activeLabelFilters.map(label => (
                        <Badge key={label._id} color="purple" className="flex items-center">
                            {label.name}
                            <button
                                onClick={() => handleRemoveLabelFilter(label._id)}
                                className="ml-1 text-white focus:outline-none"
                                aria-label={`Remove filter ${label.name}`}
                            >
                                &times;
                            </button>
                        </Badge>
                    ))}
                </div>
            )}

            {/* Characters Table */}
            <Table striped theme={TableTheme}>
                <TableHead>
                    <TableHeadCell></TableHeadCell>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Affiliation</TableHeadCell>
                    <TableHeadCell>
                        <span className="sr-only">Actions</span>
                    </TableHeadCell>
                </TableHead>
                <TableBody>
                    {characters.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan="3" className="text-center">No characters found.</TableCell>
                        </TableRow>
                    ) : (
                        characters.map((char) => (
                            <TableRow
                                key={char._id}
                                className="cursor-pointer"
                                onClick={() => openModal(char, true)}
                            >
                                <TableCell className="w-8"><IoPerson title={"Character"}/></TableCell>
                                <TableCell>{char.name}</TableCell>
                                <TableCell className={"w-min"}>
                                    <Badge
                                        color={char.affiliation > 2 ? "green" : char.affiliation < 0 ? "red" : "gray"}
                                        className="cursor-pointer inline-block"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            // Assuming labels are based on affiliation
                                            // If not, adjust accordingly
                                            const label = { _id: `aff-${char.affiliation}`, name: affiliationLabels[char.affiliation] };
                                            handleLabelClick(label);
                                        }}
                                        aria-label={`Filter by affiliation ${affiliationLabels[char.affiliation]}`}
                                    >
                                        {affiliationLabels[char.affiliation]}
                                    </Badge>
                                </TableCell>
                                <TableCell className="flex space-x-4">
                                    {/* Edit Button */}
                                    <GrEdit
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            openModal(char, false);
                                        }}
                                        size={18}
                                        title="Edit Character"
                                        className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                        aria-label={`Edit ${char.name}`}
                                    />
                                    {/* Delete Button */}
                                    <BsTrash
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            handleDelete(char._id);
                                        }}
                                        size={18}
                                        title="Delete Character"
                                        className="text-gray-400 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                        aria-label={`Delete ${char.name}`}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>

            {/* Pagination Controls */}
            {totalPages > 1 && (
                <div className="flex justify-between items-center mt-4">
                    <Button
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                        color="gray"
                    >
                        Previous
                    </Button>
                    <span className="text-gray-700 dark:text-gray-300">Page {page} of {totalPages}</span>
                    <Button
                        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={page === totalPages}
                        color="gray"
                    >
                        Next
                    </Button>
                </div>
            )}

            {/* Character Modal */}
            <CharacterFormModal
                isOpen={isModalOpen}
                onClose={closeModal}
                characterToEdit={characterToEdit}
                story={story}
                viewOnly={isModalViewOnly}
                onSuccess={handleSuccess}
            />
        </div>
    );
};

export default ListCharacters;
