// src/components/ScrollableRow.js

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ScrollableRow = ({ children, className }) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const scrollContainerRef = useRef(null);
    const arrowsOverlap = false;

    // Check if scrolling is possible and update arrow visibility
    const checkForScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            setCanScrollLeft(container.scrollLeft > 0);
            setCanScrollRight(container.scrollWidth > container.clientWidth + container.scrollLeft + 1); // +1 to account for rounding errors
        }
    };

    // Scroll handler
    const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollAmount = window.innerWidth; // Scroll by one screen width
            if (direction === 'left') {
                container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            } else {
                container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };

    // Handle scroll event
    const handleScroll = () => {
        checkForScroll();
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            checkForScroll();
            container.addEventListener('scroll', handleScroll);
        }
        window.addEventListener('resize', checkForScroll);

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', checkForScroll);
        };
    }, [children]);

    if (arrowsOverlap) {
        return (
            <div className="relative">
                {/* Left Arrow */}
                {canScrollLeft && (
                    <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-full p-2 shadow-lg z-10 focus:outline-none"
                        onClick={() => scroll('left')}
                        aria-label="Scroll Left"
                    >
                        <FaChevronLeft size={24}/>
                    </button>
                )}

                {/* Scrollable Container */}
                <div
                    ref={scrollContainerRef}
                    className={`flex space-x-4 overflow-x-auto scroll-smooth ${className}`}
                >
                    {children}
                </div>

                {/* Right Arrow */}
                {canScrollRight && (
                    <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-full p-2 shadow-lg z-10 focus:outline-none"
                        onClick={() => scroll('right')}
                        aria-label="Scroll Right"
                    >
                        <FaChevronRight size={24}/>
                    </button>
                )}
            </div>
        );
    }
    else {
        return (
            <div className="relative">
                {/* Left Arrow */}
                {canScrollLeft && (
                    <button
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-full p-2 shadow-lg z-10 focus:outline-none"
                        onClick={() => scroll('left')}
                        aria-label="Scroll Left"
                    >
                        <FaChevronLeft size={24}/>
                    </button>
                )}

                {/* Scrollable Container */}
                <div
                    ref={scrollContainerRef}
                    className={`flex space-x-4 overflow-x-auto scroll-smooth ${className}`}
                >
                    {children}
                </div>

                {/* Right Arrow */}
                {canScrollRight && (
                    <button
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 rounded-full p-2 shadow-lg z-10 focus:outline-none"
                        onClick={() => scroll('right')}
                        aria-label="Scroll Right"
                    >
                        <FaChevronRight size={24}/>
                    </button>
                )}
            </div>
        );
    }
};

ScrollableRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string, // Additional classes for the scrollable container
};

ScrollableRow.defaultProps = {
    className: '',
};

export default ScrollableRow;
