import { Card } from "flowbite-react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

function CharCard({ character, handleSubmit, isSelected }) {
    const truncatedDescription = character.description.length > 200
        ? character.description.slice(0, 197) + '...'
        : character.description;

    return (
        <div
            className={`relative cursor-pointer rounded-lg transition-transform transform ${
                isSelected ? 'border-green-500 border-4 rounded-xl shadow-lg' : 'border-gray-700'
            } hover:scale-105 max-h-[540px] max-w-[300px] overflow-hidden`} // Add max-h and overflow
            onClick={() => handleSubmit(character)}
        >
            <Card
                className="dark:bg-gray-900 max-w-s mx-auto h-full"
                key={character._id}
                imgAlt="Alt text"
                imgSrc={`${process.env.REACT_APP_API_URL}/image/attached/${character._id}?d=4`}
            >
                {isSelected && (
                    <FaCheckCircle className="absolute top-0 right-0 m-2 text-green-500" size={24} />
                )}
                <div className="flex flex-col h-full justify-between">
                    <div className="max-h-[240px] overflow-hidden"> {/* Restrict height of card content */}
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-2">
                            {character.name}
                        </h5>
                        <p className="font-normal text-sm text-gray-700 dark:text-gray-400 mb-4">
                            {truncatedDescription}
                        </p>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default CharCard;
