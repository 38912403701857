import React from "react";
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import {ItemTypes} from "./ItemTypes";

// Draggable Group Component
export const DraggableGroup = React.memo(({ group, imgSrc }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.GROUP,
        item: { type: ItemTypes.GROUP, groupId: group._id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [group._id]);

    return (
        <div
            ref={drag}
            className={`cursor-pointer rounded border border-green-500`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <img
                src={imgSrc}
                alt={`Group ${group.name}`}
                className="w-12 h-12 object-cover rounded"
            />
            <span className="text-xs text-center block">{group.name}</span>
        </div>
    );
});

DraggableGroup.propTypes = {
    group: PropTypes.object.isRequired,
    imgSrc: PropTypes.string.isRequired,
};
