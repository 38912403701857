import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import MyStoriesPage from "./pages/private/MyStoriesPage";
import StoryPage from "./pages/private/StoryPage";
import MyNavbar from "./components/header/MyNavbar";
import MyHomePage from "./pages/private/MyHomePage";
import WorldPage from "./pages/private/WorldPage";
import PricingPage from "./pages/public/PricingPage";
import GuidesPage from "./pages/public/GuidesPage";
import ShareStoryLink from "./components/share/ShareStoryLink";
import ShareWorldLink from "./components/share/ShareWorldLink";
import MyAccount from "./pages/private/MyAccountPage";
import LandingPage from "./pages/public/LandingPage";
import DiscoverPage from "./pages/public/DiscoverPage";
import NewsPage from "./pages/public/NewsPage";
import NewsArticlePage from "./pages/public/NewsArticlePage";
import PrivateRoute from "./context/PrivateRoute";
import PrimaryBanner from "./components/banner/PrimaryBanner";
import AdventurePage from "./pages/private/AdventurePage";
import SiteFooter from "./components/footer/SiteFooter";
import StoryWizardForm from "./components/story/wizard/StoryWizardForm";
import LoggedOutBanner from "./components/banner/LoggedOutBanner";
import MyAdventuresPage from "./pages/private/MyAdventuresPage";
import Error404 from "./pages/public/error/Error404";
import MyWorldsPage from "./pages/private/MyWorldsPage";
import WorldNew from "./components/world/WorldNew";
import StoryNew from "./components/story/StoryNew";
import GraphBuilder from "./components/story/plot/graph/GraphBuilder";
import 'react-toastify/dist/ReactToastify.css';

// Initialize ToastContainer somewhere in your app, e.g., in App.jsx
import { ToastContainer } from 'react-toastify';
import DebugScenePage from "./pages/private/DebugScenePage";
import CheckoutPage from "./pages/private/CheckoutPage";
import PaymentPage from "./pages/private/PaymentPage";
import ForgetMeLink from "./components/account/ForgetMeLink";
import GoodbyePage from "./components/account/GoodbyePage";
import AdminPage from "./pages/private/AdminPage";
import StoryStatusPage from "./pages/private/StoryStatusPage";
import LockscreenPage from "./pages/public/SignedOutPage";
import FeedbackPage from "./pages/private/FeedbackPage";
import SidebarMenu from "./components/common/SidebarMenu";
import NewAdventurePage from "./pages/private/NewAdventurePage";

export default function App() {
    return (
        <div className="flex flex-col h-screen dark:bg-gray-800 dark:text-gray-400"> {/* Full-screen flex layout */}
            <Router>
                {/* Header */}
                <header>
                    <MyNavbar/>
                    <LoggedOutBanner/>
                    <PrimaryBanner/>
                    <ToastContainer/>
                </header>

                {/* Main content area that grows and scrolls */}
                <main className="flex flex-grow h-0 overflow-auto">
                    <SidebarMenu>
                        <Routes>
                            <Route path="/home" element={<PrivateRoute><MyHomePage/></PrivateRoute>}/>
                            <Route path="/account" element={<PrivateRoute><MyAccount/></PrivateRoute>}/>
                            <Route path="/account/forgetme" element={<ForgetMeLink/>}/>
                            <Route path="/goodbye" element={<GoodbyePage/>}/>
                            <Route path="/adventure/:id" element={<PrivateRoute><AdventurePage/></PrivateRoute>}/>
                            <Route path="/scene/:id/debug" element={<DebugScenePage/>}/>
                            <Route path="/share/story/:id"
                                   element={<PrivateRoute><ShareStoryLink/></PrivateRoute>}/>
                            <Route path="/share/world/:id"
                                   element={<PrivateRoute><ShareWorldLink/></PrivateRoute>}/>
                            <Route path="/story/:id/status"
                                   element={<PrivateRoute><StoryStatusPage/></PrivateRoute>}/>
                            <Route path="/story/wizard" element={<PrivateRoute><StoryWizardForm/></PrivateRoute>}/>
                            <Route path="/story/new" element={<PrivateRoute><StoryNew/></PrivateRoute>}/>
                            <Route path="/story/:id/adventure/new"
                                   element={<PrivateRoute><NewAdventurePage/></PrivateRoute>}/>
                            <Route path="/story/:id/adventures"
                                   element={<PrivateRoute><MyAdventuresPage/></PrivateRoute>}/>
                            <Route path="/story/:id" element={<PrivateRoute><StoryPage/></PrivateRoute>}/>
                            <Route path="/stories" element={<PrivateRoute><MyStoriesPage/></PrivateRoute>}/>
                            <Route path="/world/wizard" element={<PrivateRoute><WorldNew/></PrivateRoute>}/>
                            <Route path="/world/new" element={<PrivateRoute><WorldNew/></PrivateRoute>}/>
                            <Route path="/world/:id/stroies" element={<PrivateRoute><WorldPage/></PrivateRoute>}/>
                            <Route path="/world/:id" element={<PrivateRoute><WorldPage/></PrivateRoute>}/>
                            <Route path="/worlds" element={<PrivateRoute><MyWorldsPage/></PrivateRoute>}/>
                            <Route path="/news/article/:id" element={<NewsArticlePage/>}/>
                            <Route path="/news" element={<NewsPage/>}/>
                            <Route path="/guide/:guideName" element={<GuidesPage/>}/>
                            <Route path="/guides" element={<GuidesPage/>}/>
                            <Route path="/pricing/:type" element={<PricingPage/>}/>
                            <Route path="/pricing" element={<PricingPage/>}/>
                            <Route path="/checkout" element={<CheckoutPage/>}/>
                            <Route path="/payment/:type" element={<PaymentPage/>}/>
                            <Route path="/discover" element={<DiscoverPage/>}/>
                            <Route path="/landing" element={<LandingPage/>}/>
                            <Route path="/lockscreen" element={<LockscreenPage/>}/>
                            <Route path="/admin" element={<AdminPage/>}/>
                            <Route path="/feedback" element={<FeedbackPage/>}/>
                            <Route path="/" element={<LandingPage/>}/>
                            <Route path="*" element={<Error404/>}/>
                        </Routes>
                    </SidebarMenu>
                </main>

                {/* Footer */}
                <footer>
                    <SiteFooter/>
                </footer>
            </Router>
        </div>
    );
}
