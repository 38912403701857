function FeedbackPage() {

    return (
        // <div className="wrapper-class">
        //     <iframe
        //         src="https://docs.google.com/forms/d/e/1FAIpQLSePYHwRWufedoEKarYJEWHYpWTVN3ClHxGL3BCuqe_xuB3Tfw/viewform?embedded=true"
        //         width="640"
        //         height="1747"
        //         frameBorder="0"
        //         marginHeight="0"
        //         marginWidth="0"
        //         style={{all: 'unset'}} // Unset any styles applied by other CSS
        //     >
        //         Loading…
        //     </iframe>
        // </div>
        <div className="wrapper-class">
            <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSePYHwRWufedoEKarYJEWHYpWTVN3ClHxGL3BCuqe_xuB3Tfw/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
            >
                Open Feedback Form
            </a>
        </div>
    );
}

export default FeedbackPage;