// src/components/graph/CustomNode.jsx

import React from 'react';
import { Handle } from '@xyflow/react';
import { GrEdit } from 'react-icons/gr';
import PropTypes from 'prop-types';

const PlotElementNode = ({ data }) => {
    const handleEditClick = (e) => {
        e.stopPropagation(); // Prevent triggering other events
        if (data.onEdit) {
            data.onEdit(data.plotElement, false);
        }
    };

    return (
        <div className="relative bg-blue-100 dark:bg-gray-900 border border-blue-500 dark:border-gray-700 rounded p-2 px-3 min-w-[10rem]">
            {/* Stage Number Box */}
            {data.plotElement.plotStage !== undefined && (
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-300 border border-blue-500 dark:border-gray-400 dark:bg-gray-400 dark:text-gray-900 rounded px-3 text-sm font-semibold shadow-lg">
                    Stage {data.plotElement.plotStage}
                </div>
            )}

            {/* Header: Label and Edit Icon */}
            <div className="flex justify-between items-center space-x-2 pt-2">
                <span className="font-medium">{data.label}</span>
                <GrEdit
                    className="text-gray-500 hover:text-green-500 transition-colors duration-200 transform hover:scale-110 cursor-pointer"
                    onClick={handleEditClick}
                    title="Edit Plot Element"
                    aria-label="Edit Plot Element"
                />
            </div>

            {/* Tags Section */}
            <div className="mt-2 flex flex-wrap space-x-1">
                {data.plotElement.tags && data.plotElement.tags.map((tag, index) => (
                    <span
                        key={index}
                        className="inline-block bg-green-200 border border-green-600 rounded-md text-xs font-semibold text-gray-700 px-1 dark:border-green-700 dark:bg-green-700 dark:text-gray-900"
                    >
                        #{tag}
                    </span>
                ))}
            </div>

            {/* Handles for Edges */}
            <Handle type="source" position="right" id="a" className="w-3 h-3 bg-blue-500 border border-white rounded-full" />
            <Handle type="target" position="left" id="b" className="w-3 h-3 bg-blue-500 border border-white rounded-full" />
        </div>
    );
};

PlotElementNode.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        plotElement: PropTypes.shape({
            plotStage: PropTypes.number, // Ensures plotStage is a number if provided
            tags: PropTypes.arrayOf(PropTypes.string),
            // Add other plotElement properties as needed
        }).isRequired,
        onEdit: PropTypes.func, // Function to trigger edit
    }).isRequired,
};

export default PlotElementNode;
