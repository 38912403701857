// src/components/NewStory/TagsInput.js
import React, { useState } from 'react';

function TagsInput({ tags = [], onChange, placeholder }) {
    const [inputValue, setInputValue] = useState('');

    const addTag = (tag) => {
        if (tag && !tags.includes(tag)) {
            onChange([...tags, tag]);
        }
    };

    const removeTag = (indexToRemove) => {
        onChange(tags.filter((_, index) => index !== indexToRemove));
    };

    const handleKeyDown = (e) => {
        if (['Enter', ','].includes(e.key)) {
            e.preventDefault();
            addTag(inputValue.trim());
            setInputValue('');
        }
    };

    return (
        <div className="flex flex-wrap items-center p-0.5 rounded-lg border border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500">
            {tags.map((tag, index) => (
                <span
                    key={index}
                    className="flex items-center m-1 px-2 py-0.5 bg-blue-100 text-blue-800 rounded dark:bg-gray-400 dark:text-gray-900"
                >
          {tag}
                    <button
                        type="button"
                        className="ml-1 text-red-500 dark:text-red-800"
                        onClick={() => removeTag(index)}
                    >
            ×
          </button>
        </span>
            ))}
            <input
                className="flex-grow p-2 text-sm border-none focus:outline-none bg-gray-50 dark:bg-gray-700"
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
            />
        </div>
    );
}

export default TagsInput;
