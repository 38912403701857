// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {
    predefinedFormats,
    predefinedIncitingIncident,
    predefinedLocations, predefinedObstacles,
    predefinedStoryTypes
} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_Obstacle = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select some Obstacles"
            subTitle="(Pick at Least one but upto Three)"
            predefinedList={predefinedObstacles}
            formDataField="obstacles" // Ensure this matches the key in formData
            selectionLimit={3} // Example: limit to 3 selections, set to null for no limit
            minSelection={1}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_Obstacle;
