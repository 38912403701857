import React from "react";

function LockscreenPage() {
    return (
        <div className="relative w-full h-full overflow-hidden flex items-center justify-center">
            {/* Background Layer */}
            <div
                className={
                    'absolute inset-0 bg-center bg-cover bg-no-repeat opacity-20 blur-sm'
                }
                style={{
                    backgroundImage: 'url(https://storage.googleapis.com/myadventures-images/lockscreen.webp)',
                }}
            />

            {/* Text Layer */}
            <h2 className="relative text-white text-xl md:text-2xl font-bold text-center">
                Please Sign-In to use My Adventures
            </h2>
        </div>
    );
}

export default LockscreenPage;