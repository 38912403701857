import React, { useState, useEffect } from 'react';
import {Modal, Button, TextInput, Label, Select, Spinner, Alert, Checkbox, ToggleSwitch} from 'flowbite-react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ModalTheme } from "../../../../themes/ModalTheme";
import MapGrid from './MapGrid'; // Ensure the path is correct
import { GiQuillInk } from "react-icons/gi";
import { GrEdit } from "react-icons/gr";
import { FaMap, FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";
import {ToggleTheme} from "../../../../themes/ToggleTheme";
import { FaBug } from "react-icons/fa";
import MapDebug from "./MapDebug";
import { MdOutlineKeyboardReturn } from "react-icons/md";


const DEFAULT_MAP_SIZE = 40;

const MapFormModal = ({ isOpen, onClose, mapToEdit, story, viewOnly, onSuccess }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [isExpanded, setIsExpanded] = useState(false);
    const [name, setName] = useState('');
    const [implicitConnections, setImplicitConnections] = useState(false);
    const [implicitConnectionWeight, setImplicitConnectionWeight] = useState(1);
    const [mapSizeX, setMapSizeX] = useState(DEFAULT_MAP_SIZE);
    const [mapSizeY, setMapSizeY] = useState(DEFAULT_MAP_SIZE);
    const [connections, setConnections] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [backgroundTiles, setBackgroundTiles] = useState(Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
    const [foregroundTiles, setForegroundTiles] = useState(Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tilesets, setTilesets] = useState([]);
    const [selectedTileset, setSelectedTileset] = useState(null);
    const [tilesetLoading, setTilesetLoading] = useState(false);
    const [tilesetError, setTilesetError] = useState('');
    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (mapToEdit) {
                axios.get(`${process.env.REACT_APP_API_URL}/map/${mapToEdit._id}`)
                    .then((res) => {
                        const map = res.data;
                        setName(map.name || '')
                        setImplicitConnections(map.implicitConnections || false);
                        setImplicitConnectionWeight(map.implicitConnectionWeight || 1);
                        setMapSizeX(map.mapSizeX || DEFAULT_MAP_SIZE);
                        setMapSizeY(map.mapSizeY || DEFAULT_MAP_SIZE);
                        setMarkers(map.markers || []);
                        setConnections(map.connections || []);
                        setBackgroundTiles(map.tileTypes?.backgroundTiles || Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
                        setForegroundTiles(map.tileTypes?.foregroundTiles || Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
                        setSelectedTileset(map.tileset || null);
                    })
                    .catch(err => {
                        console.error('There was an error!', err);
                        setError('Failed to load the map data.');
                    });
            } else {
                // Reset form for adding a new map
                setName('');
                setImplicitConnections( false);
                setImplicitConnectionWeight(1);
                setMapSizeX(DEFAULT_MAP_SIZE);
                setMapSizeY(DEFAULT_MAP_SIZE);
                setMarkers([]);
                setConnections([]);
                setBackgroundTiles(Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
                setForegroundTiles(Array(DEFAULT_MAP_SIZE * DEFAULT_MAP_SIZE).fill(0));
                setSelectedTileset(null);
            }
            setIsViewOnly(viewOnly);
            setIsExpanded(false); // Reset expanded mode when modal opens
            setError('');
            setLoading(false);
            fetchLocations();
            fetchTilesets(); // Fetch Tilesets when modal opens
        }
    }, [isOpen, mapToEdit, viewOnly]);

    const fetchLocations = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/location/list`, { params: { story: story._id, limit: 25 } });
            // Handle locations if needed
        } catch (err) {
            console.error('Error fetching locations:', err);
        }
    };

    // **Fetch Tilesets by World**
    const fetchTilesets = async () => {
        try {
            setTilesetLoading(true);
            setTilesetError('');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tileset/list`, { params: { worldId: story.world._id } });
            setTilesets(response.data);
            if (response.data.length > 0) {
                setSelectedTileset(response.data[0]);
            }
        } catch (err) {
            console.error('Error fetching tilesets:', err);
            setTilesetError('Failed to load tilesets. Please try again.');
        } finally {
            setTilesetLoading(false);
        }
    };

    // **Handle Tileset Selection Change**
    const handleTilesetChange = async (selectedOption) => {
        const tilesetId = selectedOption ? selectedOption.value : null;
        if (!tilesetId) {
            setSelectedTileset(null);
            return;
        }
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/tileset/${tilesetId}`);
            setSelectedTileset(res.data || null);
        } catch (err) {
            console.error('There was an error!', err);
            setTilesetError('Failed to load the selected tileset.');
        }
    };


    // **Handle Map Updates from MapGrid**
    const handleMapUpdate = (updatedLayerTiles, layer) => {
        if (layer === 'background') {
            setBackgroundTiles(updatedLayerTiles);
        } else if (layer === 'foreground') {
            setForegroundTiles(updatedLayerTiles);
        }
    };

    // **Handle Markers and Connections Updates**
    const handleMarkersUpdate = (updatedMarkers) => {
        setMarkers(updatedMarkers);
    };

    const handleConnectionsUpdate = (updatedConnections) => {
        setConnections(updatedConnections);
    };

    // **Handle Form Submission**
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            // Prepare sanitized markers
            const sanitizedMarkers = markers.map(marker => ({
                ...marker,
                // If map is not set, ensure it's null or undefined
                map: marker.map ? marker.map : null,
                location: marker.location ? marker.location : null,
                mapLocation: marker.mapLocation ? marker.mapLocation : null,
            }));

            // Prepare sanitized connections
            const sanitizedConnections = connections.map(connection => ({
                ...connection,
                // Ensure 'from' and 'to' are valid ObjectIds
                from: connection.from,
                to: connection.to,
            }));

            const payload = {
                name,
                implicitConnections,
                implicitConnectionWeight,
                markers: sanitizedMarkers,
                connections: sanitizedConnections,
                mapSizeX,
                mapSizeY,
                tileTypes: {
                    backgroundTiles,
                    foregroundTiles,
                },
                tileset: selectedTileset?._id || null,
                story: story._id,
            };

            if (mapToEdit) {
                await axios.put(`${process.env.REACT_APP_API_URL}/map/${mapToEdit._id}`, payload);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/map`, payload);
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving the map:', err);
            if (err.response && err.response.data && err.response.data.message) {
                setError(`Failed to save the map: ${err.response.data.message}`);
            } else {
                setError('Failed to save the map.');
            }
        } finally {
            setLoading(false);
        }
    };


    // **Handle Map Deletion**
    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this map?')) return;
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/map/${mapToEdit._id}`);
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting a map:', err);
            setError('Failed to delete the map.');
        }
    };

    const handleEdit = () => {
        setIsViewOnly(false);
        setIsExpanded(false); // Reset expanded mode when switching to edit mode
    };

    // **Handle Expand/Collapse Toggle**
    const toggleExpand = () => {
        setIsExpanded(prev => !prev);
    };

    if(debugMode && mapToEdit)
    {
        const map = {
            _id: mapToEdit._id,
            name,
            implicitConnections,
            implicitConnectionWeight,
            markers,
            connections,
            mapSizeX,
            mapSizeY,
            tileTypes: {
                backgroundTiles,
                foregroundTiles,
            },
            tileset: selectedTileset._id,
            story: story._id,
        };

        return (
            <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size={'5xl'} className="w-full h-full">
                <Modal.Header>
                    <div className="flex justify-between items-center w-full space-x-2">
                        <div className="flex items-center space-x-2">
                            <FaMap/>
                            <span>{'Debug Map Graph'}</span>
                        </div>
                        <button
                            onClick={() => setDebugMode(!debugMode)}
                            title={'Exit Debug'}
                            aria-label={'Exit Debug'}
                            className="text-gray-500 hover:text-green-500 transition-all duration-100 transform hover:scale-110"
                        >
                            <MdOutlineKeyboardReturn/>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <MapDebug currentMap={map}/>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex justify-end w-full">
                        <Button color="gray" onClick={onClose} disabled={loading}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size={isExpanded ? 'full' : '5xl'}
               className="w-full h-full">
            <Modal.Header>
                <div className="flex justify-between items-center w-full space-x-2">
                    {/* Left Side: Icon and Title */}
                    <div className="flex items-center space-x-2">
                        <FaMap />
                        <span>{isViewOnly ? 'View Map' : mapToEdit ? 'Edit Map' : 'Add Map'}</span>
                    </div>
                    {/* Right Side: Expand/Collapse or Edit Button */}
                    <div className="flex items-center space-x-2">
                        {!isViewOnly && (
                            <button
                                onClick={toggleExpand}
                                title={isExpanded ? 'Collapse' : 'Expand'}
                                aria-label={isExpanded ? 'Collapse' : 'Expand'}
                                className="text-gray-500 hover:text-green-500 transition-all duration-100 transform hover:scale-110"
                            >
                                {isExpanded ? <FaCompressArrowsAlt/> : <FaExpandArrowsAlt/>}
                            </button>
                        )}
                        {isViewOnly && (
                            <>
                                <GrEdit
                                    className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110 cursor-pointer"
                                    onClick={handleEdit}
                                    title="Edit Map"
                                    aria-label="Edit Map"
                                />
                                <button
                                    onClick={toggleExpand}
                                    title={isExpanded ? 'Collapse' : 'Expand'}
                                    aria-label={isExpanded ? 'Collapse' : 'Expand'}
                                    className="text-gray-500 hover:text-blue-500 transition-all duration-100 transform hover:scale-110"
                                >
                                    {isExpanded ? <FaCompressArrowsAlt/> : <FaExpandArrowsAlt/>}
                                </button>
                            </>
                        )}
                        <button
                            onClick={()=> setDebugMode(!debugMode)}
                            title={'Debug'}
                            aria-label={'Debug'}
                            className="text-gray-500 hover:text-red-500 transition-all duration-100 transform hover:scale-110"
                        >
                            <FaBug/>
                        </button>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                {isExpanded ? (
                    // Full-Screen Mode: Only MapGrid
                    <div className="flex-1 flex gap-y-48 flex-grow min-h-[1000px]">
                        {selectedTileset ? (
                            <MapGrid
                                story={story}
                                tileset={selectedTileset}
                                backgroundTiles={backgroundTiles}
                                foregroundTiles={foregroundTiles}
                                setBackgroundTiles={setBackgroundTiles}
                                setForegroundTiles={setForegroundTiles}
                                markers={markers}
                                connections={connections}
                                setMarkers={setMarkers}
                                setConnections={setConnections}
                                viewOnly={isViewOnly}
                                onMapUpdate={handleMapUpdate}
                                onMarkersUpdate={handleMarkersUpdate}
                                onConnectionsUpdate={handleConnectionsUpdate}
                                mapSizeX={mapSizeX}
                                mapSizeY={mapSizeY}
                            />
                        ) : (
                            <div className="flex items-center justify-center w-full text-center text-gray-500">
                                No tileset available.
                            </div>
                        )}
                    </div>
                ) : isViewOnly ? (
                    // View-Only Mode: Only MapGrid (non-editable)
                    <div className="flex-1 flex min-h-[740px]">
                        {selectedTileset ? (
                            <MapGrid
                                story={story}
                                tileset={selectedTileset}
                                backgroundTiles={backgroundTiles}
                                foregroundTiles={foregroundTiles}
                                markers={markers}
                                connections={connections}
                                viewOnly={isViewOnly}
                                mapSizeX={mapSizeX}
                                mapSizeY={mapSizeY}
                            />
                        ) : (
                            <div className="flex items-center justify-center w-full text-center text-gray-500">
                                No tileset available.
                            </div>
                        )}
                    </div>
                ) : isExpanded ? (
                    // This block is redundant since full-screen is handled above
                    // Kept for clarity; can be removed if not needed
                    <div className="flex-1 flex min-h-[740px]">
                        {selectedTileset ? (
                            <MapGrid
                                story={story}
                                tileset={selectedTileset}
                                backgroundTiles={backgroundTiles}
                                foregroundTiles={foregroundTiles}
                                setBackgroundTiles={setBackgroundTiles}
                                setForegroundTiles={setForegroundTiles}
                                markers={markers}
                                connections={connections}
                                setMarkers={setMarkers}
                                setConnections={setConnections}
                                viewOnly={isViewOnly}
                                onMapUpdate={handleMapUpdate}
                                onMarkersUpdate={handleMarkersUpdate}
                                onConnectionsUpdate={handleConnectionsUpdate}
                                mapSizeX={mapSizeX}
                                mapSizeY={mapSizeY}
                            />
                        ) : (
                            <div className="flex items-center justify-center w-full text-center text-gray-500">
                                Please select a tileset to view the map.
                            </div>
                        )}
                    </div>
                ) : (
                    // Normal Edit Mode: Form + MapGrid (editable)
                    <div className="flex flex-col h-full">
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            {/* Map Details */}
                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1 min-w-[200px]">
                                    <Label htmlFor="name" value="Map Name"/>
                                    <TextInput
                                        id="name"
                                        type="text"
                                        required
                                        disabled={isViewOnly}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px] pl-2">
                                    <Label htmlFor="Implicit" value="Implicit Connection Weighting"/>
                                    <div className="flex space-x-3 items-center">
                                        <ToggleSwitch
                                            checked={implicitConnections}
                                            theme={ToggleTheme}
                                            onChange={setImplicitConnections}
                                        />
                                        <TextInput
                                            id="implicitConnectionWeight"
                                            type={"number"}
                                            required
                                            disabled={isViewOnly || !implicitConnections}
                                            value={implicitConnectionWeight}
                                            onChange={(e) => setImplicitConnectionWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-4">
                                <div className="flex-1 min-w-[200px]">
                                    <Label htmlFor="sizeX" value="Map Size X"/>
                                    <TextInput
                                        id="sizeX"
                                        type="number"
                                        required
                                        min={25}
                                        max={250}
                                        disabled={isViewOnly}
                                        value={mapSizeX}
                                        onChange={(e) => setMapSizeX(Number(e.target.value))}
                                    />
                                </div>
                                <div className="flex-1 min-w-[200px]">
                                    <Label htmlFor="sizeY" value="Map Size Y"/>
                                    <TextInput
                                        id="sizeY"
                                        type="number"
                                        required
                                        min={25}
                                        max={250}
                                        disabled={isViewOnly}
                                        value={mapSizeY}
                                        onChange={(e) => setMapSizeY(Number(e.target.value))}
                                    />
                                </div>
                            </div>

                            {/* Tileset Selection */}
                            <div>
                                <Label htmlFor="tileset" value="Select Tileset"/>
                                {tilesetLoading ? (
                                    <div className="flex items-center space-x-2">
                                        <Spinner size="sm"/>
                                        <span>Loading Tilesets...</span>
                                    </div>
                                ) : tilesetError ? (
                                    <Alert color="failure">
                                        <span>{tilesetError}</span>
                                    </Alert>
                                ) : (
                                    <Select
                                        id="tileset"
                                        value={selectedTileset ? selectedTileset._id : ''}
                                        onChange={handleTilesetChange}
                                        disabled={isViewOnly}
                                    >
                                        {tilesets && tilesets.map(tileset => (
                                            <option key={tileset._id} value={tileset._id}>
                                                {tileset.name}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            </div>

                            {/* Error Message */}
                            {error && <Alert color="failure">{error}</Alert>}
                        </form>
                        {/* Map Grid Display */}
                        <div className="mt-4 flex-1 flex min-h-[540px]">
                            {selectedTileset ? (
                                <MapGrid
                                    story={story}
                                    tileset={selectedTileset}
                                    backgroundTiles={backgroundTiles}
                                    foregroundTiles={foregroundTiles}
                                    setBackgroundTiles={setBackgroundTiles}
                                    setForegroundTiles={setForegroundTiles}
                                    markers={markers}
                                    connections={connections}
                                    setMarkers={setMarkers}
                                    setConnections={setConnections}
                                    viewOnly={isViewOnly}
                                    onMapUpdate={handleMapUpdate}
                                    onMarkersUpdate={handleMarkersUpdate}
                                    onConnectionsUpdate={handleConnectionsUpdate}
                                    mapSizeX={mapSizeX}
                                    mapSizeY={mapSizeY}
                                />
                            ) : (
                                <div className="flex items-center justify-center w-full text-center text-gray-500">
                                    Please select a tileset to view the map.
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Modal.Body>

            {!isExpanded && (
                <Modal.Footer>
                    {!isViewOnly ? (
                        <div className="flex justify-between w-full">
                            <Button onClick={handleSubmit} disabled={loading || tilesetLoading}>
                                {loading ? 'Saving...' : mapToEdit ? 'Save Changes' : (
                                    <>
                                        Add Map <GiQuillInk size={16} className="ml-1 translate-x-1" />
                                    </>
                                )}
                            </Button>
                            {mapToEdit && (
                                <Button color="red" onClick={handleDelete} disabled={loading}>
                                    Delete Map
                                </Button>
                            )}
                            <Button color="gray" onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                        </div>
                    ) : (
                        <div className="flex justify-end w-full">
                            <Button color="gray" onClick={onClose} disabled={loading}>
                                Close
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    )
};

// **PropTypes Validation**
MapFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    mapToEdit: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string,
        dimension: PropTypes.number,
        implicitConnections: PropTypes.bool,
        implicitConnectionWeight: PropTypes.number,
        mapSizeX: PropTypes.number,
        mapSizeY: PropTypes.number,
        tileset: PropTypes.object,
    }),
    story: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        world: PropTypes.object.isRequired, // Ensure story has world
    }).isRequired,
    viewOnly: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
};

MapFormModal.defaultProps = {
    mapToEdit: null,
    viewOnly: false,
};

export default MapFormModal;
