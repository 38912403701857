// src/components/edit/utils/GroupSelectionModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Label, TextInput, Table } from 'flowbite-react';
import PropTypes from 'prop-types';
import { FiTrash2 } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import SelectTheme from "../../../../themes/SelectTheme";

export function GroupSelectionModal({
                                        show,
                                        onClose,
                                        onSave,
                                        group,
                                        tileImages,
                                    }) {
    const { register, handleSubmit, reset } = useForm();
    const [selectedTiles, setSelectedTiles] = useState([]);
    const [probabilities, setProbabilities] = useState({});
    const [availableTiles, setAvailableTiles] = useState([]);

    useEffect(() => {
        if (group) {
            setSelectedTiles(group.tileIndices || []);
            const initialProbs = {};
            (group.tileIndices || []).forEach((tileIndex, idx) => {
                initialProbs[tileIndex] = group.probabilities
                    ? group.probabilities[idx] || 1
                    : 1;
            });
            setProbabilities(initialProbs);
        } else {
            // If group is null, reset states
            setSelectedTiles([]);
            setProbabilities({});
        }
    }, [group]);

    useEffect(() => {
        // Determine available tiles that are not already selected
        const available = tileImages
            .map((imgSrc, index) => ({ value: index, label: `Tile ${index}`, imgSrc }))
            .filter(tile => !selectedTiles.includes(tile.value));
        setAvailableTiles(available);
    }, [selectedTiles, tileImages]);

    const handleAddTiles = (selectedOption) => {
        if (selectedOption) {
            const newTileIndex = selectedOption.value;
            setSelectedTiles(prev => [...prev, newTileIndex]);
            setProbabilities(prev => ({
                ...prev,
                [newTileIndex] : 1
            }));
        }
    };

    const handleRemoveTile = (tileIndex) => {
        setSelectedTiles(prev => prev.filter(index => index !== tileIndex));
        setProbabilities(prev => {
            const updated = { ...prev };
            delete updated[tileIndex];
            return updated;
        });
    };

    const handleProbabilityChange = (tileIndex, value) => {
        setProbabilities(prev => ({
            ...prev,
            [tileIndex]: parseFloat(value) || 0,
        }));
    };

    const onSubmit = (data) => {
        if (!group) return; // Prevent submission if group is null

        const updatedProbabilities = selectedTiles.map(tileIndex => probabilities[tileIndex] || 1);

        const updatedGroup = {
            ...group,
            name: data.groupName, // Updated from form
            tileIndices: selectedTiles,
            probabilities: updatedProbabilities, // Correctly mapped probabilities
            inPalette: group.inPalette, // Preserve inPalette
        };
        onSave(updatedGroup);
        onClose();
    };


    // If group is null, don't render the modal content
    if (!group) {
        return null;
    }

    return (
        <Modal show={show} size="lg" popup onClose={onClose}>
            <Modal.Header />
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <div>
                        <Label htmlFor="groupName">Group Name</Label>
                        <TextInput
                            id="groupName"
                            defaultValue={group.name}
                            {...register('groupName', { required: true })}
                        />
                    </div>
                    <div>
                        <Label>Add Tiles to Group</Label>
                        <Select
                            options={availableTiles}
                            onChange={handleAddTiles}
                            placeholder="Select tiles to add..."
                            styles={SelectTheme()}
                        />
                    </div>
                    {selectedTiles.length > 0 && (
                        <div>
                            {/*<Label>Probabilities</Label>*/}
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Tile</Table.HeadCell>
                                    <Table.HeadCell>Probability</Table.HeadCell>
                                    <Table.HeadCell>Remove</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {selectedTiles.map((tileIndex) => (
                                        <Table.Row key={tileIndex} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="flex items-center space-x-2">
                                                <img
                                                    src={tileImages[tileIndex]}
                                                    alt={`Tile ${tileIndex}`}
                                                    className="w-10 h-10 object-cover"
                                                />
                                                {/*<span>Tile {tileIndex}</span>*/}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <TextInput
                                                    type="number"
                                                    min="0"
                                                    step="0.1"
                                                    value={probabilities[tileIndex]}
                                                    onChange={(e) => handleProbabilityChange(tileIndex, e.target.value)}
                                                    className="w-24"
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    type="button"
                                                    color="failure"
                                                    size="xs"
                                                    onClick={() => handleRemoveTile(tileIndex)}
                                                >
                                                    <FiTrash2 />
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    )}
                    <div className="flex justify-end space-x-2">
                        <Button type="button" color="gray" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

GroupSelectionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    group: PropTypes.object, // Make group optional
    tileImages: PropTypes.array.isRequired,
};
