// src/components/SceneView.js
import React, { useCallback, useState } from 'react';
import {
    FaTrashAlt,
    FaImage,
    FaRegStickyNote,
    FaBug
} from 'react-icons/fa';
import { RiExpandHeightFill } from "react-icons/ri";
import { BsArrowsCollapse } from "react-icons/bs";
import { GiArchiveRegister } from "react-icons/gi";
import axios from "axios";

const SceneView = React.memo(
    ({
         scene,
         onRejectParagraph,
         onAddPicture,
         onCreateMemory,
         onRemovePicture,
     }) => {
        // Local state for managing paragraph actions
        const [rejectedParagraphs, setRejectedParagraphs] = useState({});
        const [collapsedParagraphs, setCollapsedParagraphs] = useState({});
        const [openMenuIndex, setOpenMenuIndex] = useState(null);
        const [hoveredCharacter, setHoveredCharacter] = useState(null);

        if (!scene) return null;

        const handleParagraphClick = (index) => {
            setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
        };

        const handleRejectParagraphLocal = (index) => {
            setRejectedParagraphs((prevState) => ({
                ...prevState,
                [index]: true,
            }));
            if (onRejectParagraph) {
                onRejectParagraph(scene._id, index);
            }
            setOpenMenuIndex(null);
        };

        const handleAddPictureLocal = async (index) => {
            try {
                // const response = await axios.post(
                //     `${process.env.REACT_APP_API_URL}/scene/${scene._id}/picture/${index}`,
                //     { index }
                // );
                // const imageId = response.data.imageId;
                if (onAddPicture) {
                    onAddPicture(scene._id, index);
                }
            } catch (err) {
                console.error('Error generating image:', err);
            } finally {
                setOpenMenuIndex(null);
            }
        };

        const handleCreateMemoryLocal = (index) => {
            if (onCreateMemory) {
                onCreateMemory(scene._id, index);
            }
            setOpenMenuIndex(null);
        };

        const handleRemovePictureLocal = (index) => {
            if (onRemovePicture) {
                onRemovePicture(scene._id, index);
            }
            setOpenMenuIndex(null);
        };

        const toggleCollapseParagraph = (index) => {
            setCollapsedParagraphs((prevState) => ({
                ...prevState,
                [index]: !prevState[index],
            }));
            if (openMenuIndex === index) {
                setOpenMenuIndex(null);
            }
        };

        function showSceneDebugInfo()
        {
            const url = `${window.location.origin}/scene/${scene._id}/debug`;
            // Opens new tab or window based on browser settings
            window.open(url, '_blank');
        }

        // Helper function to find character by name
        const getCharacterByName = (name) => {
            return scene.characters.find(
                (character) => character.name.toLowerCase() === name.toLowerCase()
            );
        };

        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="ml-4 mr-4 mt-2 mb-2 max-w-4xl mx-auto">
                    {/* Scene Break and Header */}
                    <div className="border-t border-gray-300 dark:border-gray-600 mt-4 mb-4"></div>
                    <div className="flex items-center justify-between mb-2">
                        <div>
                            <h2 className="text-xl font-semibold">{scene.name || 'Scene'}</h2>
                            <p className="text-gray-600">{scene.location?.name || scene.locationName || 'Unknown Location'}</p>
                        </div>
                        {/* Memory Icon */}
                        <FaBug
                            onClick={showSceneDebugInfo}
                            className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                        />

                        {scene.summary && (
                            <div className="relative group">
                                <FaRegStickyNote className="text-gray-600 hover:text-gray-800 cursor-pointer"/>
                                <div
                                    className="absolute right-0 mt-2 w-64 p-2 bg-white border border-gray-300 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                    <p className="text-sm text-gray-700">{scene.summary}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Characters List */}
                    {scene.characters && scene.characters.length > 0 && (
                        <div className="flex -space-x-4 mb-4">
                            {scene.characters.map((character, index) => (
                                <div
                                    key={index}
                                    className="relative w-10 h-10 rounded-full border-2 border-white dark:border-gray-800 cursor-pointer z-20"
                                    onMouseEnter={() => setHoveredCharacter(character)}
                                    onMouseLeave={() => setHoveredCharacter(null)}
                                >
                                    <img
                                        className="w-full h-full rounded-full object-cover"
                                        src={`${process.env.REACT_APP_API_URL}/image/attached/${character._id}?d=4`}
                                        alt={character.name}
                                    />
                                    {hoveredCharacter === character && (
                                        <div
                                            className="absolute bottom-0 left-0 transform translate-y-full mt-2 w-32 p-2 bg-white border border-gray-300 dark:bg-gray-900 dark:border-gray-500 rounded shadow-lg">
                                            <p className="text-sm font-semibold">{character.name}</p>
                                            <p className="text-xs text-gray-600">{character.details}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {/* Scene Location Image if it exists */}
                    <div className={"pb-4 px-4"}>
                        <img
                            src={`${process.env.REACT_APP_API_URL}/image/attached/${scene.location._id}?d=6`}
                            alt="Paragraph"
                            className="mt-2 w-full aspect-[16/9] object-cover object-center rounded-xl"
                        />
                    </div>
                    {/* Render Completed Paragraphs */}
                    {scene.paragraphs &&
                        scene.paragraphs.map((paragraph, index) => {
                            const isRejected = rejectedParagraphs[index];
                            const isCollapsed = collapsedParagraphs[index];
                            const isMenuOpen = openMenuIndex === index;

                            // Determine if paragraph is spoken by a character
                            const character = paragraph.by ? getCharacterByName(paragraph.by) : null;

                            return (
                                <div key={index} className="relative group flex items-start mb-2">
                                    {/* Character Icon */}
                                    {character && (
                                        <div className="mr-2  flex-shrink-0 translate-y-2">
                                            <img
                                                className="w-8 h-8 rounded-full object-cover"
                                                src={`${process.env.REACT_APP_API_URL}/image/attached/${character._id}?d=4`}
                                                alt={character.name}
                                                title={character.name}
                                            />
                                        </div>
                                    )}
                                    {/* Paragraph Content */}
                                    <div
                                        onClick={() => handleParagraphClick(index)}
                                        className={`text-md p-2 rounded-lg w-full ${
                                            openMenuIndex === index ? 'bg-gray-100 dark:bg-gray-900 ' : ''
                                        } ${
                                            isRejected
                                                ? 'text-gray-400 dark:text-gray-600 line-through cursor-pointer'
                                                : 'cursor-pointer'
                                        }`}
                                    >
                                        {!isCollapsed && (
                                            <>
                                                {paragraph.text ? (
                                                    <p>{paragraph.text}</p> // Render unstructured text
                                                ) : (
                                                    <>
                                                        <p className="font-semibold">
                                                            {paragraph.by || '...'}
                                                        </p>
                                                        {paragraph.speech && <p>{paragraph.speech}</p>}
                                                        {paragraph.action && (
                                                            <p className="">
                                                                {paragraph.action}
                                                            </p>
                                                        )}
                                                    </>
                                                )}
                                                {/* Display image if exists */}
                                                {paragraph.image && (
                                                    <img
                                                        src={`${process.env.REACT_APP_API_URL}/image/${paragraph.image}`}
                                                        alt="Paragraph"
                                                        className="mt-2 w-full aspect-[16/9] object-cover object-center rounded-xl"
                                                    />
                                                )}
                                            </>
                                        )}
                                        {isCollapsed && (
                                            <p className="text-gray-500 italic">
                                                Paragraph collapsed
                                            </p>
                                        )}
                                    </div>
                                    {/* Menu Icons */}
                                    {isMenuOpen && (
                                        <div
                                            className="absolute top-0 right-0 mt-2 mr-2 bg-white border border-gray-300 rounded shadow-lg p-2 flex space-x-2 z-10">
                                            {/* Add Picture */}
                                            {!paragraph.image && (
                                                <FaImage
                                                    size={24}
                                                    className="text-blue-500 hover:text-blue-700 cursor-pointer"
                                                    onClick={() => handleAddPictureLocal(index)}
                                                    title="Add Picture"
                                                />
                                            )}
                                            {/* Remove Picture */}
                                            {paragraph.image && (
                                                <FaTrashAlt
                                                    size={24}
                                                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                                    onClick={() => handleRemovePictureLocal(index)}
                                                    title="Remove Picture"
                                                />
                                            )}
                                            {/* Create Memory */}
                                            <GiArchiveRegister
                                                size={24}
                                                className="text-green-500 hover:text-green-700 cursor-pointer"
                                                onClick={() => handleCreateMemoryLocal(index)}
                                                title="Create Memory"
                                            />
                                            {/* Collapse/Expand */}
                                            <button
                                                className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                                onClick={() => toggleCollapseParagraph(index)}
                                                title={
                                                    isCollapsed
                                                        ? 'Expand Paragraph'
                                                        : 'Collapse Paragraph'
                                                }
                                            >
                                                {isCollapsed ? <RiExpandHeightFill size={24}/> :
                                                    <BsArrowsCollapse size={24}/>}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}

                    {/* Render the Last Partial Paragraph as it's being built */}
                    {scene.currentPartial && (
                        <div
                            key={`partial-${scene.paragraphs?.length || 0}`}
                            className={`text-md p-3 rounded-lg mb-2 w-full`}
                        >
                            {scene.currentPartial.text ? (
                                <p>{scene.currentPartial.text}</p> // Render unstructured partial text
                            ) : (
                                <>
                                    <p className="font-semibold">
                                        {scene.currentPartial.by || '...'}
                                    </p>
                                    {scene.currentPartial.speech && (
                                        <p>{scene.currentPartial.speech}</p>
                                    )}
                                    {scene.currentPartial.action && (
                                        <p>{scene.currentPartial.action}</p>
                                    )}
                                    {!scene.currentPartial.speech &&
                                        !scene.currentPartial.action && (
                                            <p>Receiving data...</p>
                                        )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.scene.paragraphs === nextProps.scene.paragraphs &&
            prevProps.scene.currentPartial === nextProps.scene.currentPartial
        );
    }
);

export default SceneView;
