import React, {useEffect, useRef, useState} from 'react';
import { Label, TextInput, Textarea, Badge, Button } from "flowbite-react";
import axios from 'axios';
import AddEditImage from "../common/AddEditImage";
import {useNavigate} from "react-router-dom";
import TagsInput from "../common/TagsInput";

function WorldOverview({ world, entitledWorlds = [], onSubmit }) {
    const [updatedWorld, setUpdatedWorld] = useState({ ...world });
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const addEditImageRef = useRef();

    const handleImageSave = async () => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving:', err);
            //setError('Failed to save changes.');
        }
    };

    if (!world) {
        return <div>Loading...</div>;
    }

    const handleChange = (field, value) => {
        setUpdatedWorld((prev) => ({ ...prev, [field]: value }));
    };

    // Handle story submission
    const handlePublish = async () => {
        setIsProcessing(true);
        await handleImageSave();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/world/publish/${updatedWorld._id}`, updatedWorld, {params:{ clone: false, copyEntitlements: false, deepClone: false}});
        setUpdatedWorld(response.data);
        setIsProcessing(false);
    };

    // Handle story submission
    const handleSubmit = async () => {
        setIsProcessing(true);
        await handleImageSave();
        await axios.put(`${process.env.REACT_APP_API_URL}/world/${updatedWorld._id}`, updatedWorld);
        setIsProcessing(false);
    };

    // Handle story submission
    const handleDelete = async () => {
        setIsProcessing(true);
        if (window.confirm('Are you sure you want to delete this?')) {
            // Perform deletion operations here if user clicked "OK" ...
            await axios.delete(`${process.env.REACT_APP_API_URL}/world/${updatedWorld._id}`);
            navigate('/worlds');
        }
        setIsProcessing(false);
    };

    return (

        <div>
            {isProcessing && (
                <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">Processing your world...</span>
                </div>
            )}

            <div className="flex items-center justify-between px-6">
                {/* Save Button */}
                <div className="">
                    <Button onClick={handleSubmit} disabled={isProcessing}>Save Updated World</Button>
                </div>
                {/*<div className="">*/}
                {/*    <Button color={"gray"} onClick={handleDelete} disabled={isProcessing}>Clone World</Button>*/}
                {/*</div>*/}
                {!world.isPublished &&
                    <div className="">
                        <Button color={"gray"} onClick={handlePublish} disabled={isProcessing}>Publish World</Button>
                    </div>
                }
                <div className="">
                    <Button color={"red"} onClick={handleDelete} disabled={isProcessing}>Delete World</Button>
                </div>

            </div>
            <div className='px-6 pt-6 pb-16'>
                {/* Flex container for the name field and save button */}
                <div className="flex items-center justify-between mb-4">
                    {/* World Name */}
                    <div className="flex-grow">
                        <Label value='Name'/>
                        <TextInput
                            placeholder="Name this world"
                            value={updatedWorld.name}
                            onChange={(event) => setUpdatedWorld({...updatedWorld, name: event.target.value})}
                        />
                    </div>
                    {/*/!* Save Button *!/*/}
                    {/*<div className="ml-4 mt-5">*/}
                    {/*    <Button onClick={handleSubmit}>Save</Button>*/}
                    {/*</div>*/}
                </div>

                {/* World Description */}
                <div className='mt-4'>
                    <Label value='Description'/>
                    <Textarea
                        placeholder="What Is the main idea or purpose behind this world"
                        theme={TextAreaTheme}
                        value={updatedWorld.description}
                        rows={3}
                        onChange={(event) => setUpdatedWorld({...updatedWorld, description: event.target.value})}
                    />
                </div>

                {/* Inherited Worlds */}
                <div className='mt-4'>
                    <Label value="Inherited From Worlds"/>
                    {updatedWorld.inheritFrom && updatedWorld.inheritFrom.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {updatedWorld.inheritFrom.map((inheritedWorld) => {
                                return inheritedWorld ? (
                                    <Badge key={inheritedWorld._id} className="cursor-pointer">
                                        {inheritedWorld._id}
                                    </Badge>
                                ) : null;
                            })}
                        </div>
                    ) : (
                        <p className="text-gray-500">No inherited worlds</p>
                    )}
                </div>

                {/* Story Tags */}
                <div className='mt-4'>
                    <Label htmlFor="tags" value="Tags"/>
                    <TagsInput
                        tags={updatedWorld.tags || []}
                        onChange={(tags) => handleChange('tags', tags)}
                        placeholder="Add tag and press Enter"
                    />
                </div>

                {/* Responsive layout for World Setting and Image */}
                <div className="flex flex-col lg:flex-row mt-4 gap-4">
                    {/* World Setting */}
                    <div className="flex-1 order-2 lg:order-1">
                        <Label value='Setting'/>
                        <Textarea
                            placeholder="Start to give this world some flavour, describe its general setting"
                            theme={TextAreaTheme}
                            value={updatedWorld.setting}
                            rows={14}
                            onChange={(event) => setUpdatedWorld({...updatedWorld, setting: event.target.value})}
                        />
                    </div>

                    {/* World Cover Art */}
                    <div className='w-full lg:w-96 order-1 lg:order-2'>
                        <Label value='Cover Art'/>
                        <AddEditImage
                            ref={addEditImageRef}
                            parentData={world}
                            placeholder={1}
                        />
                    </div>


                </div>
            </div>
        </div>
    );
}

export default WorldOverview;


const TextAreaTheme = {
    "base": "block w-full rounded-lg border text-sm disabled:cursor-not-allowed px-3 py-2 disabled:opacity-50",

    "colors": {
        "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
    },

    "withShadow": {
        "on": "shadow-sm dark:shadow-sm-light",
        "off": ""
    }
}
