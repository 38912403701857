// src/components/MapGrid.js

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ObjectId from 'bson-objectid';
import { Button, Label, TextInput, Modal } from 'flowbite-react';
import { debounce } from 'lodash';
import Select from "react-select";
import { ModalTheme } from "../../../../themes/ModalTheme";
import MarkerModal from './MarkerModal';
import ConnectionModal from './ConnectionModal';
import axios from "axios";
import { transformToSelectOptions } from "../../../common/transformToSelectOptions";
import SelectTheme from "../../../../themes/SelectTheme";
import MapDebug from "./MapDebug";

const MapGrid = ({
                     story,
                     tileset,
                     backgroundTiles,
                     foregroundTiles,
                     setBackgroundTiles,
                     setForegroundTiles,
                     markers,
                     connections,
                     setConnections,
                     setMarkers,
                     viewOnly,
                     onMapUpdate,
                     onMarkersUpdate,
                     onConnectionsUpdate,
                     mapSizeX,
                     mapSizeY,
                 }) => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [tileImages, setTileImages] = useState([]);
    const [selectedPaletteItem, setSelectedPaletteItem] = useState(null);
    const [markerName, setMarkerName] = useState('');
    const [connectionName, setConnectionName] = useState('');
    const [selectedMarkersForConnection, setSelectedMarkersForConnection] = useState([]);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Mode: 'edit', 'marker', 'connect'
    const [mode, setMode] = useState('edit');

    // Zoom and pan state
    const [scale, setScale] = useState(1);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const isPanning = useRef(false);
    const panStart = useRef({ x: 0, y: 0 });
    const panStartOffset = useRef({ x: 0, y: 0 });

    const [locations, setLocations] = useState([]);
    const [maps, setMaps] = useState([]);

// Add this line along with your other useState hooks
    const [selectedLocation, setSelectedLocation] = useState(null);

    // Painting state
    const isPainting = useRef(false);

    // State for marker modal
    const [isMarkerModalOpen, setIsMarkerModalOpen] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);

    // State for connection modal
    const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
    const [selectedConnection, setSelectedConnection] = useState(null);

    // State for dragging markers
    const [draggingMarkerId, setDraggingMarkerId] = useState(null);
    const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });

    // Utility function to clamp a value between min and max
    const clamp = useCallback((value, min, max) => Math.min(Math.max(value, min), max), []);

    // Inside your MapGrid component
    const isDragging = useRef(false);
    const mouseDownPos = useRef({ x: 0, y: 0 });
    const dragThreshold = 5; // pixels


    // Function to start dragging a marker
    const handleMarkerDragStart = useCallback((marker, mousePos) => {
        setDraggingMarkerId(marker._id);
        setDraggingOffset({
            x: mousePos.pixelX - (marker.x * tileset.tileSize + tileset.tileSize / 2),
            y: mousePos.pixelY - (marker.y * tileset.tileSize + tileset.tileSize / 2),
        });
    }, []);

    // Function to handle marker dragging
    const handleMarkerDrag = useCallback((e) => {
        if (!draggingMarkerId) return;
        const mousePos = getMousePos(e);
        const newPixelX = mousePos.pixelX - draggingOffset.x;
        const newPixelY = mousePos.pixelY - draggingOffset.y;

        // Snap to grid
        const newX = Math.floor(newPixelX / tileset.tileSize);
        const newY = Math.floor(newPixelY / tileset.tileSize);

        // Clamp within map boundaries
        const clampedX = clamp(newX, 0, mapSizeX - 1);
        const clampedY = clamp(newY, 0, mapSizeY - 1);

        // Update the marker's position
        setMarkers((prevMarkers) =>
            prevMarkers.map((marker) =>
                marker._id === draggingMarkerId
                    ? { ...marker, x: clampedX, y: clampedY }
                    : marker
            )
        );
    }, [draggingMarkerId, draggingOffset, tileset.tileSize, mapSizeX, mapSizeY, clamp]);

    // Function to end dragging
    const handleMarkerDragEnd = useCallback(() => {
        if (draggingMarkerId) {
            onMarkersUpdate(markers); // Notify parent about the update
            setDraggingMarkerId(null);
            setDraggingOffset({ x: 0, y: 0 });
        }
    }, [draggingMarkerId, markers, onMarkersUpdate]);

    const loadTileSheet = useCallback((tileset) => {
        const image = new Image();
        image.src = tileset.imageSrc;
        image.crossOrigin = 'Anonymous'; // Handle CORS if needed
        image.onload = () => {
            const tiles = [];
            const columns = Math.floor(image.width / tileset.tileSize);
            const rows = Math.floor(image.height / tileset.tileSize);

            for (let y = 0; y < rows; y++) {
                for (let x = 0; x < columns; x++) {
                    const canvas = document.createElement('canvas');
                    canvas.width = tileset.tileSize;
                    canvas.height = tileset.tileSize;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(
                        image,
                        x * tileset.tileSize,
                        y * tileset.tileSize,
                        tileset.tileSize,
                        tileset.tileSize,
                        0,
                        0,
                        tileset.tileSize,
                        tileset.tileSize
                    );

                    // Check for transparency
                    const imageData = ctx.getImageData(0, 0, tileset.tileSize, tileset.tileSize);
                    const { data } = imageData;
                    let hasTransparency = false;

                    for (let i = 3; i < data.length; i += 4) {
                        if (data[i] < 255) {
                            hasTransparency = true;
                            break;
                        }
                    }

                    // Store tile and transparency information
                    tiles.push({ canvas, hasTransparency });
                }
            }

            setTileImages(tiles);
        };
    }, []);

    useEffect(() => {
        // Define async function to fetch data
        const fetchData = async () => {
            try {
                const params = {
                    storyId: story._id,
                    limit: 200, // Adjust as needed
                };

                // Fetch maps and locations in parallel
                const [mapsResponse, locationsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/map/list`, { params }),
                    axios.get(`${process.env.REACT_APP_API_URL}/location/list`, { params })
                ]);

                // Transform the fetched data
                const transformedMaps = transformToSelectOptions(mapsResponse.data.maps);
                const transformedLocations = transformToSelectOptions(locationsResponse.data.locations);

                // Set state with transformed data
                setMaps(transformedMaps);
                setLocations(transformedLocations);

                // Optionally, set default selectedMarker values
                if (transformedMaps.length > 0) {
                    setSelectedMarker(prev => ({ ...prev, map: transformedMaps[0].value }));
                }

                if (transformedLocations.length > 0) {
                    setSelectedMarker(prev => ({ ...prev, location: transformedLocations[0].value }));
                }

            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [story._id]);

    // Function to detect if mouse is over a marker
    const isMouseOverMarker = useCallback(
        (mousePos) => {
            const { pixelX, pixelY } = mousePos;
            const markerRadius = tileset.tileSize / 4;
            return markers.find((marker) => {
                const markerX = marker.x * tileset.tileSize + tileset.tileSize / 2;
                const markerY = marker.y * tileset.tileSize + tileset.tileSize / 2;
                const dx = pixelX - markerX;
                const dy = pixelY - markerY;
                return dx * dx + dy * dy <= markerRadius * markerRadius;
            });
        },
        [markers, tileset.tileSize]
    );

    // Function to detect if mouse is over a connection
    const isMouseOverConnection = useCallback(
        (mousePos) => {
            const { pixelX, pixelY } = mousePos;
            const threshold = 5; // pixels
            return connections.find((connection) => {
                const startMarker = markers.find((marker) => marker._id === connection.from);
                const endMarker = markers.find((marker) => marker._id === connection.to);

                if (!startMarker || !endMarker) return false;

                const startX = startMarker.x * tileset.tileSize + tileset.tileSize / 2;
                const startY = startMarker.y * tileset.tileSize + tileset.tileSize / 2;
                const endX = endMarker.x * tileset.tileSize + tileset.tileSize / 2;
                const endY = endMarker.y * tileset.tileSize + tileset.tileSize / 2;

                const distance = pointToSegmentDistance(
                    { x: pixelX, y: pixelY },
                    { x: startX, y: startY },
                    { x: endX, y: endY }
                );

                return distance < threshold;
            });
        },
        [connections, markers, tileset.tileSize]
    );

    useEffect(() => {
        if (tileset && tileset.imageSrc && tileset.tileSize) {
            loadTileSheet(tileset); // Load the defined Tileset
        }
    }, [tileset, loadTileSheet]);

    // Updated panning handler with clamping
    const handlePanningMouseMove = useCallback(
        (e) => {
            if (isPanning.current && !draggingMarkerId) { // Only pan if not dragging
                const deltaX = e.clientX - panStart.current.x;
                const deltaY = e.clientY - panStart.current.y;

                let newOffsetX = panStartOffset.current.x + deltaX;
                let newOffsetY = panStartOffset.current.y + deltaY;

                // Access the container element to get its dimensions
                const container = containerRef.current;
                if (container) {
                    const containerWidth = container.clientWidth;
                    const containerHeight = container.clientHeight;

                    // Calculate the map's size in pixels considering the current scale
                    const mapWidth = mapSizeX * tileset.tileSize * scale;
                    const mapHeight = mapSizeY * tileset.tileSize * scale;

                    // Calculate minimum and maximum offsets
                    const minOffsetX = Math.min(0, containerWidth - mapWidth);
                    const minOffsetY = Math.min(0, containerHeight - mapHeight);
                    const maxOffsetX = 0;
                    const maxOffsetY = 0;

                    // Check if the map is smaller than the container and center it if necessary
                    if (mapWidth < containerWidth) {
                        newOffsetX = (containerWidth - mapWidth) / 2;
                    } else {
                        // Clamp the new offsets within the calculated bounds
                        newOffsetX = clamp(newOffsetX, minOffsetX, maxOffsetX);
                    }

                    if (mapHeight < containerHeight) {
                        newOffsetY = (containerHeight - mapHeight) / 2;
                    } else {
                        // Clamp the new offsets within the calculated bounds
                        newOffsetY = clamp(newOffsetY, minOffsetY, maxOffsetY);
                    }

                    setOffset({
                        x: newOffsetX,
                        y: newOffsetY,
                    });
                }
            }
        },
        [mapSizeX, mapSizeY, tileset.tileSize, scale, clamp, draggingMarkerId]
    );

    // Function to render the tile palette
    const renderPalette = () => (
        <div className="grid grid-cols-4 overflow-auto max-h-full gap-1">
            {paletteItems.map((item) => {
                let displayTileIndex = null;
                if (item.type === 'group') {
                    displayTileIndex = item.tileIndices.length > 0 ? item.tileIndices[0] : null;
                } else if (item.type === 'rule') {
                    const centerCell = item.grid[4];
                    const group = tileset.tileGroups.find((g) => g._id === centerCell);
                    if (group && group.tileIndices.length > 0) {
                        displayTileIndex = group.tileIndices[0];
                    } else {
                        displayTileIndex = parseInt(centerCell, 10);
                    }
                }

                return (
                    <div
                        key={item.id}
                        className="relative cursor-pointer h-14"
                        onClick={() => handleSelectTile(item)}
                    >
                        {/* Overlay for selected item */}
                        {selectedPaletteItem && selectedPaletteItem.id === item.id && (
                            <div className="absolute inset-0 bg-blue-500 opacity-25 border-2 rounded"></div>
                        )}

                        {displayTileIndex !== null && tileImages[displayTileIndex] ? (
                            <div className="w-full h-full flex items-center justify-center">
                                <img
                                    src={tileImages[displayTileIndex].canvas.toDataURL()}
                                    alt={`${item.type} ${item.name}`}
                                    className="m-auto object-cover h-10 w-10"
                                />
                            </div>
                        ) : (
                            <div className="flex p-2 items-center justify-center bg-gray-200 dark:bg-gray-900 hover:border rounded">
                                <span>?</span>
                            </div>
                        )}

                        {/* Overlay for item name */}
                        <div className="absolute inset-0 top-0 left-0 text-white text-xs px-1">
                            <span>{item.name}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    // Function to conditionally render the palette based on the current mode
    const renderEditPalette = () => {
        if (mode !== 'edit') return null;
        if (!tileImages || tileImages.length === 0) return null;
        return renderPalette();
    };

    // Function to handle markers update
    const handleMarkersUpdate = (updatedMarkers) => {
        setMarkers(updatedMarkers);
        onMarkersUpdate(updatedMarkers); // Prop callback to inform parent or perform side-effects
    };

    // Initiate Panning
    const handleMouseDownPanning = (e) => {
        if (e.button === 1 || e.button === 2) {
            // Middle or right-click
            e.preventDefault(); // Prevent default actions like context menu on right-click
            isPanning.current = true;
            panStartOffset.current = { ...offset };
            panStart.current = { x: e.clientX, y: e.clientY };
            // Change cursor to grabbing
            if (canvasRef.current) {
                canvasRef.current.style.cursor = 'grabbing';
            }
        }
    };

    // Terminate Panning
    const handleMouseUpPanning = () => {
        if (isPanning.current) {
            isPanning.current = false;
            // Reset cursor to grab
            if (canvasRef.current) {
                canvasRef.current.style.cursor = 'grab';
            }
        }
    };

    // Attach event listeners for panning termination
    useEffect(() => {
        const handleDocumentMouseUp = () => {
            if (isPanning.current) {
                isPanning.current = false;
                // Reset cursor to grab
                if (canvasRef.current) {
                    canvasRef.current.style.cursor = 'grab';
                }
            }
        };

        document.addEventListener('mouseup', handleDocumentMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleDocumentMouseUp);
        };
    }, []);

    const getMousePos = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const dpr = window.devicePixelRatio || 1;

        // Adjust for device pixel ratio
        const x = (e.clientX - rect.left) * (canvas.width / rect.width);
        const y = (e.clientY - rect.top) * (canvas.height / rect.height);

        // Reverse the transformations: first remove offset, then scale
        const adjustedX = (x / dpr - offset.x) / scale;
        const adjustedY = (y / dpr - offset.y) / scale;

        const tileX = Math.floor(adjustedX / tileset.tileSize);
        const tileY = Math.floor(adjustedY / tileset.tileSize);

        return { x: tileX, y: tileY, pixelX: adjustedX, pixelY: adjustedY };
    };

    // Auto-Tiling Functions
    const calculateBitmask = (x, y, layerTiles, tileTypeIndex) => {
        let bitmask = 0;

        // Define neighbor positions relative to current tile
        const neighbors = [
            { dx: -1, dy: -1, bit: 1 }, // Top-Left
            { dx: 0, dy: -1, bit: 2 }, // Top
            { dx: 1, dy: -1, bit: 4 }, // Top-Right
            { dx: 1, dy: 0, bit: 8 }, // Right
            { dx: 1, dy: 1, bit: 16 }, // Bottom-Right
            { dx: 0, dy: 1, bit: 32 }, // Bottom
            { dx: -1, dy: 1, bit: 64 }, // Bottom-Left
            { dx: -1, dy: 0, bit: 128 }, // Left
        ];

        const tileType = getTileTypeByIndex(tileTypeIndex);

        neighbors.forEach(({ dx, dy, bit }) => {
            const nx = x + dx;
            const ny = y + dy;
            if (nx >= 0 && nx < mapSizeX && ny >= 0 && ny < mapSizeY) {
                const neighborIndex = ny * mapSizeX + nx;
                const neighborTileIndex = layerTiles[neighborIndex];
                const neighborTileType = getTileTypeByIndex(neighborTileIndex);
                if (neighborTileType === tileType) {
                    bitmask |= bit;
                }
            }
        });

        return bitmask;
    };

    const getTileTypeByIndex = (index) => {
        // Search through tileset.tileGroups and find the first group that contains this index and return the name of the group
        const group = tileset.tileGroups.find((group) => group.tileIndices.includes(index));
        return group ? group.name : 'unknown';
    };

    const selectTileVariant = (tileType, bitmask) => {
        const rule = tileset.edgeRules.find((rule) => rule.name === tileType);
        if (rule) {
            const variant = rule.grid[bitmask];
            return variant !== undefined ? parseInt(variant, 10) : null;
        }
        // Fallback to a default variant index if no rule matches
        return null;
    };

    const placeTile = useCallback(
        (x, y, tileIndex, layer, layerTiles, setLayerTiles) => {
            const index = y * mapSizeX + x;
            const newLayerTiles = [...layerTiles];
            newLayerTiles[index] = tileIndex;
            setLayerTiles(newLayerTiles);
            onMapUpdate(newLayerTiles, layer);
        },
        [tileset, mapSizeX, mapSizeY, onMapUpdate]
    );

    // Drawing Functions
    const drawGrid = useCallback(
        (ctx) => {
            // Draw Background Tiles
            backgroundTiles.forEach((tile, index) => {
                const x = index % mapSizeX;
                const y = Math.floor(index / mapSizeX);
                const drawX = x * tileset.tileSize;
                const drawY = y * tileset.tileSize;
                if (tileImages[tile] && tileImages[tile].canvas) {
                    ctx.drawImage(
                        tileImages[tile].canvas, // Access the canvas element from the tileImages object
                        drawX,
                        drawY,
                        tileset.tileSize + 1,
                        tileset.tileSize + 1
                    );
                } else {
                    ctx.fillStyle = '#777';
                    ctx.fillRect(drawX, drawY, tileset.tileSize, tileset.tileSize);
                }
            });

            // Draw Foreground Tiles
            foregroundTiles.forEach((tile, index) => {
                if (tile === 0) return; // Skip if no foreground tile
                const x = index % mapSizeX;
                const y = Math.floor(index / mapSizeX);
                const drawX = x * tileset.tileSize;
                const drawY = y * tileset.tileSize;
                if (tileImages[tile] && tileImages[tile].canvas) {
                    ctx.drawImage(
                        tileImages[tile].canvas, // Access the canvas element from the tileImages object
                        drawX,
                        drawY,
                        tileset.tileSize + 1,
                        tileset.tileSize + 1
                    );
                }
            });
        },
        [backgroundTiles, foregroundTiles, tileset.tileSize, tileImages, mapSizeX]
    );

    const drawMarkers = useCallback((ctx) => {
        ctx.font = `${tileset.tileSize / 2}px Arial`;
        markers.forEach(marker => {
            const drawX = marker.x * tileset.tileSize + tileset.tileSize / 2;
            const drawY = marker.y * tileset.tileSize + tileset.tileSize / 2;
            ctx.beginPath();
            ctx.arc(
                drawX,
                drawY,
                tileset.tileSize / 4,
                0,
                2 * Math.PI
            );

            // Change color if dragging
            if (marker._id === draggingMarkerId) {
                ctx.fillStyle = 'orange';  // Highlight the dragging marker
            } else if (marker.location || marker.map) {
                ctx.fillStyle = 'white';  // Markers with location or map
            } else {
                ctx.fillStyle = 'gray';   // Markers without location or map
            }

            ctx.fill();

            // Optional: Different stroke color based on association
            if (marker.location || marker.map) {
                ctx.strokeStyle = '#444444'; // Dark gray border for associated markers
            } else {
                ctx.strokeStyle = '#888888'; // Lighter gray border for unassociated markers
            }
            ctx.stroke(); // Apply stroke

            // Draw marker name
            ctx.fillStyle = 'black';
            ctx.fillText(
                marker.name,
                drawX + 6,
                drawY - 10
            );
        });
    }, [markers, tileset.tileSize, draggingMarkerId]);

    const drawConnections = useCallback((ctx) => {
        ctx.font = `${tileset.tileSize / 4 }px Arial`;
        connections.forEach(connection => {
            const { from, to, name } = connection;
            const startMarker = markers.find(marker => marker._id === from);
            const endMarker = markers.find(marker => marker._id === to);

            if (!startMarker || !endMarker) return;

            const startX = startMarker.x * tileset.tileSize + tileset.tileSize / 2;
            const startY = startMarker.y * tileset.tileSize + tileset.tileSize / 2;
            const endX = endMarker.x * tileset.tileSize + tileset.tileSize / 2;
            const endY = endMarker.y * tileset.tileSize + tileset.tileSize / 2;

            // Calculate the connection line angle
            const angle = Math.atan2(endY - startY, endX - startX);

            const textWidth = ctx.measureText(name).width + 2;

            // Find the start and end of the text along the line
            const lineLength = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
            const textStart = (lineLength - textWidth) / 2;
            const startTextX = startX + (textStart / lineLength) * (endX - startX);
            const startTextY = startY + (textStart / lineLength) * (endY - startY);
            const endTextX = startTextX + (textWidth / lineLength) * (endX - startX);
            const endTextY = startTextY + (textWidth / lineLength) * (endY - startY);

            // Draw first part of the line
            ctx.strokeStyle = '#444444';
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(startTextX, startTextY);
            ctx.stroke();

            // Save the context and rotate around the midpoint of the text
            ctx.save();
            const midTextX = (startTextX + endTextX) / 2;
            const midTextY = (startTextY + endTextY) / 2;
            ctx.translate(midTextX, midTextY);
            ctx.rotate(angle);

            // Draw the text
            ctx.fillStyle = '#333333';
            ctx.fillText(name, -(textWidth / 2) + 1, 1.5);

            ctx.restore();

            // Draw second part of the line
            ctx.strokeStyle = '#444444';
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(endTextX, endTextY);
            ctx.lineTo(endX, endY);
            ctx.stroke();
        });
    }, [connections, markers, tileset.tileSize]);

    // Adjusted useEffect for rendering with high-DPI support and preventing cumulative scaling
    useEffect(() => {
        const canvas = canvasRef.current;
        const container = containerRef.current;
        if (!tileset || !tileset.tileSize || !canvas || !container) return;

        const ctx = canvas.getContext('2d');

        // Function to resize the canvas based on device pixel ratio
        const resizeCanvas = () => {
            const { clientWidth, clientHeight } = container;
            const dpr = window.devicePixelRatio || 1;

            // Set the canvas size considering the device pixel ratio
            canvas.width = clientWidth * dpr;
            canvas.height = clientHeight * dpr;

            // Style the canvas to match the container's size
            canvas.style.width = `${clientWidth}px`;
            canvas.style.height = `${clientHeight}px`;

            // Reset the transformation matrix before applying new scaling
            ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
        };

        // Debounced resize handler to improve performance
        const debouncedResize = debounce(resizeCanvas, 100);
        resizeCanvas();
        window.addEventListener('resize', debouncedResize);

        let animationFrameId;

        const render = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();

            // Disable image smoothing to prevent gaps
            ctx.imageSmoothingEnabled = false;

            ctx.translate(offset.x, offset.y);
            ctx.scale(scale, scale);
            drawGrid(ctx);
            drawConnections(ctx);
            drawMarkers(ctx);

            // Draw temporary line if connecting
            if (mode === 'connect' && selectedMarkersForConnection.length === 1) {
                const startMarker = selectedMarkersForConnection[0];
                const startX = startMarker.x * tileset.tileSize + tileset.tileSize / 2;
                const startY = startMarker.y * tileset.tileSize + tileset.tileSize / 2;

                ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
                ctx.lineWidth = 1;
                ctx.beginPath();
                ctx.moveTo(startX, startY);
                ctx.lineTo(mousePosition.x, mousePosition.y);
                ctx.stroke();
            }

            ctx.restore();
            animationFrameId = requestAnimationFrame(render);
        };
        render();

        return () => {
            cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', debouncedResize);
        };
    }, [
        backgroundTiles,
        foregroundTiles,
        markers,
        connections,
        scale,
        offset,
        tileset,
        tileImages,
        mapSizeX,
        mapSizeY,
        drawGrid,
        drawMarkers,
        drawConnections,
        mode,
        selectedMarkersForConnection,
        mousePosition,
    ]);

    // Update the close functions to reset selectedMarker and selectedConnection
    const closeMarkerModal = () => {
        setIsMarkerModalOpen(false);
        setSelectedMarker(null);
    };

    const closeConnectionModal = () => {
        setIsConnectionModalOpen(false);
        setSelectedConnection(null);
    };

    // Update the open modal functions to ensure selectedMarker/selectedConnection is set before opening
    const openMarkerModal = (marker) => {
        setSelectedMarker(marker);
        setIsMarkerModalOpen(true);
    };

    const openConnectionModal = (connection) => {
        setSelectedConnection(connection);
        setIsConnectionModalOpen(true);
    };

    // // Function to detect distance from a point to a line segment
    // const pointToSegmentDistance = (p, p1, p2) => {
    //     const A = p.x - p1.x;
    //     const B = p.y - p1.y;
    //     const C = p2.x - p1.x;
    //     const D = p2.y - p1.y;
    //
    //     const dot = A * C + B * D;
    //     const len_sq = C * C + D * D;
    //     let param = -1;
    //     if (len_sq !== 0) param = dot / len_sq;
    //
    //     let xx, yy;
    //
    //     if (param < 0) {
    //         xx = p1.x;
    //         yy = p1.y;
    //     } else if (param > 1) {
    //         xx = p2.x;
    //         yy = p2.y;
    //     } else {
    //         xx = p1.x + param * C;
    //         yy = p1.y + param * D;
    //     }
    //
    //     const dx = p.x - xx;
    //     const dy = p.y - yy;
    //     return Math.sqrt(dx * dx + dy * dy);
    // };

    // Auto-Tiling placement function
    const paintTile = useCallback(
        (e) => {
            const { x, y } = getMousePos(e);

            if (x < 0 || x >= mapSizeX || y < 0 || y >= mapSizeY) return;

            if (mode === 'edit') {
                if (selectedPaletteItem !== null) {
                    let tileIndex = null;
                    let isForeground = false;

                    if (selectedPaletteItem.type === 'group') {
                        const { tileIndices, probabilities } = selectedPaletteItem;
                        if (tileIndices.length > 0) {
                            // Calculate the sum of probabilities
                            let totalProbability = probabilities.reduce((a, b) => a + b, 0);
                            // Pick a random "target" value between 0 and the total
                            let target = Math.random() * totalProbability;
                            // Iterate through the probabilities
                            for (let i = 0; i < probabilities.length; i++) {
                                target -= probabilities[i];
                                if (target <= 0) {
                                    tileIndex = tileIndices[i];
                                    break;
                                }
                            }
                            if (tileIndex === null) {
                                tileIndex = tileIndices[tileIndices.length - 1];
                            }
                        }
                    } else if (selectedPaletteItem.type === 'rule') {
                        const { name: tileType } = selectedPaletteItem;
                        // Use edge rules to determine the tile variant based on surroundings
                        const layerTiles = isForeground ? foregroundTiles : backgroundTiles;
                        const currentTileIndex = layerTiles[y * mapSizeX + x];
                        const bitmask = calculateBitmask(x, y, layerTiles, currentTileIndex);
                        const resolvedTileIndex = selectTileVariant(tileType, bitmask);
                        if (resolvedTileIndex !== null) {
                            tileIndex = resolvedTileIndex;
                        } else {
                            const group = tileset.tileGroups.find((g) => g.name === tileType);
                            if (group && group.tileIndices.length > 0) {
                                tileIndex = group.tileIndices[0];
                            }
                        }
                    }

                    if (tileIndex !== null) {
                        // Determine the layer based on transparency information of the tile
                        const hasTransparency = tileImages[tileIndex].hasTransparency;
                        isForeground = hasTransparency;

                        const layer = isForeground ? 'foreground' : 'background';
                        const layerTiles = isForeground ? foregroundTiles : backgroundTiles;
                        const setLayerTiles = isForeground ? setForegroundTiles : setBackgroundTiles;

                        placeTile(x, y, tileIndex, layer, layerTiles, setLayerTiles);
                    } else {
                        console.warn('Unable to determine tile to place.');
                    }
                }
            }
        },
        [
            selectedPaletteItem,
            mode,
            backgroundTiles,
            foregroundTiles,
            mapSizeX,
            mapSizeY,
            tileset,
            tileImages,
            placeTile,
            calculateBitmask,
            selectTileVariant
        ]
    );

    // Handle Canvas Mouse Down for Painting or Dragging
    const handleCanvasMouseDown = (e) => {
        if (isPanning.current) return; // Prevent painting while panning

        const mousePos = getMousePos(e);
        const clickedMarker = isMouseOverMarker(mousePos);

        if (e.button === 0) { // Left-click
            if (mode === 'edit') {
                isPainting.current = true;
                paintTile(e);
            } else if (mode === 'marker') {
                if (clickedMarker) {
                    // Start dragging the marker
                    handleMarkerDragStart(clickedMarker, mousePos);
                    // Initialize drag detection
                    isDragging.current = false;
                    mouseDownPos.current = { x: e.clientX, y: e.clientY };
                } else if (markerName && mousePos.x >= 0 && mousePos.x < mapSizeX && mousePos.y >= 0 && mousePos.y < mapSizeY) {
                    const newMarker = {
                        _id: ObjectId().toString(),
                        x: mousePos.x,
                        y: mousePos.y,
                        name: markerName,
                        location: selectedLocation ? selectedLocation.value : null, // Newly added
                    };
                    const updatedMarkers = [...markers, newMarker];
                    setMarkers(updatedMarkers);
                    onMarkersUpdate(updatedMarkers);
                    setMarkerName('');
                    setSelectedLocation(null); // Optionally reset the selected location
                }
            } else if (mode === 'connect') {
                // Existing connection logic...
            }
        } else if (e.button === 2) { // Right-click
            e.preventDefault(); // Prevent context menu
            if (mode === 'connect' && selectedMarkersForConnection.length === 1) {
                setSelectedMarkersForConnection([]);
            }
        }
    };




    // Handle Canvas Mouse Move for Painting or Dragging
    const handleCanvasMouseMove = useCallback(
        (e) => {
            if (isPainting.current) {
                paintTile(e);
            } else if (draggingMarkerId) {
                // Calculate movement distance
                const deltaX = e.clientX - mouseDownPos.current.x;
                const deltaY = e.clientY - mouseDownPos.current.y;
                const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                if (distance > dragThreshold) {
                    isDragging.current = true;
                }

                handleMarkerDrag(e);
            } else {
                const mousePos = getMousePos(e);
                setMousePosition({ x: mousePos.pixelX, y: mousePos.pixelY }); // Update mouse position
                let cursorStyle = 'default';

                if (mode === 'marker') {
                    const overMarker = isMouseOverMarker(mousePos);
                    if (overMarker) {
                        cursorStyle = 'pointer';
                    }
                } else if (mode === 'connect') {
                    const overConnection = isMouseOverConnection(mousePos);
                    const overMarker = isMouseOverMarker(mousePos);
                    if (overConnection || overMarker) {
                        cursorStyle = 'pointer';
                    }
                }
                if (canvasRef.current) {
                    canvasRef.current.style.cursor = cursorStyle;
                }
            }
        },
        [mode, paintTile, isMouseOverMarker, isMouseOverConnection, draggingMarkerId, handleMarkerDrag]
    );


    // Handle Canvas Mouse Up to Stop Painting or Dragging
    const handleCanvasMouseUp = (e) => {
        if (isPainting.current) {
            isPainting.current = false;
        }
        if (draggingMarkerId) {
            handleMarkerDragEnd();
        }

        // Only handle click if not dragging
        if (!isDragging.current) {
            handleCanvasClick(e);
        }

        // Reset dragging flag
        isDragging.current = false;
    };


    // Handle Clicks on the Canvas
// Inside your MapGrid component

// Handle Clicks on the Canvas
    const handleCanvasClick = (e) => {
        const { x, y, pixelX, pixelY } = getMousePos(e);

        if (mode === 'marker') {
            const clickedMarker = markers.find((marker) => marker.x === x && marker.y === y);
            if (clickedMarker) {
                // Open modal to edit/remove marker
                openMarkerModal({
                    ...clickedMarker,
                    location: clickedMarker.location, // Assuming _id is the value
                    map: clickedMarker.map ? clickedMarker.map : '', // If map exists
                });
            }
        } else if (mode === 'connect') {
            const clickedMarker = markers.find((marker) => marker.x === x && marker.y === y);

            if (clickedMarker) {
                if (selectedMarkersForConnection.length === 0) {
                    // Start a new connection
                    setSelectedMarkersForConnection([clickedMarker]);
                } else if (selectedMarkersForConnection.length === 1) {
                    // Finalize the connection
                    const [start] = selectedMarkersForConnection;
                    if (start._id !== clickedMarker._id) {
                        const newConnection = {
                            _id: ObjectId().toString(),
                            from: start._id,
                            to: clickedMarker._id,
                            name: connectionName || `${start.name} to ${clickedMarker.name}`,
                        };
                        const updatedConnections = [...connections, newConnection];
                        setConnections(updatedConnections);
                        onConnectionsUpdate(updatedConnections);
                        setSelectedMarkersForConnection([]);
                        setConnectionName('');
                    }
                }
            } else {
                if (selectedMarkersForConnection.length === 1) {
                    // **New Logic: User clicked on an empty area, cancel the connection**
                    setSelectedMarkersForConnection([]);
                    setConnectionName(''); // Optionally clear the connection name
                } else {
                    // Existing logic: Check if clicked on a connection to open ConnectionModal
                    const clickedConnection = isMouseOverConnection({ pixelX, pixelY });
                    if (clickedConnection) {
                        openConnectionModal(clickedConnection);
                    }
                }
            }
        }
    };


    // Handle Zoom
    const handleWheel = (e) => {
        if (canvasRef.current && canvasRef.current.contains(e.target)) {
            e.preventDefault();
            // e.stopPropagation();
            const zoomAmount = e.deltaY > 0 ? 0.9 : 1.1;
            let newScale = Math.min(Math.max(scale * zoomAmount, 0.8), 4); // Clamp between 0.8x and 4x

            const rect = canvasRef.current.getBoundingClientRect();
            const mouseX = (e.clientX - rect.left - offset.x) / scale;
            const mouseY = (e.clientY - rect.top - offset.y) / scale;

            const newOffset = {
                x: e.clientX - rect.left - mouseX * newScale,
                y: e.clientY - rect.top - mouseY * newScale,
            };

            setScale(newScale);
            setOffset(newOffset);
        }
    };

    // Memoize the palette items to avoid unnecessary recalculations
    const paletteItems = useMemo(() => {
        const groups = tileset.tileGroups.map((group) => ({
            id: group._id,
            type: 'group',
            name: group.name,
            tileIndices: group.tileIndices,
            probabilities: group.probabilities || Array(group.tileIndices.length).fill(1),
        }));

        const rules = tileset.edgeRules.map((rule) => ({
            id: rule._id,
            type: 'rule',
            name: rule.name,
            grid: rule.grid, // Array of 9 strings representing the grid
            enforce: rule.enforce,
            repeatMask: rule.repeatMask || 0,
        }));

        return [...groups, ...rules];
    }, [tileset.tileGroups, tileset.edgeRules]);

    // Handle Tile Selection from Palette
    const handleSelectTile = useCallback((item) => {
        setSelectedPaletteItem(item);
    }, []);

    // Function to detect distance from a point to a line segment
    const pointToSegmentDistance = useCallback((p, p1, p2) => {
        const A = p.x - p1.x;
        const B = p.y - p1.y;
        const C = p2.x - p1.x;
        const D = p2.y - p1.y;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) param = dot / len_sq;

        let xx, yy;

        if (param < 0) {
            xx = p1.x;
            yy = p1.y;
        } else if (param > 1) {
            xx = p2.x;
            yy = p2.y;
        } else {
            xx = p1.x + param * C;
            yy = p1.y + param * D;
        }

        const dx = p.x - xx;
        const dy = p.y - yy;
        return Math.sqrt(dx * dx + dy * dy);
    }, []);

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
        if (selectedOption) {
            setMarkerName(selectedOption.label); // Assumes 'label' is the location name
        } else {
            setMarkerName('');
        }
    };

    if (loading) {
        return <div className="App"><h2>Loading...</h2></div>;
    }

    if (error) {
        return <div className="App"><h2>{error}</h2></div>;
    }

    return viewOnly
        ? (
            <div className="flex flex-grow overflow-hidden">
                <div ref={containerRef} className="flex-1 overflow-hidden relative">
                    <canvas
                        ref={canvasRef}
                        className="w-full h-full"
                        onMouseDown={handleCanvasMouseDown}
                        onMouseMove={(e) => {
                            handleCanvasMouseMove(e);
                            handlePanningMouseMove(e);
                        }}
                        onMouseUp={handleCanvasMouseUp}
                        onMouseLeave={handleMouseUpPanning}
                        onWheel={handleWheel}
                        onContextMenu={(e) => e.preventDefault()}
                        onMouseDownCapture={handleMouseDownPanning}
                    />
                </div>
            </div>
        )
        : (
            <div className="flex flex-grow overflow-hidden">
                <div ref={containerRef} className="flex-1 overflow-hidden relative">
                    <canvas
                        ref={canvasRef}
                        className="w-full h-full"
                        onMouseDown={handleCanvasMouseDown}
                        onMouseMove={(e) => {
                            handleCanvasMouseMove(e);
                            handlePanningMouseMove(e);
                        }}
                        onMouseUp={handleCanvasMouseUp}
                        onMouseLeave={handleMouseUpPanning}
                        onWheel={handleWheel}
                        onContextMenu={(e) => e.preventDefault()}
                        onMouseDownCapture={handleMouseDownPanning}
                    />
                </div>

                {/* Side Panel for Buttons and Palette */}
                <div className="w-1/4 min-w-[250px] bg-gray-100 dark:bg-gray-800 pl-4 overflow-auto">
                    <div className="flex flex-col gap-4">
                        {/* Mode Selection Buttons */}
                        <div className="flex flex-col space-y-2">
                            <Button
                                onClick={() => setMode('edit')}
                                className={`w-full ${
                                    mode === 'edit' ? 'bg-blue-600' : 'bg-blue-500'
                                } text-white rounded`}
                            >
                                Edit Tiles
                            </Button>
                            <Button
                                onClick={() => setMode('marker')}
                                className={`w-full ${
                                    mode === 'marker' ? 'bg-green-600' : 'bg-green-500'
                                } text-white rounded`}
                            >
                                Add/Edit Marker
                            </Button>
                            <Button
                                onClick={() => setMode('connect')}
                                className={`w-full ${
                                    mode === 'connect' ? 'bg-purple-600' : 'bg-purple-500'
                                } text-white rounded`}
                            >
                                Add/Edit Connections
                            </Button>
                        </div>

                        {/* Input Fields Based on Mode */}
                        {mode === 'marker' && (
                            <div className="space-y-2">
                                {/* Marker Name Input */}
                                <div>
                                    <Label htmlFor="markerName" value="Marker Name" />
                                    <TextInput
                                        id="markerName"
                                        type="text"
                                        value={markerName}
                                        onChange={(e) => setMarkerName(e.target.value)}
                                        placeholder="Enter marker name"
                                    />
                                </div>

                                {/* Location Select Dropdown */}
                                <div>
                                    <Label htmlFor="markerLocation" value="Select Location" />
                                    <Select
                                        id="markerLocation"
                                        options={locations}
                                        value={selectedLocation}
                                        onChange={handleLocationChange} // Use the custom handler
                                        placeholder="Select a location..."
                                        styles={SelectTheme()}
                                        isClearable
                                    />
                                </div>
                            </div>
                        )}

                        {mode === 'connect' && (
                            <div>
                                <Label htmlFor="connectionName" value="Connection Name"/>
                                <TextInput
                                    id="connectionName"
                                    type="text"
                                    value={connectionName}
                                    onChange={(e) => setConnectionName(e.target.value)}
                                    placeholder="Enter connection name"
                                />
                            </div>
                        )}

                        {/* Tile Palette */}
                        {!viewOnly && renderEditPalette()}
                    </div>
                </div>

                {/* Marker Modal */}
                {isMarkerModalOpen && selectedMarker && (
                    <MarkerModal
                        isOpen={isMarkerModalOpen}
                        onClose={closeMarkerModal}
                        selectedMarker={selectedMarker}
                        setSelectedMarker={setSelectedMarker}
                        markers={markers}
                        setMarkers={setMarkers}
                        onMarkersUpdate={handleMarkersUpdate}
                        locations={locations} // Transformed data
                        maps={maps} // Transformed data
                    />
                )}

                {/* Connection Modal */}
                {isConnectionModalOpen && selectedConnection && (
                    <ConnectionModal
                        isOpen={isConnectionModalOpen}
                        onClose={closeConnectionModal}
                        selectedConnection={selectedConnection}
                        setSelectedConnection={setSelectedConnection}
                        connections={connections}
                        setConnections={setConnections}
                        onConnectionsUpdate={onConnectionsUpdate}
                    />
                )}
            </div>
        )

};

MapGrid.propTypes = {
    story: PropTypes.object.isRequired,
    tileset: PropTypes.shape({
        name: PropTypes.string.isRequired,
        imageSrc: PropTypes.string.isRequired,
        tileSize: PropTypes.number.isRequired,
        tileGroups: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                tileIndices: PropTypes.arrayOf(PropTypes.number),
                inPalette: PropTypes.bool,
                probabilities: PropTypes.arrayOf(PropTypes.number),
            })
        ),
        edgeRules: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                grid: PropTypes.arrayOf(PropTypes.string),
                enforce: PropTypes.bool,
                repeatMask: PropTypes.number,
            })
        ),
        world: PropTypes.string,
    }).isRequired,
    backgroundTiles: PropTypes.arrayOf(PropTypes.number).isRequired,
    foregroundTiles: PropTypes.arrayOf(PropTypes.number).isRequired,
    setBackgroundTiles: PropTypes.func, // Made optional
    setForegroundTiles: PropTypes.func, // Made optional
    markers: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            location: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    name: PropTypes.string, // Adjust based on Location schema
                    // ... other Location fields
                }),
            ]),
            map: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    name: PropTypes.string, // Adjust based on Map schema if populated
                    // ... other Map fields
                }),
            ]),
            mapLocation: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    name: PropTypes.string, // Adjust based on Location schema
                    // ... other Location fields
                }),
            ]),
        })
    ).isRequired,
    connections: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            from: PropTypes.string.isRequired, // Marker _id
            to: PropTypes.string.isRequired, // Marker _id
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    viewOnly: PropTypes.bool.isRequired,
    onMapUpdate: PropTypes.func, // Made optional
    onMarkersUpdate: PropTypes.func, // Made optional
    onConnectionsUpdate: PropTypes.func, // Made optional
    mapSizeX: PropTypes.number.isRequired,
    mapSizeY: PropTypes.number.isRequired,
};

export default MapGrid;
