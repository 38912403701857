import React from "react";
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {ItemTypes} from "./ItemTypes";


// Draggable Tile Component
export const DraggableTile = React.memo(({ tileIndex, imgSrc, isSelected, toggleSelect }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.TILE,
        item: { type: ItemTypes.TILE, tileIndices: isSelected ? Array.from(toggleSelect.selectedTiles) : [tileIndex], imgSrc },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [tileIndex, imgSrc, isSelected, toggleSelect.selectedTiles]);

    return (
        <div
            ref={drag}
            className={`relative cursor-pointer rounded border border-gray-300 ${isSelected ? 'border-blue-500' : ''}`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            onClick={(e) => {
                e.stopPropagation();
                toggleSelect(tileIndex);
            }}
        >
            <img
                src={imgSrc}
                alt={`Tile ${tileIndex}`}
                className="w-12 h-12 object-cover rounded pl-1 pt-1"
            />
            <span className="text-xs text-center block">{tileIndex}</span>
            {isSelected && (
                <div className="absolute top-0 left-0 w-full h-full bg-blue-200 bg-opacity-50 rounded"></div>
            )}
        </div>
    );
});

DraggableTile.propTypes = {
    tileIndex: PropTypes.number.isRequired,
    imgSrc: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelect: PropTypes.func.isRequired, // Pass an object containing toggleSelect and selectedTiles
};