import React, {useCallback, useEffect, useRef, useState} from 'react';
import SceneLoader from '../../components/adventure/SceneLoader';
import {useSession} from "../../context/SessionContext";
import CharCard from "../../components/adventure/CharCard";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {IoArrowBackCircle, IoBookmarksSharp, IoBookSharp, IoHomeSharp} from "react-icons/io5";
import { TiArrowBack } from "react-icons/ti";

const AdventurePage = () => {
    const { session, setSession } = useSession();
    const { id } = useParams();
    const [adventure, setAdventure] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [groupCharacters, setGroupCharacters] = useState([]);


    const isCreatingInitialScene = useRef(false); // Ref to prevent multiple initial scene creations

    // Fetch adventure and characters when the component mounts
    useEffect(() => {
        const fetchAdventureAndCharacters = async () => {
            setLoading(true);
            try {
                // Fetch adventure
                const adventureResponse = await axios.get(`${process.env.REACT_APP_API_URL}/adventure/${id}`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                const adventureData = adventureResponse.data
                if (!adventureData.hasStarted)
                {

                    // Fetch characters
                    const charactersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/character/list`, {
                        params: { storyId: adventureData.story._id, limit: 100 },
                        headers: { Authorization: `Bearer ${session.token}`}
                    });
                    const charData = charactersResponse.data.characters;
                    ///setStoryCharacters(charData);
                    // Filter characters based on affiliation
                    //const filteredCharData = charData.filter(char => char.affiliation >= 3);
                    const filteredCharData = charData; // just let them play any char for now
                    setGroupCharacters(filteredCharData);

                    await createInitialScene(adventureData, filteredCharData);
                }

                setAdventure(adventureData);
                // setSession(prevSession => ({
                //     ...prevSession,
                //     lastAdventure: adventureData,
                // }));

            } catch (err) {
                console.error('Failed to fetch adventure or characters:', err);
                setError('Failed to load adventure or characters. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (session?.token) {
            fetchAdventureAndCharacters();
        }
   }, [id, session.token]);

    // Create the initial scene if none exists
    const createInitialScene = async (adventureData, characters) => {
        if (isCreatingInitialScene.current) return;
        isCreatingInitialScene.current = true;
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/scene`, {
                adventure: adventureData._id,
                narrativeOrder: 1,
                location: adventureData.startingLocation,
                name: 'Initial Scene',
                characters: characters.map(c => c._id),
            });
            await axios.patch(`${process.env.REACT_APP_API_URL}/adventure/${id}/hasStarted`, {hasStarted: true});
        } catch (err) {
            console.error('Failed to create initial scene:', err);
            setError('Failed to create initial scene. Please try again later.');
        }
        isCreatingInitialScene.current = false;
    };

    // Handle character selection
    async function handleCharacterSelection(character)
    {
        // console.log("adventure.character", adventure.character);
        if (adventure.character) return // if we have already set lets not set again
        try {

            await axios.patch(`${process.env.REACT_APP_API_URL}/adventure/${id}/character`, {character: character._id});

            // this should then start the adventure
            setAdventure(prevAdventure => ({
                ...prevAdventure,    // spread the previous state
                character: character // assign new character
            }));
        } catch (err) {
            console.error('Failed to update character selection:', err);
            setError('Failed to select character. Please try again later.');
        }
    }

    // Render loading state
    if (loading) {
        return <div>Loading adventure...</div>;
    }

    // Render error state
    if (error) {
        return <div className="text-red-500 p-6">{error}</div>;
    }


    // Render character selection if no character is selected
    if (!adventure?.character && groupCharacters) {
        return (
            <div>
                <div className={"flex flex-row justify-center items-center mx-auto"}>
                    <IoBookmarksSharp size={24}/>
                    <div className={"text-2xl font-bold pl-1 pr-6"}>Adventure - "{adventure.name}"</div>
                </div>
                <div className="flex justify-center items-center text-center">
                    <h3 className="my-4 text-2xl font-semibold">Please choose a story character part to play...</h3>
                </div>
                <div
                    className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-1">
                    {groupCharacters.map((char) => (
                        <CharCard character={char} handleSubmit={handleCharacterSelection} key={char._id}/>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full"> {/* Use h-full to let it take up the full height of the parent */}
            {session.user.permissionLevel >= 2 &&
                <Link to={`/story/${adventure.story._id}`}
                      className="flex flex-row  justify-center items-center mx-auto hover:text-blue-600">
                    <IoBookSharp size={24}/>
                    <div className={"text-2xl font-bold pl-1 pr-6"}>Story - "{adventure.story.name}"</div>
                </Link>
            }
            {session.user.permissionLevel < 2 &&
                <div>
                      className="flex flex-row  justify-center items-center mx-auto hover:text-blue-600">
                    <IoBookSharp size={24}/>
                    <div className={"text-2xl font-bold pl-1 pr-6"}>Story - "{adventure.story.name}"</div>
                </div>
            }
            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <IoBookmarksSharp size={16}/>
                <div className={"pl-1 pr-6"}>"{adventure.name}" - {adventure.character.name}</div>
            </div>
            {/*<div className="bg-blue-500 text-white p-2 text-center">*/}
            {/*    <h1 className="text-xl font-bold">Adventure Story</h1>*/}
            {/*    {currentScene && (*/}
            {/*        <div className="mt-1">*/}
            {/*            <p>Current Scene: {currentScene}</p>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
            <div className="flex-grow h-0 overflow-y-auto"> {/* Take up remaining height */}
                <SceneLoader
                    session={session}
                    setSession={setSession}
                    adventureId={adventure._id}
                    startAt="end" // or "start"
                />
            </div>
        </div>
    );
};

export default AdventurePage;