// src/utils/streamingUtils.js
// export const tryParseCompleteMessage = (message) => {
//     try {
//         const jsonObjectPattern = /{[^{}]*}/g;
//         const parsedObjects = [];
//         let match;
//
//         while ((match = jsonObjectPattern.exec(message)) !== null) {
//             try {
//                 const parsedData = JSON.parse(match[0]);
//                 parsedObjects.push(parsedData);
//             } catch (error) {
//                 console.error('Error parsing individual JSON object:', error);
//             }
//         }
//
//         const lastMatchIndex = parsedObjects.length > 0 ? parsedObjects[parsedObjects.length - 1]._endIndex : 0;
//         const remainingData = message.slice(lastMatchIndex);
//
//         return { parsedObjects: parsedObjects.length > 0 ? parsedObjects : null, remainingData: remainingData };
//     } catch (e) {
//         console.error('Error parsing JSON:', e.message);
//         return { parsedObjects: null, remainingData: message };
//     }
// };


export const tryParseCompleteMessage = (message) => {
    try {
        // Regex to match complete JSON objects, including handling commas and whitespace.
        const jsonObjectPattern = /{[^{}]*}/g;
        const parsedObjects = [];
        let match;

        // Extract all complete JSON objects
        while ((match = jsonObjectPattern.exec(message)) !== null) {
            try {
                const parsedData = JSON.parse(match[0]);
                parsedData._endIndex = match.index + match[0].length;  // Track where the object ends
                parsedObjects.push(parsedData);
            } catch (error) {
                console.error('Error parsing individual JSON object:', error);
            }
        }

        // Get remaining data after the last valid JSON object
        const lastMatchIndex = parsedObjects.length > 0 ? parsedObjects[parsedObjects.length - 1]._endIndex : 0;
        const remainingData = message.slice(lastMatchIndex);

        return {
            parsedObjects: parsedObjects.length > 0 ? parsedObjects : null,
            remainingData: remainingData
        };
    } catch (e) {
        console.error('Error parsing JSON:', e.message);
        return { parsedObjects: null, remainingData: message };
    }
};


export const tryExtractFields = (partialMessage, partialData) => {
    try {
        // Attempt to extract partial fields, allowing for incomplete matches
        const speechMatch = partialMessage.match(/"speech"\s*:\s*"([^"]*)?$/);
        const actionMatch = partialMessage.match(/"action"\s*:\s*"([^"]*)?$/);
        const byMatch = partialMessage.match(/"by"\s*:\s*"([^"]*)?$/);

        // If a match is found, update the corresponding field in partialData
        if (speechMatch) {
            // Check if speech is incomplete (missing closing quote)
            partialData.speech = speechMatch[1] || ""; // Append new data
        }

        if (actionMatch) {
            partialData.action = actionMatch[1] || "";   // Append the new data
        }

        if (byMatch) {
            partialData.by = byMatch[1] || "";    // Append more as it comes
        }
    } catch (e) {
        console.error('Error extracting fields', e);
    }
};

