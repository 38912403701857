// src/components/CharacterFormModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Modal,
    Button,
    TextInput,
    Textarea,
    Label,
} from 'flowbite-react';
import { GiQuillInk } from "react-icons/gi";
import { GrEdit } from "react-icons/gr";
import { IoPerson } from "react-icons/io5";
import AddEditImage from "../../common/AddEditImage";
import AddEditProperties from "../../common/AddEditProperties";
import { TextAreaTheme, TextAreaThemeReadOnly } from "../../../themes/TextAreaTheme";
import { ModalTheme } from "../../../themes/ModalTheme";
import { ButtonTheme } from "../../../themes/ButtonTheme";
import { TextInputTheme, TextInputThemeReadOnly } from "../../../themes/TextInputTheme";
import Select from "react-select";
import useSelectThemeStyle from "../../../themes/SelectTheme";
import { PiMagicWandFill } from "react-icons/pi";
import { FaMagic } from "react-icons/fa";
import { GiScrollQuill } from "react-icons/gi";


const CharacterFormModal = ({
                                isOpen,
                                onClose,
                                characterToEdit,
                                story,
                                viewOnly,
                                onSuccess,
                            }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [name, setName] = useState('');
    const [affiliation, setAffiliation] = useState(0);
    const [description, setDescription] = useState('');
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const addEditImageRef = useRef();

    useEffect(() => {
        if (characterToEdit) {
            setName(characterToEdit.name || '');
            setAffiliation(characterToEdit.affiliation || 0);
            setDescription(characterToEdit.description || '');
            setProperties(characterToEdit.properties || []);
        } else {
            setName('');
            setAffiliation(0);
            setDescription('');
            setProperties([]);
        }
        setIsViewOnly(viewOnly);
    }, [characterToEdit, viewOnly]);

    const handleImageSave = async () => {
        try {
            if (addEditImageRef.current) {
                await addEditImageRef.current.commit();
            }
        } catch (err) {
            console.error('Error saving image:', err);
            setError('Failed to save image.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            // Save image first
            await handleImageSave();

            const data = {
                name,
                affiliation,
                description,
                properties,
                story: story._id,
                // Include image data if necessary
            };

            if (characterToEdit) {
                // Update existing character
                await axios.put(`${process.env.REACT_APP_API_URL}/character/${characterToEdit._id}`, data);
            } else {
                // Create new character
                await axios.post(`${process.env.REACT_APP_API_URL}/character`, data);
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving character:', err);
            setError('Failed to save character.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!characterToEdit) return;
        if (!window.confirm('Are you sure you want to delete this character?')) return;

        setLoading(true);
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/character/${characterToEdit._id}`);
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting character:', err);
            setError('Failed to delete character.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsViewOnly(false);
    };

    const handlePropertiesUpdate = (newProperties) => {
        setProperties(newProperties);
    };

    const affiliationOptions = [
        { value: 5, label: "Confidant" },
        { value: 4, label: "Friend" },
        { value: 3, label: "Companion" },
        { value: 2, label: "Acquaintance" },
        { value: 1, label: "Favorable" },
        { value: 0, label: "Neutral" },
        { value: -1, label: "Unfavorable" },
        { value: -2, label: "Distant" },
        { value: -3, label: "Rival" },
        { value: -4, label: "Enemy" },
        { value: -5, label: "Nemesis" }
    ];

    return (
        <Modal
            theme={ModalTheme}
            show={isOpen}
            onClose={onClose}
            size="4xl"
        >
            <Modal.Header>
                {isViewOnly ? (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <IoPerson/>
                        <span className="text-2xl font-bold">
                            {characterToEdit ? 'View Character' : 'Add Character'}
                        </span>
                        {characterToEdit && (
                            <GrEdit
                                className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110"
                                onClick={handleEdit}
                                title="Edit Character"
                                aria-label="Edit Character"
                            />
                        )}
                    </div>
                ) : (
                    <div className="flex justify-between items-center w-full space-x-2">
                        <IoPerson/>
                        <span className="text-2xl font-bold text-gray-900 dark:text-white">
                            {characterToEdit ? 'Edit Character' : 'Add Character'}
                        </span>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="mb-4">
                        <Label color="failure" value={error}/>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Left Column */}
                    <div className="space-y-6">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name" value="Name"/>
                            <TextInput
                                id="name"
                                type="text"
                                placeholder="Enter character name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                            />
                        </div>
                        {/* Affiliation Field */}
                        <div>
                            <Label htmlFor="affiliation" value="Affiliation (to protagonist)"/>
                            <Select
                                id="affiliation"
                                value={affiliationOptions.find(option => option.value === affiliation)}
                                onChange={selectedOption => setAffiliation(selectedOption.value)}
                                required
                                styles={useSelectThemeStyle()}
                                isDisabled={isViewOnly}
                                options={affiliationOptions}
                            />
                        </div>
                        {/* Image Field */}
                        <div>
                            <Label htmlFor="image" value="Image"/>
                            <AddEditImage
                                parentData={characterToEdit}
                                ref={addEditImageRef}
                                placeholder={4}
                                disabled={isViewOnly}
                            />
                        </div>
                        {/* Properties Field */}
                        <div>
                            <Label htmlFor="properties" value="Properties"/>
                            <AddEditProperties
                                properties={properties}
                                onPropertyUpdate={handlePropertiesUpdate}
                                disabled={isViewOnly}
                            />
                        </div>
                    </div>
                    {/* Right Column */}
                    <div className="space-y-4">
                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description" value="Description"/>
                            <Textarea
                                id="description"
                                placeholder="Enter character description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                rows={8}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Additional Fields (if any) */}
                        {/* You can add more fields here as needed */}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {!isViewOnly ? (
                    <div className="flex justify-between w-full">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                            theme={ButtonTheme}
                            color="blue"
                        >
                            {loading ? 'Saving...' : characterToEdit ? 'Save' : 'Add'}
                            {!characterToEdit &&
                                <><GiQuillInk size={16} className="ml-1 translate-x-1"/>1</>
                            }
                        </Button>
                        {/*{!characterToEdit && (*/}
                        {/*    <Button*/}
                        {/*        color="green"*/}
                        {/*        onClick={handleDelete}*/}
                        {/*        disabled={loading}*/}
                        {/*        theme={ButtonTheme}*/}
                        {/*        className="mr-4"*/}
                        {/*    >*/}
                        {/*        Generate*/}
                        {/*        <><GiQuillInk size={16} className="ml-1 translate-x-1"/>1</>*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                        {characterToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={() => {
                                if (characterToEdit === null) onClose();
                                else setIsViewOnly(true);
                            }}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="flex justify-between w-full">
                        {characterToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                                className="mr-4"
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Close
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    );
}

CharacterFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    characterToEdit: PropTypes.object,
    story: PropTypes.object.isRequired,
    viewOnly: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
};

CharacterFormModal.defaultProps = {
    characterToEdit: null,
    viewOnly: false,
};

export default CharacterFormModal;
