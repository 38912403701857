
import React, { useContext } from 'react';
import DarkModeContext from '../../context/DarkModeContext';
import {BsMoonStars, BsSun} from "react-icons/bs";

const DarkModeToggle = () => {
    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

    return (
        <a onClick={toggleDarkMode} className="cursor-pointer mt-1.5 mr-4">
            <div className="flex items-center space-x-1">
                {darkMode ? <BsSun/> : <BsMoonStars/>}
                <span>{darkMode ? 'Light' : 'Dark'}</span>
            </div>
        </a>
    );
};

export default DarkModeToggle;
