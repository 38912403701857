// src/common/AddEditImage.jsx

import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Spinner, Textarea, Modal, Button, Tooltip } from "flowbite-react";
import { FaTrash, FaImage, FaUpload } from "react-icons/fa";
import { GiQuillInk } from "react-icons/gi";
import axios from "axios";
import { ModalTheme } from "../../themes/ModalTheme";

const AddEditImage = forwardRef(({
                                     parentData,
                                     imageData,
                                     className = "", // "w-96 h-96",
                                     apiUrl = process.env.REACT_APP_API_URL,
                                     placeholder = 0,
                                     disabled,
                                     draftImageSet
                                 }, ref) => {
    const [committedImage, setCommittedImage] = useState(imageData || null);
    const [draftImage, setDraftImage] = useState(imageData || null);
    const [isGeneratingImage, setIsGeneratingImage] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [error, setError] = useState(null);

    // Fetch committed image if not provided
    useEffect(() => {
        const fetchImageData = async () => {
            try {
                if (!committedImage && parentData && parentData._id) {
                    const response = await axios.get(`${apiUrl}/image/attached/info/${parentData._id}`);
                    if (response.data.length > 0) {
                        setCommittedImage(response.data[0]);
                        setDraftImage(response.data[0]);
                    }
                }
            } catch (err) {
                console.error('Error fetching image data:', err);
                setError('Failed to load image.');
            }
        };
        fetchImageData();
    }, [parentData, apiUrl, committedImage]);

    // Expose commit and cancel functions to parent
    useImperativeHandle(ref, () => ({
        async commit(characterId) {
            if (!characterId) {
                setError('Character ID is required to save the image.');
                throw new Error('Character ID is missing.');
            }

            if (committedImage && (!draftImage || draftImage._id !== committedImage._id)) {
                try {
                    // Detach the image
                    await axios.put(`${apiUrl}/image/detach/${characterId}/${committedImage._id}`);
                    setCommittedImage(null);
                } catch (err) {
                    console.error('Error releasing image:', err);
                    setError('Failed to release image.');
                    throw err; // Re-throw to inform parent
                }
            }

            if (draftImage && (!committedImage || draftImage._id !== committedImage._id)) {
                try {
                    // Attach the new image
                    await axios.put(`${apiUrl}/image/attach/${characterId}/${draftImage._id}`);
                    setCommittedImage(draftImage);
                } catch (err) {
                    console.error('Error attaching image:', err);
                    setError('Failed to attach image.');
                    throw err; // Re-throw to inform parent
                }
            }
            // Reset pending actions
            // setPendingDelete(false);
        },
        cancel() {
            setDraftImage(committedImage);
            if (draftImageSet) draftImageSet(committedImage);
            // setPendingDelete(false);
            setError(null);
        }
    }));

    // Handle generating a new image via API
    const handleGenerateNewImage = useCallback(async () => {
        if (!prompt.trim()) {
            setError('Prompt cannot be empty.');
            return;
        }
        try {
            setIsGeneratingImage(true);
            setShowPromptModal(false);
            const response = await axios.post(`${apiUrl}/image/generate`, { prompt });
            setDraftImage(response.data);
            if (draftImageSet) draftImageSet(response.data);
        } catch (err) {
            console.error('Error generating image:', err);
            if (err.response) {
                setError(`Failed to generate image: ${err.response.data.message || err.response.statusText}`);
            } else if (err.request) {
                setError('Failed to generate image: No response from server.');
            } else {
                setError(`Failed to generate image: ${err.message}`);
            }
        } finally {
            setIsGeneratingImage(false);
        }
    }, [prompt, apiUrl, draftImageSet]);

    // Handle deleting the current image (detach)
    const handleDeleteImage = useCallback(() => {
        if (!draftImage) return;
        // setPendingDelete(true);
        setDraftImage(null);
        if (draftImageSet) draftImageSet(null);
    }, [draftImage, draftImageSet]);

    // Handle uploading a new image
    const handleUploadImage = useCallback(async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Basic client-side validation
        if (!file.type.startsWith('image/')) {
            setError('Only image files are allowed.');
            return;
        }

        if (file.size > 5 * 1024 * 1024) { // 5MB limit
            setError('Image size should be less than 5MB.');
            return;
        }

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('image', file);
            const response = await axios.post(`${apiUrl}/image/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Image uploaded successfully:', response.data);
            setDraftImage(response.data);
            if (draftImageSet) draftImageSet(response.data);
        } catch (err) {
            console.error('Error uploading image:', err);
            if (err.response) {
                setError(`Failed to upload image: ${err.response.data.message || err.response.statusText}`);
            } else if (err.request) {
                setError('Failed to upload image: No response from server.');
            } else {
                setError(`Failed to upload image: ${err.message}`);
            }
        } finally {
            setIsUploading(false);
        }
    }, [apiUrl, draftImageSet]);

    function getDefaultImageUrl(type) {
        const defaultImages = {
            0: 'https://storage.googleapis.com/myadventures-images/DefaultPlaceholder.webp',
            1: 'https://storage.googleapis.com/myadventures-images/DefaultWorldCover.webp',
            2: 'https://storage.googleapis.com/myadventures-images/DefaultStoryCover.webp',
            3: 'https://storage.googleapis.com/myadventures-images/DefaultAdventureCover.webp',
            4: 'https://storage.googleapis.com/myadventures-images/DefaultCharacterPortrait.webp',
            5: 'https://storage.googleapis.com/myadventures-images/DefaultLoreImage.webp',
            6: 'https://storage.googleapis.com/myadventures-images/DefaultLocationImage.webp',
            7: 'https://storage.googleapis.com/myadventures-images/DefaultItemImage.webp'
            // ... other mappings
        };
        return defaultImages[type] || defaultImages[0]; // Default to type 0 if not found
    }

    // Generate default prompt based on parent data
    const getDefaultPrompt = useCallback(() => {
        if (draftImage && draftImage.prompt) return draftImage.prompt;
        const {
            name = '',
            description = '',
            setting = '',
            premise = '',
            appearance = ''
        } = parentData || {};
        const imageType = appearance ? 'a single realistic head and shoulders character portrait' : 'an image';
        return `Generate ${imageType} for; ${setting || premise || appearance || description || name || 'Something random'}`;
    }, [draftImage, parentData]);

    return (
        <div className={`relative w-full ${className}`}>
            {/* Image Display */}
            <div
                className={`relative ${!disabled ? 'cursor-pointer' : ''} rounded-lg overflow-hidden ${className}`}
                onClick={() => !disabled && setShowActionMenu(!showActionMenu)}
            >
                {isGeneratingImage || isUploading ? (
                    <img
                        src={'https://media3.giphy.com/media/Tz30dcgKE3GCTYpxol/200w.gif?cid=6c09b952zfty97so9odf55mqja0xf8l0rvmr443efwxmbb0h&ep=v1_gifs_search&rid=200w.gif&ct=g'}
                        alt={'Wait for Ai Generation...'}
                        className="object-cover w-full"
                    />
                ) : (
                    <img
                        src={draftImage ? `${apiUrl}/image/${draftImage._id}?d=${placeholder}` : getDefaultImageUrl(placeholder)}
                        alt={draftImage ? draftImage.prompt : 'Default Image'}
                        className="object-cover w-full"
                    />
                )}
            </div>
            {/* Action Menu */}
            {showActionMenu && !isGeneratingImage && !isUploading && (
                <div className="absolute top-2 left-2 flex space-x-2 bg-white bg-opacity-90 p-2 rounded shadow-md z-10">
                    {/* Delete Image */}
                    {draftImage && (
                        <div title="Delete Image">
                            <FaTrash
                                className="text-red-500 cursor-pointer hover:text-red-700"
                                size={20}
                                onClick={handleDeleteImage}
                            />
                        </div>
                    )}
                    {/* Generate Image */}
                    <div title="Generate New Image">
                        <FaImage
                            className="text-blue-500 cursor-pointer hover:text-blue-700"
                            size={20}
                            onClick={() => {
                                setPrompt(getDefaultPrompt());
                                setShowPromptModal(true);
                            }}
                        />
                    </div>
                    {/* Upload Image */}
                    <div title="Upload Image">
                        <label htmlFor="upload-image" className="cursor-pointer">
                            <FaUpload className="text-green-500 hover:text-green-700" size={20} />
                        </label>
                        <input
                            type="file"
                            id="upload-image"
                            accept="image/*"
                            className="hidden"
                            onChange={handleUploadImage}
                        />
                    </div>
                </div>
            )}

            {/* Prompt Modal */}
            <Modal theme={ModalTheme} show={showPromptModal} size="lg" popup onClose={() => setShowPromptModal(false)}>
                <Modal.Header>Generate New Image</Modal.Header>
                <Modal.Body>
                    <div className="space-y-4">
                        <Textarea
                            id="imagePrompt"
                            placeholder="Enter a prompt for generating the image"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            rows={4}
                        />
                        <div className="flex justify-end space-x-2">
                            <Button color="gray" onClick={() => setShowPromptModal(false)}>
                                Cancel
                            </Button>
                            <Button onClick={handleGenerateNewImage} disabled={isGeneratingImage || isUploading}>
                                {isGeneratingImage ? 'Generating...' : 'Generate'} <GiQuillInk size={16} className="ml-1 translate-x-1" />
                            </Button>
                        </div>
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                    </div>
                </Modal.Body>
            </Modal>

            {/* Error Notification */}
            {error && (
                <div
                    className="absolute bottom-2 left-2 bg-red-100 text-red-700 px-3 py-1 rounded shadow flex items-center">
                    <span>{error}</span>
                    <button
                        className="ml-2 text-lg leading-none focus:outline-none"
                        onClick={() => setError(null)}
                    >
                        &times;
                    </button>
                </div>
            )}
        </div>
    )
});

export default AddEditImage;
