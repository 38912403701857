// src/components/adventure/AudioPlayer.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PiUserSoundFill } from "react-icons/pi";
import { Spinner } from "flowbite-react";

const AudioPlayer = ({
                         session,
                         currentStreamingSceneId,
                         lastSceneId,
                         shouldReadToMe,
                         setShouldReadToMe,
                         autoRead,
                         readToMeQuality
                     }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audioError, setAudioError] = useState(null);
    const audioRef = useRef(null);

    // Queue to manage audio playback
    const [audioQueue, setAudioQueue] = useState([]);
    const isProcessingQueue = useRef(false);

    // Function to process the next audio in the queue
    const processQueue = useCallback(async () => {
        if (isProcessingQueue.current || audioQueue.length === 0) return;

        isProcessingQueue.current = true;
        const nextAudio = audioQueue[0];

        try {
            setIsLoadingAudio(true);
            setAudioError(null);

            let url = `${process.env.REACT_APP_API_URL}/audio/scene/${nextAudio.sceneId}/read`;
            let params = new URLSearchParams();
            params.append('service', readToMeQuality);
            if (nextAudio.override) {
                params.append('overrideReadTo', '-1');
            }
            url += `?${params.toString()}`;

            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${session.token}` },
                responseType: 'blob',
            });

            if (response.status !== 200) {
                setAudioError('Failed to fetch audio.');
                removeFromQueue();
                return;
            }

            const audioBlob = response.data;
            const audioUrl = URL.createObjectURL(audioBlob);

            if (audioRef.current) {
                audioRef.current.src = audioUrl;
                await audioRef.current.play();
                setIsPlaying(true);

                // Cleanup previous event listeners before assigning new ones
                audioRef.current.onended = null;
                audioRef.current.onerror = null;

                audioRef.current.onended = () => {
                    setIsPlaying(false);
                    URL.revokeObjectURL(audioUrl);
                    removeFromQueue();
                };

                audioRef.current.onerror = (e) => {
                    console.error('Error during audio playback:', e);
                    setAudioError('Failed to play audio.');
                    setIsPlaying(false);
                    URL.revokeObjectURL(audioUrl);
                    removeFromQueue();
                };
            }
        } catch (error) {
            console.error('Error fetching audio:', error);
            setAudioError('Failed to fetch audio.');
            removeFromQueue();
        } finally {
            setIsLoadingAudio(false);
            isProcessingQueue.current = false;
        }
    }, [audioQueue, readToMeQuality, session.token]);

    // Function to remove the first item from the queue and process the next
    const removeFromQueue = useCallback(() => {
        setAudioQueue(prevQueue => prevQueue.slice(1));
    }, []);

    // Function to stop audio playback and cleanup
    const stopAudio = useCallback(() => {
        if (audioRef.current) {
            // Remove event listeners to prevent repeated firing
            audioRef.current.onended = null;
            audioRef.current.onerror = null;

            // Stop playback and reset audio element
            audioRef.current.pause();
            audioRef.current.currentTime = 0;

            // Only revoke the current object URL if set
            if (audioRef.current.src) {
                URL.revokeObjectURL(audioRef.current.src);
                audioRef.current.removeAttribute('src'); // Clear the source properly
            }

            audioRef.current.load(); // Reset the audio element
        }
        setIsPlaying(false);
        setAudioError(null);
        setAudioQueue([]); // Clear the playback queue
    }, []);

    // Effect to process the queue whenever it changes
    useEffect(() => {
        if (!isPlaying && audioQueue.length > 0) {
            processQueue();
        }
    }, [audioQueue, isPlaying, processQueue]);

    // Function to enqueue audio playback
    const enqueueAudio = useCallback((sceneId, override = false) => {
        setAudioQueue(prevQueue => {
            if (override) {
                // If override, clear the queue and prepend the new audio
                if (audioRef.current && isPlaying) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                    audioRef.current.load(); // Reset the audio element
                }
                return [{ sceneId, override }];
            } else {
                // Otherwise, append to the queue
                return [...prevQueue, { sceneId, override }];
            }
        });
    }, [isPlaying]);

    // Function to handle ReadToMe (user-initiated) with toggle functionality
    const handleUserReadToMe = useCallback(() => {
        if (isPlaying && audioRef.current) {
            // If audio is playing, stop it
            stopAudio();
        } else {
            // If audio is not playing, enqueue a new playback request
            const sceneIdToPlay = lastSceneId;
            if (!sceneIdToPlay) {
                console.warn('No sceneId available for Read To Me.');
                return;
            }
            enqueueAudio(sceneIdToPlay, true);
        }
    }, [isPlaying, audioRef, lastSceneId, enqueueAudio]);

    // Function to handle ReadToMe from streaming completion
    const handleStreamReadToMe = useCallback((sceneId) => {
        if (!sceneId) return;
        if (autoRead) { // Only enqueue if autoRead is on
            enqueueAudio(sceneId, false);
        }
    }, [enqueueAudio, autoRead]);

    // Effect to watch for changes in shouldReadToMe and enqueue accordingly
    useEffect(() => {
        if (shouldReadToMe) {
            handleStreamReadToMe(shouldReadToMe);
            setShouldReadToMe(null); // Reset after handling
        }
    }, [shouldReadToMe, handleStreamReadToMe, setShouldReadToMe]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.onended = null;
                audioRef.current.onerror = null;
                audioRef.current.src = '';
                audioRef.current.load();
            }
            audioQueue.forEach(audio => {
                if (audioRef.current && audioRef.current.src) {
                    URL.revokeObjectURL(audioRef.current.src);
                }
            });
        };
    }, [audioQueue]);

    return (
        <div className="relative">
            {/* Read To Me Button with Spinner */}
            <div className="mt-2 mr-2">
                {isLoadingAudio && !isPlaying ? (
                    <Spinner size="md" aria-label="Loading" />
                ) : (
                    <PiUserSoundFill
                        size={28}
                        onClick={handleUserReadToMe}
                        className={`rounded cursor-pointer transition-transform duration-200 ${
                            isPlaying
                                ? 'dark:text-yellow-500 text-blue-500 hover:text-red-600 dark:hover:text-red-600 animate-pulse hover:scale-110'
                                : 'hover:text-green-600 hover:scale-110'
                        }`}
                    />
                )}
            </div>

            {/* Display audio errors if any */}
            {audioError && (
                <div className="text-red-500 p-2">
                    {audioError}
                </div>
            )}

            {/* Hidden Audio Element */}
            <audio ref={audioRef} controls style={{ display: 'none' }} />
        </div>
    );
};

export default React.memo(AudioPlayer);
