import React, { useEffect, useState, useCallback } from "react";
import { Button, Modal, TextInput, Textarea, Label, Select } from "flowbite-react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {ModalTheme} from "../../../themes/ModalTheme";
import {GiQuillInk} from "react-icons/gi";


function AddEditAgent({ agentData, onSubmit, roleData }) {
    const { id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [listOfRoles, setListOfRoles] = useState(roleData || []);
    const [agent, setAgent] = useState(agentData || {
        name: '',
        description: '',
        role: 'Actor',
        world: id,
        prompt: ''
    });
    const [isNew, setIsNew] = useState(!agentData);
    const [showInfoPanel, setShowInfoPanel] = useState(false);

    // Update listOfRoles only if roleData changes and is different from the current state
    useEffect(() => {
        if (roleData && roleData.length > 0 && JSON.stringify(roleData) !== JSON.stringify(listOfRoles)) {
            setListOfRoles(roleData);
        }
    }, [roleData, listOfRoles]);

    const togglePanel = useCallback(() => {
        setShowInfoPanel(prev => !prev);
    }, []);

    const onCloseModal = useCallback(() => {
        setOpenModal(false);
        if (isNew) {
            setAgent({
                name: '',
                description: '',
                role: 'Other',
                world: id,
                prompt: ''
            });
        }
    }, [isNew, id]);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        try {
            const updatedAgent = isNew
                ? await axios.post(`${process.env.REACT_APP_API_URL}/agent`, agent)
                : await axios.put(`${process.env.REACT_APP_API_URL}/agent/${agent._id}`, agent);
            setAgent(updatedAgent.data);
            onCloseModal();
            if (onSubmit) onSubmit();
        } catch (error) {
            console.error(error);
        }
    }, [agent, isNew, onSubmit, onCloseModal]);

    return (
        <>
            {isNew ? (
                <Button onClick={() => setOpenModal(true)}>New Agent</Button>
            ) : (
                <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
                   onClick={() => setOpenModal(true)}>
                    Edit
                </a>
            )}
            <Modal theme={ModalTheme} dismissible show={openModal} size="6xl" onClose={onCloseModal} popup>
                <Modal.Header>
                    <span className="text-2xl p-1 ml-2.5 font-bold text-gray-900 dark:text-white">
                        {isNew ? "New Agent" : "Edit Agent"}
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-4">
                        <div className="flex space-x-4">
                            <div className="flex-1">
                                <Label htmlFor="name" value="Name" />
                                <TextInput
                                    id="name"
                                    placeholder="enter a name..."
                                    value={agent.name}
                                    onChange={(e) => setAgent({...agent, name: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="flex-1">
                                <Label htmlFor="version" value="Version" />
                                <TextInput
                                    id="version"
                                    placeholder="enter a version..."
                                    value={agent.version}
                                    onChange={(e) => setAgent({...agent, version: e.target.value})}
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex-1">
                            <Label htmlFor="role" value="Role" />
                            <Select
                                id="role"
                                required
                                value={agent.role}
                                onChange={(e) => setAgent({...agent, role: e.target.value})}
                            >
                                {listOfRoles.map((roleValue, index) => (
                                    <option key={index} value={roleValue}>{roleValue}</option>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full">
                            <Label htmlFor="description" value="Description" />
                            <Textarea
                                theme={TextAreaTheme}
                                id="description"
                                placeholder="enter a description..."
                                rows={2}
                                required
                                value={agent.description}
                                onChange={(e) => setAgent({...agent, description: e.target.value})}
                            />
                        </div>
                        <div>
                            <div style={{ display: 'flex', fontSize: 'small', cursor: 'pointer' }}>
                                <a href="#" onClick={togglePanel} style={{ color: 'blue' }}>Show State Data Syntax</a>
                            </div>
                            {showInfoPanel &&
                                <div style={{ marginTop: '10px', backgroundColor: '#D3D3D3', fontFamily: 'monospace' }}>
                                    {/* Info Panel Content */}
                                </div>
                            }
                        </div>
                        <div className="w-full">
                            <Label htmlFor="prompt" value="Prompt (AI)" />
                            <Textarea
                                theme={TextAreaCodeTheme}
                                id="prompt"
                                placeholder="enter a prompt..."
                                rows={15}
                                required
                                value={agent.prompt}
                                onChange={(e) => setAgent({...agent, prompt: e.target.value})}
                            />
                        </div>
                        <div className="w-full">
                            <Button type="submit" onClick={handleSubmit}>
                                {isNew ? <>
                                    Add Agent
                                    <GiQuillInk size={16} className="ml-1 translate-x-1" />1
                                </> : "Save"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default React.memo(AddEditAgent);

const TextAreaTheme = {
    "base": "block w-full rounded-lg border text-sm disabled:cursor-not-allowed px-3 py-2 disabled:opacity-50",

    "colors": {
        "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
    },

    "withShadow": {
        "on": "shadow-sm dark:shadow-sm-light",
        "off": ""
    }
}

const TextAreaCodeTheme = {
    "base": "block w-full rounded-lg border text-sm disabled:cursor-not-allowed px-3 py-2 disabled:opacity-50 font-roboto",

    "colors": {
        "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
    },

    "withShadow": {
        "on": "shadow-sm dark:shadow-sm-light",
        "off": ""
    }
}

