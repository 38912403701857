// src/components/StoryShelves.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StoryCard from './StoryCard';
import StoryDetailModal from './StoryDetailModal';
import CustomShelf from './CustomShelf';
import ScrollableRow from '../common/ScrollableRow'; // Import the ScrollableRow component
import { useSession } from "../../context/SessionContext"; // Import the SessionContext
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";

const StoryShelves = ({ includePublic = true }) => {
    const [storyEntitlements, setStoryEntitlements] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { session } = useSession();
    const navigate = useNavigate();

    const fetchEntitlements = async () => {
        try {
            setLoading(true);
            let response = {};

            if (includePublic) {
                if (session) {
                    const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/story`);
                    const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/story`, {
                        headers: {
                            Authorization: `Bearer ${session.token}`,
                        },
                    });

                    const [response1, response2] = await Promise.all([promise1, promise2]);

                    const combinedData = [...response1.data, ...response2.data];

                    const storiesMap = new Map();

                    combinedData.forEach((item) => {
                        const existingStory = storiesMap.get(item.story._id);

                        if (existingStory) {
                            storiesMap.set(item.story._id, { ...existingStory, ...item });
                        } else {
                            storiesMap.set(item.story._id, item);
                        }
                    });

                    response.data = Array.from(storiesMap.values());
                } else {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/public/story`);
                }
            } else if (session) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/entitlement/story`, {
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });
            }

            setStoryEntitlements(response.data || []);
        } catch (error) {
            console.error('Error fetching story entitlements:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEntitlements();
    }, [session]);

    const handleStoryClick = (story) => {
        setSelectedStory(story);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedStory(null);
    };

    // Define your shelves. This can be dynamic based on available data.
    const shelves = [];

    // Only add "Recommended For You" if a session exists
    if (session) {
        shelves.push(
            {
                title: 'Continue Your Adventures',
                criteria: { sortBy: 'updatedAt', order: 'desc' },
            },
            {
                title: 'Recommended For You',
                criteria: {
                    // Add your recommendation logic here
                    // For example, you might sort based on user preferences
                    // Here, we'll just reuse some criteria
                    sortBy: 'popularity',
                    order: 'desc',
                },
            }
        );
    }
    shelves.push(
        { title: 'Most Popular', criteria: { sortBy: 'popularity', order: 'desc' } },
        { title: 'New Stories', criteria: { sortBy: 'createdAt', order: 'desc' } },
        { title: 'Most Highly Rated', criteria: { sortBy: 'averageRating', order: 'desc' } }
    );

    const applyShelfCriteria = (stories, criteria) => {
        let filtered = [...stories];

        if (criteria.genre) {
            filtered = filtered.filter((story) => story.story.genre === criteria.genre);
        }

        if (criteria.themes) {
            filtered = filtered.filter((story) =>
                story.story.themes.includes(criteria.themes)
            );
        }

        if (criteria.tags) {
            filtered = filtered.filter((story) => story.story.tags.includes(criteria.tags));
        }

        if (criteria.sortBy) {
            filtered.sort((a, b) => {
                if (!a.story || !b.story) {
                    console.error("Missing 'story' property in one of objects!");
                    return 0;
                }
                const fieldA = a.story[criteria.sortBy];
                const fieldB = b.story[criteria.sortBy];
                if (fieldA === undefined || fieldB === undefined) {
                    console.error(`Missing '${criteria.sortBy}' property in 'story' object!`);
                    return 0;
                }
                if (typeof fieldA === 'string') {
                    if (criteria.order === 'desc') {
                        return fieldB.localeCompare(fieldA);
                    } else {
                        return fieldA.localeCompare(fieldB);
                    }
                } else if (typeof fieldA === 'number' || fieldA instanceof Date) {
                    if (criteria.order === 'desc') {
                        return new Date(fieldB) - new Date(fieldA);
                    } else {
                        return new Date(fieldA) - new Date(fieldB);
                    }
                } else {
                    return 0;
                }
            });
        }

        return filtered;
    };

    if (storyEntitlements.length === 0) {
        navigate('/story/new');
    }

    return (
        <div className="container mx-auto p-4 pb-12">
            {/* Render Custom Shelf */}
            <CustomShelf
                session={session}
                expandedView={false} // Set to true if you want to display as grid
                includePublic={includePublic}
                autoSearch={false} // Automatically fetch on mount
            />

            {/* Render standard shelves */}
            {shelves.map((shelf, index) => (
                <div key={index} className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">{shelf.title}</h2>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <ScrollableRow className="py-2">
                            {applyShelfCriteria(storyEntitlements, shelf.criteria).map(
                                (storyEntitlement) => (
                                    <StoryCard
                                        key={storyEntitlement._id}
                                        story={storyEntitlement.story}
                                        onClick={() => handleStoryClick(storyEntitlement)}
                                    />
                                )
                            )}
                        </ScrollableRow>
                    )}
                </div>
            ))}

            {/* Story Modal */}
            {selectedStory && (
                <StoryDetailModal
                    story={selectedStory.story}
                    isOpen={isModalOpen}
                    session={session}
                    grantLevel={selectedStory.grantLevel}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );
};

StoryShelves.propTypes = {
    includePublic: PropTypes.bool, // Include public stories
};

StoryShelves.defaultProps = {
    includePublic: true,
};

export default StoryShelves;
