import { useNavigate } from 'react-router-dom';
import { SlHome, SlGlobeAlt, SlPeople } from "react-icons/sl";
import { IoBookOutline, IoBookmarksOutline } from "react-icons/io5";
import { GrHelpBook } from "react-icons/gr";
import React, {useContext, useEffect, useState} from "react";
import {googleLogout, useGoogleLogin} from "@react-oauth/google";
import { FaRegCreditCard } from "react-icons/fa6";
import { PiSignInFill, PiSignOutFill, PiBank } from "react-icons/pi";
import { GiQuillInk } from "react-icons/gi";
import { FaRegNewspaper } from "react-icons/fa6";
import { MdOutlineLocalGroceryStore } from "react-icons/md";


import {
    Avatar,
    Dropdown,
    DropdownHeader,
    DropdownDivider,
    DropdownItem,
} from "flowbite-react";
import {useSession} from "../../context/SessionContext";
import axios from "axios";
import DarkModeToggle from "../common/DarkModeToggle";
import DarkModeContext from "../../context/DarkModeContext";
import {BsMoonStars, BsSun} from "react-icons/bs";
function UserProfileAvatar({absolute = true}) {
    const navigate = useNavigate();
    const { session, setSession } = useSession();
    //const [inkBalance, setInkBalance] = useState(0);
    const { darkMode, toggleDarkMode } = useContext(DarkModeContext);

    const logOut = () => {
        googleLogout();
        setSession(null);
        navigate("/#"); // Redirect to home page
    };

    const style = absolute ? "absolute top-0 right-4 z-20 p-2" : ""

    if (!session || !session.user) {
        return null;
    } else {
        const inkBalance = session.ink.availableBalance || 0;
        const inkNextExpiry = session.ink.nextExpiry || Date.now();
        return (
             <div className={style}>
                 <div className={"flex "}>
                     <GiQuillInk className={"mt-2 translate-x-0.5"} size={18} title={'Expires: ' + inkNextExpiry.toLocaleString()}/>
                     <div
                         className={"mr-2 mt-1"}
                         title={'Expires: ' + inkNextExpiry.toLocaleString()}>
                         {inkBalance.toLocaleString()}
                     </div>
                     <Dropdown
                         label={<Avatar alt="User settings"
                                        img={`${process.env.REACT_APP_API_URL}/image/${session.user.image}`} rounded/>}
                         arrowIcon={false}
                         theme={dropdownTheme}
                         inline
                     >
                         <DropdownHeader>
                             <span className="block text-sm">{session.user.name}</span>
                             <span className="block truncate text-sm font-medium">{session.user.email}</span>
                         </DropdownHeader>
                         {darkMode ? <DropdownItem icon={BsSun} onClick={toggleDarkMode}>Light Mode</DropdownItem> :
                             <DropdownItem icon={BsMoonStars} onClick={toggleDarkMode}>Dark Mode</DropdownItem>}
                         <DropdownDivider/>
                         <DropdownItem icon={SlHome} href="/home">My Home</DropdownItem>
                         {session.user.permissionLevel >= 3 &&
                             <DropdownItem icon={SlGlobeAlt} href="/worlds">My Worlds</DropdownItem>}
                         {session.user.permissionLevel >= 2 &&
                             <DropdownItem icon={IoBookOutline} href="/stories">My Stories</DropdownItem>}
                         {/*<DropdownItem icon={IoBookmarksOutline} href="/adventures">My Adventures</DropdownItem>*/}
                         <DropdownDivider/>
                         <DropdownItem icon={FaRegNewspaper} href="/news">News</DropdownItem>
                         <DropdownItem icon={GrHelpBook} href="/guide/Overview">Documentation</DropdownItem>
                         <DropdownItem icon={FaRegCreditCard}
                                       onClick={() => navigate("/account")}>Account</DropdownItem>
                         <DropdownItem icon={MdOutlineLocalGroceryStore}
                                       onClick={() => navigate("/pricing/boosters")}>Store</DropdownItem>
                         <DropdownDivider/>
                         <DropdownItem icon={PiSignOutFill} onClick={logOut}>Sign out</DropdownItem>
                     </Dropdown>
                 </div>
             </div>
        );
    }

}


export default UserProfileAvatar;


const dropdownTheme = {
    "arrowIcon": "ml-2 h-4 w-4",
    "content": "py-1 focus:outline-none",
    "floating": {
        "animation": "transition-opacity",
        "arrow": {
            "base": "absolute z-10 h-2 w-2 rotate-45",
            "style": {
                "dark": "bg-gray-900 dark:bg-gray-700",
                "light": "bg-white",
                "auto": "bg-white dark:bg-gray-700"
            },
            "placement": "-4px"
        },
        "base": "z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none",
        "content": "py-1 text-sm text-gray-700 dark:text-gray-200",
        "divider": "my-1 h-px bg-gray-100 dark:bg-gray-600",
        "header": "block px-4 py-2 text-sm text-gray-700 dark:text-gray-200",
        "hidden": "invisible opacity-0",
        "item": {
            "container": "",
            "base": "flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white",
            "icon": "mr-2 h-4 w-4"
        },
        "style": {
            "dark": "bg-gray-900 text-white dark:bg-gray-700",
            "light": "border border-gray-200 bg-white text-gray-900",
            "auto": "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white"
        },
        "target": "w-fit"
    },
    "inlineWrapper": "flex items-center"
}
