// src/components/edit/AddEditLore.js

import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    TextInput,
    Textarea,
    Label,
    Modal,
    Alert,
} from "flowbite-react";
import { useForm, Controller } from 'react-hook-form';
import CreatableSelect from "react-select/creatable";
import AddEditImage from "../../common/AddEditImage";
import AddEditProperties from "../../common/AddEditProperties";
import axios from "axios";
import PropTypes from 'prop-types';
import { GiQuillInk } from "react-icons/gi";
import {ModalTheme} from "../../../themes/ModalTheme";

function AddEditLore({ loreData = null, onSubmit, worldId }) {
    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [error, setError] = useState(null);
    const [properties, setProperties] = useState([]); // Manage properties state

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: loreData ? loreData.name : '',
            description: loreData ? loreData.description : '',
            tags: loreData ? loreData.tags : [],
            imagePrompt: loreData ? loreData.imagePrompt : '',
        },
    });

    // Reset form when modal is opened or loreData changes
    useEffect(() => {
        if (isOpen) {
            reset({
                name: loreData ? loreData.name : '',
                description: loreData ? loreData.description : '',
                tags: loreData ? loreData.tags : [],
                imagePrompt: loreData ? loreData.imagePrompt : '',
            });
            setImage(loreData ? loreData.image : null);
            setError(null);
            // Initialize properties if editing existing lore
            if (loreData && loreData.properties) {
                setProperties(loreData.properties.map(prop => ({
                    ...prop,
                    id: prop._id || prop.name, // Ensure each property has a unique id
                })));
            } else {
                setProperties([]);
            }
        }
    }, [isOpen, loreData, reset]);

    const onCloseModal = () => {
        setIsOpen(false);
        reset();
        setImage(null);
        setError(null);
        setProperties([]); // Clear properties when closing
    };

    const handleImageChange = (image) => {
        setNewImage(image);
    };

    const handlePropertiesUpdate = useCallback((updatedProperties) => {
        setProperties(updatedProperties);
    }, []);

    const handleFormSubmit = useCallback(async (data) => {
        try {
            // Prepare the payload
            const payload = {
                name: data.name.trim(),
                description: data.description.trim(),
                tags: data.tags.map(tag => tag).filter(tag => tag !== ''),
                world: worldId,
            };

            let updatedLore;
            if (loreData) {
                console.log('payload: ',payload);
                // Update existing lore
                updatedLore = (await axios.put(`${process.env.REACT_APP_API_URL}/lore/${loreData._id}`, payload)).data;
            } else {
                // Create new lore
                updatedLore = (await axios.post(`${process.env.REACT_APP_API_URL}/lore`, payload)).data;
            }

            // Handle image attachment if a new image is generated
            if (newImage) {
                await axios.put(`${process.env.REACT_APP_API_URL}/image/release/${updatedLore._id}`);
                await axios.put(`${process.env.REACT_APP_API_URL}/image/attach/${updatedLore._id}/${newImage._id}`);
            }

            if (onSubmit) onSubmit(updatedLore);
            onCloseModal();
        } catch (err) {
            console.error("Error saving lore:", err);
            setError('Failed to save lore. Please try again.');
        }
    }, [image, loreData, onSubmit, worldId, properties, newImage]);

    return (
        <>
            {loreData ? (
                <a
                    href="#"
                    className="font-medium text-cyan-600 hover:underline"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(true);
                    }}
                >
                    Edit
                </a>
            ) : (
                <Button onClick={() => setIsOpen(true)}>New Lore</Button>
            )}

            <Modal theme={ModalTheme} show={isOpen} size="3xl" onClose={onCloseModal} dismissible>
                <Modal.Header>
                    {loreData ? "Edit Lore" : "Add New Lore"}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <TextInput
                                id="name"
                                placeholder="Lore name"
                                {...register('name', { required: 'Name is required' })}
                                className="mt-1 block w-full"
                            />
                            {errors.name && (
                                <span className="text-red-500 text-sm">
                                    {errors.name.message}
                                </span>
                            )}
                        </div>

                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description">Description</Label>
                            <Textarea
                                id="description"
                                placeholder="Describe the lore..."
                                rows={4}
                                {...register('description', { required: 'Description is required' })}
                                className="mt-1 block w-full"
                            />
                            {errors.description && (
                                <span className="text-red-500 text-sm">
                                    {errors.description.message}
                                </span>
                            )}
                        </div>

                        {/* Tags Field */}
                        <div>
                            <Label htmlFor="tags">Tags</Label>
                            <Controller
                                control={control}
                                name="tags"
                                render={({ field }) => (
                                    <CreatableSelect
                                        {...field}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                            field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                        }}
                                        onCreateOption={(inputValue) => {
                                            const newTag = inputValue.trim();
                                            if (newTag === '') return;
                                            const updatedTags = [...(field.value || []), newTag];
                                            field.onChange(updatedTags);
                                        }}
                                        value={field.value ? field.value.map(tag => ({ value: tag, label: tag })) : []}
                                        placeholder="Select or create tags..."
                                        className="mt-1 block w-full"
                                    />
                                )}
                            />
                            {error && (
                                <span className="text-red-500 text-sm">
                                    {error}
                                </span>
                            )}
                        </div>

                        {/* Image Upload Field */}
                        <div>
                            <Label>Image</Label>
                            <AddEditImage
                                parentData={loreData}
                                imageData={image}
                                onImageChange={handleImageChange}
                                placeholder="5"
                            />
                        </div>

                        {/* Error Message */}
                        {error && (
                            <Alert color="failure">
                                <span>
                                    <span className="font-medium">Error!</span> {error}
                                </span>
                            </Alert>
                        )}

                        {/* Submit Button */}
                        <div className="w-full text-right">
                            <Button type="submit">
                                {loreData
                                    ? "Save Changes"
                                    : <>
                                        Add Lore
                                        <GiQuillInk size={16} className="ml-1 translate-x-1" />1
                                    </>
                                }
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

AddEditLore.propTypes = {
    loreData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    worldId: PropTypes.string.isRequired,
};

export default AddEditLore;
