import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'flowbite-react';

function PaymentResultPage() {
    const { type } = useParams(); // Fetch the route parameter
    const navigate = useNavigate();

    const handleGoBack = async () => {
        navigate('/account'); // Redirect to account page
    };

    return (
        <div className="flex justify-center items-start min-h-screen pt-10">
            <div className="text-center">
                {type === 'success' ? (
                    <>
                        <h2 className="text-2xl font-bold">Payment Successful!</h2>
                        <p className="mt-4">Thank you for your purchase. Your ink balance has been updated.</p>
                    </>
                ) : (
                    <>
                        <h2 className="text-2xl font-bold">Payment Unsuccessful!</h2>
                        <p className="mt-4">Sorry, something went wrong trying to take payment!</p>
                    </>
                )}
                <div className="mt-8 flex justify-center">
                    <Button onClick={handleGoBack} color={'gray'}>
                        Return To My Account
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default PaymentResultPage;
