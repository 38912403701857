import {Button, Card, Label, Modal, TextInput, Tooltip} from "flowbite-react";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSession } from "../../context/SessionContext";
import {ModalTheme} from "../../themes/ModalTheme";
import { FaPlay } from "react-icons/fa";

function AdventureCard({ adventure, fetchAdventures }) {
    const [tokens, setTokens] = useState(0);
    const { session } = useSession();

    // State for Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newName, setNewName] = useState(adventure.name);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleAdventureDelete = async (event, adventureId) => {
        event.preventDefault();
        if (!window.confirm("Are you sure you want to delete this adventure? This action cannot be undone.")) {
            return;
        }

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/adventure/${adventureId}`, {
                headers: { 'Authorization': `Bearer ${session.token}` }
            });
            fetchAdventures();
        } catch (error) {
            console.error("Error deleting adventure:", error);
            alert("Failed to delete adventure. Please try again.");
        }
    };

    // const getTotalTokensForAdventure = async () => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/adventure/${adventure._id}/token-count`, {
    //             headers: {
    //                 'Authorization': `Bearer ${session.token}`
    //             }
    //         });
    //         setTokens(response.data.totalTokens);
    //     } catch (error) {
    //         console.error("Error fetching tokens:", error);
    //     }
    // };
    //
    // const formatNumber = (num) => {
    //     return num >= 1000 ? (num / 1000).toFixed(1) + 'k' : num;
    // };
    //
    // useEffect(() => {
    //     if (adventure._id) getTotalTokensForAdventure();
    // }, [adventure]);

    // Handle opening the modal
    const openModal = () => {
        setNewName(adventure.name); // Reset to current name
        setIsModalOpen(true);
    };

    // Handle closing the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Handle saving the new name
    const handleSave = async () => {
        if (!newName.trim()) {
            alert("Adventure name cannot be empty.");
            return;
        }

        setIsUpdating(true);
        try {
            await axios.patch(
                `${process.env.REACT_APP_API_URL}/adventure/${adventure._id}/name`,
                { name: newName },
                {
                    headers: {
                        'Authorization': `Bearer ${session.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setIsModalOpen(false);
            fetchAdventures(); // Refresh the adventures list
        } catch (error) {
            console.error("Error updating adventure name:", error);
            alert("Failed to update adventure name. Please try again.");
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <>
            <Card className="flex flex-col justify-between shadow-lg dark:bg-gray-900 hover:shadow-2xl transition-shadow duration-300">
                <div>
                    <div className="flex items-center justify-between">
                        <h5 className="text-xl font-bold text-gray-800 dark:text-gray-400 flex items-center">
                            {adventure.name}
                            <GrEdit
                                className="ml-2 text-gray-500 cursor-pointer hover:text-gray-700 dark:hover:text-gray-300"
                                size={18}
                                onClick={openModal}
                                title="Edit Adventure Name"
                            />
                        </h5>
                        {/*<Tooltip content="Tokens Used">*/}
                        {/*    <span className="text-sm text-gray-600 dark:text-gray-500">💬 {formatNumber(tokens)}</span>*/}
                        {/*</Tooltip>*/}
                    </div>
                    <p className="text-gray-600 dark:text-gray-500 mt-2">
                        <strong>World:</strong> {adventure.story.world.name}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Story:</strong> {adventure.story.name}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Character:</strong> {adventure.character?.name || 'Unknown'}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Plot Lines:</strong> {adventure.plotProgress?.length || 0}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Ink Used:</strong> {adventure.inkUsed || 0}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Created:</strong> {new Date(adventure.createdAt).toLocaleDateString()}
                    </p>
                    <p className="text-gray-600 dark:text-gray-500">
                        <strong>Last Played:</strong> {new Date(adventure.updatedAt).toLocaleDateString()}
                    </p>
                </div>

                <div className="mt-4 flex justify-between items-center">
                    <Button href={`/adventure/${adventure._id}`} color="success" className="flex items-center"><FaPlay className={"mr-2 translate-y-0.5"} size={16} />
                    Continue
                    </Button>
                    <Button color="failure" size="sm" onClick={(e) => handleAdventureDelete(e, adventure._id)}>
                        <BsTrash />
                    </Button>
                </div>
            </Card>

            {/* Edit Modal */}
            <Modal
                theme={ModalTheme}
                show={isModalOpen}
                size="md"
                popup={true}
                onClose={closeModal}
            >
                <Modal.Header> Change Adventure Name </Modal.Header>
                <Modal.Body>

                    <div className="space-y-4 p-1 pt-4">
                        <div>
                            <Label>Name</Label>
                            <TextInput
                                theme={CustomTextInputTheme}
                                id="adventure-name"
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex justify-end space-x-4">
                            <Button
                                color="gray"
                                onClick={closeModal}
                                disabled={isUpdating}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSave}
                                disabled={isUpdating}
                            >
                                {isUpdating ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdventureCard;


export const CustomTextInputTheme={
    "base": "flex",
    "addon": "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-200 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-400",
    "field": {
        "base": "relative w-full",
        "icon": {
            "base": "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3",
            "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "rightIcon": {
            "base": "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3",
            "svg": "h-5 w-5 text-gray-500 dark:text-gray-400"
        },
        "input": {
            "base": "block w-full border disabled:cursor-not-allowed disabled:opacity-50",
            "sizes": {
                "sm": "p-2 sm:text-xs",
                "md": "p-2.5 text-sm",
                "lg": "p-4 sm:text-base"
            },
            "colors": {
                "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
                "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
                "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
                "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
                "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
            },
            "withRightIcon": {
                "on": "pr-10",
                "off": ""
            },
            "withIcon": {
                "on": "pl-10",
                "off": ""
            },
            "withAddon": {
                "on": "rounded-r-lg",
                "off": "rounded-lg"
            },
            "withShadow": {
                "on": "shadow-sm dark:shadow-sm-light",
                "off": ""
            }
        }
    }
}