// src/hooks/useSceneStreaming.js
import { useRef, useEffect, useCallback } from 'react';
import { tryParseCompleteMessage, tryExtractFields } from '../common/streamingUtils';

const useSceneStreaming = ({ sessionToken, onAppendCompletedParagraphs, onAppendPartialData, onClearPartialData }) => {
    const eventSourceRef = useRef(null);
    console.log('eventSourceRef changed:', eventSourceRef.current);

    const stopStreaming = useCallback(() => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            eventSourceRef.current = null;
            console.log("Streaming connection closed.");
        }
    }, []);

    const startStreaming = useCallback((sid) => {
        if (!sid) {
            console.error('No sceneId provided for streaming.');
            return;
        }

        // Close any existing connection before starting a new one
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
            console.log("Existing streaming connection closed before starting a new one.");
        }

        let accumulatedData = '';
        let accumulatedText = '';
        let partialData = {};

        const url = `${process.env.REACT_APP_API_URL}/page/${sid}/generate-next?access_token=${sessionToken}`;
        console.log(`Setting EventSource : ${url}`);

        eventSourceRef.current = new EventSource(url);

        eventSourceRef.current.addEventListener('para', (event) => {
            console.log("Received 'para' event:", event.data);
            accumulatedData += event.data.replace(/^paragraphs:/, '').replace(/^\[|\]$/g, '');
            const { parsedObjects, remainingData } = tryParseCompleteMessage(accumulatedData);

            if (parsedObjects) {
                onAppendCompletedParagraphs(parsedObjects);
                partialData = {};
                onClearPartialData();
            }

            accumulatedData = remainingData;
            tryExtractFields(accumulatedData, partialData);
            onAppendPartialData(partialData);
        });

        eventSourceRef.current.addEventListener('text', (event) => {
            console.log("Received 'text' event:", event.data);
            accumulatedText += event.data;

            const sentenceEndRegex = /[.!?]\n/;
            const sentences = accumulatedText.split(sentenceEndRegex);
            accumulatedText = sentences.pop();

            sentences.forEach((sentence) => {
                if (sentence) {
                    onAppendCompletedParagraphs([{ text: sentence }]);
                }
            });

            partialData.text = accumulatedText;
            onAppendPartialData(partialData);
        });

        eventSourceRef.current.addEventListener('end', () => {
            console.log("Received 'end' event");
            if (accumulatedText) {
                onAppendCompletedParagraphs([{ text: accumulatedText }]);
            }

            const { parsedObjects } = tryParseCompleteMessage(accumulatedData);
            if (parsedObjects) {
                onAppendCompletedParagraphs(parsedObjects);
            }

            onClearPartialData();
            stopStreaming();
        });

        eventSourceRef.current.onerror = (error) => {
            console.error('Error occurred while streaming data:', error);
            // Optionally, you can implement reconnection logic here
        };

        eventSourceRef.current.onopen = () => {
            console.log("Streaming connection opened.");
        };
    }, [sessionToken, onAppendCompletedParagraphs, onAppendPartialData, onClearPartialData, stopStreaming]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            stopStreaming();
        };
    }, [stopStreaming]);

    return { startStreaming, stopStreaming };
};

export default useSceneStreaming;
