// src/components/MarkerModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Label, TextInput } from 'flowbite-react';
import Select from 'react-select';
import { ModalTheme } from '../../../../themes/ModalTheme';
import SelectTheme from "../../../../themes/SelectTheme";

const MarkerModal = ({
                         isOpen,
                         onClose,
                         selectedMarker,
                         setSelectedMarker,
                         markers,
                         setMarkers,
                         onMarkersUpdate,
                         locations,
                         maps,
                     }) => {

    if (!selectedMarker) {
        return null; // Do not render the modal if selectedMarker is null
    }

    // Helper function to find the selected option based on location
    const getSelectedOption = (options, value) => {
        if (!value) return null;
        if (typeof value === 'string') {
            return options.find(option => option.value === value) || null;
        } else if (typeof value === 'object' && value !== null) {
            return { value: value._id, label: value.name };
        }
        return null;
    };

    const selectedMapOption = getSelectedOption(maps, selectedMarker.map);
    const selectedLocationOption = getSelectedOption(locations, selectedMarker.location);

    const handleMapChange = (selectedOption) => {
        setSelectedMarker(prev => ({
            ...prev,
            map: selectedOption ? selectedOption.value : '',
        }));
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedMarker(prev => ({
            ...prev,
            location: selectedOption ? selectedOption.value : '',
        }));
    };

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose}>
            <Modal.Header>Edit Marker</Modal.Header>
            <Modal.Body>
                <div className="space-y-4 pb-2">
                    {/* Marker Name */}
                    <div>
                        <Label htmlFor="editMarkerName" value="Marker Name" />
                        <TextInput
                            id="editMarkerName"
                            type="text"
                            value={selectedMarker.name}
                            onChange={(e) => {
                                const updatedMarker = { ...selectedMarker, name: e.target.value };
                                setSelectedMarker(updatedMarker);
                            }}
                        />
                    </div>

                    {/* Map Selection */}
                    <div className="form-group">
                        <Label htmlFor="editMap" value="Map" />
                        <Select
                            id="editMap"
                            value={selectedMapOption}
                            onChange={handleMapChange}
                            options={maps}
                            styles={SelectTheme()}
                            placeholder="Select a map..."
                            isClearable
                            aria-label="Select a map"
                        />
                    </div>

                    {/* Location Selection */}
                    <div className="form-group">
                        <Label htmlFor="editLocation" value="Location" />
                        <Select
                            id="editLocation"
                            value={selectedLocationOption}
                            onChange={handleLocationChange}
                            options={locations}
                            styles={SelectTheme()}
                            placeholder="Select a location..."
                            isClearable
                            aria-label="Select a location"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        // Update marker
                        const updatedMarkers = markers.map((marker) =>
                            marker._id === selectedMarker._id ? selectedMarker : marker
                        );
                        setMarkers(updatedMarkers);
                        onMarkersUpdate(updatedMarkers);
                        onClose();
                    }}
                >
                    Save
                </Button>
                <Button
                    color="failure"
                    onClick={() => {
                        // Remove marker
                        const updatedMarkers = markers.filter(
                            (marker) => marker._id !== selectedMarker._id
                        );
                        setMarkers(updatedMarkers);
                        onMarkersUpdate(updatedMarkers);
                        onClose();
                    }}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

MarkerModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedMarker: PropTypes.object,
    setSelectedMarker: PropTypes.func.isRequired,
    markers: PropTypes.array.isRequired,
    setMarkers: PropTypes.func.isRequired,
    onMarkersUpdate: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired,
    maps: PropTypes.array.isRequired,
};

export default MarkerModal;
