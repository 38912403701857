import React, {useState, createContext, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {setupAxiosInterceptors} from "./SetupAxios";
// Create a new context
export const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(() => {
        //const storedSession = localStorage.getItem('session');
        const storedSession = localStorage.getItem('session');
        return storedSession ? JSON.parse(storedSession) : null;
    });

    useEffect(() => {
        if (session) {
            localStorage.setItem('session', JSON.stringify(session));
        } else {
            localStorage.removeItem('session');
        }
        setupAxiosInterceptors(session, setSession); // Pass setSession to the interceptor
    }, [session]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Create a custom hook for using the session state
export const useSession = () => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};