import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSession } from "../../context/SessionContext";
import CharCard from "../../components/adventure/CharCard";
import {Button, Label, TextInput} from "flowbite-react";
import {FaPlay} from "react-icons/fa";

const NewAdventurePage = () => {
    const { id: storyId } = useParams();
    const { session } = useSession();
    const [characters, setCharacters] = useState([]);
    const [adventureName, setAdventureName] = useState('New Adventure');
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [partyMembers, setPartyMembers] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCharacters = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/character/list`, {
                    params: { storyId, limit: 100 },
                    headers: { Authorization: `Bearer ${session.token}` }
                });
                setCharacters(response.data.characters);
            } catch (error) {
                console.error('Failed to fetch characters:', error);
                setError('Failed to load characters. Please try again later.');
            }
        };

        if (session?.token) {
            fetchCharacters();
        }
    }, [storyId, session?.token]);

    const handleCreateAdventure = async () => {
        if (!adventureName || !selectedCharacter) {
            setError('Please enter a name and select a character.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/adventure`, {
                story: storyId,
                name: adventureName,
                character: selectedCharacter._id,
                party: partyMembers.map(member => member._id),
            }, {
                headers: { 'Authorization': `Bearer ${session.token}` }
            });

            // sceneChars is all the partyMembers and the selectedCharacter in one list
            const sceneChars = [...partyMembers, selectedCharacter];

            const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/scene`, {
                adventure: response.data._id,
                narrativeOrder: 1,
                location: response.data.startingLocation,
                name: 'Initial Scene',
                characters: sceneChars.map(member => member._id),
            });
            await axios.patch(`${process.env.REACT_APP_API_URL}/adventure/${response.data._id}/hasStarted`, {hasStarted: true});

            navigate(`/adventure/${response.data._id}`); // Redirect to the newly created adventure
        } catch (error) {
            console.error('Failed to create adventure:', error);
            setError('Failed to create adventure. Please try again.');
        }
    };

    const togglePartyMember = (character) => {
        setPartyMembers((prevParty) => {
            if (prevParty.includes(character)) {
                return prevParty.filter(member => member._id !== character._id);
            } else if (prevParty.length < 3 && character._id !== selectedCharacter?._id && character.affiliation > 2) {
                return [...prevParty, character];
            }
            return prevParty;
        });
    };

    if (storyId) {
        axios.get(`${process.env.REACT_APP_API_URL}/story/${storyId}/status`, {
            headers: {
                'Authorization': `Bearer ${session.token}` // Set header directly
                //'Cache-Control': 'no-cache'
            }
        })
            .then(response => {
                if (response.data.generationProgress) {
                    navigate('/story/' + storyId + '/status');
                }
            })
            .catch(error => {
                console.error('Failed to fetch story status:', error);
            });
    }

    return (
        <div className={'container mx-auto pb-16'}>
            <h1 className={'text-3xl font-bold'}>Create a New Adventure</h1>
            {error && <div className="text-red-500">{error}</div>}
            <div className={'mt-4'}>
                <Label>Adventure Name:</Label>
                <div className={'flex items-center mt-0.5'}>
                    <TextInput type="text" value={adventureName} onChange={(e) => setAdventureName(e.target.value)}  className="mr-4 w-72" />
                    <Button color={'green'} onClick={handleCreateAdventure}><FaPlay className={"mr-2 translate-y-0.5"} size={16} />
                        Create New Adventure
                    </Button>
                </div>
            </div>
            <div className={'mt-6'}>
                <h2 className={'text-2xl mb-2'}>Select Your Character</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {characters.filter(char => char.playable).map(char => (
                        <CharCard
                            key={char._id}
                            character={char}
                            handleSubmit={() => setSelectedCharacter(char)}
                            isSelected={selectedCharacter?._id === char._id}
                        />
                    ))}
                </div>
            </div>
            <div className={'mt-6'}>
                <h2 className={'text-2xl mb-2'}>Select Party Members (0-3)</h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {characters.filter(char => char._id !== selectedCharacter?._id && char.affiliation > 2).map(char => (
                        <CharCard
                            key={char._id}
                            character={char}
                            handleSubmit={() => togglePartyMember(char)}
                            isSelected={partyMembers.some(member => member._id === char._id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewAdventurePage;