import React from 'react';
import {useParams, Link, useNavigate} from 'react-router-dom';
import { Card } from "flowbite-react";
import PriceCard from "../../oldComponents/card/PriceCard";

function PricingPage() {
    const navigate = useNavigate();
    const { type } = useParams(); // Fetch the route parameter
    const priceSetA = [
        { comingSoon: true, planName: 'Basic Subscription', price: 9.99, saving: null, included: ['1,400 Ink', '4 Custom World Slots'], excluded: [], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Subscription-Small.webp', note: "*Does not roll over" },
        { comingSoon: true, planName: 'Standard Subscription', price: 19.99, saving: '7% over a basic plan', included: ['3,000 Ink', '8 Custom World Slots'], excluded: [], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Subscription-Medium.webp', note: "*Does not roll over" },
        { comingSoon: true, planName: 'Plus Subscription', price: 29.99, saving: '14% over a basic plan', included: ['4,800 Ink', '12 Custom World Slots'], excluded: [], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Subscription-Large.webp', note: "*Does not roll over" },
        { comingSoon: true, planName: 'Premium Subscription', price: 39.99, saving: '21% over a basic plan', included: ['6,800 Ink', '16 Custom World Slots'], excluded: [], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Subscription-Huge.webp', note: "*Does not roll over" },
    ];

    const boosterPacks = [
        { id: 0, packName: 'Small Booster', price: 4.99, saving: null, included: ['500 Ink'], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Booster-Small.webp', note: "*Valid for 12 months" },
        { id: 1, packName: 'Medium Booster', price: 9.99, saving: '9% over a small pack', included: ['1,100 Ink'], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Booster-Medium.webp', note: "*Valid for 12 months" },
        { id: 2, packName: 'Large Booster', price: 19.99, saving: '17% over a small pack', included: ['2,350 Ink'], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Booster-Large.webp', note: "*Valid for 12 months" },
        { id: 3, packName: 'Mega Booster', price: 29.99, saving: '26% over a small pack', included: ['3,800 Ink'], imageUrl: 'https://storage.googleapis.com/myadventures-images/pricing/Ink-Booster-Huge.webp', note: "*Valid for 12 months" },
    ];

    const handleBuy = (product) => {
        // Navigate to CheckoutPage with product details
        product.type = 'booster'
        navigate('/checkout', { state: { product } });
    };

    const handleSubscribe = (product) => {
        // Navigate to CheckoutPage with product details
        product.type = 'subscription'
        navigate('/checkout', { state: { product } });
    };

    return (
        <div>
            {/* Toggle between subscriptions and booster packs */}
            <div className="flex justify-center space-x-4 py-4">
                <Link to="/pricing/subscriptions" className={`btn ${type === 'subscriptions' ? 'bg-blue-500 text-white dark:bg-gray-800' : ''}`}>Subscriptions</Link>
                <Link to="/pricing/boosters" className={`btn ${type === 'boosters' ? 'bg-blue-500 text-white dark:bg-gray-800' : ''}`}>Booster Packs</Link>
            </div>

            {/* Ink Description Banner */}
            <div className="w-full pl-8 pr-8">
                <Card theme={cardTheme} className="w-full text-center dark:bg-gray-900 dark:border-0">
                    <h3 className="text-2xl font-bold mb-4">What is Ink?</h3>
                    <p>Ink is the currency of MyAdventures, used to generate documents, stories, worlds, items, lore, and more.
                        Each time you generate a new page to continue your adventure, Ink is consumed. Most actions,
                        like turning a new page, adding a new lore entry, or introducing a new plot element, cost 1 Ink.
                        However generating an image costs 10 Ink.</p>
                    <p className="mt-4 text-sm text-gray-500">For subscription plans, Ink is provided monthly and resets
                        at the end of each billing cycle. Unused Ink does not roll over.</p>
                    <p className="text-sm text-gray-500">Booster packs of Ink can be purchased separately, and they are
                        valid for up to 12 months from the date of purchase. Ink from subscriptions will be used before Ink from a booster pack</p>
                </Card>
            </div>

            {/* Conditionally show based on route */}
            {type === 'subscriptions' && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-center gap-6 pl-8 pr-8 pb-6 pt-8">
                    {priceSetA.map((price, index) => (
                        <div className="flex flex-col items-center mb-4" key={index}>
                            <PriceCard
                                handleBuy={() => handleSubscribe(price)}
                                planName={price.planName}
                                price={price.price}
                                saving={price.saving}
                                included={price.included}
                                excluded={price.excluded}
                                imageUrl={price.imageUrl}
                                period={"Month"}
                                comingSoon={price.comingSoon}
                            />
                            <span className="mt-1 text-sm text-gray-500">{price.note}</span>
                        </div>
                    ))}
                </div>
            )}

            {type === 'boosters' && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-center gap-6 pl-8 pr-8 pb-6 pt-8">
                    {boosterPacks.map((pack, index) => (
                        <div className="flex flex-col items-center mb-4" key={index}>
                            <PriceCard
                                handleBuy={() => handleBuy(pack)}
                                planName={pack.packName}
                                price={pack.price}
                                saving={pack.saving}
                                included={pack.included}
                                excluded={[]}
                                imageUrl={pack.imageUrl}
                                period={null}
                                comingSoon={pack.comingSoon}
                            />
                            <span className="mt-1 text-sm text-gray-500">{pack.note}</span>
                        </div>
                    ))}
                </div>
            )}

        </div>
    );
}

export default PricingPage;

const cardTheme = {
    root: {
        base: "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
        children: "flex h-full flex-col justify-center gap-2 p-6",
        horizontal: {
            off: "flex-col",
            on: "flex-col md:max-w-xl md:flex-row",
        },
        href: "hover:bg-gray-100 dark:hover:bg-gray-700",
    },
    img: {
        base: "",
        horizontal: {
            off: "rounded-t-lg",
            on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg",
        },
    },
};
