import React, { useEffect, useState, useCallback } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from "flowbite-react";
import axios from "axios";
import AddEditAgent from "./AddEditAgent";
import ViewLabel from "../../../oldComponents/view/ViewLabel";
import { PiMagicWandFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import ViewAgent from "../../../oldComponents/view/ViewAgent";

function ListAgents({ world }) {
    const { id } = useParams();
    const [agentList, setAgentList] = useState([]);
    const [listOfRoles, setListOfRoles] = useState([]);

    const fetchRoles = useCallback(async () => {
        try {
            const agentRoles = await axios.get(`${process.env.REACT_APP_API_URL}/agent/roles`);
            if (agentRoles.data.length > 0 && JSON.stringify(agentRoles.data) !== JSON.stringify(listOfRoles)) {
                setListOfRoles(agentRoles.data);
            }
        } catch (error) {
            console.error('Error fetching agent roles:', error);
        }
    }, [listOfRoles]);

    const fetchAgents = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/agent?worldId=${world._id}`);
            if (JSON.stringify(response.data) !== JSON.stringify(agentList)) {
                setAgentList(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch agents:', error);
        }
    }, [world, agentList]);

    useEffect(() => {
        if (listOfRoles.length === 0) {
            fetchRoles();
        }
    }, [fetchRoles]);

    useEffect(() => {
        if (world) {
            fetchAgents();
        }
    }, [fetchAgents]);

    const handleAgentDelete = async (event, agentId) => {
        event.preventDefault();
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/agent/${agentId}`);
            fetchAgents();
        } catch (error) {
            console.error(error);
        }
    };

    const magicWandFunc = async () => {
        try {
            const newAgents = await axios.post(`${process.env.REACT_APP_API_URL}/agent/defaults?worldId=${world._id}`);
            setAgentList((prevList) => [...prevList, ...newAgents.data.agents]);
        } catch (error) {
            console.error('Error creating agents:', error);
        }
    };

    if (!(world && listOfRoles.length > 0)) {
        return <div>Loading...</div>;
    }

    return (
        <div className="overflow-x-auto">
            <div className='flex ml-4 mb-4 space-x-2'>
                <AddEditAgent onSubmit={fetchAgents} roleData={listOfRoles} />
                {
                    agentList.length === 0 &&
                    (<Button theme={buttonTheme} pill color="gray" onClick={magicWandFunc}>
                        Create Presets<PiMagicWandFill className="mt-1 ml-1" />
                    </Button>)
                }
            </div>

            <Table theme={tableTheme} striped>
                <TableHead>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Role</TableHeadCell>
                    <TableHeadCell>Version</TableHeadCell>
                    <TableHeadCell>
                        <span className="sr-only">Edit</span>
                    </TableHeadCell>
                </TableHead>
                <TableBody className="divide-y">
                    {agentList.map(agent => {
                        const isInherited = agent.world !== world._id;
                        return (
                            <TableRow key={agent._id} className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${isInherited ? 'opacity-65' : ''}`}>
                                <TableCell>{agent.name}</TableCell>
                                <TableCell>{agent.role}</TableCell>
                                <TableCell>{agent.version}</TableCell>
                                <TableCell>
                                    {!isInherited && (
                                        <>
                                            <AddEditAgent agentData={agent} onSubmit={fetchAgents} roleData={listOfRoles} />
                                            <a href="#"
                                               className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
                                               onClick={(event) => handleAgentDelete(event, agent._id)}>
                                                Delete
                                            </a>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

export default ListAgents;

const tableTheme = {
    "root": {
        "base": "w-full text-left text-sm text-gray-500 dark:text-gray-400",
        "shadow": "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md dark:bg-black",
        "wrapper": "relative"
    },
    "body": {
        "base": "group/body",
        "cell": {
            "base": "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg"
        }
    },
    "head": {
        "base": "group/head text-xs uppercase text-gray-700 dark:text-gray-400",
        "cell": {
            "base": "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700"
        }
    },
    "row": {
        "base": "group/row",
        "hovered": "hover:bg-gray-50 dark:hover:bg-gray-600",
        "striped": "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
    }
}

const buttonTheme =
    {
        "base": "inline-flex",
        "position": {
            "none": "",
            "start": "rounded-r-none focus:ring-2",
            "middle": "rounded-none border-l-0 pl-0 focus:ring-2",
            "end": "rounded-l-none border-l-0 pl-0 focus:ring-2"
        }
    }
