// src/components/story/plot/graph/ContextMenu.jsx

import React from 'react';
import PropTypes from 'prop-types';

const ContextMenu = ({
                         nodeId,
                         type,
                         top,
                         left,
                         nodeLabel,
                         onDelete,
                         onRename,
                         onAddTag,
                         onRemoveTag,
                         onAssignToGroup,
                         availableGroups,
                     }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top,
                left,
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                zIndex: 1000,
                width: '200px',
            }}
            className="p-2"
        >
            <ul className="space-y-1">
                {type !== 'plotLine' && (
                    <>
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={onDelete}
                        >
                            Delete
                        </li>
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={() => {
                                const newLabel = prompt('Enter new name:', nodeLabel);
                                if (newLabel) onRename(newLabel);
                            }}
                        >
                            Rename
                        </li>
                        {/* Add other actions like Add Tag, Remove Tag, Assign to Group */}
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={() => {
                                const tag = prompt('Enter new tag:');
                                if (tag) onAddTag(tag);
                            }}
                        >
                            Add Tag
                        </li>
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={() => {
                                const tag = prompt('Enter tag to remove:');
                                if (tag) onRemoveTag(tag);
                            }}
                        >
                            Remove Tag
                        </li>
                        {availableGroups.length > 0 && (
                            <li
                                className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                                onClick={() => {
                                    const groupId = prompt('Enter Group ID to assign:');
                                    if (groupId) onAssignToGroup(groupId);
                                }}
                            >
                                Assign to Group
                            </li>
                        )}
                    </>
                )}

                {type === 'plotLine' && (
                    <>
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={() => {
                                const newLabel = prompt('Enter new name:', nodeLabel);
                                if (newLabel) onRename(newLabel);
                            }}
                        >
                            Rename Plot Line
                        </li>
                        <li
                            className="cursor-pointer hover:bg-gray-100 p-1 rounded"
                            onClick={() => {
                                const confirmDelete = window.confirm('Are you sure you want to delete this Plot Line? This may affect connected Plot Elements.');
                                if (confirmDelete) onDelete();
                            }}
                        >
                            Delete Plot Line
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
};

ContextMenu.propTypes = {
    nodeId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    nodeLabel: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onAddTag: PropTypes.func,
    onRemoveTag: PropTypes.func,
    onAssignToGroup: PropTypes.func,
    availableGroups: PropTypes.array.isRequired,
};

export default ContextMenu;
