// src/components/LocationList.jsx

import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import {
    Badge,
    Button,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    TextInput,
} from 'flowbite-react';
import { BsTrash } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaMap } from "react-icons/fa";
import LocationFormModal from './LocationFormModal';
import MapFormModal from './map/MapFormModal'; // Ensure correct path
import { TableTheme } from "../../../themes/TableTheme";

const LocationList = ({ story }) => {
    // State variables for Locations
    const [locations, setLocations] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [errorLocations, setErrorLocations] = useState('');

    // State variables for Maps
    const [maps, setMaps] = useState([]);
    const [loadingMaps, setLoadingMaps] = useState(true);
    const [errorMaps, setErrorMaps] = useState('');

    // Pagination state
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalViewOnly, setIsModalViewOnly] = useState(false);
    const [isMapModal, setIsMapModal] = useState(false); // To distinguish between Locations and Maps
    const [itemToEdit, setItemToEdit] = useState(null);

    // Search and Filters
    const [searchTerm, setSearchTerm] = useState('');
    const [labelFilter, setLabelFilter] = useState([]);
    const [activeLabelFilters, setActiveLabelFilters] = useState([]);

    // View Toggle
    const [viewMaps, setViewMaps] = useState(false); // false: Locations, true: Maps

    /**
     * Utility function to escape special characters in regex
     * Prevents ReDoS attacks.
     * @param {string} text
     * @returns {string}
     */
    const escapeRegex = (text) => {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    /**
     * Fetch data based on the current view (Locations or Maps).
     * @param {number} pageNumber - Current page number.
     * @param {string} search - Search term.
     * @param {array} labels - Array of label IDs to filter.
     */
    const fetchData = async (pageNumber, search = searchTerm, labels = labelFilter) => {
        if (viewMaps) {
            // Fetch Maps
            setLoadingMaps(true);
            setErrorMaps('');
            try {
                const params = {
                    page: pageNumber,
                    limit: 20, // Adjust as needed
                    storyId: story._id,
                };

                if (search) {
                    params.search = search;
                }

                if (labels.length > 0) {
                    params.labels = labels.join(',');
                    // Optional: Add 'match' parameter if backend supports it
                    // params.match = 'any'; // or 'all'
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/map/list`, { params });

                setMaps(response.data.maps);
                setTotalPages(response.data.totalPages);
                setPage(response.data.currentPage);
            } catch (err) {
                console.error(err);
                setErrorMaps('Failed to fetch maps.');
            } finally {
                setLoadingMaps(false);
            }
        } else {
            // Fetch Locations
            setLoadingLocations(true);
            setErrorLocations('');
            try {
                const params = {
                    storyId: story._id,
                    page: pageNumber,
                    limit: 20, // Adjust as needed
                };

                if (search) {
                    params.search = search;
                }

                if (labels.length > 0) {
                    params.labels = labels.join(',');
                    // Optional: Add 'match' parameter if backend supports it
                    // params.match = 'any'; // or 'all'
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/location/list`, { params });
                setLocations(response.data.locations);
                setTotalPages(response.data.totalPages);
                setPage(response.data.currentPage);
            } catch (err) {
                console.error(err);
                setErrorLocations('Failed to fetch locations.');
            } finally {
                setLoadingLocations(false);
            }
        }
    };

    /**
     * Effect hook to fetch data whenever page, searchTerm, labelFilter, or viewMaps changes.
     */
    useEffect(() => {
        fetchData(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchTerm, labelFilter, viewMaps]);

    /**
     * Handle deletion of a Location or Map.
     * @param {string} id - ID of the item to delete.
     * @param {boolean} isMap - Determines if the item is a Map.
     */
    const handleDelete = async (id, isMap = false) => {
        if (!window.confirm(`Are you sure you want to delete this ${isMap ? 'map' : 'location'}?`)) return;
        try {
            const endpoint = isMap
                ? `${process.env.REACT_APP_API_URL}/map/${id}`
                : `${process.env.REACT_APP_API_URL}/location/${id}`;
            await axios.delete(endpoint);
            fetchData(page);
        } catch (err) {
            console.error(err);
            alert(`Failed to delete the ${isMap ? 'map' : 'location'}.`);
        }
    };

    /**
     * Open the modal for adding or editing a Location or Map.
     * @param {object|null} item - The item to edit or null for adding.
     * @param {boolean} isMap - Determines if the modal is for a Map.
     * @param {boolean} isViewOnly - Determines if the modal is view-only.
     */
    const openModal = (item = null, isMap = false, isViewOnly = false) => {
        setItemToEdit(item);
        setIsMapModal(isMap);
        setIsModalViewOnly(isViewOnly);
        setIsModalOpen(true);
    };

    /**
     * Close the modal.
     */
    const closeModal = () => {
        setIsModalOpen(false);
        setItemToEdit(null);
    };

    /**
     * Callback function after successful add/edit/delete operations.
     */
    const handleSuccess = () => {
        fetchData(page);
    };

    /**
     * Handle the submission of the search form.
     * @param {object} e - Event object.
     */
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setPage(1); // Reset to first page on new search
        fetchData(1, searchTerm, labelFilter);
    };

    /**
     * Add a label to the active label filters.
     * @param {object} label - Label object to add.
     */
    const handleLabelClick = (label) => {
        if (!labelFilter.includes(label._id)) {
            setLabelFilter([...labelFilter, label._id]);
            setActiveLabelFilters([...activeLabelFilters, label]);
            setPage(1); // Reset to first page on filter change
        }
    };

    /**
     * Remove a label from the active label filters.
     * @param {string} labelId - ID of the label to remove.
     */
    const handleRemoveLabelFilter = (labelId) => {
        setLabelFilter(labelFilter.filter(id => id !== labelId));
        setActiveLabelFilters(activeLabelFilters.filter(label => label._id !== labelId));
        setPage(1); // Reset to first page on filter change
    };

    return (
        <div className="overflow-x-auto p-4 pt-0 pb-8">
            {/* Header Section: Buttons and Search Bar */}
            <div className="flex flex-col md:flex-row mb-6 justify-between w-full">
                <div className="flex space-x-2 mb-4 md:mb-0">
                    {!viewMaps ? (
                        <>
                            <Button onClick={() => openModal(null, false, false)} color="blue">
                                Add Location
                            </Button>
                            <Button onClick={() => setViewMaps(true)} color="light">
                                Show Maps
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={() => openModal(null, true, false)} color="blue">
                                Add Map
                            </Button>
                            <Button onClick={() => setViewMaps( false)} color="light">
                                Show Locations
                            </Button>
                        </>
                    )}
                </div>
                <form onSubmit={handleSearchSubmit} className="flex items-center space-x-2">
                    <TextInput
                        type="text"
                        placeholder={viewMaps ? "Search Maps by name..." : "Search by name..."}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-64"
                        aria-label={viewMaps ? "Search Maps" : "Search Locations"}
                    />
                    <Button type="submit" color="green">
                        Search
                    </Button>
                </form>
            </div>

            {/* Active Label Filters */}
            {activeLabelFilters.length > 0 && (
                <div className="flex items-center mb-4 space-x-2">
                    <span className="font-semibold">Active Filters:</span>
                    {activeLabelFilters.map(label => (
                        <Badge key={label._id} color="purple" className="flex items-center">
                            {label.name}
                            <button
                                onClick={() => handleRemoveLabelFilter(label._id)}
                                className="ml-1 text-white focus:outline-none"
                                aria-label={`Remove filter ${label.name}`}
                            >
                                &times;
                            </button>
                        </Badge>
                    ))}
                </div>
            )}

            {/* Locations or Maps Table */}
            {viewMaps ? (
                // Maps View
                loadingMaps ? (
                    <div className="flex justify-center items-center">
                        <Spinner aria-label="Loading maps..." />
                    </div>
                ) : errorMaps ? (
                    <div className="text-red-500 text-center">{errorMaps}</div>
                ) : (
                    <Table striped theme={TableTheme}>
                        <TableHead>
                            <TableHeadCell></TableHeadCell>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Dimension</TableHeadCell>
                            <TableHeadCell>Connections Count</TableHeadCell>
                            <TableHeadCell>
                                <span className="sr-only">Actions</span>
                            </TableHeadCell>
                        </TableHead>
                        <TableBody>
                            {maps.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan="5" className="text-center">No maps found.</TableCell>
                                </TableRow>
                            ) : (
                                maps.map((map) => (
                                    <TableRow
                                        key={map._id}
                                        className="cursor-pointer"
                                        onClick={() => openModal(map, true, true)}
                                    >
                                        <TableCell className="w-8"><FaMap title={"Map"} /></TableCell>
                                        <TableCell>{map.name}</TableCell>
                                        <TableCell>{map.dimension !== undefined ? map.dimension : 'N/A'}</TableCell>
                                        <TableCell>{map.connections.length}</TableCell>
                                        <TableCell className="flex space-x-4">
                                            <GrEdit
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openModal(map, true);
                                                }}
                                                size={18}
                                                title="Edit Map"
                                                className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Edit ${map.name}`}
                                            />
                                            <BsTrash
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(map._id, true);
                                                }}
                                                size={18}
                                                title="Delete Map"
                                                className="text-gray-400 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Delete ${map.name}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                )
            ) : (
                // Locations View
                loadingLocations ? (
                    <div className="flex justify-center items-center">
                        <Spinner aria-label="Loading locations..." />
                    </div>
                ) : errorLocations ? (
                    <div className="text-red-500 text-center">{errorLocations}</div>
                ) : (
                    <Table striped theme={TableTheme}>
                        <TableHead>
                            <TableHeadCell></TableHeadCell>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Labels</TableHeadCell>
                            <TableHeadCell>
                                <span className="sr-only">Actions</span>
                            </TableHeadCell>
                        </TableHead>
                        <TableBody>
                            {locations.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan="4" className="text-center">No locations found.</TableCell>
                                </TableRow>
                            ) : (
                                locations.map((location) => (
                                    <TableRow
                                        key={location._id}
                                        className="cursor-pointer"
                                        onClick={() => openModal(location, false, true)}
                                    >
                                        <TableCell className="w-8"><FaMapMarkerAlt title={"Location"} /></TableCell>
                                        <TableCell>{location.name}</TableCell>
                                        <TableCell>
                                            {location.labels && location.labels.length > 0 ? (
                                                location.labels.map(label => (
                                                    <Badge
                                                        key={label._id}
                                                        color="gray"
                                                        className="mr-1 cursor-pointer"
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent row click
                                                            handleLabelClick(label);
                                                        }}
                                                        aria-label={`Filter by label ${label.name}`}
                                                    >
                                                        {label.name}
                                                    </Badge>
                                                ))
                                            ) : (
                                                <span className="text-gray-500 dark:text-gray-400">No Labels</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="flex space-x-4">
                                            {/* Edit Button */}
                                            <GrEdit
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click
                                                    openModal(location, false);
                                                }}
                                                size={18}
                                                title="Edit Location"
                                                className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Edit ${location.name}`}
                                            />
                                            {/* Delete Button */}
                                            <BsTrash
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent row click
                                                    handleDelete(location._id, false);
                                                }}
                                                size={18}
                                                title="Delete Location"
                                                className="text-gray-400 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Delete ${location.name}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                )
            )}

            {/* Pagination Controls */}
            {totalPages > 1 && (
                <div className="flex justify-between items-center mt-4">
                    <Button
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                        color="gray"
                    >
                        Previous
                    </Button>
                    <span className="text-gray-700 dark:text-gray-300">Page {page} of {totalPages}</span>
                    <Button
                        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={page === totalPages}
                        color="gray"
                    >
                        Next
                    </Button>
                </div>
            )}

            {/* Active Label Filters */}
            {activeLabelFilters.length > 0 && (
                <div className="flex items-center mb-4 space-x-2">
                    <span className="font-semibold">Active Filters:</span>
                    {activeLabelFilters.map(label => (
                        <Badge key={label._id} color="purple" className="flex items-center">
                            {label.name}
                            <button
                                onClick={() => handleRemoveLabelFilter(label._id)}
                                className="ml-1 text-white focus:outline-none"
                                aria-label={`Remove filter ${label.name}`}
                            >
                                &times;
                            </button>
                        </Badge>
                    ))}
                </div>
            )}

            {/* Location or Map Modal */}
            {!isMapModal ? (
                <LocationFormModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    locationToEdit={itemToEdit}
                    story={story}
                    viewOnly={isModalViewOnly}
                    onSuccess={handleSuccess}
                />
            ) : (
                <MapFormModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    mapToEdit={itemToEdit}
                    story={story}
                    viewOnly={isModalViewOnly}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );

};

export default LocationList;
