import { useContext } from "react";
import DarkModeContext from "../context/DarkModeContext";
import { components } from 'react-select';

// Styles for light mode
const lightThemeStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#f9fafb', // Example: light-color
        borderColor: '#d1d5db',     // darker border
        color: '#000',           // Example: dark-color
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff', // Example: light-color
        color: '#000',           // Example: dark-color
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#6b7280' // darker icon color
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#000' // light mode text color for selected value
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#000' // light mode text color for selected values (multi select)
    })
};

// Styles for dark mode
const darkThemeStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#374151', //bg-gray-600
        borderColor: '#4b5563',     // lighter border
        color: '#9ca3af'            //text-gray-400
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#1F2937', //bg-gray-800
        color: '#9ca3af'            //text-gray-400
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#9ca3af' // lighter icon color
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#d1d5db' // dark mode text color for selected value
    }),
    multiValue: (provided) => ({
        ...provided,
        color: '#e5e7eb' // dark mode text color for selected values (multi select)
    })
};

const useSelectThemeStyle = () => {
    const { darkMode } = useContext(DarkModeContext);

    return darkMode ? darkThemeStyles : lightThemeStyles;
};

export default useSelectThemeStyle;