import {Footer, FooterCopyright, FooterLink, FooterLinkGroup} from "flowbite-react";
import {FooterTheme} from "../../themes/FooterTheme";
import {useLocation} from "react-router-dom";
import React from "react";
import { FaDiscord } from "react-icons/fa";

const SiteFooter = () => {

    const location = useLocation();  // Now inside Router

    // Define a list of paths where the footer should not be displayed
    const noFooterRoutes = ["/test", "/adventure"];

    // Check if the current path matches any in the list of routes without a footer
    const shouldShowFooter = !noFooterRoutes.some(route => location.pathname.startsWith(route));

    if (!shouldShowFooter) return null;

    return (
        <Footer theme={FooterTheme} className="fixed bottom-0 left-0 right-0 z-50" container>
            <FooterCopyright href="#" by="Ephor Software Ltd" year={2024} className="hidden md:block" />
            <FooterLinkGroup>
                <FooterLink href="/privacypolicy.html">Privacy Policy</FooterLink>
                <FooterLink href="/termsofservice.html">Terms of Service</FooterLink>
                <FooterLink href="/cookiepolicy.html">Cookie Policy</FooterLink>
                <FooterLink href="https://discord.gg/CF64D9g84E" className="hidden sm:flex">
                    <div className="flex items-center gap-1">
                        <FaDiscord size={18}/>
                        <span>Discord</span>
                    </div>
                </FooterLink>
            </FooterLinkGroup>
        </Footer>
    );
};

export default SiteFooter;