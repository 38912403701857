// src/components/CharacterFormModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Modal,
    Button,
    TextInput,
    Textarea,
    Label,
    Checkbox,
    Spinner,
} from 'flowbite-react';
import { GiQuillInk } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';
import { IoPerson } from 'react-icons/io5';
import { FaMagic } from 'react-icons/fa';
import AddEditImage from '../../common/AddEditImage';
import AddEditProperties from '../../common/AddEditProperties';
import { TextAreaTheme, TextAreaThemeReadOnly } from '../../../themes/TextAreaTheme';
import { ModalTheme } from '../../../themes/ModalTheme';
import { ButtonTheme } from '../../../themes/ButtonTheme';
import { TextInputTheme, TextInputThemeReadOnly } from '../../../themes/TextInputTheme';
import Select from 'react-select';
import useSelectThemeStyle from '../../../themes/SelectTheme';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";

const CharacterFormModal = ({
                                isOpen,
                                onClose,
                                characterToEdit,
                                story,
                                viewOnly,
                                onSuccess,
                            }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [name, setName] = useState('');
    const [affiliation, setAffiliation] = useState(0);
    const [description, setDescription] = useState('');
    const [appearance, setAppearance] = useState('');
    const [properties, setProperties] = useState([]);
    const [playable, setPlayable] = useState(false);
    const [gender, setGender] = useState('other'); // Default value
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const addEditImageRef = useRef();

    const [suggestions, setSuggestions] = useState([]);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);

    // New state to track if a new character has been created
    const [newCharacterId, setNewCharacterId] = useState(null);

    useEffect(() => {
        if (characterToEdit) {
            setName(characterToEdit.name || '');
            setAffiliation(characterToEdit.affiliation || 0);
            setDescription(characterToEdit.description || '');
            setAppearance(characterToEdit.appearance || '');
            setProperties(characterToEdit.properties || []);
            setGender(characterToEdit.gender || 'other');
            setPlayable(characterToEdit.playable || false);
        } else {
            setName('');
            setAffiliation(0);
            setDescription('');
            setAppearance('');
            setProperties([]);
            setGender('other');
            setPlayable(false);
        }
        setIsViewOnly(viewOnly);
        // Reset suggestions when characterToEdit or viewOnly changes
        setSuggestions([]);
        setCurrentSuggestionIndex(0);
        // Reset newCharacterId when modal is opened or closed
        if (!isOpen) {
            setNewCharacterId(null);
        }
    }, [characterToEdit, viewOnly, isOpen]);

    const handleImageSave = async (characterId) => {
        try {
            if (addEditImageRef.current) {
                await addEditImageRef.current.commit(characterId);
            }
        } catch (err) {
            console.error('Error saving image:', err);
            setError('Failed to save image.');
            throw err; // Re-throw to handle in handleSubmit
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            const data = {
                name,
                affiliation,
                description,
                appearance,
                properties,
                playable,
                gender,
                story: story._id,
                // Include other necessary fields
            };

            let characterId = characterToEdit ? characterToEdit._id : null;

            if (characterToEdit) {
                // Editing existing character
                await handleImageSave(characterId);
                // Update character details
                await axios.put(`${process.env.REACT_APP_API_URL}/character/${characterId}`, data);
            } else {
                // Creating new character without image
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/character`, data);
                const newCharacter = response.data;
                characterId = newCharacter._id;
                setNewCharacterId(characterId); // Update state with new _id
                // Now, attach/upload the image using the new _id
                await handleImageSave(characterId);
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving character:', err);
            setError('Failed to save character.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!characterToEdit) return;
        if (!window.confirm('Are you sure you want to delete this character?')) return;

        setLoading(true);
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/character/${characterToEdit._id}`);
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting character:', err);
            setError('Failed to delete character.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsViewOnly(false);
    };

    const handlePropertiesUpdate = (newProperties) => {
        setProperties(newProperties);
    };

    const affiliationOptions = [
        { value: 6, label: 'Protagonist' },
        { value: 5, label: 'Confidant' },
        { value: 4, label: 'Friend' },
        { value: 3, label: 'Companion' },
        { value: 2, label: 'Acquaintance' },
        { value: 1, label: 'Favorable' },
        { value: 0, label: 'Neutral' },
        { value: -1, label: 'Unfavorable' },
        { value: -2, label: 'Distant' },
        { value: -3, label: 'Rival' },
        { value: -4, label: 'Enemy' },
        { value: -5, label: 'Nemesis' },
    ];

    const handleGenerateSuggestions = async () => {
        setIsGenerating(true);
        setError('');
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/character/generate?storyId=${story._id}`
            );
            setSuggestions(response.data); // Assuming the API returns an array of suggestions in data
            setCurrentSuggestionIndex(0);
            // Load the first suggestion into the form fields
            loadSuggestion(response.data[0]);
        } catch (error) {
            console.error('Error generating character suggestions:', error);
            setError('Failed to generate character suggestions.');
        } finally {
            setIsGenerating(false);
        }
    };

    const loadSuggestion = (suggestion) => {
        setName(suggestion.name || '');
        setAffiliation(suggestion.affiliation || 0);
        setDescription(suggestion.description || '');
        setAppearance(suggestion.appearance || '');
        setProperties(suggestion.properties || []);
        setGender(suggestion.gender || 'other');
        setPlayable(suggestion.playable || false);
        // Handle image if any
        // If AddEditImage component supports setting the image, we can set it here
    };

    const handlePreviousSuggestion = () => {
        if (currentSuggestionIndex > 0) {
            const newIndex = currentSuggestionIndex - 1;
            setCurrentSuggestionIndex(newIndex);
            loadSuggestion(suggestions[newIndex]);
        }
    };

    const handleNextSuggestion = () => {
        if (currentSuggestionIndex < suggestions.length - 1) {
            const newIndex = currentSuggestionIndex + 1;
            setCurrentSuggestionIndex(newIndex);
            loadSuggestion(suggestions[newIndex]);
        }
    };

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size="4xl">
            <Modal.Header>
                <div className="flex justify-between items-center w-full space-x-2">
                    <div className="flex items-center space-x-2">
                        <IoPerson />
                        <span
                            className={`text-2xl font-bold ${
                                isViewOnly ? '' : 'text-gray-900 dark:text-white'
                            }`}
                        >
                            {characterToEdit
                                ? isViewOnly
                                    ? 'View Character'
                                    : 'Edit Character'
                                : 'Add Character'}
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        {!isViewOnly && !characterToEdit && (
                            <>
                                {isGenerating ? (
                                    <Spinner size="sm" className="ml-2" />
                                ) : (
                                    <FaMagic
                                        className={`text-gray-300 dark:text-gray-500 hover:text-purple-700 dark:hover:text-purple-500 transition-all duration-100 transform hover:scale-110 ${
                                            isGenerating ? 'cursor-not-allowed' : 'cursor-pointer'
                                        }`}
                                        onClick={handleGenerateSuggestions}
                                        title="Generate Character Suggestions"
                                        aria-label="Generate Character Suggestions"
                                    />
                                )}
                            </>
                        )}
                        {characterToEdit && isViewOnly && (
                            <GrEdit
                                className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110 cursor-pointer"
                                onClick={handleEdit}
                                title="Edit Character"
                                aria-label="Edit Character"
                            />
                        )}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="mb-4">
                        <Label color="failure" value={error} />
                    </div>
                )}
                {suggestions.length > 0 && (
                    <div className="flex justify-between items-center mb-4">
                        <MdOutlineArrowBackIos
                            size={24}
                            onClick={handlePreviousSuggestion}
                            disabled={currentSuggestionIndex === 0}
                            className={`transition-transform transform hover:scale-110 ${
                                currentSuggestionIndex === 0
                                    ? 'text-gray-400 dark:text-gray-500 cursor-not-allowed'
                                    : 'text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 cursor-pointer'
                            }`}
                        />
                        <span>
                            Suggestion {currentSuggestionIndex + 1} of {suggestions.length}
                        </span>
                        <MdOutlineArrowForwardIos
                            size={24}
                            onClick={handleNextSuggestion}
                            disabled={currentSuggestionIndex === suggestions.length - 1}
                            className={`transition-transform transform hover:scale-110 ${
                                currentSuggestionIndex === suggestions.length - 1
                                    ? 'text-gray-400 dark:text-gray-500 cursor-not-allowed'
                                    : 'text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 cursor-pointer'
                            }`}
                        />
                    </div>
                )}
                <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Left Column */}
                    <div className="space-y-6">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name" value="Name" />
                            <TextInput
                                id="name"
                                type="text"
                                placeholder="Enter character name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                            />
                        </div>
                        {/* Affiliation Field */}
                        <div>
                            <Label htmlFor="affiliation" value="Affiliation (to protagonist)" />
                            <Select
                                id="affiliation"
                                value={affiliationOptions.find((option) => option.value === affiliation)}
                                onChange={(selectedOption) => setAffiliation(selectedOption.value)}
                                required
                                styles={useSelectThemeStyle()}
                                isDisabled={isViewOnly}
                                options={affiliationOptions}
                            />
                        </div>
                        {/* Image Field */}
                        <div>
                            <Label htmlFor="image" value="Image" />
                            <AddEditImage
                                parentData={{
                                    _id: characterToEdit?._id || newCharacterId, // Use newCharacterId if available
                                    name,
                                    affiliation,
                                    description,
                                    appearance,
                                    properties,
                                    playable,
                                    gender,
                                    story: story._id,
                                    // Include image data if necessary
                                }}
                                ref={addEditImageRef}
                                placeholder={4}
                                disabled={isViewOnly} // Disable if no _id available
                            />
                        </div>
                        {/* Properties Field */}
                        <div>
                            <Label htmlFor="properties" value="Properties" />
                            <AddEditProperties
                                properties={properties}
                                onPropertyUpdate={handlePropertiesUpdate}
                                disabled={isViewOnly}
                            />
                        </div>
                    </div>
                    {/* Right Column */}
                    <div className="space-y-4">
                        {/* Playable */}
                        <div className={'space-x-2 pt-2'}>
                            <Checkbox
                                disabled={isViewOnly}
                                checked={playable}
                                onChange={() => setPlayable(!playable)}
                            />
                            <Label>Playable?</Label>
                        </div>
                        {/* Gender Field */}
                        <div>
                            <Label htmlFor="gender" value="Gender" />
                            <Select
                                id="gender"
                                value={{ value: gender, label: gender.charAt(0).toUpperCase() + gender.slice(1) }}
                                onChange={(selectedOption) => setGender(selectedOption.value)}
                                isDisabled={isViewOnly}
                                options={[
                                    { value: 'male', label: 'Male' },
                                    { value: 'female', label: 'Female' },
                                    { value: 'other', label: 'Other' },
                                ]}
                                styles={useSelectThemeStyle()}
                                required
                            />
                        </div>
                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description" value="Description (Backstory)" />
                            <Textarea
                                id="description"
                                placeholder="Enter character description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                rows={8}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Appearance Field */}
                        <div>
                            <Label htmlFor="appearance" value="Appearance" />
                            <Textarea
                                id="appearance"
                                placeholder="Enter character appearance"
                                value={appearance}
                                onChange={(e) => setAppearance(e.target.value)}
                                required
                                rows={6}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Additional Fields (if any) */}
                        {/* You can add more fields here as needed */}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {!isViewOnly ? (
                    <div className="flex justify-between w-full">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading || !name || !description}
                            theme={ButtonTheme}
                            color="blue"
                        >
                            {loading ? 'Saving...' : characterToEdit ? 'Save' : 'Add'}
                            {!characterToEdit && (
                                <>
                                    <GiQuillInk size={16} className="ml-1 translate-x-1" />
                                </>
                            )}
                        </Button>
                        {characterToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="flex justify-between w-full">
                        {characterToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                                className="mr-4"
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Close
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
};

CharacterFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    characterToEdit: PropTypes.object,
    story: PropTypes.object.isRequired,
    viewOnly: PropTypes.bool,
    onSuccess: PropTypes.func.isRequired,
};

CharacterFormModal.defaultProps = {
    characterToEdit: null,
    viewOnly: false,
};

export default CharacterFormModal;
