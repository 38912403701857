import React, { useCallback, useMemo } from 'react';
import { TextInput } from "flowbite-react";
import { CgAdd, CgRemove } from "react-icons/cg";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid'; // Ensure to install uuid: npm install uuid

const AddEditProperties = React.memo(({ properties, onPropertyUpdate, disableInherited = false }) => {

    /**
     * Process the properties to merge inherited and local properties.
     * For each property name, prefer the local version over the inherited one.
     */
    const mergedProperties = useMemo(() => {
        const propertyMap = new Map();

        // Iterate in reverse to give precedence to local properties
        for (let i = properties.length - 1; i >= 0; i--) {
            const prop = properties[i];
            if (!propertyMap.has(prop.name)) {
                propertyMap.set(prop.name, prop);
            }
        }

        return Array.from(propertyMap.values());
    }, [properties]);

    // Handler to add a new property
    const handleAddProperty = useCallback(() => {
        const newProperty = {
            id: uuidv4(),
            name: 'New Property',
            attributes: [],
            inherited: false
        };
        const updatedProperties = [...properties, newProperty];
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    // Handler to add a new attribute to a specific property
    const handleAddAttribute = useCallback((propertyName) => {
        const newAttribute = {
            id: uuidv4(),
            key: 'Attribute',
            value: '',
            inherited: false // New attributes are local by default
        };
        const updatedProperties = properties.map(prop => {
            if (prop.name === propertyName) {
                // If it's inherited, we need to create a local override first
                if (prop.inherited) {
                    return {
                        ...prop,
                        inherited: false,
                        attributes: [...prop.attributes, newAttribute]
                    };
                }
                // If it's already local
                return {
                    ...prop,
                    attributes: [...prop.attributes, newAttribute]
                };
            }
            return prop;
        });
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    // Handler to remove a property (only for local properties)
    const handleRemoveProperty = useCallback((propertyName) => {
        const updatedProperties = properties.filter(prop => prop.name !== propertyName || prop.inherited);
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    // Handler to remove an attribute (only for local attributes)
    const handleRemoveAttribute = useCallback((propertyName, attrId) => {
        const updatedProperties = properties.map(prop => {
            if (prop.name === propertyName) {
                return {
                    ...prop,
                    attributes: prop.attributes.filter(attr => attr.id !== attrId)
                };
            }
            return prop;
        });
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    // Handler to change an attribute's key or value
    const handleChangeAttribute = useCallback((propertyName, attrId, key, value) => {
        const updatedProperties = properties.map(prop => {
            if (prop.name === propertyName) {
                return {
                    ...prop,
                    attributes: prop.attributes.map(attr => {
                        if (attr.id === attrId) {
                            // Prevent changing the key if the attribute is inherited
                            if (key === 'key' && attr.inherited) return attr;
                            return { ...attr, [key]: value };
                        }
                        return attr;
                    })
                };
            }
            return prop;
        });
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    // Handler to change a property's name (only for local properties)
    const handleChangePropertyName = useCallback((propertyName, newName) => {
        const property = properties.find(prop => prop.name === propertyName);

        // Prevent renaming if the property is inherited
        if (property.inherited) return;

        // Check for duplicate property names
        const isDuplicate = properties.some(prop => prop.name === newName && prop.name !== propertyName);
        if (isDuplicate) {
            alert('Property name must be unique.');
            return;
        }

        const updatedProperties = properties.map(prop => {
            if (prop.name === propertyName) {
                return { ...prop, name: newName };
            }
            return prop;
        });
        onPropertyUpdate(updatedProperties);
    }, [properties, onPropertyUpdate]);

    if (!properties) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-full">
            {mergedProperties.map((property, index) => (
                <div
                    key={index} // Use unique id as key
                    className={`border p-2 mb-2 rounded-lg shadow-sm ${
                        property.inherited ? 'bg-gray-100 text-gray-500' : ''
                    }`}
                >
                    {/* Property Header */}
                    <div className="flex items-center justify-between mb-1">
                        <TextInput
                            type="text"
                            sizing="sm"
                            className={`flex-grow mr-2 font-semibold ${property.inherited ? 'cursor-not-allowed' : ''}`}
                            value={property.name}
                            onChange={(e) => handleChangePropertyName(property.name, e.target.value)}
                            placeholder="Property Name"
                            disabled={property.inherited || disableInherited} // Disable if inherited or inheritance is disabled
                        />
                        {!property.inherited && (
                            <CgRemove
                                className="text-red-500 cursor-pointer hover:text-red-700"
                                onClick={() => handleRemoveProperty(property.name)}
                                size={18}
                                title="Remove Property"
                            />
                        )}
                    </div>

                    {/* Attributes List */}
                    {property.attributes.map((attribute, index) => (
                        <div key={index} className="flex items-center justify-between mb-1">
                            {/* Attribute Key */}
                            <TextInput
                                type="text"
                                sizing="xs"
                                className="flex-grow mr-1"
                                value={attribute.key}
                                onChange={(e) => handleChangeAttribute(property.name, attribute.id, 'key', e.target.value)}
                                placeholder="Key"
                                disabled={attribute.inherited || disableInherited} // Disable if inherited or inheritance is disabled
                            />
                            {/* Attribute Value */}
                            <TextInput
                                type="text"
                                sizing="xs"
                                className={`flex-grow mr-1 ${(property.inherited && !attribute.inherited) ? 'font-bold' : ''}`}
                                value={attribute.value}
                                onChange={(e) => handleChangeAttribute(property.name, attribute.id, 'value', e.target.value)}
                                placeholder="Value"
                                disabled={disableInherited} // Allow editing value even if inherited
                            />
                            {/* Remove Attribute Button (only for local attributes) */}
                            {!attribute.inherited && (
                                <CgRemove
                                    className="text-red-500 cursor-pointer hover:text-red-700"
                                    onClick={() => handleRemoveAttribute(property.name, attribute.id)}
                                    size={18}
                                    title="Remove Attribute"
                                />
                            )}
                        </div>
                    ))}

                    {/* Add Attribute Button */}
                    {!disableInherited && (
                        <button
                            type="button"
                            className="text-blue-500 hover:text-blue-700 text-xs mt-1 flex items-center"
                            onClick={() => handleAddAttribute(property.name)}
                        >
                            <CgAdd className="mr-1" size={16} />
                            Add Attribute
                        </button>
                    )}

                    {/* Indicate if Property is Inherited */}
                    {property.inherited && (
                        <div className="text-xs text-gray-500 mt-1">
                            (Inherited from another label)
                        </div>
                    )}
                </div>
            ))}

            {/* Add Property Button */}
            {!disableInherited && (
                <button
                    type="button"
                    className="text-blue-500 hover:text-blue-700 text-xs mt-2 flex items-center"
                    onClick={handleAddProperty}
                >
                    <CgAdd className="mr-1" size={18} />
                    Add Property
                </button>
            )}
        </div>
    );

});

AddEditProperties.propTypes = {
    properties: PropTypes.array.isRequired,
    onPropertyUpdate: PropTypes.func.isRequired,
    disableInherited: PropTypes.bool,
};

export default AddEditProperties;
