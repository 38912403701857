import {Blockquote, Breadcrumb, Button} from "flowbite-react";
import Breadcrumbs from "../header/Breadcrumbs";
import { IoIosLogIn } from "react-icons/io";
import {IoHomeSharp} from "react-icons/io5";
import {GiStarSwirl} from "react-icons/gi";
import {RiGlobeFill} from "react-icons/ri";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useSession} from "../../context/SessionContext";

function ShareStoryLink() {
    const { id } = useParams();
    const { session } = useSession();
    const [shareLink, setShareLink] = useState({});
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (!id) return;
        if (!session) return;
        const redeemLink = async () => {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/entitlement/story/grant/${id}`);
            setShareLink(response.data);
        }
        redeemLink();
    }, [id, session])


    useEffect(() => {
        const redirect = async () => {
            if (shareLink?.story)
            {
                navigate(`/stories`); // Redirect to shared resource
            }
        }
        redirect();
    }, [shareLink])


    if (!session)
    {
        return (
            <div className={"ml-4 mt-2 text-2xl"}>
                Please login to access this link
            </div>
        )
    }

    if (!shareLink)
    {
        return (
            <div className={"ml-4 mt-2 text-2xl"}>
                Error!
            </div>
        )
    }

    return (
        <div className={"ml-4 mt-2 text-2xl"}>
            {shareLink.message}
        </div>
    )
}

export default ShareStoryLink;
