// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {predefinedFormats, predefinedLocations, predefinedSymbolism} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_Symbolism = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Choose Symbolic Elements to Incorporate as Recurring Motifs in Your Story"
            subTitle="(Pick upto Three or None)"
            predefinedList={predefinedSymbolism}
            formDataField="symbolism" // Ensure this matches the key in formData
            selectionLimit={3} // Example: limit to 3 selections, set to null for no limit
            minSelection={0}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_Symbolism;
