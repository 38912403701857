import React from "react";
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import {ItemTypes} from "./ItemTypes";

// Drop Cell Component
export const DropCell = React.memo(({ ruleId, index, cellData, onDrop, onClear }) => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: [ItemTypes.TILE, ItemTypes.GROUP],
        drop: (item) => {
            onDrop(ruleId, index, item);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }), [ruleId, index, onDrop]);

    const handleClick = () => {
        onDrop(ruleId, index, null, true); // Open selector modal
    };

    return (
        <div
            ref={drop}
            className={`relative w-24 h-24 border rounded cursor-pointer flex items-center justify-center 
                ${isOver && canDrop ? 'bg-green-100 dark:bg-green-950' : 'bg-gray-100 dark:bg-gray-900'}`}
            onClick={handleClick}
        >
            {cellData ? (
                Array.isArray(cellData) ? (
                    cellData.map((cell, idx) => (
                        <React.Fragment key={idx}>
                            {cell.type === 'group' ? (
                                <>
                                    <img
                                        src={cell.imageSrc}
                                        alt={cell.label}
                                        className="w-6 h-6 object-cover rounded"
                                    />
                                    <span className="absolute bottom-0 left-0  bg-opacity-75 text-xs px-1 rounded-tl">
                                        {cell.label}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <img
                                        src={cell.imgSrc}
                                        alt={`Tile ${cell.tileIndex}`}
                                        className="w-14 h-14 object-cover rounded"
                                    />
                                    <span className="absolute bottom-0 left-0  bg-opacity-75 text-xs px-1 rounded-tl">
                                        {cell.tileIndex}
                                    </span>
                                </>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    cellData.type === 'group' ? (
                        <>
                            <img
                                src={cellData.imageSrc}
                                alt={cellData.label}
                                className="w-14 h-14 object-cover rounded"
                            />
                            <span className="absolute bottom-0 left-0  bg-opacity-75 text-xs px-1 rounded-tl">
                                {cellData.label}
                            </span>
                        </>
                    ) : (
                        <>
                            <img
                                src={cellData.imgSrc}
                                alt={`Tile ${cellData.tileIndex}`}
                                className="w-12 h-12 object-cover rounded"
                            />
                            <span className="absolute bottom-0 left-0  bg-opacity-75 text-xs px-1 rounded-tl">
                                {cellData.tileIndex}
                            </span>
                        </>
                    )
                )
            ) : (
                <span className="text-xs text-gray-500">Empty</span>
            )}
            {cellData && (
                <button
                    className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1 rounded-bl"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClear(ruleId, index);
                    }}
                >
                    Clear
                </button>
            )}
            {isOver && canDrop && (
                <div className="absolute inset-0 bg-green-200 opacity-50 rounded"></div>
            )}
        </div>
    );
});

DropCell.propTypes = {
    ruleId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    cellData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    onDrop: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};