import axios from 'axios';
import { useSession } from './SessionContext';


export const setupAxiosInterceptors = (session, setSession) => {

    // Request interceptor
    axios.interceptors.request.use(
        (config) => {
            if (session?.token) {
                config.headers['Authorization'] = `Bearer ${session.token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
        (response) => {const newInkStatus = response.headers['ink-status']; // use lowercase 'ink-balance'
            if (newInkStatus) {
                setSession((prevSession) => ({
                    ...prevSession,
                    ink: JSON.parse(newInkStatus)
                }));
            }
            return response;  // <--- return the response here
        },
        (error) => {
            if (error.response?.status === 401) {
                // Token is likely expired or invalid
                console.log("Token expired or unauthorized. Clearing session and redirecting to login.");

                // Clear session
                setSession(null);
                localStorage.addItem('LoggedOutBanner', 'true');
            }
            return Promise.reject(error); // Return the error so it can be handled elsewhere if needed
        }
    );
};


