
import {Label, Modal, TextInput, Textarea, Badge} from "flowbite-react";
import React, {useEffect, useState} from "react";
import Markdown from 'react-markdown'
import WorldPage from "../../pages/private/WorldPage";
import axios from "axios";
import ViewProperties from "./ViewProperties";
import ReactMarkdown from "react-markdown";
import {ModalTheme} from "../../themes/ModalTheme";


function ViewAgent({ agentData }) {
    const [openModal, setOpenModal] = useState(false);


    function onCloseModal() {
        setOpenModal(false);
    }


    return (
        <>
            <a href="#"
               className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
               onClick={() => setOpenModal(true)}>
                View
            </a>
            <Modal theme={ModalTheme} dismissible show={openModal} size="5xl" onClose={onCloseModal} popup>
                <Modal.Header>
                    <div className="text-3xl p-1 ml-2.5 text-gray-900 dark:text-white font-bold">
                        {agentData.name}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ReactMarkdown>
                        {agentData.prompt}
                    </ReactMarkdown>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewAgent;
