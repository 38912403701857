// components/AddEditLabel.js
import React, { useState, useEffect, useCallback } from "react";
import {
    Button,
    TextInput,
    Textarea,
    Label,
    Modal,
    Select,
    Alert,
    Badge,
} from "flowbite-react";
import { useForm, Controller } from 'react-hook-form';
import SelectReact from "react-select";
import axios from "axios";
import AddEditProperties from "../../common/AddEditProperties";
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { calculateInheritanceDepth, isCircularInheritance } from '../../../oldComponents/utils/inheritanceUtils';
import {ModalTheme} from "../../../themes/ModalTheme";
import {GiQuillInk} from "react-icons/gi";

const MAX_DEPTH = 7; // Define the same MAX_DEPTH as in backend

function AddEditLabel({ labelList, labelData = null, onSubmit, worldId }) {
    const [isOpen, setIsOpen] = useState(false);
    const [allLabels, setAllLabels] = useState(labelList); // List of all labels
    const [error, setError] = useState(null);
    const [properties, setProperties] = useState([]); // Manage properties state
    const [inheritanceError, setInheritanceError] = useState(null); // For inheritance-related errors
    const [deepInheritanceLabels, setDeepInheritanceLabels] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: labelData ? labelData.name : '',
            description: labelData ? labelData.description : '',
            color: labelData ? labelData.color : 'gray',
            isCategory: labelData ? labelData.isCategory : false,
            inheritFrom: [], // Initialize empty; will set via reset
            imagePrompt: labelData ? labelData.imagePrompt : '',
        },
    });


    const inheritFrom = watch('inheritFrom');

    // Fetch all labels when component mounts or labelList changes
    useEffect(() => {
        setAllLabels(labelList);
    }, [labelList]);


    // utils/inheritanceUtils.js

    const MAX_DEPTH = 7; // Define the same MAX_DEPTH as in backend

    /**
     * Calculates the maximum inheritance depth for a given label.
     * @param {string} labelId - The ID of the label to calculate depth for.
     * @param {Array} allLabels - Array of all label objects.
     * @param {number} depth - Current depth (used for recursion).
     * @param {Set} visited - Set of visited label IDs to prevent infinite loops.
     * @returns {number} - The maximum depth.
     */
    function calculateInheritanceDepth(labelId, allLabels, depth = 0, visited = new Set()) {
        if (visited.has(labelId.toString()) || depth >= MAX_DEPTH) {
            return depth;
        }
        visited.add(labelId.toString());

        const label = allLabels.find(l => l._id.toString() === labelId.toString());
        if (label && label.inheritFrom && label.inheritFrom.length > 0) {
            const depths = label.inheritFrom.map(parentId =>
                calculateInheritanceDepth(parentId.toString(), allLabels, depth + 1, visited)
            );
            return Math.max(...depths);
        }
        return depth;
    }

    /**
     * Checks if adding a parent label would create a circular inheritance.
     * @param {string|null} currentLabelId - The ID of the current label (can be null for new labels).
     * @param {string} targetLabelId - The ID of the target parent label.
     * @param {Array} allLabels - Array of all label objects.
     * @param {Set} visited - Set of visited label IDs to prevent infinite loops.
     * @returns {boolean} - True if circular inheritance is detected, else false.
     */
    function isCircularInheritance(currentLabelId, targetLabelId, allLabels, visited = new Set()) {
        if (currentLabelId && currentLabelId === targetLabelId) {
            return true;
        }
        if (visited.has(targetLabelId.toString())) {
            return false;
        }
        visited.add(targetLabelId.toString());

        const targetLabel = allLabels.find(l => l._id.toString() === targetLabelId.toString());
        if (targetLabel && targetLabel.inheritFrom && targetLabel.inheritFrom.length > 0) {
            for (const parentId of targetLabel.inheritFrom) {
                if (isCircularInheritance(currentLabelId, parentId.toString(), allLabels, visited)) {
                    return true;
                }
            }
        }
        return false;
    }



    // Compute deepInheritanceLabels based on selected inheritFrom
    const computeDeepInheritance = useCallback((inheritFromArray, allLabels) => {
        const visited = new Set();
        const result = [];

        const helper = (labelIds) => {
            for (const labelId of labelIds) {
                if (visited.has(labelId)) {
                    throw new Error('Circular inheritance detected');
                }
                visited.add(labelId);

                const label = allLabels.find((l) => l._id.toString() === labelId.toString());
                if (label) {
                    result.push(label);
                    if (label.inheritFrom && label.inheritFrom.length > 0) {
                        helper(label.inheritFrom.map(id => id.toString()));
                    }
                }
            }
        };

        helper(inheritFromArray.map(option => option.value.toString()));
        return result;
    }, [/* dependencies */]);

    useEffect(() => {
        try {
            if (inheritFrom && inheritFrom.length > 0) {
                const deepLabels = computeDeepInheritance(inheritFrom, allLabels);
                setDeepInheritanceLabels(deepLabels);
                setInheritanceError(null);
            } else {
                setDeepInheritanceLabels([]);
                setInheritanceError(null);
            }
        } catch (err) {
            console.error(err);
            setInheritanceError(err.message);
            setDeepInheritanceLabels([]);
        }

    }, [inheritFrom, allLabels, computeDeepInheritance]);

    // Update properties when deepInheritanceLabels or labelData changes
    useEffect(() => {
        const updateProperties = () => {
            let inheritedProps = [];
            if (deepInheritanceLabels && deepInheritanceLabels.length > 0) {
                deepInheritanceLabels.forEach(label => {
                    if (label.properties) {
                        label.properties.forEach(prop => {
                            inheritedProps.push({
                                id: uuidv4(),
                                name: prop.name,
                                attributes: prop.attributes.map(attr => ({
                                    ...attr,
                                    inherited: true,
                                })),
                                inherited: true,
                            });
                        });
                    }
                });
            }

            // Collect local properties
            const localProps = labelData
                ? labelData.properties.map(prop => ({
                    ...prop,
                    id: uuidv4(),
                    inherited: false,
                    attributes: prop.attributes.map(attr => ({
                        ...attr,
                        inherited: false,
                    })),
                }))
                : [];

            // Identify overridden property names
            const overriddenNames = localProps.map(prop => prop.name);

            // Filter inherited properties to exclude those overridden by local properties
            const filteredInheritedProps = inheritedProps.filter(
                prop => !overriddenNames.includes(prop.name)
            );

            // Merge inherited and local properties
            setProperties([...filteredInheritedProps, ...localProps]);
        };

        updateProperties();
    }, [deepInheritanceLabels, labelData]);

    // Reset form when modal is opened or labelData changes
    useEffect(() => {
        if (isOpen) {
            // Build inheritFrom options based on labelData
            const inheritOptions = labelData && labelData.inheritFrom ?
                labelData.inheritFrom.map(id => {
                    const label = allLabels.find(l => l._id.toString() === id.toString());
                    return label ? { value: label._id.toString(), label: label.name } : { value: id.toString(), label: 'Unknown Label' };
                }) : [];

            reset({
                name: labelData ? labelData.name : '',
                description: labelData ? labelData.description : '',
                color: labelData ? labelData.color : 'gray',
                isCategory: labelData ? labelData.isCategory : false,
                inheritFrom: inheritOptions,
                imagePrompt: labelData ? labelData.imagePrompt : '',
            });

            setError(null);
            setInheritanceError(null);
        }
    }, [isOpen, labelData, reset, allLabels]);

    const onCloseModal = () => {
        setIsOpen(false);
        reset();
        setError(null);
        setInheritanceError(null);
        setProperties([]); // Clear properties when closing
    };

    const handleFormSubmit = useCallback(async (data) => {
        try {
            // Calculate inheritance depth to enforce limit (e.g., 7)
            let maxDepth = 0;
            const selectedParentIds = data.inheritFrom.map(option => option.value.toString());

            for (let parentId of selectedParentIds) {
                const depth = calculateInheritanceDepth(parentId, allLabels);
                if (depth > maxDepth) maxDepth = depth;
            }
            const totalDepth = maxDepth + 1; // Including current label
            if (totalDepth > MAX_DEPTH) {
                setInheritanceError(`Inheritance depth exceeds the maximum allowed depth of ${MAX_DEPTH}.`);
                return;
            }

            // Check for circular inheritance
            for (let parentId of selectedParentIds) {
                if (isCircularInheritance(labelData ? labelData._id.toString() : null, parentId, allLabels)) {
                    setInheritanceError('Circular inheritance detected. Please select different parent labels.');
                    return;
                }
            }

            const payload = {
                name: data.name.trim(),
                description: data.description.trim(),
                color: data.color.value,
                isCategory: data.isCategory,
                inheritFrom: selectedParentIds,
                imagePrompt: data.imagePrompt,
                world: worldId,
                properties: properties
                    .filter(prop => !prop.inherited) // Only send local properties
                    .map(prop => ({
                        name: prop.name,
                        attributes: prop.attributes.map(attr => ({
                            key: attr.key,
                            value: attr.value,
                        })),
                    })),
            };

            let updatedLabel;
            if (labelData) {
                // Update existing label
                updatedLabel = (await axios.put(`${process.env.REACT_APP_API_URL}/label/${labelData._id}`, payload, {
                    // headers: { Authorization: `Bearer ${labelData.token}` }, // Adjust based on your auth setup
                })).data;
            } else {
                // Create new label
                updatedLabel = (await axios.post(`${process.env.REACT_APP_API_URL}/label`, payload, {
                    // headers: { Authorization: `Bearer ${labelData ? labelData.token : ''}` }, // Adjust based on your auth setup
                })).data;
            }

            if (onSubmit) onSubmit(updatedLabel);
            onCloseModal();
        } catch (err) {
            console.error("Error saving label:", err);
            setError('Failed to save label. Please try again.');
        }
    }, [properties, labelData, onSubmit, worldId, allLabels]);

    // Handler to update properties state when changes occur in AddEditProperties
    const handlePropertiesUpdate = useCallback((updatedProperties) => {
        setProperties(updatedProperties);
    }, []);

    // Fetch all labels when component mounts or worldId changes, to keep allLabels up to date
    useEffect(() => {
        const fetchAllLabels = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/label/list`, {
                    params: { worldId },
                    headers: { Authorization: `Bearer ${labelData ? labelData.token : ''}` }, // Adjust based on your auth setup
                });
                setAllLabels(response.data);
            } catch (error) {
                console.error('Failed to fetch labels:', error);
            }
        };

        // If labelList is not up to date, fetch it
        if (!labelList || labelList.length === 0) {
            fetchAllLabels();
        }
    }, [worldId, labelList, labelData]);

    return (
        <>
            {labelData ? (
                <a
                    href="#"
                    className="font-medium text-cyan-600 hover:underline"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(true);
                    }}
                >
                    Edit
                </a>
            ) : (
                <Button onClick={() => setIsOpen(true)}>New Label</Button>
            )}
            <Modal theme={ModalTheme} show={isOpen} size="3xl" onClose={onCloseModal} dismissible>
                <Modal.Header>
                    {labelData ? "Edit Label" : "Add New Label"}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name">Name</Label>
                            <TextInput
                                id="name"
                                placeholder="Label name"
                                {...register('name', { required: 'Name is required' })}
                                className="mt-1 block w-full"
                            />
                            {errors.name && (
                                <span className="text-red-500 text-sm">
                                    {errors.name.message}
                                </span>
                            )}
                        </div>

                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description">Description</Label>
                            <Textarea
                                id="description"
                                placeholder="Enter a description..."
                                rows={4}
                                {...register('description', { required: 'Description is required' })}
                                className="mt-1 block w-full"
                            />
                            {errors.description && (
                                <span className="text-red-500 text-sm">
                                    {errors.description.message}
                                </span>
                            )}
                        </div>

                        {/* Color Selection */}
                        <div>
                            <Label htmlFor="color">Label Color</Label>
                            <Controller
                                control={control}
                                name="color"
                                render={({ field }) => (
                                    <SelectReact
                                        {...field}
                                        id="color"
                                        options={[
                                            { value: 'indigo', label: 'Indigo' },
                                            { value: 'purple', label: 'Purple' },
                                            { value: 'pink', label: 'Pink' },
                                            { value: 'blue', label: 'Blue' },
                                            { value: 'cyan', label: 'Cyan' },
                                            { value: 'dark', label: 'Dark' },
                                            { value: 'light', label: 'Light' },
                                            { value: 'green', label: 'Green' },
                                            { value: 'lime', label: 'Lime' },
                                            { value: 'red', label: 'Red' },
                                            { value: 'teal', label: 'Teal' },
                                            { value: 'yellow', label: 'Yellow' },
                                        ]}
                                        placeholder="Select a color..."
                                        className="mt-1 block w-full"
                                        isClearable
                                    />
                                )}
                            />
                        </div>


                        {/* Inherit From Selection */}
                        <div>
                            {/* Display Inherited Labels */}
                            {deepInheritanceLabels.length > 0 && (
                                <div >
                                    <Label>Inherited Labels</Label>
                                    <div className="flex flex-wrap mt-1">
                                        {deepInheritanceLabels.map(label => (
                                            <Badge key={label._id} color={label.color} className="mr-1 mb-1">
                                                {label.name}
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <Label htmlFor="inheritFrom">Inherit From</Label>
                            <Controller
                                control={control}
                                name="inheritFrom"
                                render={({ field }) => (
                                    <SelectReact
                                        {...field}
                                        isMulti
                                        options={allLabels
                                            .filter(label => label._id.toString() !== (labelData ? labelData._id.toString() : ''))
                                            .map(label => ({ value: label._id.toString(), label: label.name }))}
                                        placeholder="Select labels to inherit from..."
                                        className="mt-1 block w-full"
                                        onChange={(selectedOptions) => {
                                            if (selectedOptions) {
                                                const selectedParentIds = selectedOptions.map(option => option.value.toString());
                                                let hasError = false;
                                                let errorMsg = '';

                                                for (let parentId of selectedParentIds) {
                                                    // Check for circular inheritance
                                                    const currentLabelId = labelData ? labelData._id.toString() : null;
                                                    if (isCircularInheritance(currentLabelId, parentId, allLabels)) {
                                                        const parentLabel = allLabels.find(l => l._id.toString() === parentId);
                                                        errorMsg = parentLabel ? `Circular inheritance detected with label "${parentLabel.name}".` : 'Circular inheritance detected.';
                                                        hasError = true;
                                                        break;
                                                    }

                                                    // Calculate inheritance depth
                                                    const depth = calculateInheritanceDepth(parentId, allLabels);
                                                    const totalDepth = depth + 1; // Including current label
                                                    if (totalDepth > MAX_DEPTH) {
                                                        errorMsg = `Inheritance depth exceeds the maximum allowed depth of ${MAX_DEPTH}.`;
                                                        hasError = true;
                                                        break;
                                                    }
                                                }

                                                if (hasError) {
                                                    setInheritanceError(errorMsg);
                                                } else {
                                                    field.onChange(selectedOptions);
                                                    setInheritanceError(null);
                                                }
                                            } else {
                                                field.onChange([]);
                                                setInheritanceError(null);
                                            }
                                        }}
                                        value={inheritFrom} // Ensure the value is controlled
                                    />
                                )}
                            />
                            {inheritanceError && (
                                <span className="text-red-500 text-sm">
                                    {inheritanceError}
                                </span>
                            )}
                            {error && (
                                <span className="text-red-500 text-sm">
                                    {error}
                                </span>
                            )}
                        </div>

                        {/* Properties Section */}
                        <div>
                            <Label>Properties</Label>
                            <AddEditProperties
                                properties={properties}
                                onPropertyUpdate={handlePropertiesUpdate}
                                disableInherited={false} // Adjust based on your requirements
                            />
                        </div>

                        {/* Error Message */}
                        {error && (
                            <Alert color="failure">
                                <span>
                                    <span className="font-medium">Error!</span> {error}
                                </span>
                            </Alert>
                        )}

                        {/* Submit Button */}
                        <div className="w-full text-right">
                            <Button type="submit">
                                {labelData
                                    ? "Save Changes"
                                    : <>
                                        Add Lore
                                        <GiQuillInk size={16} className="ml-1 translate-x-1" />1
                                    </>
                                }
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

AddEditLabel.propTypes = {
    labelList: PropTypes.array.isRequired, // Array of all labels
    labelData: PropTypes.object, // Existing label data for editing
    onSubmit: PropTypes.func.isRequired, // Callback after successful submit
    worldId: PropTypes.string.isRequired, // ID of the world the label belongs to
};

export default AddEditLabel;
