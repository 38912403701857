// src/components/graph/PlotLineNode.jsx

import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { GrEdit } from 'react-icons/gr';
import PropTypes from 'prop-types';

const PlotLineNode = ({ data }) => {
    const handleEditClick = (e) => {
        e.stopPropagation(); // Prevent triggering other events
        if (data.onEdit) {
            data.onEdit(data.plotLine, false);
        }
    };

    return (
        <div className="relative bg-yellow-200 dark:bg-yellow-600 border border-yellow-500 dark:border-yellow-700 rounded p-2 px-3 min-w-[10rem]">
            {/* Header: Label and Edit Icon */}
            <div className="flex justify-between items-center space-x-2 p-2 dark:text-gray-900">
                <span className="font-semibold">{data.label}</span>
                {/*<GrEdit*/}
                {/*    className="text-gray-500 hover:text-green-500 transition-colors duration-200 transform hover:scale-110 cursor-pointer"*/}
                {/*    onClick={handleEditClick}*/}
                {/*    title="Edit Plot Line"*/}
                {/*    aria-label="Edit Plot Line"*/}
                {/*/>*/}
            </div>

            {/* Handles for Edges */}
            <Handle type="source" position={Position.Right} id="a" className="w-3 h-3 bg-yellow-500 border border-white rounded-full" />
            <Handle type="target" position={Position.Left} id="b" className="w-3 h-3 bg-yellow-500 border border-white rounded-full" />
        </div>
    );
};

PlotLineNode.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        plotLine: PropTypes.object.isRequired, // Ensure plotLine data is provided
        onEdit: PropTypes.func, // Function to trigger edit
    }).isRequired,
};

export default PlotLineNode;
