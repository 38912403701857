// src/pages/MyStoriesPage.js

import React from 'react';
import PropTypes from 'prop-types';
import { useSession } from "../../context/SessionContext";
import CustomShelf from "../../components/story/CustomShelf";
import CustomAtlas from "../../components/world/CustomAtlas";

function MyWorldsPage() {
    const { session } = useSession();

    return (
        <>

            <div className={"flex flex-row justify-center items-center mx-auto"}>
                {session.user.permissionLevel >= 3 &&
                    <a href={`/world/new`} className="text-blue-500 hover:underline">New World</a>
                }
                <div className={"text-2xl font-bold px-6"}>My Worlds</div>
                {session.user.permissionLevel >= 3 &&
                    <a href={`/world/wizard`} className="text-blue-500 hover:underline">World Wizard</a>
                }
            </div>


            <div className="container mx-auto p-4 pb-12">
                <CustomAtlas
                    session={session}
                    expandedView={true}
                    includePublic={false}
                    autoSearch={true}
                />
            </div>
        </>
    );
}

MyWorldsPage.propTypes = {
    // Define any props if needed
};

export default MyWorldsPage;
