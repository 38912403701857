import React, {useEffect, useRef, useState} from 'react';
import { Label, TextInput, Textarea, Badge, Button } from "flowbite-react";
import axios from 'axios';
import AddEditImage from "../common/AddEditImage";
import {useNavigate} from "react-router-dom";
import TagsInput from "../common/TagsInput";
import {useSession} from "../../context/SessionContext";
import {GiQuillInk} from "react-icons/gi";

function WorldNew() {
    const addEditImageRef = useRef();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const { session } = useSession();
    const navigate = useNavigate();
    const [newWorld, setNewWorld] = useState({
        name: '',
        description: '',
        isTemplate: false,
        tags: [],
        setting: '',
        inheritFrom: [],
    });

    const [entitledWorlds, setEntitledWorlds] = useState([]);

    // Fetch worlds the user is entitled to inherit from
    useEffect(() => {
        async function fetchEntitledWorlds() {
            try {
                const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/world`, {
                });

                const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world`, {
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });

                const [response1, response2] = await Promise.all([promise1, promise2]);

                const combinedData = [...response1.data, ...response2.data];
                setEntitledWorlds(combinedData);
            } catch (error) {
                console.error('Failed to fetch entitled worlds', error);
            }
        }
        fetchEntitledWorlds();

    }, []);


    const handleImageSave = async (parent) => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.setParentData(parent);
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving:', err);
            //setError('Failed to save changes.');
        }
    };

    const handleChange = (field, value) => {
        setNewWorld((prev) => ({ ...prev, [field]: value }));
    };

    // Handle story submission
    const handleSubmit = async () => {
        setIsProcessing(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/world`, newWorld);
        await handleImageSave(response.data);
        setIsProcessing(false);
        navigate(`/world/${response.data._id}`);
    };

    return (

        <div className={"pt-8 px-6 pb-16"}>
            {isProcessing && (
                <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-600">Processing your world...</span>
                </div>
            )}

            <div className=''>
                {/* Flex container for the name field and save button */}
                <div className="flex items-center justify-between mb-4">
                    {/* World Name */}
                    <div className="flex-grow">
                        <Label value='Name'/>
                        <TextInput
                            placeholder="Name this world"
                            value={newWorld.name}
                            onChange={(event) => handleChange('name', event.target.value)}
                        />
                    </div>
                </div>

                {/* World Description */}
                <div className='mt-4'>
                    <Label value='Description'/>
                    <Textarea
                        placeholder="What Is the main idea or purpose behind this world"
                        theme={TextAreaTheme}
                        value={newWorld.description}
                        rows={3}
                        onChange={(event) => handleChange('description', event.target.value)}
                    />
                </div>

                {/* Inherited Worlds */}
                <div className='mt-4'>
                    <Label value="Inherited From Worlds"/>
                    {newWorld.inheritFrom && newWorld.inheritFrom.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                            {newWorld.inheritFrom.map((inheritedWorld) => {
                                return inheritedWorld ? (
                                    <Badge key={inheritedWorld._id} className="cursor-pointer">
                                        {inheritedWorld._id}
                                    </Badge>
                                ) : null;
                            })}
                        </div>
                    ) : (
                        <p className="text-gray-500">No inherited worlds</p>
                    )}
                </div>

                {/* Story Tags */}
                <div className='mt-4'>
                    <Label htmlFor="tags" value="Tags"/>
                    <TagsInput
                        tags={newWorld.tags || []}
                        onChange={(tags) => handleChange('tags', tags)}
                        placeholder="Add tag and press Enter"
                    />
                </div>

                {/* Responsive layout for World Setting and Image */}
                <div className="flex flex-col lg:flex-row mt-4 gap-4">
                    {/* World Setting */}
                    <div className="flex-1 order-2 lg:order-1">
                        <Label value='Setting'/>
                        <Textarea
                            placeholder="Start to give this world some flavour, describe its general setting"
                            theme={TextAreaTheme}
                            value={newWorld.setting}
                            rows={14}
                            onChange={(event) => handleChange('setting', event.target.value)}
                        />
                    </div>

                    {/* World Cover Art */}
                    <div className='w-full lg:w-96 order-1 lg:order-2'>
                        <Label value='Cover Art'/>
                        <AddEditImage
                            ref={addEditImageRef}
                            parentData={null}
                            placeholder={1}
                        />
                    </div>


                </div>
            </div>

            <div className="w-full">
                <Button onClick={handleSubmit}>Create New World <GiQuillInk size={16} className={"ml-1 translate-x-1"}/>1</Button>
            </div>

        </div>
    );
}

export default WorldNew;


const TextAreaTheme = {
    "base": "block w-full rounded-lg border text-sm disabled:cursor-not-allowed px-3 py-2 disabled:opacity-50",

    "colors": {
        "gray": "border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "info": "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
        "failure": "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500",
        "warning": "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
        "success": "border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500"
    },

    "withShadow": {
        "on": "shadow-sm dark:shadow-sm-light",
        "off": ""
    }
}
