// src/components/NewStory/StoryWizardForm/StoryWizardForm.js

import React from 'react';
import { WizardProvider, useWizard } from './WizardContext';
import StepsConfig from './StepsConfig';
import SummaryPanel from './SummaryPanel';
import { FaCheckCircle } from 'react-icons/fa';

const WizardContent = ({ onSubmit }) => {
    const { currentStep } = useWizard();
    const StepComponent = StepsConfig[currentStep]?.component;

    return (
        <div id="wizard-container" className="space-y-4">
            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <div className={"text-2xl font-bold pr-6 dark:text-gray-200"}>New Story Wizard</div>
            </div>
            {/* Step Indicators */}
            <div className="flex justify-center mb-4">
                <div className="flex space-x-2">
                    {StepsConfig.map((step, index) => (
                        <div
                            key={`step-indicator-${step.id}`}
                            className={`w-8 h-8 flex items-center justify-center rounded-full ${
                                currentStep === index
                                    ? 'bg-blue-500 text-white'
                                    : currentStep > index
                                        ? 'bg-green-500 text-white'
                                        : 'bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-900'
                            }`}
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
            </div>

            {/* Step Content */}
            <div>
                {StepComponent && <StepComponent onSubmit={onSubmit}/>}
            </div>

            {/* Real-time Summary Panel */}
            <SummaryPanel/>
        </div>
    );
};

// Main Wizard Form Component
const StoryWizardForm = ({onSubmit}) => {
    return (
        <div className={"px-6"}>
            <WizardProvider>
                <WizardContent onSubmit={onSubmit}/>
            </WizardProvider>
        </div>
    );
};

export default StoryWizardForm;
