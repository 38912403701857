// src/components/GoodbyePage.jsx

import React from "react";
import { Link } from "react-router-dom";

function GoodbyePage() {
    return (
        <div className="container mx-auto p-4 text-center">
            <h1 className="text-3xl font-bold mb-4">Goodbye!</h1>
            <p>Your account has been successfully deleted.</p>
            <Link to="/" className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded">
                Return to Home
            </Link>
        </div>
    );
}

export default GoodbyePage;
