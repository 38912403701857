import React, {useEffect, useState, useCallback} from "react";
import {Breadcrumb, Tabs} from "flowbite-react";
import { LuLayoutDashboard, LuBadgeInfo } from "react-icons/lu";
import { SlPicture } from "react-icons/sl";
import { RiGlobeFill } from "react-icons/ri";
import { PiScrollLight } from "react-icons/pi";
import { VscTypeHierarchy } from "react-icons/vsc";
import { BsTags, BsRobot } from "react-icons/bs";
import { MdOutlineImagesearchRoller, MdImagesearchRoller } from "react-icons/md";
import { GrConfigure } from "react-icons/gr";
import { PiBookmarksSimpleFill, PiBooksFill } from "react-icons/pi";
import {IoBookmarksOutline, IoBookSharp, IoBookOutline, IoHomeSharp} from "react-icons/io5";
import {IoMdShare} from "react-icons/io";
import ListLore from "../../components/world/lore/ListLore";
import ListAgents from "../../components/world/agent/ListAgents";
import WorldOverview from "../../components/world/WorldOverview";
import ShareWorld from "../../components/share/ShareWorld";
import {useNavigate, useParams} from "react-router-dom";
import {useSession} from "../../context/SessionContext";
import axios from "axios";
import ListLabels from "../../components/world/label/ListLabels";
import {debounce} from "lodash";
import MyAdventuresPage from "./MyAdventuresPage";
import MyStoriesPage from "./MyStoriesPage";
import {TabsTheme} from "../../themes/TabsTheme";
import ListTilesets from "../../components/world/tiles/ListTilesets";


function WorldPage() {
    const {id } = useParams();
    const [world, setWorld] = useState(null);
    const { session, setSession } = useSession();
    const navigate = useNavigate();

    const handleResize = debounce(() => {
        // handle resize logic
    }, 200); // Debounce by 100ms

    useEffect(() => {
        const fetchWorld = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/world/${id}`, {
                    headers: {
                        Authorization: `Bearer ${session.token}`
                    }
                });
                if (response.data && JSON.stringify(response.data) !== JSON.stringify(world)) {
                    setWorld(response.data);
                }
            } catch (error) {
                console.error('Failed to fetch world:', error);
                console.log(axios.defaults.headers.common);
            }
        }
        if (id && session.token) fetchWorld();
    }, [id, session?.token]);

    useEffect(() => {
        if (world && session.lastWorld !== world) {
            setSession((prevSession) => ({
                ...prevSession,
                lastWorld: world,
            }));
        }
    }, [world, session.lastWorld]); // Depend on `lastWorld` to avoid unnecessary updates


    if (!session || !session.token) {
        return <div>Loading session...</div>;
    }

    if (!world) {
        return <div>Loading world...</div>;
    }

    return (
        <div>
            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <RiGlobeFill size={24}/>
                <div className={"text-2xl font-bold pl-1 pr-6"}>World - "{world.name}"</div>
                {
                    world.isPublished && <div className={"text-lg font-semibold text-gray-700 dark:text-gray-900 bg-yellow-400 dark:bg-yellow-600 py-1 px-4 rounded-lg"}>
                        Published
                    </div>
                }
            </div>
            <Tabs aria-label="Default tabs" style="underline" >
                <Tabs.Item active title="Overview" icon={SlPicture}>
                    <WorldOverview world={world}/>
                </Tabs.Item>
                <Tabs.Item title="Lore" icon={LuBadgeInfo}>
                    <ListLore world={world}/>
                </Tabs.Item>
                <Tabs.Item title="Labels" icon={BsTags}>
                    <ListLabels world={world}/>
                </Tabs.Item>
                <Tabs.Item title="Rules" icon={PiScrollLight}>
                    {/*<ListRules world={world} />*/}
                </Tabs.Item>
                <Tabs.Item  title="Tilesets" icon={MdOutlineImagesearchRoller}>
                    <ListTilesets world={world}/>
                </Tabs.Item>
                <Tabs.Item title="Agents" icon={BsRobot}>
                    <ListAgents world={world}/>
                </Tabs.Item>
                <Tabs.Item  title="Stories" icon={IoBookOutline}>
                    <MyStoriesPage world={world}/>
                </Tabs.Item>
                {world.entitlement.grantLevel >= 3 && (
                    <Tabs.Item title="Sharing" icon={IoMdShare}>
                        <ShareWorld world={world}/>
                    </Tabs.Item>
                )}
            </Tabs>
        </div>
    );
}

export default WorldPage;
