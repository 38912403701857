//
// import {Button, Select, Spinner, Label, Modal, TextInput, Textarea, ToggleSwitch} from "flowbite-react";
// import React, {useEffect, useRef, useState} from "react";
// import AddEditImage from "../../common/AddEditImage";
// import {useParams} from "react-router-dom";
// import axios from "axios";
//
// import AddEditProperties from "../../common/AddEditProperties";
// import {TextAreaTheme} from "../../../themes/TextAreaTheme";
// import {ModalTheme} from "../../../themes/ModalTheme";
// import {GiQuillInk} from "react-icons/gi";
//
//
// function AddEditCharacter({ charData, onSubmit }) {
//     const { id } = useParams();
//     const [openModal, setOpenModal] = useState(false);
//     const [char, setChar] = useState(charData ? charData : {name: '', story: id});
//     const [isNew, setIsNew] = useState(!charData);
//     const addEditImageRef = useRef();
//
//     const handleImageSave = async () => {
//         try {
//             // Trigger the commit action on AddEditImage
//             await addEditImageRef.current.commit();
//         } catch (err) {
//             console.error('Error saving:', err);
//             //setError('Failed to save changes.');
//         }
//     };
//
//     function onCloseModal() {
//         setOpenModal(false);
//         if (isNew) setChar(oldLore => ({name: '', story: id}));
//     }
//
//
//     const handleSubmit = async (event) => {
//         // event.preventDefault(); // Uncomment this if you're submitting a form
//         try {
//             let updatedChar;
//
//             if (isNew) {
//                 updatedChar = (await axios.post(`${process.env.REACT_APP_API_URL}/character`, char)).data;
//             } else {
//                 updatedChar = (await axios.put(`${process.env.REACT_APP_API_URL}/character/${char._id}`, char)).data;
//             }
//
//             // Set the updated character data in the state
//             setChar(updatedChar);
//
//             await handleImageSave();
//
//         } catch (error) {
//             console.error(error);
//         }
//         onCloseModal();
//         if (onSubmit) onSubmit();
//     };
//
//
//     const handlePropertiesUpdate = (newProperties) => {
//         //setProperties(newProperties);
//         setChar({...char, properties: newProperties});
//     };
//
//     if (!char) {
//         return <div>Loading...</div>;
//     }
//
//     return (
//         <>
//             {isNew ?
//                 (
//                     <Button onClick={() => setOpenModal(true)}>New Character</Button>
//                 ) : (
//                     <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
//                        onClick={() => setOpenModal(true)}>
//                         Edit
//                     </a>
//                 )}
//             <Modal theme={ModalTheme} dismissible show={openModal} size="3xl" onClose={onCloseModal} popup>
//                 <Modal.Header>
//                     {isNew ?
//                         (<span className="text-2xl p-1 ml-2.5 font-bold text-gray-900 dark:text-white">New Character</span>):
//                         (<span className="text-2xl p-1 ml-2.5 font-bold text-gray-900 dark:text-white">Edit Character</span>)}
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
//                         <div className="space-y-6">
//                             <div>
//                                 <div className="mb-2 block">
//                                     <Label htmlFor="name" value="Name"/>
//                                 </div>
//                                 <TextInput
//
//                                     id="name"
//                                     placeholder="name"
//                                     value={char.name}
//                                     onChange={(e) => setChar({...char, name: e.target.value})}
//                                     required
//                                 />
//                             </div>
//                             <div>
//                                 <div className="mb-2 block">
//                                     <Label htmlFor="affiliation" value="Affiliation (to protagonist)"/>
//                                 </div>
//                                 <Select
//                                     id="affiliation"
//                                     placeholder="affiliation"
//                                     value={char.affiliation || 0}
//                                     onChange={(e) => setChar({...char, affiliation: e.target.value})}
//                                     required
//                                 >
//                                     <option value={5}>Confidant</option>
//                                     <option value={4}>Friend</option>
//                                     <option value={3}>Companion</option>
//                                     <option value={2}>Acquaintance</option>
//                                     <option value={1}>Favorable</option>
//                                     <option value={0}>Neutral</option>
//                                     <option value={-1}>Unfavorable</option>
//                                     <option value={-2}>Distant</option>
//                                     <option value={-3}>Rival</option>
//                                     <option value={-4}>Enemy</option>
//                                     <option value={-5}>Nemesis</option>
//                                 </Select>
//                             </div>
//                             <AddEditImage parentData={char} ref={addEditImageRef} placeholder={4}/>
//                             <AddEditProperties properties={char.properties ?? []}
//                                                onPropertyUpdate={handlePropertiesUpdate}/>
//                         </div>
//
//                         <div className="space-y-2">
//                             <Label htmlFor="description" value="Description"/>
//                             <Textarea
//                                 id="description"
//                                 placeholder="description"
//                                 value={char.description}
//                                 onChange={(e) => setChar({...char, description: e.target.value})}
//                                 required
//                                 theme={TextAreaTheme}
//                                 rows={8}
//                                 className="w-full h-full"
//                             />
//                         </div>
//
//                         <div className="w-full">
//                             {isNew ?
//                                 (<Button type="submit" onClick={handleSubmit}>Add<GiQuillInk size={16} className="ml-1 translate-x-1" />1</Button>) :
//                                 (<Button type="submit" onClick={handleSubmit}>Save</Button>)}
//                         </div>
//                     </div>
//                 </Modal.Body>
//
//             </Modal>
//         </>
//     );
// }
//
//
// export default AddEditCharacter;
