import React, {useEffect, useState, useCallback, useContext} from "react";
import {
    Breadcrumb,
    Button,
    Navbar,
    NavbarBrand,
    NavbarLink,
} from "flowbite-react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { PiSignInFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSession } from '../../context/SessionContext';
import Breadcrumbs from "./Breadcrumbs";
import {debounce} from "lodash";
import UserProfileAvatar from "./UserProfileAvatar";
import DarkModeContext from "../../context/DarkModeContext";
import DarkModeToggle from "../common/DarkModeToggle";

function MyNavbar() {
    //const [user, setUser] = useState(null);
    const { session, setSession } = useSession();
    const navigate = useNavigate();
    const [navbarOpen, setNavbarOpen] = useState(false);
    const { darkMode } = useContext(DarkModeContext);

    const handleResize = debounce(() => {
        // handle resize logic
    }, 100); // Debounce by 100ms

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            try {
                const accessToken = codeResponse.access_token;
                // Send the access token to the backend to authenticate the user
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth?access_token=${accessToken}`);
                const sessionData = await response.data // get user session

                // Now you can manage the user session on the client-side
                setSession(sessionData);
                // if we are on the page /lockscreen  we should navigate to /home
                if (window.location.pathname.startsWith('/lockscreen')) {
                    navigate('/home');
                }
            } catch (error) {
                console.error("Error during authentication:", error);
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });


    const toggleNavbar = useCallback(() => {
        setNavbarOpen(prev => !prev);
    }, []);

    if (session) {
        // Authenticated User
        return (
            <div className={"flex justify-between"}>
                <a className={"flex sm:text-xl font-semibold pt-1 pl-1"} href="/home">
                    {darkMode ?
                        <img src={"/logo-dark.png"} className="z-10 w-10 h-10 sm:w-12 sm:h-12" alt="Logo"/>
                        :
                        <img src={"/logo-light.png"} className="z-10 w-10 h-10 sm:w-12 sm:h-12" alt="Logo"/>
                    }
                    <div className={"pl-2 pt-4"}>
                        MY ADVENTURES
                    </div>
                    <div className={'font-bold text-yellow-600 dark:text-yellow-500 text-xs sm:text-sm pt-5 pl-2 sm:translate-y-0.5'}>BETA</div>

                    {/*<Breadcrumbs labels={false}/>*/}
                </a>
                <div className={"p-2 pr-4"}>
                    <UserProfileAvatar absolute={false}/>
                </div>
            </div>
        );
    }

    // Not Authenticated
    return (
        <>
            {/*<DarkModeToggle/>*/}
            <Navbar theme={navbartheme} fluid rounded className="z-10 font-raleway">
                <div className="flex items-center justify-between w-full p-1">
                    <NavbarBrand href="/" className="text-3xl font-semibold">
                        {darkMode ?
                            <img src={"/logo-dark.png"} className="z-10 w-16 h-16 -translate-x-3 -translate-y-1" alt="Logo"/>
                            :
                            <img src={"/logo-light.png"} className="z-10 w-16 h-16 -translate-x-3 -translate-y-1" alt="Logo"/>
                        }
                        MY ADVENTURES
                    </NavbarBrand>
                    <div className={'font-bold text-yellow-600 dark:text-yellow-500 text-md pt-2 pl-2'} >BETA</div>

                    <div className="hidden md:flex flex-1 justify-end space-x-6 list-none">
                        <NavbarLink href="/discover" className="block md:inline-block" active>
                            Discover
                        </NavbarLink>
                        <NavbarLink href="/news" className="block md:inline-block" active>
                            News
                        </NavbarLink>
                        <NavbarLink href="/pricing/subscriptions" className="block md:inline-block" active>
                            Pricing
                        </NavbarLink>
                        <NavbarLink href="https://discord.gg/CF64D9g84E" target="_blank" rel="noopener noreferrer" className="block md:inline-block" active>
                            Discord
                        </NavbarLink>
                        <NavbarLink href="/guide/Overview" className="hidden lg:inline-block" active>
                            Documentation
                        </NavbarLink>
                        <NavbarLink onClick={login} href="#" className="block md:inline-block" active>
                            <div className="inline-flex items-center">
                                <span>Sign In</span>
                                <PiSignInFill className="ml-2" size={24} />
                            </div>
                        </NavbarLink>
                    </div>

                    <div className="md:hidden">
                        <button
                            className="text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                            onClick={toggleNavbar}
                        >
                            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d={navbarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className={`md:hidden ${navbarOpen ? "block" : "hidden"} w-full text-center list-none`}>
                    <NavbarLink href="/discover" className="block md:inline-block" active>
                        Discover
                    </NavbarLink>
                    <NavbarLink href="/news" className="block md:inline-block" active>
                        News
                    </NavbarLink>
                    <NavbarLink href="/pricing/subscriptions" className="block md:inline-block" active>
                        Pricing
                    </NavbarLink>
                    <NavbarLink href="https://discord.gg/CF64D9g84E" className="block md:inline-block" active>
                        Discord
                    </NavbarLink>
                    <NavbarLink href="/guide/Overview" className="block md:inline-block" active>
                        Documentation
                    </NavbarLink>
                    <NavbarLink onClick={login} href="#" className="block md:hidden mt-2" active>
                        <div className="flex justify-center items-center">
                            <span>Sign In</span>
                            <PiSignInFill className="ml-2" size={24} />
                        </div>
                    </NavbarLink>

                </div>
            </Navbar>
        </>
    );
}

export default MyNavbar;


const navbartheme = {
    "root": {
        "base": "bg-white px-2 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4",
        "rounded": {
            "on": "rounded",
            "off": ""
        },
        "bordered": {
            "on": "border",
            "off": ""
        },
        "inner": {
            "base": "mx-auto flex flex-wrap items-center justify-between",
            "fluid": {
                "on": "",
                "off": "container"
            }
        }
    },
    "brand": {
        "base": "flex items-center"
    },
    "collapse": {
        "base": "w-full md:block md:w-auto",
        "list": "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
        "hidden": {
            "on": "hidden",
            "off": ""
        }
    },
    "link": {
        "base": "block py-2 pl-3 pr-4 md:p-0 text-sm lg:text-base ",
        "active": {
            "on": "bg-cyan-700 text-white dark:text-gray-300 md:bg-transparent md:text-cyan-700",
            "off": "border-b border-gray-100  text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
        },
        "disabled": {
            "on": "text-gray-400 hover:cursor-not-allowed dark:text-gray-600",
            "off": ""
        }
    },
    "toggle": {
        "base": "inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden",
        "icon": "h-6 w-6 shrink-0"
    }
}


