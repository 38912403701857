// src/hooks/useSceneStreaming.js
import { useRef, useEffect, useCallback } from 'react';
import { tryParseCompleteMessage, tryExtractFields } from '../common/streamingUtils';
import { getEventSource, closeEventSource } from './EventSourceSingleton';

/**
 * Custom hook to manage scene streaming using Server-Sent Events (SSE).
 * It utilizes a singleton pattern to ensure only one EventSource instance exists.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.sessionToken - The session token for authentication.
 * @param {React.MutableRefObject<Function>} params.onAppendCompletedParagraphsRef - Ref to callback to append completed paragraphs.
 * @param {React.MutableRefObject<Function>} params.onAppendPartialDataRef - Ref to callback to append partial data.
 * @param {React.MutableRefObject<Function>} params.onClearPartialDataRef - Ref to callback to clear partial data.
 * @param {React.MutableRefObject<Function>} params.onLoadNewSceneRef - Ref to callback to load a new scene.
 * @param {React.MutableRefObject<Function>} params.onStopStreamingRef - Ref to callback to stop streaming.
 * @returns {Object} - An object containing startStreaming and stopStreaming functions.
 */
const useSceneStreaming = ({
                               sessionToken,
                               onAppendCompletedParagraphsRef,
                               onAppendPartialDataRef,
                               onClearPartialDataRef,
                               onLoadNewSceneRef,
                               onStopStreamingRef,
                           }) => {
    const eventSourceRef = useRef(null);

    /**
     * Stops the streaming by closing the EventSource and invoking the stop handler.
     */
    const stopStreaming = useCallback(() => {
        closeEventSource();
        if (onStopStreamingRef.current) {
            onStopStreamingRef.current();
        }
    }, [onStopStreamingRef]);

    /**
     * Starts streaming for a given scene ID by initializing the EventSource with listeners.
     * @param {string} sid - The scene ID to stream.
     */
    const startStreaming = useCallback((sid) => {
        if (!sid) {
            console.error('No sceneId provided for streaming.');
            return;
        }

        // Close any existing connection before starting a new one
        if (eventSourceRef.current) {
            closeEventSource();
            //console.log("Existing streaming connection closed before starting a new one.");
        }

        let accumulatedData = '';
        let accumulatedText = '';
        let partialData = {};

        //console.log("Start Streaming Scene: ", sid);

        const url = `${process.env.REACT_APP_API_URL}/page/${sid}/generate-next?access_token=${sessionToken}`;
        //console.log("Connecting to SSE URL:", url);

        // Define event listeners, accessing handler functions via refs
        const listeners = {
            'para': (event) => {
                //console.log("Received 'para' event:", event.data);
                accumulatedData += event.data.replace(/^paragraphs:/, '').replace(/^\[|\]$/g, '');
                const { parsedObjects, remainingData } = tryParseCompleteMessage(accumulatedData);

                if (parsedObjects && onAppendCompletedParagraphsRef.current) {
                    onAppendCompletedParagraphsRef.current(parsedObjects);
                    partialData = {};
                    if (onClearPartialDataRef.current) {
                        onClearPartialDataRef.current();
                    }
                }

                accumulatedData = remainingData;
                tryExtractFields(accumulatedData, partialData);
                if (onAppendPartialDataRef.current) {
                    onAppendPartialDataRef.current(partialData);
                }
            },
            'text': (event) => {
                //console.log("Received 'text' event:", event.data);
                accumulatedText += event.data;

                const sentenceEndRegex = /[.!?]\n/;
                const sentences = accumulatedText.split(sentenceEndRegex);
                accumulatedText = sentences.pop();

                sentences.forEach((sentence) => {
                    if (sentence) {
                        if (onAppendCompletedParagraphsRef.current) {
                            onAppendCompletedParagraphsRef.current([{ text: sentence }]);
                        }
                    }
                });

                partialData.text = accumulatedText;
                if (onAppendPartialDataRef.current) {
                    onAppendPartialDataRef.current(partialData);
                }
            },
            'scene': (event) => {
                ///console.log("Received 'scene' event:", event.data);
                const newSceneId = JSON.parse(event.data).sceneId;

                if (onLoadNewSceneRef.current) {
                    onLoadNewSceneRef.current(newSceneId);
                }

            },
            'end': () => {
                //console.log("Received 'end' event");
                if (accumulatedText && onAppendCompletedParagraphsRef.current) {
                    onAppendCompletedParagraphsRef.current([{ text: accumulatedText }]);
                }

                const { parsedObjects } = tryParseCompleteMessage(accumulatedData);
                if (parsedObjects && onAppendCompletedParagraphsRef.current) {
                    onAppendCompletedParagraphsRef.current(parsedObjects);
                }

                if (onClearPartialDataRef.current) {
                    onClearPartialDataRef.current();
                }

                stopStreaming();
            },
        };

        // Initialize the singleton EventSource with the URL and listeners
        eventSourceRef.current = getEventSource(url, listeners);
    }, [
        sessionToken,
        onAppendCompletedParagraphsRef,
        onAppendPartialDataRef,
        onClearPartialDataRef,
        onLoadNewSceneRef,
        stopStreaming,
    ]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            //console.log("Component unmounted, closing streaming connection.");
            stopStreaming();
        };
    }, [stopStreaming]);

    return { startStreaming, stopStreaming };
};

export default useSceneStreaming;
