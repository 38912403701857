import React from "react";
import { Card } from "flowbite-react";
import {CardTheme} from "../../../themes/CardTheme";

function Error404() {
    return (
        <div className="flex justify-center items-center p-6 min-h-[calc(100vh-10rem)]"> {/* Adjust min-h to account for the footer */}
            <Card theme={CardTheme}>
                <div className="overflow-visible">
                    <img
                        className="w-full h-auto max-h-[calc(100vh-10rem)] object-contain" // max-h calculation for viewport height
                        src="https://storage.googleapis.com/myadventures-images/Error404-PageNotFound.webp"
                        alt="Error 404 Page Not Found"
                    />
                </div>
            </Card>
        </div>
    );
}

export default Error404;

