// src/utils/inheritanceUtils.js

/**
 * Helper function to calculate the inheritance depth of a label.
 * @param {string} labelId - The ID of the label to calculate depth for.
 * @param {Object[]} allLabels - Array of all labels with their inheritFrom relationships.
 * @param {number} currentDepth - The current depth in recursion.
 * @param {Set} visited - Set of visited label IDs to prevent infinite loops.
 * @returns {number} - The maximum inheritance depth.
 */
export const calculateInheritanceDepth = (labelId, allLabels, currentDepth = 0, visited = new Set()) => {
    if (currentDepth > 10) return 10; // Prevent excessive recursion
    if (visited.has(labelId)) return currentDepth; // Prevent circular inheritance
    visited.add(labelId);

    const label = allLabels.find(l => l.value === labelId);
    if (!label || !label.inheritFrom || label.inheritFrom.length === 0) {
        return currentDepth;
    }

    let maxDepth = currentDepth;
    label.inheritFrom.forEach(parentId => {
        const depth = calculateInheritanceDepth(parentId, allLabels, currentDepth + 1, visited);
        if (depth > maxDepth) maxDepth = depth;
    });

    return maxDepth;
};

/**
 * Helper function to check if adding an inheritance would create a circular relationship.
 * @param {string} currentLabelId - The ID of the current label.
 * @param {string} newParentId - The ID of the label to inherit from.
 * @param {Object[]} allLabels - Array of all labels with their inheritFrom relationships.
 * @returns {boolean} - True if circular inheritance would occur, else false.
 */
export const isCircularInheritance = (currentLabelId, newParentId, allLabels) => {
    // Check if currentLabelId is in the ancestry of newParentId
    const checkRecursion = (labelId, targetId, allLabels, visited = new Set()) => {
        if (labelId === targetId) return true;
        if (visited.has(labelId)) return false;
        visited.add(labelId);
        const label = allLabels.find(l => l.value === labelId);
        if (!label || !label.inheritFrom) return false;
        for (let parentId of label.inheritFrom) {
            if (checkRecursion(parentId, targetId, allLabels, visited)) return true;
        }
        return false;
    };

    return checkRecursion(newParentId, currentLabelId, allLabels);
};
