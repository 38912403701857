import React, { useState } from "react";
import {
    Button,
    TextInput,
    Label,
    Modal,

} from "flowbite-react";
import PropTypes from 'prop-types';
import { ModalTheme } from "../../../../themes/ModalTheme";
import Select from "react-select";

// Selection Modal Component
export const RuleSelectionModal = ({ show, onClose, onSelect, tileGroups, tileImages }) => {
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedTileIndex, setSelectedTileIndex] = useState('');

    const handleGroupSelect = () => {
        if (selectedGroupId) {
            onSelect('group', selectedGroupId);
            setSelectedGroupId(null);
            onClose();
        } else {
            alert('Please select a group.');
        }
    };

    const handleTileSelect = () => {
        const tileIndex = parseInt(selectedTileIndex);
        if (!isNaN(tileIndex) && tileIndex >= 0 && tileIndex < tileImages.length) {
            onSelect('tile', tileIndex);
            setSelectedTileIndex('');
            onClose();
        } else {
            alert('Please enter a valid tile index.');
        }
    };

    return (
        <Modal theme={ModalTheme} show={show} onClose={onClose}>
            <Modal.Header>Assign to Group or Tile</Modal.Header>
            <Modal.Body>
                <div className="space-y-4">
                    <div onClick={(e) => e.stopPropagation()}>
                        <Label>Select a Group</Label>
                        <Select
                            options={tileGroups.map(group => ({ value: group._id, label: group.name }))}
                            value={tileGroups.find(group => group._id === selectedGroupId) ? { value: selectedGroupId, label: tileGroups.find(group => group._id === selectedGroupId).name } : null}
                            onChange={(selected) => setSelectedGroupId(selected ? selected.value : null)}
                            isClearable
                            placeholder="Select Group"
                        />
                        <Button type="button" className="mt-2" onClick={handleGroupSelect} disabled={!selectedGroupId}>
                            Assign to Group
                        </Button>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Label>Or Enter a Tile Index</Label>
                        <TextInput
                            type="number"
                            min="0"
                            max={tileImages.length - 1}
                            value={selectedTileIndex}
                            onChange={(e) => setSelectedTileIndex(e.target.value)}
                            placeholder={`0 - ${tileImages.length - 1}`}
                        />
                        <Button type="button" className="mt-2" onClick={handleTileSelect} disabled={selectedTileIndex === ''}>
                            Assign to Tile
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

RuleSelectionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    tileGroups: PropTypes.array.isRequired,
    tileImages: PropTypes.array.isRequired,
};

