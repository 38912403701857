import React, { useEffect, useState } from "react";
import { GiQuillInk } from "react-icons/gi";

function InkBalanceDisplay({ session }) {
    const [inkBalance, setInkBalance] = useState(session.ink.availableBalance || 0);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // If the balance changes, trigger the animation
        const newBalance = session.ink.availableBalance || 0;
        if (newBalance !== inkBalance) {
            setAnimate(true); // Trigger animation
            // Update the ink balance after the animation is queued
            setTimeout(() => setInkBalance(newBalance), 150); // 150ms offset to allow animation
        }
    }, [session.ink.availableBalance, inkBalance]);

    // Remove the animation class after completing
    useEffect(() => {
        if (animate) {
            const timeout = setTimeout(() => setAnimate(false), 700); // Matches animation duration
            return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [animate]);

    return (
        <div className={`flex font-bold text-gray-700 dark:text-gray-300`}>
            <GiQuillInk
                className={`mt-2 translate-x-0.5  ${
                    animate ? "transition-transform transform scale-125 text-blue-600 dark:text-yellow-400" : ""
                }`}
                size={18}
            />
            <div
                className={`mr-3 mt-2 ${
                    animate ? "transition-transform transform scale-125 text-blue-600 dark:text-yellow-400" : ""
                }`}
            >
                {inkBalance.toLocaleString()}
            </div>
        </div>
    );
}

export default InkBalanceDisplay;