// src/components/NewStory/StoryWizardForm/steps/Step7Review.js

import React from 'react';
import { useWizard } from '../WizardContext';
import {
    predefinedStoryTypes,
    predefinedThemes,
    predefinedStoryConflicts,
    predefinedLocations,
    predefinedTone,
    predefinedIncitingIncident,
    predefinedSymbolism,
    predefinedFormats,
    predefinedObstacles
} from '../predefinedData';
import { Button, Progress } from 'flowbite-react'; // Import Progress from Flowbite
import axios from 'axios';
import { GiQuillInk } from "react-icons/gi";
import { useSession } from "../../../../context/SessionContext";
import {useNavigate} from "react-router-dom";

const Step_Review = ({ onSubmit }) => {
    const { formData, prevStep } = useWizard();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const { session } = useSession(); // Ensure you have access to the session
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    // Function to handle the story generation process
    const handleGenerateStory = async () => {
        setIsProcessing(true);
        setError('');


        // Mapping of form fields to predefined data
        const predefinedDataMap = {
            format: predefinedFormats,
            genres: predefinedStoryTypes,
            themes: predefinedThemes,
            conflict: predefinedStoryConflicts,
            location: predefinedLocations,
            tone: predefinedTone,
            incident: predefinedIncitingIncident,
            symbolism: predefinedSymbolism,
            obstacles: predefinedObstacles,
        };


        // Build the prompt based on user input
        const promptData = {
            storyPrompt: formData.storyPrompt,
            format: formData.format,
            location: formData.location,
            protagonist: formData.protagonist,
            antagonist: formData.antagonist,
            genres: formData.genres,
            themes: formData.themes,
            conflict: formData.conflict,
            tone: formData.tone,
            incident: formData.incident,
            symbolism: formData.symbolism,
            obstacles: formData.obstacles,
        };
        // Iterate over the formData keys
        Object.keys(formData).forEach((key) => {
            if (predefinedDataMap[key]) {
                const predefinedData = predefinedDataMap[key];
                const selectedValue = formData[key];

                if (Array.isArray(selectedValue)) {
                    // For arrays, map each value to the corresponding predefined object
                    promptData[key] = selectedValue
                        .map((value) => predefinedData[value])
                        .filter(Boolean);
                } else {
                    // For single values, get the corresponding predefined object
                    promptData[key] = predefinedData[selectedValue];
                }
            } else if (!promptData[key]) {
                // Include any other fields as they are
                promptData[key] = formData[key];
            }
        });

        try {
            // Call your backend API to generate the story details
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/story/generate`,
                { promptData },
                {
                    headers: { Authorization: `Bearer ${session.token}` },
                }
            );

            const generatedStory = response.data;
            navigate(`/story/${generatedStory._id}`);
        } catch (error) {
            console.error('Error generating story:', error);
            setError('Failed to generate story. Please try again.');
        } finally {
            setIsProcessing(false);
        }
    };

    // Function to collect selected items with image URLs
    const getSelectedItemsWithImages = () => {
        const selectedItems = [];

        // Helper function to add items to the selectedItems array
        const addSelected = (selected, predefinedData, category) => {
            if (!selected) return;
            if (Array.isArray(selected)) {
                selected.forEach(itemKey => {
                    const item = predefinedData[itemKey];
                    if (item) {
                        selectedItems.push({
                            category,
                            name: item.name,
                            imageUrl: item.imageUrl
                        });
                    }
                });
            } else {
                const item = predefinedData[selected];
                if (item) {
                    selectedItems.push({
                        category,
                        name: item.name,
                        imageUrl: item.imageUrl
                    });
                }
            }
        };

        // Add selections from different categories
        addSelected(formData.format, predefinedFormats, 'Format');
        addSelected(formData.genres, predefinedStoryTypes, 'Genre');
        addSelected(formData.conflict, predefinedStoryConflicts, 'Conflict');
        addSelected(formData.tone, predefinedTone, 'Tone');
        addSelected(formData.incident, predefinedIncitingIncident, 'Incident');
        addSelected(formData.location, predefinedLocations, 'Location');
        addSelected(formData.symbolism, predefinedSymbolism, 'Symbolism');
        // addSelected(formData.obstacles, predefinedObstacles, 'Obstacles');
        // addSelected(formData.protagonist, predefinedCharacters, 'Protagonist');
        // addSelected(formData.antagonist, predefinedCharacters, 'Antagonist');
        addSelected(formData.themes, predefinedThemes, 'Theme');

        return selectedItems;
    };

    const selectedItemsWithImages = getSelectedItemsWithImages();

    return (
        <div className="space-y-6">
            <h3 className="font-bold italic text-lg">Review & Generate</h3>

            {/* Image Grid */}
            <div>
                <h4 className="font-semibold mb-2">Selected Elements:</h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                    {selectedItemsWithImages.map((item, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img
                                src={item.imageUrl}
                                alt={item.name}
                                className="w-16 h-16 object-cover rounded-md shadow-sm"
                            />
                            <span className="text-xs mt-1 text-center">{item.name}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Progress Bar */}
            {isProcessing && (
                <div className="flex items-center space-x-2">
                    <Progress className="w-full" progress={100} animated />
                    <span className="text-sm text-gray-600">Generating your story... Please note this can take a long time (10 mins or more)</span>
                </div>
            )}

            {/* Error Message */}
            {error && <div className="text-red-500 text-sm">{error}</div>}

            {/*/!* Generated Story Link *!/*/}
            {/*{generatedStory && generatedStory.storyLink && (*/}
            {/*    <div className="mt-4 p-4 bg-green-100 rounded-md">*/}
            {/*        <h4 className="font-semibold">Your Story is Ready!</h4>*/}
            {/*        <a*/}
            {/*            href={generatedStory.storyLink}*/}
            {/*            target="_blank"*/}
            {/*            rel="noopener noreferrer"*/}
            {/*            className="text-blue-600 underline mt-2 block"*/}
            {/*        >*/}
            {/*            Click here to view your story*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*)}*/}

            {/* Action Buttons */}
            <div className="flex justify-between mt-6">
                <Button color="gray" onClick={prevStep} disabled={isProcessing}>
                    Back
                </Button>
                <Button onClick={handleGenerateStory} disabled={isProcessing}>
                    {isProcessing ? 'Generating...' : 'Generate Story'}
                    <GiQuillInk size={16} className="ml-2 translate-x-1" />30
                </Button>
            </div>
        </div>
    );
};

export default Step_Review;
