import { Modal } from "flowbite-react";
import React, { useState } from "react";
import Markdown from 'react-markdown';
import ViewProperties from "./ViewProperties";
import {ModalTheme} from "../../themes/ModalTheme";

function ViewLabel({ labelData }) {
    const [openModal, setOpenModal] = useState(false);
    const [existingLabel, setExistingLabel] = useState(labelData); // Initialize label state with the provided label data

    // Close modal function
    function onCloseModal() {
        setOpenModal(false);
    }

    return (
        <>
            {/* View button to open the modal */}
            <a
                href="#"
                className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
                onClick={() => setOpenModal(true)}
            >
                View
            </a>

            {/* Modal to display label details */}
            <Modal theme={ModalTheme} dismissible show={openModal} size="xl" onClose={onCloseModal} popup>
                <Modal.Header>
                    {/* Label Name */}
                    <h2 className="text-3xl p-1 ml-2.5 text-gray-900 dark:text-white font-bold">
                        {existingLabel.name}
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        {/* Image and Description Section */}
                        <div className="flex items-start space-x-4">
                            {/* Label Image */}
                            <div className="w-32 h-32 lg:w-48 lg:h-48">
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/image/attached/${existingLabel._id}`}
                                    alt={existingLabel.imagePrompt || 'Label Image'}
                                    className="rounded-lg"
                                />
                            </div>
                            {/* Label Description */}
                            <div className="flex-grow">
                                <Markdown className="text-sm text-gray-500 dark:text-gray-400">
                                    {existingLabel.description}
                                </Markdown>
                            </div>
                        </div>

                        {/* Display Label Properties */}
                        <ViewProperties properties={existingLabel.properties ?? []} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewLabel;
