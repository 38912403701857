import React, {useState} from "react";
import StoryShelves from "../../components/story/StoryShelves";
import {useSession} from "../../context/SessionContext";

function MyHomePage() {
    const { session } = useSession();


    return (
        <div>
            <StoryShelves session={session}/>
        </div>
    )
}

export default MyHomePage;
