import LocationList from "../LocationList";

/**
 * Renders a single map by adding its nodes and edges to the graph.
 *
 * @param {Object} map - The map object to render.
 * @param {Number} childLimit - The remaining depth to render child maps.
 * @param {Object} graph - The graph object containing nodes and edges.
 * @param {Set} renderedMaps - A set of already rendered map IDs to prevent cycles.
 */
async function renderSingleMap(map, childLimit, graph, renderedMaps = new Set()) {
    try {
        // Avoid re-rendering the same map to prevent infinite loops
        const mapId = map._id.toString();
        if (renderedMaps.has(mapId)) {
            return; // Already rendered
        }
        renderedMaps.add(mapId);

        // Add the current map as a node
        graph.nodes.push({
            id: mapId,
            label: `Map: ${map.name}`
        });

        //console.log('Rendering map:', mapId, map.name);

        // Ensure markers is an array
        const markers = Array.isArray(map.markers) ? map.markers : [];

        // Iterate through each marker in the map
        for (const marker of markers) {
            const markerId = marker._id.toString();
            // Add marker as a node
            graph.nodes.push({
                id: markerId,
                label: `Marker: ${marker.name}`
            });

            // Add edge from map to marker
            graph.edges.push({
                from: mapId,
                to: markerId,
                label: '' // Optional: add connection type or description
            });

            // If the marker is associated with a location, add location node and edge
            if (marker.location) {
                const locationId = marker.location._id.toString();
                graph.nodes.push({
                    id: locationId,
                    label: `Location: ${marker.location.name}`
                });

                graph.edges.push({
                    from: markerId,
                    to: locationId,
                    label: 'Located At' // Optional: label for the connection
                });
            }

            // Handle outgoing connections from this marker
            const outgoingConnections = Array.isArray(map.connections)
                ? map.connections.filter(conn => conn.from.toString() === markerId)
                : [];

            for (const conn of outgoingConnections) {
                const toId = conn.to.toString();
                // Add connection name as label
                const connectionLabel = conn.name || 'Unnamed Connection';
                graph.edges.push({
                    from: markerId,
                    to: toId,
                    label: connectionLabel
                });
            }
        }
    } catch (error) {
        console.error(`Error rendering map ${map._id}:`, error);
    }
}



function convertGraphToDot(graph) {
    let dot = 'digraph MapGraph {\n';

    // Define default node styles
    dot += '  node [shape=ellipse, fontsize=10];\n';

    // Add all nodes with specific styles
    graph.nodes.forEach(node => {
        let shape = 'ellipse';
        let color = 'black';

        if (node.label.startsWith('Map:')) {
            shape = 'box';
            color = 'blue';
        } else if (node.label.startsWith('Marker:')) {
            shape = 'diamond';
            color = 'green';
        } else if (node.label.startsWith('Location:')) {
            shape = 'oval';
            color = 'orange';
        }

        dot += `  "${node.id}" [label="${node.label}", shape=${shape}, color=${color}];\n`;
    });

    // Add all edges
    graph.edges.forEach(edge => {
        if (edge.label) {
            dot += `  "${edge.from}" -> "${edge.to}" [label="${edge.label}"];\n`;
        } else {
            dot += `  "${edge.from}" -> "${edge.to}";\n`;
        }
    });

    dot += '}';
    return dot;
}

const MapDebug = ({currentMap}) => {

    if (!currentMap) {
        return ''; // Return empty string if the current map is not found
    }

    // Initialize the graph object
    const graph = {
        nodes: [],
        edges: []
    };

    // Initialize the renderedMaps set to track already rendered maps
    const renderedMaps = new Set();

    // Render the current map
    renderSingleMap(currentMap, 6, graph, renderedMaps);

    // Convert the graph object to DOT format
    return convertGraphToDot(graph);
}
export default MapDebug;
