import React from 'react';
import { ToggleSwitch } from "flowbite-react";
import { ToggleTheme } from "../../themes/ToggleTheme";

const ReadToMeToggle = ({ autoRead, setAutoRead, readToMeQuality, setReadToMeQuality }) => {
    const handleAutoReadToggle = () => {
        setAutoRead(!autoRead);
    };

    const handleQualityToggle = () => {
        // Toggle between "standard" and "premium" quality
        setReadToMeQuality(prevQuality => (prevQuality === 'standard' ? 'premium' : 'standard'));
    };

    return (
        <div className="flex items-center space-x-4">
            {/* Auto Read Toggle */}
            <ToggleSwitch
                theme={ToggleTheme}
                checked={autoRead}
                onChange={handleAutoReadToggle}
                label={autoRead ? 'Auto Read: On' : 'Auto Read: Off'}
            />

            {/*/!* Quality Toggle *!/*/}
            {/*<div className="flex items-center space-x-2" title={'Premium quality will cost 1 ink to read a page'}>*/}
            {/*    <label htmlFor="readToMeQuality" className="text-sm font-medium">*/}
            {/*        Quality:*/}
            {/*    </label>*/}
            {/*    <ToggleSwitch*/}
            {/*        theme={ToggleTheme}*/}
            {/*        checked={readToMeQuality === 'premium'}*/}
            {/*        onChange={handleQualityToggle}*/}
            {/*        label={readToMeQuality === 'premium' ? 'Premium' : 'Standard'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};

export default React.memo(ReadToMeToggle);