// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {
    predefinedFormats,
    predefinedIncitingIncident,
    predefinedLocations,
    predefinedStoryTypes
} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_StartLocation = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select an Inciting Incident"
            subTitle="(Pick One)"
            predefinedList={predefinedIncitingIncident}
            formDataField="incident" // Ensure this matches the key in formData
            selectionLimit={1} // Example: limit to 3 selections, set to null for no limit
            minSelection={1}
            isSingleSelect={true}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_StartLocation;
