// components/common/MarkdownRenderer.jsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownRenderer = ({ content, darkMode }) => {
    // Fallback content if none is provided
    const safeContent = content || 'No content available.';

    return (
        <div className="content markdown">
            <ReactMarkdown
                className="prose dark:prose-dark"
                remarkPlugins={[remarkGfm]}
                components={{
                    h1: ({ node, ...props }) => (
                        <h1 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                        <h2 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                        <h3 style={{ color: darkMode ? '#aaaaaa' : '#444444' }} {...props} />
                    ),
                    strong: ({ node, ...props }) => (
                        <strong style={{ color: darkMode ? '#bbbbbb' : '#444444' }} {...props} />
                    ),
                    p: ({ node, ...props }) => <p style={{ marginBottom: '1em' }} {...props} />,
                    img: ({ node, ...props }) => (
                        <img
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                marginTop: '1em',
                                marginBottom: '1em',
                            }}
                            {...props}
                        />
                    ),
                    blockquote: ({ node, ...props }) => (
                        <blockquote
                            className="border-l-4 border-gray-300 pl-4 italic text-gray-600 dark:text-gray-400"
                            {...props}
                        />
                    ),
                    ul: ({ node, ...props }) => (
                        <ul
                            className="list-disc list-inside"
                            style={{ marginLeft: '1em', paddingLeft: '1em' }}
                            {...props}
                        />
                    ),
                    ol: ({ node, ...props }) => (
                        <ol
                            className="list-decimal list-inside"
                            style={{ marginLeft: '1em', paddingLeft: '1em' }}
                            {...props}
                        />
                    ),
                    li: ({ node, ...props }) => (
                        <li style={{ marginBottom: '0.5em', paddingLeft: '0.5em' }} {...props} />
                    ),
                }}
            >
                {safeContent}
            </ReactMarkdown>
        </div>
    );
};

export default MarkdownRenderer;
