// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {
    predefinedFormats,
    predefinedLocations,
    predefinedStoryConflicts,
    predefinedStoryTypes
} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_StartLocation = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select a Central Story Conflict"
            subTitle="(Pick One)"
            predefinedList={predefinedStoryConflicts}
            formDataField="conflict" // Ensure this matches the key in formData
            isSingleSelect={true}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_StartLocation;
