
import {Label, Modal, TextInput, Textarea, Badge} from "flowbite-react";
import React, {useEffect, useState} from "react";
import Markdown from 'react-markdown'
import WorldPage from "../../pages/private/WorldPage";
import axios from "axios";
import ViewProperties from "./ViewProperties";
import {ModalTheme} from "../../themes/ModalTheme";



function combineProperties(loreItem) {
    const propertiesMap = new Map();

    // Helper function to add properties to the map
    const addProperties = (properties) => {
        properties.forEach(prop => {
            if (!propertiesMap.has(prop.name)) {
                propertiesMap.set(prop.name, new Map());
            }
            prop.attributes.forEach(attr => {
                propertiesMap.get(prop.name).set(attr.key, attr.value);
            });
        });
    };

    // Recursively add properties from inherited lore and labels
    const traverseLoreAndLabels = (lore) => {
        lore.forEach(item => {
            // Process properties from the lore item itself
            if (item.properties) {
                addProperties(item.properties);
            }
            // Process labels within the lore item
            if (item.labels) {
                item.labels.forEach(label => {
                    if (label.properties) {
                        addProperties(label.properties);
                    }
                });
            }
            // Recurse into nested lore
            if (item.lore) {
                traverseLoreAndLabels(item.lore);
            }
        });
    };

    // Traverse nested lore if present
    if (loreItem.lore) {
        traverseLoreAndLabels(loreItem.lore);
    }

    // Process properties from labels directly attached to the loreItem
    if (loreItem.labels) {
        loreItem.labels.forEach(label => {
            if (label.properties) {
                addProperties(label.properties);
            }
        });
    }

    // Add properties from the main item
    if (loreItem.properties) {
        addProperties(loreItem.properties);
    }

    // Convert the map of maps into a structured array
    const combinedProperties = [];
    propertiesMap.forEach((attrs, propName) => {
        const attributes = [];
        attrs.forEach((value, key) => {
            attributes.push({ key, value });
        });
        combinedProperties.push({
            name: propName,
            attributes: attributes
        });
    });

    return combinedProperties;
}



function ViewLore({ loreData }) {
    const [openModal, setOpenModal] = useState(false);
    const [existingLore, setExistingLore] = useState(loreData); // Initialize newLore state with the lore prop

    //let properties = existingLore.properties ?? [];

    function onCloseModal() {
        setOpenModal(false);
    }

    const handlePropertiesUpdate = (newProperties) => {
        //setProperties(newProperties);
        setExistingLore({...existingLore, properties: newProperties});
    };

    return (
        <>
            <a href="#"
               className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4" onClick={() => setOpenModal(true)}>
                View
            </a>
            <Modal theme={ModalTheme} dismissible show={openModal} size="xl" onClose={onCloseModal} popup>
                <Modal.Header>
                    <div className="text-3xl p-1 ml-2.5 text-gray-900 dark:text-white font-bold">
                        {existingLore.name}
                    </div>
                    <div className="text-1xl p-1 ml-2.5 text-gray-900 dark:text-white font-bold">
                        {existingLore.type}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-2">
                        <div className="space-x-4">
                            <div className="flex-grow">
                                <Markdown
                                    className="text-sm text-gray-500 dark:text-gray-400">{existingLore.description}</Markdown>
                            </div>
                        </div>

                    <div  className="flex ">
                        <div className="flex 1">
                            <img
                                src={`${process.env.REACT_APP_API_URL}/image/attached/${existingLore._id}`}
                                alt={existingLore.imagePrompt}
                                className="rounded-lg"
                            />
                        </div>
                        <div className="flex 1">
                            {existingLore.labels.map(label =>
                                <Badge key={label._id} color={label.color} className="p-2">{label.name}</Badge>
                            )}
                        </div>
                    </div>

                        <ViewProperties properties={combineProperties(existingLore)}
                                        onPropertyUpdate={handlePropertiesUpdate}/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewLore;

