// src/components/ListPlot.jsx

import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {
    Badge,
    Button,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    TextInput,
} from "flowbite-react";
import {BsTrash} from "react-icons/bs";
import {GrEdit} from "react-icons/gr";
import PlotElementFormModal from "./PlotElementFormModal"; // Ensure correct path
import PlotLineFormModal from "./PlotLineFormModal"; // Ensure correct path
import {TableTheme} from "../../../themes/TableTheme";
import {IoAnalyticsOutline} from "react-icons/io5";
import {TbAlertHexagonFilled} from "react-icons/tb";
import GraphBuilder from "./graph/GraphBuilder";
import { PiGraph } from "react-icons/pi";

function ListPlot({ story }) {
    const [plotItems, setPlotItems] = useState([]); // Holds plot elements or plot lines
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalViewOnly, setIsModalViewOnly] = useState(false);
    const [isPlotLineModal, setIsPlotLineModal] = useState(false); // To distinguish between plot elements and plot lines
    const [itemToEdit, setItemToEdit] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [viewPlotLines, setViewPlotLines] = useState(false); // Toggle between plot elements and plot lines
    const [viewPlotGrid, setViewPlotGrid] = useState(null); // Toggle between table and graph view

    /**
     * Fetch plot elements or plot lines based on the current view.
     * @param {number} page - Current page number.
     * @param {string} search - Search term.
     */
    const fetchPlotData = async (page, search = searchTerm) => {
        setLoading(true);
        setError('');
        try {
            const params = {
                storyId: story._id,
                page,
                limit: 20, // Adjust as per UI needs
            };

            if (search && !viewPlotLines) { // Only search plot elements
                params.search = search;
            }

            // Determine the endpoint based on the current view
            const endpoint = viewPlotLines
                ? `${process.env.REACT_APP_API_URL}/plotline/list`
                : `${process.env.REACT_APP_API_URL}/plot/list`;

            const response = await axios.get(endpoint, {params});

            setPlotItems(response.data.plotElements || response.data.plotLines);
            setTotalPages(response.data.totalPages);
            setPage(response.data.currentPage);
        } catch (err) {
            console.error(err);
            setError('Failed to fetch data.');
        } finally {
            setLoading(false);
        }
    };

    /**
     * Effect hook to fetch data whenever page, searchTerm, or viewPlotLines changes.
     */
    useEffect(() => {
        fetchPlotData(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchTerm, viewPlotLines]);

    /**
     * Handle deletion of a plot element or plot line.
     * @param {string} id - ID of the item to delete.
     * @param {boolean} isPlotLine - Determines if the item is a plot line.
     */
    const handleDelete = async (id, isPlotLine = false) => {
        if (!window.confirm(`Are you sure you want to delete this ${isPlotLine ? 'plot line' : 'plot element'}?`)) return;
        try {
            const endpoint = isPlotLine
                ? `${process.env.REACT_APP_API_URL}/plotline/${id}`
                : `${process.env.REACT_APP_API_URL}/plot/${id}`;
            await axios.delete(endpoint);
            fetchPlotData(page);
        } catch (err) {
            console.error(err);
            alert(`Failed to delete the ${isPlotLine ? 'plot line' : 'plot element'}.`);
        }
    };

    /**
     * Open the modal for adding or editing a plot element or plot line.
     * @param {object|null} item - The item to edit or null for adding.
     * @param {boolean} isPlotLine - Determines if the modal is for a plot line.
     * @param isViewOnly
     */
    const openModal = (item = null, isPlotLine = false, isViewOnly = false) => {
        setItemToEdit(item);
        setIsModalViewOnly(isViewOnly);
        setIsPlotLineModal(isPlotLine);
        setIsModalOpen(true);
    };

    /**
     * Close the modal.
     */
    const closeModal = () => {
        setIsModalOpen(false);
        setItemToEdit(null);
    };

    /**
     * Callback function after successful add/edit/delete operations.
     */
    const handleSuccess = () => {
        fetchPlotData(page);
    };

    /**
     * Handle the submission of the search form.
     * @param {object} e - Event object.
     */
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setPage(1); // Reset to first page on new search
        fetchPlotData(1, searchTerm);
    };

    /**
     * Toggle between viewing plot elements and plot lines.
     */
    const toggleView = () => {
        setViewPlotLines(!viewPlotLines);
        setPage(1); // Reset to first page when toggling views
        setSearchTerm(''); // Clear search when toggling
    };

    // Create and memoize the lookup within your component
    const sortedPlotLines = useMemo(() => {
        // First, sort the plot lines by "index"
        const sorted = [...story.plotLines].sort((a, b) => a.index - b.index);
        // Then, create a lookup object
        return sorted.reduce((acc, curr) => ({...acc, [curr.index]: curr}), {});
    }, [story.plotLines]);

    const onClose = (e) => {
        e.preventDefault();
        setViewPlotGrid(null);
    };

    const onPlotGraphView = (plotLine) => {
        closeModal();
        setViewPlotGrid(plotLine);
    };


    if (!plotItems && viewPlotLines) return null;

    if (viewPlotGrid)
    {
        return (<div className={"w-full h-screen"}>
            <GraphBuilder
                storyId={story._id}
                plotLine={viewPlotGrid}
                onEdit={openModal}
                onClose={onClose}
                onSuccess={handleSuccess}
            />

            <PlotElementFormModal
                isOpen={isModalOpen}
                onClose={closeModal}
                plotElementToEdit={itemToEdit}
                story={story}
                viewOnly={isModalViewOnly}
                onSuccess={handleSuccess}
            />
        </div>)
    }
    else return (
        <div className="overflow-x-auto p-4 pt-0 pb-8">
            {/* Header Section: Buttons and Search Bar */}
            <div className="flex flex-col md:flex-row mb-6 justify-between w-full">
                <div className="flex space-x-2 mb-4 md:mb-0">
                    <Button onClick={() => openModal(null, viewPlotLines)} color="blue">
                        {viewPlotLines ? 'Add Plot Line' : 'Add Plot Element'}
                    </Button>
                    <Button onClick={toggleView} color="light">
                        {viewPlotLines ? 'Show Plot Elements' : 'Show Plot Lines'}
                    </Button>
                </div>
                {!viewPlotLines && (
                    <form onSubmit={handleSearchSubmit} className="flex items-center space-x-2">
                        <TextInput
                            type="text"
                            placeholder="Search by name..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-64"
                            aria-label="Search Plot Elements"
                        />
                        <Button type="submit" color="green">
                            Search
                        </Button>
                    </form>
                )}
            </div>

            {/* Plot Elements or Plot Lines Table */}
            {loading ? (
                <div className="flex justify-center items-center">
                    <Spinner aria-label="Loading..."/>
                </div>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : viewPlotLines ? (
                    <Table striped theme={TableTheme}>
                        <TableHead>
                            <TableHeadCell></TableHeadCell>
                            <TableHeadCell>ID</TableHeadCell>
                            <TableHeadCell>Name</TableHeadCell>
                            <TableHeadCell>Starts Active</TableHeadCell>
                            <TableHeadCell>Elements Count</TableHeadCell>
                            <TableHeadCell>Actions</TableHeadCell>
                        </TableHead>
                        <TableBody>
                            {plotItems.length === 0 ? (
                                <TableRow key={1}>
                                    <TableCell colSpan="6" className="text-center">No plot lines found.</TableCell>
                                </TableRow>
                            ) : (
                                plotItems.map((plotLine, index) => (
                                    <TableRow key={index} className="cursor-pointer" onClick={() => openModal(plotLine, true, true)}>
                                        <TableCell className="w-8"><IoAnalyticsOutline title="Plot Line"/></TableCell>
                                        <TableCell>{plotLine.index || plotLine.plotLine}</TableCell>
                                        <TableCell>{plotLine.name}</TableCell>
                                        <TableCell className="flex items-center">
                                            <Badge color={plotLine.startsActive ? "green" : "none"}>
                                                {plotLine.startsActive ? "Yes" : "No"}
                                            </Badge>
                                        </TableCell>
                                        <TableCell>{plotLine.elementsCount || 0}</TableCell>
                                        <TableCell className="flex space-x-4">
                                            <PiGraph
                                                size={18}
                                                title="View Plot Element Graph"
                                                className="text-gray-400 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                onClick={(e) => {e.stopPropagation(); setViewPlotGrid(plotLine)}}
                                            />
                                            <GrEdit
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openModal(plotLine, true);
                                                }}
                                                size={18}
                                                title="Edit Plot Line"
                                                className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Edit ${plotLine.name}`}
                                            />
                                            <BsTrash
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(plotLine._id, true);
                                                }}
                                                size={18}
                                                title="Delete Plot Line"
                                                className="text-gray-400 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                                aria-label={`Delete ${plotLine.name}`}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
            ) : (
                <Table striped theme={TableTheme}>
                    <TableHead>
                        <TableHeadCell></TableHeadCell>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell>Plot Line</TableHeadCell>
                        <TableHeadCell>Stage</TableHeadCell>
                        <TableHeadCell>Required</TableHeadCell>
                        <TableHeadCell>Unlocks</TableHeadCell>
                        <TableHeadCell>Actions</TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {plotItems.length === 0 ? (
                            <TableRow key={1}>
                                <TableCell colSpan="7" className="text-center">No plot elements found.</TableCell>
                            </TableRow>
                        ) : (
                            plotItems.map((plotElement) => (
                                <TableRow key={plotElement._id} className="cursor-pointer" onClick={() => openModal(plotElement, false, true)}>
                                    <TableCell className="w-8"><TbAlertHexagonFilled title="Plot Point"/></TableCell>
                                    <TableCell>{plotElement.name}</TableCell>
                                    <TableCell>{sortedPlotLines[plotElement.plotLine]?.name || 'N/A'}</TableCell>
                                    <TableCell>{plotElement.plotStage !== undefined ? plotElement.plotStage : 'N/A'}</TableCell>
                                    <TableCell className="flex space-x-2">
                                        <Badge color={plotElement.isRequiredForStage ? "green" : "none"} className="px-2">
                                            {plotElement.isRequiredForStage ? "Required" : ""}
                                        </Badge>
                                        <Badge color={plotElement.advanceStageOnTrigger ? "red" : "none"} className="px-2">
                                            {plotElement.advanceStageOnTrigger ? "Advance" : ""}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>{sortedPlotLines[plotElement.unlocksPlotLine]?.name || ''}</TableCell>
                                    <TableCell className="flex space-x-4">
                                        <GrEdit
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                openModal(plotElement, false);
                                            }}
                                            size={18}
                                            title="Edit Plot Element"
                                            className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                            aria-label={`Edit ${plotElement.name}`}
                                        />
                                        <BsTrash
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(plotElement._id, false);
                                            }}
                                            size={18}
                                            title="Delete Plot Element"
                                            className="text-gray-400 dark:text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                                            aria-label={`Delete ${plotElement.name}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            )}

            {/* Pagination Controls */}
            {totalPages > 1 && (
                <div className="flex justify-between items-center mt-4">
                    <Button
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                        disabled={page === 1}
                        color="gray"
                    >
                        Previous
                    </Button>
                    <span className="text-gray-700 dark:text-gray-300">Page {page} of {totalPages}</span>
                    <Button
                        onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={page === totalPages}
                        color="gray"
                    >
                        Next
                    </Button>
                </div>
            )}

            {/* Plot Element or Plot Line Modal */}
            {!isPlotLineModal ? (
                <PlotElementFormModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    plotElementToEdit={itemToEdit}
                    story={story}
                    viewOnly={isModalViewOnly}
                    onSuccess={handleSuccess}
                />
            ) : (
                <PlotLineFormModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    plotLineToEdit={itemToEdit}
                    story={story}
                    onPlotGraphView={onPlotGraphView}
                    viewOnly={isModalViewOnly}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
}

export default ListPlot;