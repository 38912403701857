import {Banner, BannerCollapseButton, Blockquote, Breadcrumb, Button, Card} from "flowbite-react";
import { HiX } from "react-icons/hi";
import { MdAnnouncement } from "react-icons/md";
import React, {useState} from "react";
import {useSession} from "../../context/SessionContext";

function LandingPage() {

    return (
        <div>
            {/* Spacing element to avoid content being hidden behind the fixed breadcrumb */}
            {/*<div className="h-16"></div>*/}


            {/* Main content area */}
            <div className="p-6 pb-20 flex flex-col items-center space-y-16 font-raleway">
                <Card
                    className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl rounded-xl dark:border-hidden dark:shadow-none dark:bg-gray-900"
                    renderImage={() => <img src={process.env.PUBLIC_URL + '/images/WalkIntoCreation.webp'} alt="image 1" className={"rounded-t-xl"} />}
                >
                    <h5 className="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
                        Discover Unlimited Possibilities
                    </h5>
                    <p className="text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400">
                        Have you ever wished you could explore more of a story's world—peeking around corners, wandering down streets that were never written about, or uncovering hidden rooms that the author never mentioned? Now you can do just that. Powered by generative AI, My Adventures fills in all the background details, creating a living, breathing world that expands as you explore.

                        No longer confined to the main plot, you can venture off the rails and forge your own path. Whether it's a new dungeon deep within a forgotten forest, a bustling city street teeming with life, or an ancient chamber hidden within a sprawling castle, the story will continue to unfold as you wander. Every side quest, every detour, opens up fresh, uncharted territories for you to discover.

                        With My Adventures, the possibilities are endless. Your journey is yours to define—where will you explore next?


                    </p>
                </Card>
                <Blockquote className="w-full sm:max-w-lg md:max-w-4xl lg:max-w-6xl xl:max-w-8xl text-center dark:text-gray-400">
                    <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                        "Fiction is the truth inside the lie."
                    </p>
                    <footer className="mt-1 text-sm sm:text-md md:text-lg lg:text-xl text-gray-500">
                        — Stephen King
                    </footer>
                </Blockquote>

                <Card
                    className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl rounded-xl dark:border-hidden dark:shadow-none dark:bg-gray-900"
                    renderImage={() => <img src={process.env.PUBLIC_URL + '/images/Generate_Story.webp'} alt="image 1"  className={"rounded-t-xl"} />}
                >
                    <h5 className="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
                        Craft Your Own Epic Tales
                    </h5>
                    <p className="text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400">
                        My Adventures isn't just about exploring worlds—it's also a powerful tool for creating them. You can easily write your own new stories, crafting intricate plotlines and quests, designing unique locations, and introducing memorable characters.

                        With the help of generative AI, you can extend and expand your story like never before. Need a sprawling city for your characters to explore? Or a mysterious dungeon filled with hidden secrets? Simply sketch out your ideas, and My Adventures will help bring them to life, filling in the details and adding depth under your direction.

                        Your creativity is the only limit. Build worlds that others can explore, or dive into your own creations, watching as your story grows and evolves with every decision. With My Adventures, you hold the power to create a universe of endless possibilities, tailored exactly to your vision.
                    </p>
                </Card>
                <Blockquote className="w-full sm:max-w-lg md:max-w-4xl lg:max-w-6xl xl:max-w-8xl text-center dark:text-gray-400">
                    <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                        "There are no rules of architecture for a castle in the clouds."
                    </p>
                    <footer className="mt-1 text-sm sm:text-md md:text-lg lg:text-xl text-gray-500">
                        — G.K. Chesterton
                    </footer>
                </Blockquote>
                <Card
                    className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl rounded-xl dark:border-hidden dark:shadow-none dark:bg-gray-900"
                    renderImage={() => <img src={process.env.PUBLIC_URL + '/images/World_Globe_Wide.webp'} alt="image 1"  className={"rounded-t-xl"} />}
                >
                    <h5 className="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
                        Forge New Worlds
                    </h5>
                    <p className="text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400">
                        With My Adventures, you're not just creating stories—you're crafting entire universes. Imagine building a world from the ground up, where you define the very rules that govern it. You can add constraints to introduce challenges, shaping the environment to test the mettle of those who dare to explore it.

                        Create rich world lore that serves as the foundation for endless stories. Set the stage with unique histories, cultures, and magic systems, all under your control. Whether it's a realm where the laws of physics are different, or a society bound by ancient codes, you have the power to establish the guidelines that will challenge and inspire adventurers.

                        Once your world is set, you can create and share stories within it, or let others craft their own tales against the backdrop you've designed. With My Adventures, you're not just telling stories—you're building the worlds in which those stories unfold, complete with the rules, challenges, and lore that make them unforgettable.
                    </p>
                </Card>
                <Blockquote className="w-full sm:max-w-lg md:max-w-4xl lg:max-w-6xl xl:max-w-8xl text-center dark:text-gray-400">
                    <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                        "A reader lives a thousand lives before he dies . . . The man who never reads lives only one."
                    </p>
                    <footer className="mt-1 text-sm sm:text-md md:text-lg lg:text-xl text-gray-500">
                        — George R.R. Martin
                    </footer>
                </Blockquote>
                <Card
                    className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl rounded-xl dark:border-hidden dark:shadow-none dark:bg-gray-900"
                    renderImage={() => <img src={process.env.PUBLIC_URL + '/images/Adventure_Path_Wide.webp'} alt="image 1"  className={"rounded-t-xl"} />}
                >
                    <h5 className="text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
                        Share Your Worlds With Others
                    </h5>
                    <p className="text-sm md:text-lg font-normal text-gray-700 dark:text-gray-400">
                        One of the most exciting aspects of My Adventures is the ability to share your worlds and stories with others. Once you've crafted a rich, immersive world and woven intricate tales within it, you can invite others to step into your creation.

                        Let fellow adventurers explore the dungeons, cities, and landscapes you've meticulously designed. They can embark on the quests you've set, encountering the characters and challenges you've brought to life. Whether it's a world of your own making or a story spun from your imagination, My Adventures allows you to share your creativity with a community of explorers eager to experience new adventures.

                        With My Adventures, your world doesn't end when the story is told—it continues to grow and evolve as others journey through it. Share your creations, inspire new stories, and watch as your world becomes a canvas for endless possibilities.


                    </p>
                </Card>
            </div>
        </div>
    );

}

export default LandingPage;
