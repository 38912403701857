// GroupBox.jsx
import React from 'react';
import { Handle } from '@xyflow/react';

const GroupBox = ({ data }) => {
    return (
        <div className="relative">
            <span className="font-semibold text-gray-700 dark:text-gray-200">{data.label}</span>
            {/* Container for child nodes */}
            <div className="child-nodes">
                {/* Child nodes are automatically managed by React Flow */}
            </div>
        </div>
    );
};

export default GroupBox;
