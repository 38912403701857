// src/components/story/plot/graph/GraphBuilder.jsx

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    ReactFlow,
    ReactFlowProvider,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    MarkerType,
    useReactFlow,
} from '@xyflow/react';
import axios from 'axios';
import PropTypes from 'prop-types';
import PlotElementNode from './PlotElementNode';
import PlotLineNode from './PlotLineNode'; // Import the new PlotLineNode
import ContextMenu from './ContextMenu';
import Toolbar from './Toolbar';
import GroupBox from './GroupBox';
import '@xyflow/react/dist/style.css';
import { Button } from "flowbite-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Initialize unique IDs for nodes and edges
let id = 1;
const getId = () => `node_${id++}`;
const edgeId = () => `edge_${id++}`;

const GraphBuilder = ({ storyId, plotLine, onEdit, onSuccess, onClose }) => {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [showMenu, setShowMenu] = useState(null);
    const reactFlowWrapper = useRef(null);
    const { project } = useReactFlow();

    // Fetch PlotElements and PlotLines based on storyId and plotLine
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch PlotElements
                const plotElementsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/plotline/${plotLine.index}/list`, {
                    params: { storyId: storyId },
                });
                const plotElements = plotElementsResponse.data;

                // Fetch PlotLines for the story
                const plotLinesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/plotline/list`, {
                    params: { storyId: storyId },
                });

                // plotLines contains all the plotLines in plotLinesResponse.data.plotLines EXPECT 'plotLine'
                const plotLines = plotLinesResponse.data.plotLines.filter(pl => pl.index !== plotLine.index);

                // Map PlotElements to React Flow nodes
                const plotElementNodes = plotElements.map((pe) => ({
                    id: pe._id, // Use MongoDB ObjectId as node ID
                    type: 'plotElement', // Changed from 'custom' to 'plotElement' for clarity
                    data: {
                        label: pe.name,
                        plotElement: pe,
                        onEdit, // Pass the onEdit function to node's data
                    },
                    position: {
                        x: pe.graphNode?.position?.x || 200 * pe.plotStage,
                        y: pe.graphNode?.position?.y || 100 * pe.plotStage,
                    },
                }));

                // Map PlotLines to React Flow nodes
                const plotLineNodes = plotLines.map((pl) => ({
                    id: `${pl.index}`, // Unique ID for plot line nodes
                    type: 'plotLine', // PlotLineNode
                    data: {
                        label: pl.name,
                        plotLine: pl,
                        onEdit, // Pass the onEdit function if needed
                    },
                    position: {
                        x: pl.graphNode?.position?.x || 100 * pl.index, // Adjust positioning as needed
                        y: pl.graphNode?.position?.y || 100, // Fixed y-position or calculate dynamically
                    },
                }));

                setNodes([...plotElementNodes, ...plotLineNodes]);

                // Create edges based on PlotElements unlocking or having prerequisites in PlotLines
                const fetchedEdges = [];

                plotElements.forEach((pe) => {
                    // Handle unlocksPlotLine (pe.unlocksPlotLine: Number or pe.unlocksPlotLines: [Number])
                    const unlocksPlotLines = pe.unlocksPlotLine ? [pe.unlocksPlotLine] : pe.unlocksPlotLines || [];
                    unlocksPlotLines.forEach((plIndex) => {
                        const targetPlotLineId = `${plIndex}`;
                        fetchedEdges.push({
                            id: edgeId(),
                            source: pe._id,
                            target: targetPlotLineId,
                            type: 'default',
                            animated: true, // Animated for unlocks
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 12,
                                height: 12,
                            },
                            style: { stroke: 'gold', strokeWidth: 2 },
                        });
                    });

                    // Handle plotLine prerequisites (pe.prerequisites.plotLines: [Number])
                    const prerequisitePlotLines = pe.prerequisites.plotLines || [];
                    prerequisitePlotLines.forEach((plIndex) => {
                        const sourcePlotLineId = `${plIndex}`;
                        fetchedEdges.push({
                            id: edgeId(),
                            source: sourcePlotLineId,
                            target: pe._id,
                            type: 'default',
                            animated: true, // Animated for prerequisites
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 12,
                                height: 12,
                            },
                            style: { stroke: 'gold', strokeWidth: 2 },
                        });
                    });
                });

                // **New Addition:** Create PlotElement ↔ PlotElement connections based on plotStage, nextStage, etc.
                plotElements.forEach((sourcePE) => {
                    if (sourcePE.isRequiredForStage) {
                        const targetPlotStage = sourcePE.nextStage;
                        const targetPEs = plotElements.filter((pe) => pe.plotStage === targetPlotStage);
                        targetPEs.forEach((targetPE) => {
                            fetchedEdges.push({
                                id: edgeId(),
                                source: sourcePE._id,
                                target: targetPE._id,
                                type: 'default',
                                animated: sourcePE.advanceStageOnTrigger, // Animated based on property
                                markerEnd: {
                                    type: MarkerType.ArrowClosed,
                                    width: 12,
                                    height: 12,
                                },
                                style: {
                                    stroke: sourcePE.advanceStageOnTrigger ? '#f00' : '#999',
                                    strokeWidth: sourcePE.advanceStageOnTrigger ? 2 : 1,
                                },
                            });
                        });
                    }
                });

                setEdges(fetchedEdges);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Failed to fetch plot data.');
                // Optionally, handle error state (e.g., show a notification)
            }
        };

        if (storyId && plotLine !== undefined) {
            fetchData();
        }
    }, [storyId, plotLine, onEdit]);

    // Enhanced onConnectHandler
    const onConnectHandler = useCallback(
        async (params) => {
            const { source, target } = params;

            const sourceNode = nodes.find((node) => node.id === source);
            const targetNode = nodes.find((node) => node.id === target);

            if (!sourceNode || !targetNode) {
                console.error('Source or target node not found.');
                toast.error('Source or target node not found.');
                return;
            }

            const isSourcePlotLine = sourceNode.type === 'plotLine';
            const isTargetPlotLine = targetNode.type === 'plotLine';

            // Prevent connecting two PlotLineNodes directly
            if (isSourcePlotLine && isTargetPlotLine) {
                toast.error('Cannot connect PlotLine nodes directly.');
                return;
            }

            // If connecting two PlotElements, validate plotStage and nextStage
            if (!isSourcePlotLine && !isTargetPlotLine) {
                const sourceNextStage = sourceNode.data.plotElement.nextStage;
                const targetPlotStage = targetNode.data.plotElement.plotStage;

                if (targetPlotStage !== sourceNextStage) {
                    // Check if target node has no incoming connections
                    const incomingEdges = edges.filter(edge => edge.target === target);
                    if (incomingEdges.length === 0) {
                        // Adjust target's plotStage to match source's nextStage
                        try {
                            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${target}/`, {
                                plotStage: sourceNextStage,
                            });
                            const updatedPlotElement = response.data;

                            // Update the target node in state
                            setNodes((nds) =>
                                nds.map((node) =>
                                    node.id === target
                                        ? {
                                            ...node,
                                            data: {
                                                ...node.data,
                                                label: updatedPlotElement.name,
                                                plotElement: updatedPlotElement,
                                            },
                                        }
                                        : node,
                                ),
                            );

                            toast.success(`Adjusted plotStage of "${updatedPlotElement.name}" to match.`);
                        } catch (error) {
                            console.error('Error updating target plotStage:', error);
                            toast.error('Failed to update target plotStage.');
                            return;
                        }
                    } else {
                        // Check if source node has no outgoing connections
                        const outgoingEdges = edges.filter(edge => edge.source === source);
                        if (outgoingEdges.length === 0) {
                            // Adjust source's nextStage to match target's plotStage
                            try {
                                const response = await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${source}/`, {
                                    nextStage: targetPlotStage,
                                });
                                const updatedPlotElement = response.data;

                                // Update the source node in state
                                setNodes((nds) =>
                                    nds.map((node) =>
                                        node.id === source
                                            ? {
                                                ...node,
                                                data: {
                                                    ...node.data,
                                                    label: updatedPlotElement.name,
                                                    plotElement: updatedPlotElement,
                                                },
                                            }
                                            : node,
                                    ),
                                );

                                toast.success(`Adjusted nextStage of "${updatedPlotElement.name}" to match.`);
                            } catch (error) {
                                console.error('Error updating source nextStage:', error);
                                toast.error('Failed to update source nextStage.');
                                return;
                            }
                        } else {
                            // Both nodes have existing connections, cannot adjust
                            toast.error('Invalid connection: Both nodes have existing connections.');
                            return;
                        }
                    }
                }
            }

            // Define edge style based on connection type and node properties
            let edgeStyle = { stroke: '#999', strokeWidth: 1 };
            let animated = false;

            if (isSourcePlotLine || isTargetPlotLine) {
                edgeStyle = { stroke: 'yellow', strokeWidth: 2 };
                animated = true;
            } else {
                // For PlotElement ↔ PlotElement, check if advanceStageOnTrigger is set on source
                if (sourceNode.data.plotElement.advanceStageOnTrigger) {
                    edgeStyle = { stroke: '#f00', strokeWidth: 2 };
                    animated = true;
                }
            }

            // Create the new edge
            const newEdge = {
                ...params,
                id: edgeId(),
                type: 'default',
                animated: animated,
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 12,
                    height: 12,
                },
                style: edgeStyle,
            };

            // Now, validate if after adjustment, the connection is valid
            if (!isSourcePlotLine && !isTargetPlotLine) {
                // Now, targetPlotStage should match sourceNextStage
                const updatedSourceNextStage = sourceNode.data.plotElement.nextStage;
                const updatedTargetPlotStage = targetNode.data.plotElement.plotStage;

                if (updatedTargetPlotStage !== updatedSourceNextStage) {
                    // Still invalid, possibly plotStage not updated correctly
                    toast.error('Failed to adjust plotStage to allow connection.');
                    return;
                }
            }

            // Add the edge
            setEdges((eds) => addEdge(newEdge, eds));

            // Now, update backend based on connection type
            try {
                if (!isSourcePlotLine && !isTargetPlotLine) {
                    // PlotElement to PlotElement connection
                    const payload = {
                        isRequiredForStage: true,
                    };

                    if (sourceNode.data.plotElement.advanceStageOnTrigger) {
                        payload.advanceStageOnTrigger = true;
                    }

                    await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${source}/`, payload);
                } else if (!isSourcePlotLine && isTargetPlotLine) {
                    // Connecting PlotElement -> PlotLine (Unlocking PlotLine)
                    await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${source}/unlocksPlotLine`, {
                        unlocksPlotLine: parseInt(targetNode.data.plotLine.index, 10),
                    });
                } else if (isSourcePlotLine && !isTargetPlotLine) {
                    // Connecting PlotLine -> PlotElement (Prerequisite PlotLine)
                    await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${target}/prerequisites/plotLines`, {
                        add: parseInt(sourceNode.data.plotLine.index, 10),
                    });
                }

                // Notify success
                onSuccess();
                toast.success('Connection created successfully.');
            } catch (error) {
                console.error('Error connecting nodes:', error);
                toast.error('Failed to connect nodes. Please try again.');

                // Optionally, remove the edge if backend update fails
                setEdges((eds) => eds.filter((e) => e.id !== newEdge.id));
            }
        },
        [nodes, edges, onSuccess],
    );

    // Handle edge removal
    const handleEdgeRemoval = useCallback(async (edge) => {
        const { source, target } = edge;

        const sourceNode = nodes.find((node) => node.id === source);
        const targetNode = nodes.find((node) => node.id === target);

        if (!sourceNode || !targetNode) {
            console.error('Source or target node not found.');
            toast.error('Source or target node not found.');
            return;
        }

        const isSourcePlotLine = sourceNode.type === 'plotLine';
        const isTargetPlotLine = targetNode.type === 'plotLine';

        try {
            if (!isSourcePlotLine && !isTargetPlotLine) {
                // Removing PlotElement -> PlotElement connection
                // Update isRequiredForStage and possibly advanceStageOnTrigger
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${source}/`, {
                    isRequiredForStage: false,
                    advanceStageOnTrigger: false, // Or handle based on your logic
                });
            } else if (!isSourcePlotLine && isTargetPlotLine) {
                // Removing PlotElement -> PlotLine connection
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${source}/unlocksPlotLine`, {
                    unlocksPlotLine: null, // Or handle appropriately
                });
            } else if (isSourcePlotLine && !isTargetPlotLine) {
                // Removing PlotLine -> PlotElement connection
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${target}/prerequisites/plotLines`, {
                    remove: parseInt(sourceNode.data.plotLine.index, 10),
                });
            }

            onSuccess(); // Notify parent of success
            toast.success('Connection removed successfully.');
        } catch (error) {
            console.error('Error removing edge:', error);
            toast.error('Failed to remove connection. Please try again.');
        }
    }, [nodes, onSuccess]);

    // Handle node context menu
    const onNodeContextMenuHandler = useCallback(
        (event, node) => {
            event.preventDefault();
            const boundingRect = reactFlowWrapper.current.getBoundingClientRect();
            setShowMenu({
                nodeId: node.id,
                top: event.clientY - boundingRect.top - 60,
                left: event.clientX - boundingRect.left,
            });
        },
        [],
    );

    // Handle node changes
    const onNodesChangeHandler = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );

    // Handle edge changes
    const onEdgesChangeHandler = useCallback(
        (changes) => {
            setEdges((eds) => {
                const updatedEdges = applyEdgeChanges(changes, eds);

                // Handle edge removals
                changes.forEach((change) => {
                    if (change.type === 'remove') {
                        const removedEdge = eds.find(edge => edge.id === change.id);
                        if (removedEdge) {
                            handleEdgeRemoval(removedEdge);
                        }
                    }
                });

                return updatedEdges;
            });
        },
        [handleEdgeRemoval],
    );

    const onPaneClickHandler = useCallback(() => setShowMenu(null), []);

    // Delete node
    const deleteNode = useCallback(
        async (nodeId) => {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/plot/${nodeId}`);
                setNodes((nds) => nds.filter((n) => n.id !== nodeId));
                setEdges((eds) => eds.filter((e) => e.source !== nodeId && e.target !== nodeId));
                onSuccess(); // Notify parent of success
                toast.success('Plot element deleted successfully.');
            } catch (error) {
                console.error('Error deleting PlotElement:', error);
                toast.error('Failed to delete plot element. Please try again.');
            }
        },
        [onSuccess],
    );

    // Rename node
    const renameNode = useCallback(
        async (nodeId, newLabel) => {
            try {
                const node = nodes.find((n) => n.id === nodeId);
                if (!node) throw new Error('Node not found');

                const endpoint = node.type === 'plotLine'
                    ? `${process.env.REACT_APP_API_URL}/plotLine/${nodeId}/name?storyId=${storyId}`
                    : `${process.env.REACT_APP_API_URL}/plot/${nodeId}/name`;

                await axios.patch(endpoint, { name: newLabel });
                setNodes((nds) =>
                    nds.map((node) =>
                        node.id === nodeId
                            ? {
                                ...node,
                                data: {
                                    ...node.data,
                                    label: newLabel,
                                    ...(node.type === 'plotElement'
                                        ? { plotElement: { ...node.data.plotElement, name: newLabel } }
                                        : { plotLine: { ...node.data.plotLine, name: newLabel } }),
                                },
                            }
                            : node,
                    ),
                );
                onSuccess(); // Notify parent of success
                toast.success('Node renamed successfully.');
            } catch (error) {
                console.error('Error renaming node:', error);
                toast.error('Failed to rename node. Please try again.');
            }
        },
        [nodes, onSuccess],
    );

    // Add tag to PlotElement
    const addTag = useCallback(
        async (nodeId, tag) => {
            const node = nodes.find((n) => n.id === nodeId);
            if (!node || node.type !== 'plotElement') return; // Only PlotElements have tags

            const updatedTags = [...(node.data.plotElement.tags || []), tag];
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${nodeId}/tags`, { tags: updatedTags });
                setNodes((nds) =>
                    nds.map((n) =>
                        n.id === nodeId
                            ? {
                                ...n,
                                data: {
                                    ...n.data,
                                    plotElement: {
                                        ...n.data.plotElement,
                                        tags: updatedTags
                                    }
                                },
                            }
                            : n,
                    ),
                );
                onSuccess(); // Notify parent of success
                toast.success('Tag added successfully.');
            } catch (error) {
                console.error('Error adding tag:', error);
                toast.error('Failed to add tag. Please try again.');
            }
        },
        [nodes, onSuccess],
    );

    // Remove tag from PlotElement
    const removeTag = useCallback(
        async (nodeId, tag) => {
            const node = nodes.find((n) => n.id === nodeId);
            if (!node || node.type !== 'plotElement') return; // Only PlotElements have tags

            const updatedTags = (node.data.plotElement.tags || []).filter((t) => t !== tag);
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${nodeId}/tags`, { tags: updatedTags });
                setNodes((nds) =>
                    nds.map((n) =>
                        n.id === nodeId
                            ? {
                                ...n,
                                data: {
                                    ...n.data,
                                    plotElement: {
                                        ...node.data.plotElement,
                                        tags: updatedTags
                                    }
                                },
                            }
                            : n,
                    ),
                );
                onSuccess(); // Notify parent of success
                toast.success('Tag removed successfully.');
            } catch (error) {
                console.error('Error removing tag:', error);
                toast.error('Failed to remove tag. Please try again.');
            }
        },
        [nodes, onSuccess],
    );

    // Assign node to a group
    const assignNodeToGroup = useCallback(
        async (nodeId, groupId) => {
            try {
                await axios.patch(`${process.env.REACT_APP_API_URL}/plot/${nodeId}/parentId`, { parentId: groupId });
                setNodes((nds) =>
                    nds.map((node) =>
                        node.id === nodeId
                            ? { ...node, parentId: groupId }
                            : node,
                    ),
                );
                onSuccess(); // Notify parent of success
                toast.success('Node assigned to group successfully.');
            } catch (error) {
                console.error('Error assigning node to group:', error);
                toast.error('Failed to assign node to group. Please try again.');
            }
        },
        [onSuccess],
    );

    // Handle node drag stop to update position in backend
    const onNodeDragStopHandler = useCallback(
        async (event, node) => {
            const updatedPosition = { x: node.position.x, y: node.position.y };
            try {
                const endpoint = node.type === 'plotLine'
                    ? `${process.env.REACT_APP_API_URL}/plotLine/${node.id}/graphNode?storyId=${storyId}`
                    : `${process.env.REACT_APP_API_URL}/plot/${node.id}/graphNode`;

                await axios.patch(endpoint, {
                    graphNode: {
                        position: updatedPosition
                    }
                });
                onSuccess(); // Notify parent of success
                toast.success('Node position updated successfully.');
            } catch (error) {
                console.error('Error updating node position:', error);
                toast.error('Failed to update node position. Please try again.');
            }
        },
        [onSuccess],
    );

    // Automatically update group box sizes when nodes change
    useEffect(() => {
        const updateGroupSizes = () => {
            const groupNodes = nodes.filter((node) => node.type === 'group');
            groupNodes.forEach((group) => {
                const childNodes = nodes.filter((node) => node.parentId === group.id);
                if (childNodes.length === 0) {
                    // Reset group size if no children
                    setNodes((nds) =>
                        nds.map((node) =>
                            node.id === group.id
                                ? { ...node, style: { ...node.style, width: 150, height: 100 } }
                                : node,
                        ),
                    );
                    return;
                }

                // Calculate bounding box
                const minX = Math.min(...childNodes.map((node) => node.position.x));
                const minY = Math.min(...childNodes.map((node) => node.position.y));
                const maxX = Math.max(...childNodes.map((node) => node.position.x));
                const maxY = Math.max(...childNodes.map((node) => node.position.y));

                const padding = 50; // Add some padding around child nodes

                const newWidth = maxX - minX + padding;
                const newHeight = maxY - minY + padding;

                // Update group box size
                setNodes((nds) =>
                    nds.map((node) =>
                        node.id === group.id
                            ? {
                                ...node,
                                style: {
                                    ...node.style,
                                    width: newWidth,
                                    height: newHeight,
                                },
                            }
                            : node,
                    ),
                );
            });
        };

        updateGroupSizes();
    }, [nodes]);

    // Get list of available groups
    const availableGroups = nodes.filter((node) => node.type === 'group');

    // Handle Drop Event to Add Nodes
    const onDrop = useCallback(
        async (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            if (typeof type !== 'string' || !type) return;

            // Determine position where the node is dropped
            const position = project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            if (type === 'plotElement') {
                // Create a new PlotElement
                try {
                    const newPlotElement = {
                        name: 'New Plot Element',
                        story: storyId,
                        embedding: [], // Populate as needed
                        embeddingTrigger: [], // Populate as needed
                        // Add other required fields with default values
                        plotLine: plotLine.index,
                        plotStage: 0,
                        nextStage: 1,
                        isRequiredForStage: false,
                        advanceStageOnTrigger: false,
                    };

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/plot`, newPlotElement);
                    const createdPlotElement = response.data;

                    const newNode = {
                        id: createdPlotElement._id,
                        type: 'plotElement',
                        data: {
                            label: createdPlotElement.name,
                            plotElement: createdPlotElement,
                            onEdit,
                        },
                        position,
                    };

                    setNodes((nds) => nds.concat(newNode));
                    onSuccess();
                    toast.success('Plot Element created successfully.');
                } catch (error) {
                    console.error('Error creating PlotElement:', error);
                    toast.error('Failed to create PlotElement. Please try again.');
                }
            }

            if (type === 'plotLine') {
                // Create a new PlotLine
                try {
                    const newPlotLine = {
                        index: id++, // Ensure unique index or handle appropriately
                        name: 'New Plot Line',
                        story: storyId,
                        // Add other required fields with default values
                        graphNode: {
                            position: {
                                x: position.x,
                                y: position.y,
                            },
                        },
                    };

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/plotLine`, newPlotLine);
                    const createdPlotLine = response.data;

                    const newNode = {
                        id: `${createdPlotLine.index}`,
                        type: 'plotLine',
                        data: {
                            label: createdPlotLine.name,
                            plotLine: createdPlotLine,
                            onEdit,
                        },
                        position,
                    };

                    setNodes((nds) => nds.concat(newNode));
                    onSuccess();
                    toast.success('Plot Line created successfully.');
                } catch (error) {
                    console.error('Error creating PlotLine:', error);
                    toast.error('Failed to create PlotLine. Please try again.');
                }
            }
        },
        [project, storyId, plotLine.index, onEdit, onSuccess],
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    return (
        <div className="flex flex-col h-full" ref={reactFlowWrapper}>
            {/* Initialize ToastContainer for notifications */}
            <ToastContainer />

            <div className="flex justify-between items-center p-2">
                <Toolbar />
                <span className="hidden text-xl font-semibold md:block">Plot Line: {plotLine.name}</span>
                <Button className="mr-4 w-[150px] ml-20" color='gray' onClick={onClose}>Close</Button>
            </div>
            <div className="flex-1 z-10" onDrop={onDrop} onDragOver={onDragOver}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChangeHandler}
                    onEdgesChange={onEdgesChangeHandler}
                    onConnect={onConnectHandler}
                    onNodeContextMenu={onNodeContextMenuHandler}
                    onNodeDragStop={onNodeDragStopHandler}
                    onPaneClick={onPaneClickHandler}
                    fitView
                    nodeTypes={{ plotElement: PlotElementNode, plotLine: PlotLineNode, group: GroupBox }}
                    fitViewOptions={{ padding: 0.2 }}
                >
                    <Background />
                    {showMenu && (
                        <ContextMenu
                            nodeId={showMenu.nodeId}
                            type={nodes.find((n) => n.id === showMenu.nodeId)?.type}
                            top={showMenu.top}
                            left={showMenu.left}
                            nodeLabel={nodes.find((n) => n.id === showMenu.nodeId)?.data.label || ''}
                            onDelete={() => {
                                deleteNode(showMenu.nodeId);
                                setShowMenu(null);
                            }}
                            onRename={(newLabel) => {
                                renameNode(showMenu.nodeId, newLabel);
                                setShowMenu(null);
                            }}
                            onAddTag={(tag) => {
                                addTag(showMenu.nodeId, tag);
                                setShowMenu(null);
                            }}
                            onRemoveTag={(tag) => {
                                removeTag(showMenu.nodeId, tag);
                                setShowMenu(null);
                            }}
                            onAssignToGroup={(groupId) => {
                                assignNodeToGroup(showMenu.nodeId, groupId);
                                setShowMenu(null);
                            }}
                            availableGroups={availableGroups}
                        />
                    )}
                </ReactFlow>
            </div>
        </div>
    );
};

GraphBuilder.propTypes = {
    storyId: PropTypes.string.isRequired,
    plotLine: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired, // Callback to open PlotElementFormModal or PlotLineFormModal
    onClose: PropTypes.func,
    onSuccess: PropTypes.func.isRequired, // Callback after successful operations
};

// Export wrapped with ReactFlowProvider
export default ({ storyId, plotLine, onEdit, onSuccess, onClose }) => (
    <ReactFlowProvider>
        <GraphBuilder storyId={storyId} plotLine={plotLine} onEdit={onEdit} onSuccess={onSuccess} onClose={onClose} />
    </ReactFlowProvider>
);
