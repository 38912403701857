// src/components/NewStory/StoryWizardForm/steps/Step_StartLocations.js

import React from 'react';
import SelectStep from '../SelectStep';
import {predefinedFormats, predefinedLocations} from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_StartLocation = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select Adventure Format (World)"
            subTitle="(Pick One)"
            predefinedList={predefinedFormats}
            formDataField="format" // Ensure this matches the key in formData
            selectionLimit={1} // Example: limit to 3 selections, set to null for no limit
            minSelection={1}
            isSingleSelect={true}
            nextStep={nextStep}
            prevStep={null}
        />
    );
};

export default Step_StartLocation;
