// src/components/NewStory/StoryWizardForm/storyTypes.js


export const predefinedFormats = {
    fantasyTextAdventure: {
        name: 'Fantasy', // Classic D&D or Pathfinder Like Campaign
        description: 'Text Adventure',
        id: '66f5fe696f2d84a08c590a6d',
        imageUrl: 'https://img.freepik.com/free-photo/futuristic-buildings-nature_23-2150832550.jpg',
    },
    steamPunkRPGAdventure: {
        name: 'Steam Punk', // Classic D&D or Pathfinder Like Campaign
        description: 'RPG Adventure',
        id: '66f5fe696f2d84a08c590a6d',
        imageUrl: '/images/story-types/romance-drama.jpg',
        notAvailable: "Coming Soon!"
    },
    dungeonCrawlRPGAdventure: {
        name: 'Dungeon Crawl', // Classic D&D or Pathfinder Like Campaign
        description: 'Rouge Like',
        id: '66f5fe696f2d84a08c590a6d',
        imageUrl: '/images/story-types/romance-drama.jpg',
        notAvailable: "Coming Soon!"
    },
};

export const predefinedStoryConflicts = {
    character: { name: 'Character vs Character' },
    environment: { name: 'Character vs Environment' },
    society: { name: 'Character vs Society ' },
    self: { name: 'Character vs Self' },
    technology: { name: 'Character vs Technology' },
    supernatural: { name: 'Character vs Supernatural' },
};

export const predefinedStoryTypes = {
    adventure: { name: 'Adventure' },
    mystery: { name: 'Mystery' },
    crime: { name: 'Crime' },
    romance: { name: 'Romance' },
    thriller: { name: 'Thriller' },
    horror: { name: 'Horror' },
};

export const predefinedObstacles = {
    adventure: { name: 'Adventure' },
    mystery: { name: 'Mystery' },
    crime: { name: 'Crime' },
    romance: { name: 'Romance' },
    thriller: { name: 'Thriller' },
    horror: { name: 'Horror' },
};

export const predefinedThemes = {
    love: { name: 'Love' },
    betrayal: { name: 'Betrayal' },
    chaos: { name: 'Chaos' },
    unity: { name: 'Unity' },
    friendship: { name: 'Friendship' },
    redemption: { name: 'Redemption' },
    revenge: { name: 'Revenge' }, // New theme
    survival: { name: 'Survival' }, // Struggling against odds to stay alive
    sacrifice: { name: 'Sacrifice' }, // Giving something up for a greater cause
    loyalty: { name: 'Loyalty' }, // Staying true to someone or something
    ambition: { name: 'Ambition' }, // Desire for power, success, or achievement
    justice: { name: 'Justice' }, // Seeking fairness or righting wrongs
    freedom: { name: 'Freedom' }, // Breaking free from constraints
    identity: { name: 'Identity' }, // Self-discovery or defining oneself
    fate: { name: 'Fate' }, // Themes of destiny or predetermined paths
    power: { name: 'Power' }, // The struggle for control or influence
    greed: { name: 'Greed' }, // Desire for wealth or material gain
    loss: { name: 'Loss' }, // Coping with death, separation, or grief
    resilience: { name: 'Resilience' }, // Overcoming hardship or adversity
    fear: { name: 'Fear' }, // Confronting inner or external fears
    transformation: { name: 'Transformation' }, // Personal or physical change
    hope: { name: 'Hope' }, // Holding onto optimism or belief in a better future
    innocence: { name: 'Innocence' }, // Exploration of purity or naivety
    deception: { name: 'Deception' }, // Lies, trickery, or hidden motives
    honor: { name: 'Honor' }, // Upholding moral integrity or dignity
    isolation: { name: 'Isolation' }, // Being cut off from others, emotionally or physically
    destiny: { name: 'Destiny' }, // The inevitability of certain events or outcomes
    grief: { name: 'Grief' }, // Processing loss and mourning
    madness: { name: 'Madness' }, // Themes of mental instability or chaos
    temptation: { name: 'Temptation' }, // Struggling with desires or vices
};


export const predefinedLocations = {
    city: { name: 'City' },
    forest: { name: 'Forest' },
    desert: { name: 'Desert' },
    mountains: { name: 'Mountains' },
    ocean: { name: 'Ocean' },
    spaceStation: { name: 'Space Station' }, // A futuristic or sci-fi setting
    hauntedMansion: { name: 'Haunted Mansion' }, // A spooky, mysterious mansion
    ancientRuins: { name: 'Ancient Ruins' }, // Mysterious, forgotten ruins of a lost civilization
    underworld: { name: 'Underworld' }, // A dark, mythical realm beneath the surface
    floatingIslands: { name: 'Floating Islands' }, // A surreal and magical land in the sky
    undergroundCave: { name: 'Underground Cave' }, // Hidden caves or caverns with secrets
    tropicalIsland: { name: 'Tropical Island' }, // A sunny, isolated paradise or deserted island
    dystopianCity: { name: 'Dystopian City' }, // A futuristic city in decline or oppression
    enchantedForest: { name: 'Enchanted Forest' }, // A magical forest filled with strange creatures and powers
    desertOasis: { name: 'Desert Oasis' }, // A hidden lush area within the desert
    arcticTundra: { name: 'Arctic Tundra' }, // A cold, barren landscape of ice and snow
    castle: { name: 'Castle' }, // A majestic or ancient fortress
    pirateShip: { name: 'Pirate Ship' }, // A pirate’s vessel sailing through dangerous seas
    futuristicMetropolis: { name: 'Futuristic Metropolis' }, // A large, high-tech city in the future
    labyrinth: { name: 'Labyrinth' }, // A mysterious and dangerous maze
    volcano: { name: 'Volcano' }, // A fiery and dangerous volcanic setting
    ghostTown: { name: 'Ghost Town' }, // An abandoned town filled with mysteries or spirits
    underwaterCity: { name: 'Underwater City' }, // A city beneath the ocean’s surface
    moonBase: { name: 'Moon Base' }, // A base on the moon for sci-fi or space exploration stories
    jungle: { name: 'Jungle' }, // A dense, wild, and untamed jungle filled with danger
    temple: { name: 'Temple' }, // An ancient or sacred temple with hidden treasures or secrets
    alienPlanet: { name: 'Alien Planet' }, // A mysterious, unknown planet in the galaxy
    mysticalValley: { name: 'Mystical Valley' }, // A magical valley hidden away from the world
    barrenWasteland: { name: 'Barren Wasteland' }, // A desolate, post-apocalyptic land
};


export const predefinedTone = {
    suspenseful: { name: 'Suspenseful' },
    melancholic: { name: 'Melancholic' },
    joyful: { name: 'Joyful' },
    somber: { name: 'Somber' },
    sarcastic: { name: 'Sarcastic' },
    hopeful: { name: 'Hopeful' },
    dark: { name: 'Dark' },
    whimsical: { name: 'Whimsical' },
    romantic: { name: 'Romantic' },
    formal: { name: 'Formal' },
    informal: { name: 'Informal' },
    mysterious: { name: 'Mysterious' },
    dramatic: { name: 'Dramatic' },
    cynical: { name: 'Cynical' },
    optimistic: { name: 'Optimistic' },
    pessimistic: { name: 'Pessimistic' },
    eerie: { name: 'Eerie' },
    reflective: { name: 'Reflective' },
    adventurous: { name: 'Adventurous' },
    tragic: { name: 'Tragic' }
};


export const predefinedIncitingIncident = {
    challenge: { name: 'Challenge' },
    threat: { name: 'Threat' },
    request: { name: 'Request' },
    invitation: { name: 'Invitation' },
    mission: { name: 'Mission' },
    stranger: { name: 'Stranger' },
    revelation: { name: 'Revelation' },
    theft: { name: 'Theft' },
    warning: { name: 'Warning' },
    prophecy: { name: 'Prophecy' },
    accident: { name: 'Accident' },
    loss: { name: 'Loss' },
    discovery: { name: 'Discovery' },
    escape: { name: 'Escape' },
    rebellion: { name: 'Rebellion' },
    betrayal: { name: 'Betrayal' },
    mistake: { name: 'Mistake' },
    invasion: { name: 'Invasion' },
};

//title="Select Some Symbolism To Incorporate As Items Or Places"
export const predefinedSymbolism = {
    light: { name: 'Light' }, // Represents knowledge, truth, purity, or hope
    darkness: { name: 'Darkness' }, // Symbolizes ignorance, fear, or evil
    water: { name: 'Water' }, // Often linked to life, change, or cleansing
    fire: { name: 'Fire' }, // Represents destruction, passion, or purification
    journey: { name: 'Journey' }, // Symbolizes personal growth or transformation
    mirror: { name: 'Mirror' }, // Reflection of truth, self-awareness, or illusion
    tree: { name: 'Tree' }, // Represents life, growth, or interconnectedness
    circle: { name: 'Circle' }, // Symbol of unity, eternity, or the cyclical nature of life
    shadow: { name: 'Shadow' }, // Represents hidden desires, fears, or unconscious thoughts
    door: { name: 'Door' }, // Symbolizes opportunity, transition, or barriers
    labyrinth: { name: 'Labyrinth' }, // Symbol of confusion, complexity, or a journey toward understanding
    blood: { name: 'Blood' }, // Linked to life, death, sacrifice, or family ties
    crown: { name: 'Crown' }, // Represents power, authority, or responsibility
    snake: { name: 'Snake' }, // Symbol of temptation, danger, or transformation
    butterfly: { name: 'Butterfly' }, // Transformation, freedom, or fleeting beauty
    clock: { name: 'Clock' }, // Represents time, mortality, or the pressure of deadlines
    mountain: { name: 'Mountain' }, // Symbolizes challenges, aspirations, or isolation
    key: { name: 'Key' }, // Represents knowledge, access, or unlocking potential
    rose: { name: 'Rose' }, // Often symbolizes love, beauty, or the balance between pleasure and pain
    mask: { name: 'Mask' }, // Symbolizes concealment, identity, or deception
    road: { name: 'Road' }, // Represents life's journey, choices, or destiny
    phoenix: { name: 'Phoenix' }, // Symbol of rebirth, renewal, or immortality
    anchor: { name: 'Anchor' }, // Represents stability, hope, or steadfastness
    fog: { name: 'Fog' }, // Symbolizes confusion, uncertainty, or the unknown
    storm: { name: 'Storm' }, // Represents turmoil, conflict, or emotional upheaval
    seed: { name: 'Seed' }, // Symbol of potential, growth, or new beginnings
    book: { name: 'Book' }, // Represents knowledge, wisdom, or secrets
    feather: { name: 'Feather' }, // Symbolizes freedom, lightness, or spiritual connection
    sword: { name: 'Sword' }, // Represents power, justice, or conflict
    moon: { name: 'Moon' }, // Symbol of mystery, change, or femininity
    sun: { name: 'Sun' }, // Represents life, energy, or enlightenment
    bridge: { name: 'Bridge' }, // Symbolizes connection, transition, or overcoming obstacles
    eye: { name: 'Eye' }, // Represents awareness, perception, or insight
    crossroads: { name: 'Crossroads' }, // Symbol of decision-making, fate, or choices
    glass: { name: 'Glass' }, // Represents fragility, transparency, or clarity
    wings: { name: 'Wings' }, // Symbolizes freedom, aspiration, or transcendence
    desert: { name: 'Desert' }, // Represents desolation, isolation, or endurance
    rain: { name: 'Rain' }, // Symbol of cleansing, renewal, or sadness
    gate: { name: 'Gate' }, // Represents entry, new beginnings, or thresholds
    star: { name: 'Star' }, // Symbolizes hope, guidance, or dreams
    poison: { name: 'Poison' }, // Represents corruption, betrayal, or danger
    scar: { name: 'Scar' }, // Symbolizes past trauma, survival, or healing
    lion: { name: 'Lion' }, // Represents courage, strength, or leadership
    spiral: { name: 'Spiral' }, // Represents growth, evolution, or the cycle of life
    puppet: { name: 'Puppet' }, // Symbolizes manipulation, control, or lack of autonomy
    chains: { name: 'Chains' }, // Represents bondage, oppression, or connection
};
