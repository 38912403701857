// src/components/news/ArticleTableRow.js

import React from 'react';
import PropTypes from 'prop-types';
import {
    TableRow,
    TableCell,
    Badge,
    Button,
} from 'flowbite-react';
import { IoMdGlobe } from "react-icons/io";
import { BsEyeSlashFill } from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import {GrEdit} from "react-icons/gr";

const ArticleTableRow = React.memo(({ article, onDelete, onEdit }) => {
    const navigate = useNavigate();

    return (
        <TableRow
            className={`bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer`}
            onClick={() => navigate(`/news/article/${article._id}`)}
        >
            <TableCell>{article.name}</TableCell>
            <TableCell>{article.description}</TableCell>
            <TableCell>
                <img src={article.imgUrl} alt={article.name} className="w-24 h-16 object-cover rounded" />
            </TableCell>
            <TableCell>
                {article.published ? <IoMdGlobe size={20} /> : <BsEyeSlashFill size={20} className={'text-gray-500'} />}
            </TableCell>
            <TableCell className="flex flex-wrap gap-2">
                {(article.tags || []).map((tag, index) => (
                    <Badge key={index} color="gray" className="p-2">
                        {tag}
                    </Badge>
                ))}
            </TableCell>
            <TableCell>
                {article.segments && article.segments.length > 0 ? (
                    <ul className="list-disc list-inside">
                        {article.segments.map((segment, idx) => (
                            <li key={segment._id || idx}>
                                <strong>{segment.title}</strong>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <span className="text-gray-500">No segments</span>
                )}
            </TableCell>
            <TableCell>{article.category}</TableCell>
            <TableCell>
                <div className="flex justify-center items-center">
                    {/*<Button*/}
                    {/*    color="blue"*/}
                    {/*    size="xs"*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        onEdit(article)*/}
                    {/*    }}*/}
                    {/*    className="mr-2"*/}
                    {/*>*/}
                    {/*    Edit*/}
                    {/*</Button>*/}

                    <GrEdit
                        onClick={(e) => {
                            e.stopPropagation();
                            onEdit(article)
                        }}
                        size={18}
                        title="Edit Article"
                        className="text-gray-400 dark:text-gray-400 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-125 cursor-pointer"
                        aria-label={`Edit ${article.name}`}
                    />

                    {/*<Button*/}
                    {/*    color="failure"*/}
                    {/*    size="xs"*/}
                    {/*    onClick={(e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        onDelete(e, article._id)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Delete*/}
                    {/*</Button>*/}
                </div>
            </TableCell>
        </TableRow>
    );
});

ArticleTableRow.propTypes = {
    article: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default ArticleTableRow;
