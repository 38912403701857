import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSession } from "../../context/SessionContext";
import axios from "axios";
import _ from "lodash";

function MyAccountPage() {
    const { session, setSession } = useSession();
    const [account, setAccount] = useState(null);
    const [balance, setBalance] = useState(0);
    const [memberships, setMemberships] = useState([]);
    const [payments, setPayments] = useState([]); // State for payments
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedPaymentId, setExpandedPaymentId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Function to fetch account details
        const fetchAccountDetails = async () => {
            try {
                const accountResponse = await axios.get(`${process.env.REACT_APP_API_URL}/account`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                setAccount(accountResponse.data);
            } catch (err) {
                console.error('Error fetching account details:', err);
                setError('Failed to load account details.');
            }
        };

        // Function to fetch account balance
        const fetchBalance = async () => {
            try {
                const balanceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/account/balance`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                setBalance(balanceResponse.data);
                if (balanceResponse.data) {
                    setSession((prevSession) => ({
                        ...prevSession,
                        ink: { availableBalance: balanceResponse.data }
                    }));
                }
            } catch (err) {
                console.error('Error fetching balance:', err);
                setError('Failed to load account balance.');
            }
        };

        // Function to fetch memberships
        const fetchMemberships = async () => {
            try {
                const membershipsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/membership/list`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                setMemberships(membershipsResponse.data);
            } catch (err) {
                console.error('Error fetching memberships:', err);
                setError('Failed to load memberships.');
            }
        };

        // Function to fetch payments
        const fetchPayments = async () => {
            try {
                const paymentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/payment/list`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                setPayments(paymentsResponse.data);
            } catch (err) {
                console.error('Error fetching payments:', err);
                setError('Failed to load payments.');
            }
        };

        // Fetch all data concurrently
        const fetchData = async () => {
            setLoading(true);
            await Promise.all([fetchAccountDetails(), fetchBalance(), fetchMemberships(), fetchPayments()]);
            setLoading(false);
        };

        fetchData();
    }, [session.token]);

    // Function to handle subscription cancellation
    const handleCancelSubscription = async (membershipId) => {
        if (!window.confirm('Are you sure you want to cancel this subscription?')) return;

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/membership/${membershipId}`, {
                headers: { Authorization: `Bearer ${session.token}` },
            });
            setMemberships(prev =>
                prev.map(m => m._id === membershipId ? { ...m, cancelledAt: new Date() } : m)
            );
            alert('Subscription canceled successfully.');
        } catch (err) {
            console.error('Error canceling subscription:', err);
            alert('Failed to cancel subscription.');
        }
    };

    // Function to navigate to the account deletion confirmation page
    const handleDeleteAccount = () => {
        navigate('/account/forgetme');
    };

    // Toggle payment details expansion
    const togglePaymentDetails = (paymentId) => {
        setExpandedPaymentId(prevId => (prevId === paymentId ? null : paymentId));
    };

    // Group usage details
    const groupUsageDetails = (usageDetails) => {
        const grouped = [];
        let currentGroup = null;

        usageDetails.forEach((usage) => {
            if (
                currentGroup &&
                currentGroup.name === usage.name &&
                currentGroup.partial === usage.partial
            ) {
                currentGroup.count ++;
                currentGroup.amount += usage.amount;
                currentGroup.endDate = usage.createdAt;
            } else {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = {
                    name: usage.name,
                    amount: usage.amount,
                    count: 1,
                    startDate: usage.createdAt,
                    endDate: usage.createdAt,
                    partial: usage.partial,
                };
            }
        });

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    // Display loading state
    if (loading) return <div>Loading...</div>;

    // Display error state
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">My Account Details</h1>

            {/* Account Details */}
            <section className="mb-6">
                <h2 className="text-xl font-semibold">Account Details</h2>
                <div className="mt-2">
                    {/*<p><strong>Email:</strong> {account.email}</p>*/}
                    <p><strong>Created At:</strong> {new Date(account.createdAt).toLocaleDateString()}</p>
                </div>
            </section>

            {/* Balance */}
            <section className="mb-6">
                <h2 className="text-xl font-semibold">Balance</h2>
                <div className="mt-2">
                    <p><strong>Total Balance:</strong> {balance} Ink</p>
                </div>
            </section>

            {/* Memberships */}
            <section className="mb-6">
                <h2 className="text-xl font-semibold">Memberships</h2>
                <div className="mt-2">
                    {memberships.length === 0 ? (
                        <p>No memberships found.</p>
                    ) : (
                        <table className="min-w-full bg-white dark:bg-gray-800">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Interval</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {memberships.map(membership => (
                                <tr key={membership._id} className="text-center">
                                    <td className="py-2 px-4 border-b">{membership.membershipLevel.name}</td>
                                    <td className="py-2 px-4 border-b">{membership.membershipLevel.interval}</td>
                                    <td className="py-2 px-4 border-b">
                                        {membership.cancelledAt ? 'Cancelled' :
                                            new Date(membership.expiresAt) > new Date() ? 'Active' : 'Expired'}
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        {!membership.cancelledAt && new Date(membership.expiresAt) > new Date() && (
                                            <button
                                                onClick={() => handleCancelSubscription(membership._id)}
                                                className="bg-red-500 text-white px-3 py-1 rounded"
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </section>

            {/* Payments */}
            <section className="mb-6">
                <h2 className="text-xl font-semibold">Payments</h2>
                <div className="mt-2">
                    {payments.length === 0 ? (
                        <p>No payments found.</p>
                    ) : (
                        <table className="min-w-full bg-white dark:bg-gray-800">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Product Type</th>
                                <th className="py-2 px-4 border-b">Product</th>
                                <th className="py-2 px-4 border-b">Amount</th>
                                <th className="py-2 px-4 border-b">Ink</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Created At</th>
                                <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {payments.map(payment => (
                                <React.Fragment key={payment._id}>
                                    <tr className="text-center">
                                        <td className="py-2 px-4 border-b capitalize">{payment.productType}</td>
                                        <td className="py-2 px-4 border-b">{payment.product}</td>
                                        <td className="py-2 px-4 border-b">${payment.amount.toFixed(2)}</td>
                                        <td className="py-2 px-4 border-b">{payment.ink.balance}/{payment.ink.initial}</td>
                                        <td className="py-2 px-4 border-b">
                                            {payment.approved ? 'Approved' :
                                                payment.deletedAt ? 'Deleted' : 'Pending'}
                                        </td>
                                        <td className="py-2 px-4 border-b">{new Date(payment.createdAt).toLocaleDateString()}</td>
                                        <td className="py-2 px-4 border-b">
                                            <button
                                                onClick={() => togglePaymentDetails(payment._id)}
                                                className="bg-blue-500 text-white px-3 py-1 rounded"
                                            >
                                                {expandedPaymentId === payment._id ? 'Hide Usage' : 'Show Usage'}
                                            </button>
                                        </td>
                                    </tr>
                                    {expandedPaymentId === payment._id && payment.ink && payment.ink.usageDetail.length > 0 && (
                                        <tr className="text-center">
                                            <td colSpan="7" className="py-2 px-4 border-b bg-gray-100 dark:bg-gray-700">
                                                <h3 className="text-lg font-semibold mb-2">{payment.ink.usage} Ink Used</h3>
                                                <div className="overflow-auto max-w-full">
                                                    <table className="w-full bg-white dark:bg-gray-800">
                                                        <thead>
                                                        <tr>
                                                            <th className="py-2 px-4 border-b">Name</th>
                                                            <th className="py-2 px-4 border-b">Count</th>
                                                            <th className="py-2 px-4 border-b">Ink</th>
                                                            <th className="py-2 px-4 border-b">Date</th>
                                                            <th className="py-2 px-4 border-b">Fraction</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {groupUsageDetails(payment.ink.usageDetail).map((group, index) => (
                                                            <tr key={index} className="text-center">
                                                                <td className="py-2 px-4 border-b">{group.name}</td>
                                                                <td className="py-2 px-4 border-b">{group.count}</td>
                                                                <td className="py-2 px-4 border-b">{group.amount}</td>
                                                                <td className="py-2 px-4 border-b">
                                                                    {new Date(group.startDate).toLocaleDateString()} - {new Date(group.endDate).toLocaleDateString()}
                                                                </td>
                                                                <td className="py-2 px-4 border-b">{group.partial ? 'Partial' : 'All'}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </section>

            {/* Delete Account */}
            <section className="mb-6">
                <h2 className="text-xl font-semibold">Delete Account</h2>
                <div className="mt-2">
                    <button
                        onClick={handleDeleteAccount}
                        className="bg-red-600 text-white px-4 py-2 rounded"
                    >
                        Delete My Account
                    </button>
                </div>
            </section>
        </div>
    )
}

export default MyAccountPage;
