import React, { useEffect, useState } from 'react';
import {Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow, Button, Badge} from "flowbite-react";
import {PiMagicWandFill} from "react-icons/pi";
import axios from "axios";
import AddEditCharacter from "../../oldComponents/AddEditCharacter";
import { useSession } from '../../context/SessionContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa'; // Import the copy icon

function ShareWorld({world}) {
    const [shareLinks, setShareLinks] = useState([]);
    const { session, setSession } = useSession();
    const shareLevels = ["Read", "Write", "Own"];

    const fetchShareLinks = async () => {
        try {
            const response = (await axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world?id=${world._id}`)).data;
            const filteredResponse = response.filter(item => item.grantLevel <= 2);
            setShareLinks(filteredResponse);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchShareLinks();
    }, [world]);


    async function createSharingLink() {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/entitlement/world`,
                {
                    name: "sharing is caring",
                    message: "Hi fren",
                    world: world._id,
                    grantLevel: 2,
                    granted: [],
                    expiresAt: null
                }
            );
            setShareLinks([...shareLinks, response.data]);
        } catch (error) {
            console.error(error);
        }
    }

    async function deleteSharingLink(shareId) {
        try {
            const response = (await axios.delete(`${process.env.REACT_APP_API_URL}/entitlement/world/${shareId}`)).data;
            fetchShareLinks();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="overflow-x-auto">
            <div className='flex ml-4 mb-4 space-x-2'>
                <Button theme={buttonTheme} pill color="gray" onClick={createSharingLink}>Create Write Link</Button>
            </div>
            <Table theme={tableTheme} striped>
                <TableHead>
                    <TableHeadCell>Access</TableHeadCell>
                    <TableHeadCell>Users</TableHeadCell>
                    <TableHeadCell>Link</TableHeadCell>
                    <TableHeadCell>Delete</TableHeadCell>
                </TableHead>
                <TableBody className="divide-y">
                    {shareLinks.map(share => {
                        return (
                            <TableRow key={share._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <TableCell>{shareLevels[share.grantLevel - 1]}</TableCell>
                                <TableCell>{share.granted?.length}</TableCell>
                                <TableCell>
                                    <div className="flex items-center">
                                        <a href={`${process.env.REACT_APP_URL}/share/world/${share._id}`}
                                           className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4">
                                            {process.env.REACT_APP_URL}/share/world/{share._id}
                                        </a>
                                        <CopyToClipboard text={`${process.env.REACT_APP_URL}/share/world/${share._id}`}>
                                            <FaCopy
                                                className="ml-2 text-gray-500 cursor-pointer hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                                                title="Copy to clipboard"
                                            />
                                        </CopyToClipboard>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <a href="#"
                                       className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 ml-4"
                                       onClick={() => deleteSharingLink(share._id)}>
                                        Delete
                                    </a>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

export default ShareWorld;


const tableTheme = {
    "root": {
        "base": "w-full text-left text-sm text-gray-500 dark:text-gray-400",
        "shadow": "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white drop-shadow-md dark:bg-black",
        "wrapper": "relative"
    },
    "body": {
        "base": "group/body",
        "cell": {
            "base": "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg"
        }
    },
    "head": {
        "base": "group/head text-xs uppercase text-gray-700 dark:text-gray-400",
        "cell": {
            "base": "bg-gray-50 px-6 py-3 group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg dark:bg-gray-700"
        }
    },
    "row": {
        "base": "group/row",
        "hovered": "hover:bg-gray-50 dark:hover:bg-gray-600",
        "striped": "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
    }
}


const buttonTheme =
    {
        "base": "inline-flex",
        "position": {
            "none": "",
            "start": "rounded-r-none focus:ring-2",
            "middle": "rounded-none border-l-0 pl-0 focus:ring-2",
            "end": "rounded-l-none border-l-0 pl-0 focus:ring-2"
        }
    }