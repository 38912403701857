// frontend/src/components/UserInput.js
import React, { useState } from 'react';
import ListBarSuggestions from "../../oldComponents/list/ListBarSuggestions";
import {TextInput} from "flowbite-react";
import {GiQuillInk} from "react-icons/gi";
import axios from "axios";
import {useSession} from "../../context/SessionContext";

const UserInput = ({ scene, session, setSession, startStreaming }) => {

    const handleEnterKeyPress = async (event) => {
        if (event.key === 'Enter') {
            const prompt = { promptEntered: event.target.value };
            event.target.value = '';
            await postNewParagraph(prompt);
        }
    };

    const postNewParagraph = async (prompt) => {
        try {
            const lastUserPrompt = JSON.stringify(prompt);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/scene/${scene._id}/paragraph`, { lastUserPrompt }, {
                headers: { Authorization: `Bearer ${session.token}` }
            });
            startStreaming();
        } catch (error) {
            console.error('Error while posting new paragraph:', error);
        }
    };

    return (
        <div className="px-6 py-3 scene-input-container">
            {/*<div className="flex justify-between items-center">*/}
            {/*    <ListBarSuggestions suggestions={suggestions} handleSuggestion={postNewParagraph}/>*/}
            {/*</div>*/}

            <TextInput
                onKeyPress={handleEnterKeyPress}
                id="prompt"
                placeholder="Say something or leave blank to continue..."
                // rightIcon={PiKeyReturnThin}
                rightIcon={GiQuillInk}
            />
        </div>

    );
};

export default UserInput;
