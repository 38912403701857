import React, {useCallback, useEffect, useRef, useState} from 'react';
import SceneLoader from '../../components/adventure/SceneLoader';
import {useSession} from "../../context/SessionContext";
import CharCard from "../../components/adventure/CharCard";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {IoArrowBackCircle, IoBookmarksSharp, IoBookSharp, IoHomeSharp} from "react-icons/io5";
import { TiArrowBack } from "react-icons/ti";

const AdventurePage = () => {
    const { session, setSession } = useSession();
    const { id } = useParams();
    const [adventure, setAdventure] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Fetch adventure and characters when the component mounts
    useEffect(() => {
        const fetchAdventure = async () => {
            setLoading(true);
            try {
                // Fetch adventure
                const adventureResponse = await axios.get(`${process.env.REACT_APP_API_URL}/adventure/${id}`, {
                    headers: { Authorization: `Bearer ${session.token}` },
                });
                const adventureData = adventureResponse.data
                setAdventure(adventureData);
            } catch (err) {
                console.error('Failed to fetch adventure or characters:', err);
                setError('Failed to load adventure or characters. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (session?.token) {
            fetchAdventure();
        }
    }, [id, session.token]);



    // Render loading state
    if (loading) {
        return <div>Loading adventure...</div>;
    }

    // Render error state
    if (error) {
        return <div className="text-red-500 p-6">{error}</div>;
    }


    return (
        <div className="flex flex-col h-full"> {/* Use h-full to let it take up the full height of the parent */}
            {session.user.permissionLevel >= 2 &&
                <Link to={`/story/${adventure.story._id}`}
                      className="flex flex-row  justify-center items-center mx-auto hover:text-blue-600">
                    <IoBookSharp size={24}/>
                    <div className={"text-2xl font-bold pl-1 pr-6"}>Story - "{adventure.story.name}"</div>
                </Link>
            }
            {session.user.permissionLevel < 2 &&
                <div>
                    className="flex flex-row  justify-center items-center mx-auto hover:text-blue-600">
                    <IoBookSharp size={24}/>
                    <div className={"text-2xl font-bold pl-1 pr-6"}>Story - "{adventure.story.name}"</div>
                </div>
            }
            <div className={"flex flex-row justify-center items-center mx-auto"}>
                <IoBookmarksSharp size={16}/>
                <div className={"pl-1 pr-6"}>"{adventure.name}" - {adventure.character.name}</div>
            </div>

            <div className="flex-grow h-0 overflow-y-auto"> {/* Take up remaining height */}
                <SceneLoader
                    session={session}
                    setSession={setSession}
                    adventureId={adventure._id}
                    startAt="end" // or "start"
                />
            </div>
        </div>
    );
};

export default AdventurePage;