// src/components/NewStory/StoryWizardForm/steps/Step_Themes.js

import React from 'react';
import SelectStep from '../SelectStep';
import { predefinedThemes } from '../predefinedData';
import { useWizard } from '../WizardContext';

const Step_Themes = () => {
    const { nextStep, prevStep } = useWizard();

    return (
        <SelectStep
            title="Select some Prevailing Themes in Your Story"
            subTitle="(Pick at least one but upto Three)"
            predefinedList={predefinedThemes}
            formDataField="themes"
            selectionLimit={3} // Allow unlimited selections
            minSelection={1}
            nextStep={nextStep}
            prevStep={prevStep}
        />
    );
};

export default Step_Themes;
