import {Blockquote, Breadcrumb, Button, Card} from "flowbite-react";
import {useSession} from "../../context/SessionContext";
import StoryShelves from "../../components/story/StoryShelves";
import React from "react";

function DiscoverPage() {
    const { session } = useSession();

    return (
        <div>
            <StoryShelves session={session}/>
        </div>
    )
}

export default DiscoverPage;
