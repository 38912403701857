// src/components/NewStory/CreateNewForm.js
import React, {useEffect, useRef, useState} from 'react';
import { Button, Label, Select, TextInput, Textarea } from 'flowbite-react';
import TagsInput from '../../components/common/TagsInput';
import {TextAreaTheme} from "../../themes/TextAreaTheme";
import {GiQuillInk} from "react-icons/gi";
import axios from "axios";
import {useSession} from "../../context/SessionContext";
import {useNavigate} from "react-router-dom";

function StoryNew() {
    const { session } = useSession();
    const navigate = useNavigate();
    const initialState = {
        name: '',
        description: '',
        tags: [],
        world: '',
        premise: '',
        genre: [],
        themes: [],
        introduction: ''
    };
    const [newStory, setNewStory] = useState(initialState);
    const addEditImageRef = useRef();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [entitledWorlds, setEntitledWorlds] = useState(null);

    // Fetch worlds the user is entitled to inherit from
    useEffect(() => {
        async function fetchEntitledWorlds() {
            let response = {};
            try {
                const promise1 = axios.get(`${process.env.REACT_APP_API_URL}/public/world`, {
                });
                const promise2 = axios.get(`${process.env.REACT_APP_API_URL}/entitlement/world`, {
                    headers: {
                        Authorization: `Bearer ${session.token}`,
                    },
                });
                const [response1, response2] = await Promise.all([promise1, promise2]);
                const combinedData = [...response1.data, ...response2.data];
                const storiesMap = new Map();
                combinedData.forEach((item) => {
                    const existingStory = storiesMap.get(item.world._id);

                    if (existingStory) {
                        storiesMap.set(item.world._id, { ...existingStory, ...item });
                    } else {
                        storiesMap.set(item.world._id, item);
                    }
                });
                response.data = Array.from(storiesMap.values());
                setEntitledWorlds(response.data);
                initialState.world = response.data[0].world._id;
                setNewStory(initialState);
            } catch (error) {
                console.error('Failed to fetch entitled worlds', error);
            }
        }
        fetchEntitledWorlds();

    }, []);

    if (!entitledWorlds) {
        return <div className={"p-6"}>Loading worlds...</div>;
    }

    if (entitledWorlds && entitledWorlds.length === 0) {
        return <div className={"p-6 text-red-500"}>Creating a new story requires access to at least one world!</div>;
    }

    const handleImageSave = async () => {
        try {
            // Trigger the commit action on AddEditImage
            await addEditImageRef.current.commit();
        } catch (err) {
            console.error('Error saving:', err);
            //setError('Failed to save changes.');
        }
    };

    const handleChange = (field, value) => {
        setNewStory((prev) => ({ ...prev, [field]: value }));
    };

    // Handle story submission
    const handleSubmit = async () => {
        setIsProcessing(true);
        await handleImageSave();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/story`, newStory);
        setIsProcessing(false);
        navigate(`/story/${response.data._id}`);
    };

    return (
        <div className="p-6 space-y-4">
            <div>
                <Label htmlFor="name" value="Title" />
                <TextInput
                    id="name"
                    placeholder="Story title"
                    value={newStory.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    required
                />
            </div>
            {/*<div>*/}
            {/*    <Label htmlFor="description" value="Describe the story you want" />*/}
            {/*    <Textarea*/}
            {/*        id="description"*/}
            {/*        placeholder="Story description"*/}
            {/*        theme={TextAreaTheme}*/}
            {/*        value={newStory.description}*/}
            {/*        onChange={(e) => handleChange('description', e.target.value)}*/}
            {/*        required*/}
            {/*        rows={4}*/}
            {/*    />*/}
            {/*</div>*/}
            <div>
                <Label htmlFor="premise" value="Premise" />
                <Textarea
                    id="premise"
                    placeholder="Story premise"
                    theme={TextAreaTheme}
                    value={newStory.premise}
                    onChange={(e) => handleChange('premise', e.target.value)}
                    required
                    rows={4}
                />
            </div>
            <div>
                <Label htmlFor="introduction" value="Introduction" />
                <Textarea
                    id="introduction"
                    placeholder="Introduction to start the story"
                    theme={TextAreaTheme}
                    value={newStory.introduction}
                    onChange={(e) => handleChange('introduction', e.target.value)}
                    required
                    rows={4}
                />
            </div>
            <div>
                <Label htmlFor="genre" value="Genre" />
                <TagsInput
                    id="genre"
                    placeholder="Story genre"
                    tags={newStory.genre}
                    onChange={(tags) => handleChange('genre', tags)}
                    required
                />
            </div>
            {/*<div>*/}
            {/*    <Label htmlFor="tags" value="Tags" />*/}
            {/*    <TagsInput*/}
            {/*        tags={newStory.tags}*/}
            {/*        onChange={(tags) => handleChange('tags', tags)}*/}
            {/*        placeholder="Add tags"*/}
            {/*    />*/}
            {/*</div>*/}
            <div >
                <Label htmlFor="themes" value="Themes" />
                <TagsInput
                    tags={newStory.themes}
                    onChange={(tags) => handleChange('themes', tags)}
                    placeholder="Add themes"
                />
            </div>

                <div>
                    <Label htmlFor="world" value="World" />
                    <Select
                        id="world"
                        value={newStory.world}
                        onChange={(e) => handleChange('world', e.target.value)}
                        required
                    >
                        {entitledWorlds.map((entitlement, index) => (
                            <option key={index} value={entitlement.world._id}>
                                {entitlement.world.name}
                            </option>
                        ))}
                    </Select>
                </div>

            <div className="w-full">
                <Button onClick={handleSubmit}>Create New Story<GiQuillInk size={16} className={"ml-1 translate-x-1"}/>1</Button>
            </div>
        </div>
    );
}

export default StoryNew;
