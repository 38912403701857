// src/components/NewStory/StoryWizardForm/steps/SelectStep.js

import React, { useEffect, useState } from 'react';
import SelectableCard from './SelectableCard';
import PropTypes from 'prop-types';
import { Button } from "flowbite-react";
import { useWizard } from "./WizardContext";
import { useMediaQuery } from 'react-responsive';

// Utility function to shuffle an array
const shuffleArray = (array) => {
    return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
};

const SelectStep = ({
                        title,
                        subTitle,
                        predefinedList,
                        formDataField,
                        selectionLimit = null, // null means no limit
                        minSelection = 0, // New prop for minimum selection
                        isSingleSelect = false, // New prop for single selection
                        nextStep,
                        prevStep,
                    }) => {
    const { formData, updateFormData, setDisplayedItems, getDisplayedItems } = useWizard();
    const [initialized, setInitialized] = useState(false); // To ensure initialization only once
    const [error, setError] = useState('');

    // Define media query breakpoints
    const isXL = useMediaQuery({ minWidth: 1280 });
    const isMD = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
    const isSM = useMediaQuery({ minWidth: 640, maxWidth: 767 });
    const isXS = useMediaQuery({ maxWidth: 639 });

    // Determine totalDisplayCount and grid columns based on screen size
    let totalDisplayCount = 6;
    let gridCols = 1;

    if (isXL) {
        totalDisplayCount = 12;
        gridCols = 4;
    } else if (isMD) {
        totalDisplayCount = 9;
        gridCols = 3;
    } else if (isSM) {
        totalDisplayCount = 8;
        gridCols = 2;
    } else if (isXS) {
        totalDisplayCount = 6;
        gridCols = 1;
    }

    // If single select, enforce selectionLimit to 1
    const effectiveSelectionLimit = isSingleSelect ? 1 : selectionLimit;

    useEffect(() => {
        if (!initialized) {
            // Function to get random keys excluding already selected ones
            const getRandomKeys = (predefinedList, count, exclude = []) => {
                const allKeys = Object.keys(predefinedList).filter((key) => !exclude.includes(key));
                const shuffled = shuffleArray(allKeys);
                return shuffled.slice(0, count);
            };

            // Retrieve selected items from formData
            const selectedItems = formData[formDataField] || [];

            // Calculate remaining count after accounting for selected items
            const remainingCount = Math.max(totalDisplayCount - selectedItems.length, 0);

            // Get random items excluding selected ones
            const randomItems = getRandomKeys(predefinedList, remainingCount, selectedItems);

            // Combine selected items and random items
            const combinedItems = [...selectedItems, ...randomItems];

            // Shuffle the combined list to distribute selected items randomly within the grid
            const shuffledItems = shuffleArray(combinedItems);

            // Store displayed items in context
            setDisplayedItems(formDataField, shuffledItems);

            setInitialized(true);
        }
    }, [predefinedList, formDataField, totalDisplayCount, setDisplayedItems, formData, initialized]);

    const displayedItems = getDisplayedItems(formDataField);

    const handleSelect = (key) => {
        const currentSelections = formData[formDataField] || [];

        if (isSingleSelect) {
            if (currentSelections.includes(key)) {
                // **Prevent Deselecting the Selected Item**
                // Option 1: Do nothing (users cannot deselect once selected)
                // Option 2: Show a message or handle as needed
                return;
            } else {
                // Select the new item, replacing any existing selection
                updateFormData({ [formDataField]: [key] });
            }
        } else {
            const isSelected = currentSelections.includes(key);

            if (isSelected) {
                // Deselect the item
                const updatedSelections = currentSelections.filter((itemKey) => itemKey !== key);
                updateFormData({ [formDataField]: updatedSelections });
            } else {
                // Check for selection limit
                if (effectiveSelectionLimit && currentSelections.length >= effectiveSelectionLimit) {
                    // Do not select more items
                    return;
                }
                // Select the item
                const updatedSelections = [...currentSelections, key];
                updateFormData({ [formDataField]: updatedSelections });
            }
        }
    };

    const handleNext = () => {
        const currentSelections = formData[formDataField] || [];

        if (currentSelections.length < minSelection) {
            setError(`Please make at least ${minSelection} selection${minSelection > 1 ? 's' : ''}.`);
            return;
        }

        if (effectiveSelectionLimit && currentSelections.length > effectiveSelectionLimit) {
            setError(`Please select no more than ${effectiveSelectionLimit} item${effectiveSelectionLimit > 1 ? 's' : ''}.`);
            return;
        }

        setError('');
        nextStep();
    };

    const handleBack = () => {
        prevStep();
    };

    // Determine if the selection limit has been reached
    const isLimitReached = effectiveSelectionLimit && (formData[formDataField]?.length >= effectiveSelectionLimit);

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className={"flex-row"}>
                    <h3 className="font-bold italic text-lg inline pr-3 dark:text-gray-300">{title}</h3>
                    <span>{subTitle}</span>
                </div>

                {(effectiveSelectionLimit || isSingleSelect) && (
                    <span className="text-sm text-gray-600">
                        {formData[formDataField]?.length || 0} / {effectiveSelectionLimit || '∞'} selected
                    </span>
                )}
            </div>
            <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4`}>
                {displayedItems.map((key) => {
                    const isSelected = (formData[formDataField] || []).includes(key);
                    return (
                        <SelectableCard
                            key={`${formDataField}-${key}`}
                            itemKey={key}
                            itemData={predefinedList[key]}
                            isSelected={isSelected}
                            onSelect={handleSelect}
                            isDisabled={!isSingleSelect && isLimitReached && !isSelected}
                        />
                    );
                })}
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <div className="flex justify-between mt-6 pt-4">

                <Button
                    color="gray"
                    onClick={handleBack}
                    disabled={!prevStep}
                >
                    Back
                </Button>

                <Button
                    onClick={handleNext}
                    disabled={!nextStep}
                >
                    Next
                </Button>
            </div>
        </div>
    );
};

SelectStep.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    predefinedList: PropTypes.object.isRequired,
    formDataField: PropTypes.string.isRequired,
    selectionLimit: PropTypes.number,
    minSelection: PropTypes.number, // New prop
    isSingleSelect: PropTypes.bool, // New prop
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
};

export default SelectStep;
