// src/components/NewStory/StoryWizardForm/steps/SummaryPanel.js

import React from 'react';
import { useWizard } from './WizardContext';
import {
    predefinedWorldTypes,
    predefinedStoryTypes,
    predefinedThemes,
    predefinedLocations,
    predefinedCharacters,
    predefinedStoryArcs,
    predefinedFormats, predefinedStoryConflicts, predefinedSymbolism, predefinedIncitingIncident, predefinedTone
} from './predefinedData';

const SummaryPanel = () => {
    const { formData } = useWizard();

    return (
        <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-md shadow-md">
            <h4 className="font-semibold">Your Selections:</h4>
            <ul className="list-disc list-inside">
                {formData.format && <li>Format: {predefinedFormats[formData.format].name}</li>}
                {formData.genres.length > 0 && <li>Genre: {formData.genres.map(key => predefinedStoryTypes[key].name).join(', ')}</li>}
                {formData.conflict && <li>Conflict: {predefinedStoryConflicts[formData.conflict].name}</li>}
                {formData.themes.length > 0 && <li>Themes: {formData.themes.map(key => predefinedThemes[key].name).join(', ')}</li>}
                {formData.symbolism.length > 0 && <li>Symbolism: {formData.symbolism.map(key => predefinedSymbolism[key].name).join(', ')}</li>}
                {formData.location && <li>Location: {predefinedLocations[formData.location].name}</li>}
                {formData.tone && <li>Narrative Tone: {predefinedTone[formData.tone].name}</li>}
                {formData.incident && <li>Inciting Incident: {predefinedIncitingIncident[formData.incident].name}</li>}
                {/*{formData.obstacles.length > 0 && <li>Story Type: {formData.obstacles.map(key => predefinedObstacles[key].name).join(', ')}</li>}*/}
                {/*{formData.protagonist && <li>Protagonist: {predefinedCharacters[formData.protagonist].name}</li>}*/}
                {/*{formData.antagonist && <li>Antagonist: {predefinedCharacters[formData.antagonist].name}</li>}*/}

            </ul>
        </div>
    );
};

export default SummaryPanel;
