import React, { useEffect, useState } from 'react';
import { Banner, BannerCollapseButton } from 'flowbite-react';
import { MdAnnouncement } from 'react-icons/md';
import { HiX } from 'react-icons/hi';
import { useSession } from '../../context/SessionContext';

const BANNER_KEY = 'LoggedOutBanner';

export default function LoggedOutBanner() {
    const { session } = useSession();
    const [closed, setClosed] = useState(localStorage.getItem(BANNER_KEY) === 'true');

    const handleClose = () => {
        setClosed(true);
    };

    useEffect(() => {
        localStorage.setItem(BANNER_KEY, closed.toString());
    }, [closed]);

    useEffect(() => {
        if (session?.token) {
            handleClose();
        }
    }, [session]);

    if (closed) {
        return null;
    }


    return (
        <Banner>
            <div className="flex w-full justify-between border-b border-gray-200 bg-blue-200 p-4 dark:border-gray-600 dark:bg-gray-700">
                <div className="mx-auto flex items-center">
                    <p className="flex items-center text-md font-normal text-gray-600 dark:text-gray-400">
                        <MdAnnouncement className="mr-4 h-5 w-5"/>
                        <span
                            className="[&_p]:inline">You have been logged out of MyAdventures, please sign back in to continue.</span>
                    </p>
                </div>
                <BannerCollapseButton color="gray" className="border-0 bg-transparent text-gray-500 dark:text-gray-400" onClick={handleClose}>
                    <HiX className="h-4 w-4"/>
                </BannerCollapseButton>
            </div>
        </Banner>
    );
}