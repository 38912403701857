import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "../../context/SessionContext";
import {Button} from "flowbite-react";
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon

const StoryStatus = () => {
    const [story, setStory] = useState(null);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const { session } = useSession();
    const navigate = useNavigate();
    const [isRetrying, setIsRetrying] = useState(false); // New state

    useEffect(() => {
        let intervalId;

        const fetchStoryStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/story/${id}/status`, {
                    headers: {
                        'Authorization': `Bearer ${session.token}`,
                        //'Cache-Control': 'no-cache'
                    }
                });
                const fetchedStory = response.data;
                setStory(fetchedStory);

                // If generationProgress is null, stop the polling and navigate to the story page
                if (fetchedStory.generationProgress === null && fetchedStory.generationProgressPercentage === 100 ) {
                    clearInterval(intervalId);
                    // Set a 15-second delay before navigating to give it time to finish up everything
                    setTimeout(() => {
                        navigate(`/story/${id}/adventure/new`);
                    }, 15000); // 15000 milliseconds = 15 seconds
                }
            } catch (err) {
                console.error('Error fetching story status:', err);
                setError('Failed to fetch story status. Please try again later.');
                clearInterval(intervalId);
            }
        };

        // Start polling every 5 seconds
        intervalId = setInterval(fetchStoryStatus, 5000);

        // Fetch initial status immediately
        fetchStoryStatus();

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [id, session.token, navigate]);

    async function AbortStoryGeneration() {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/story/${id}`, {
                headers: {
                    'Authorization': `Bearer ${session.token}`
                }
            });
            navigate(`/stories`);
        } catch (error) {
            console.error('Error aborting story generation:', error);
            setError('Failed to abort the story generation. Please try again later.');
        }
    }

    async function handleRetry() {
        setIsRetrying(true);
        // Wait for 20 seconds before refreshing
        setTimeout(() => {
            window.location.reload();
        }, 20000); // 20000 milliseconds = 20 seconds
    }

    if (error) {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="text-red-500 text-xl text-center">
                    {error}
                </div>
                <div className={'flex pt-4 space-x-4'}>
                    <div>
                        <Button color={'red'} onClick={AbortStoryGeneration}>Abort</Button>
                    </div>
                    <Button color={'blue'} onClick={handleRetry} disabled={isRetrying}>
                        {isRetrying ? (
                            <div className="flex items-center">
                                <FaSpinner className="animate-spin mr-2" />
                                Retrying...
                            </div>
                        ) : (
                            'Retry'
                        )}
                    </Button>
                </div>
            </div>
        );
    }

    if (!story) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-xl text-center">
                    Loading story status...
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-6 pb-16">
            <h1 className="text-3xl font-bold mb-4 text-center">Title: {story.name || 'un-named...'}</h1>
            <p className="mb-2"><strong>Description:</strong> {story.description || '...'}</p>
            <p className="mb-2"><strong>Premise:</strong> {story.premise || '...'}</p>
            <p className="mb-4"><strong>Genre:</strong> {story.genre.join(', ')}</p>
            <p className="mb-4"><strong>Themes:</strong> {story.themes.join(', ')}</p>
            <div className="mb-4">
                <h2 className="text-2xl font-semibold mb-2">Generation In Progress...</h2>
                <div className="bg-gray-200 rounded-full h-6 mb-2">
                    <div
                        className="bg-blue-600 h-6 rounded-full"
                        style={{width: story.generationProgressPercentage + '%'}}
                    ></div>
                </div>
                <p className="text-lg">{story.generationProgress || 'Finalizing...'}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h3 className="text-xl font-semibold mb-2">Characters</h3>
                    {story.charactersGenerated && story.charactersGenerated.length > 0 ? (
                        <ul className="list-disc list-inside text-xs">
                            {story.charactersGenerated.map((char) => (
                                <li key={char._id}>
                                    <strong>{char.name}</strong>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No characters added yet.</p>
                    )}
                </div>

                <div>
                    <h3 className="text-xl font-semibold mb-2">Locations</h3>
                    {story.locationsGenerated && story.locationsGenerated.length > 0 ? (
                        <ul className="list-disc list-inside text-xs">
                            {story.locationsGenerated.map((loc) => (
                                <li key={loc._id}>
                                    <strong>{loc}</strong>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No locations added yet.</p>
                    )}
                </div>

                <div>
                    <h3 className="text-xl font-semibold mb-2">Plot Lines</h3>
                    {story.plotLines && story.plotLines.length > 0 ? (
                        <ul className="list-disc list-inside text-xs">
                            {story.plotLines.map((plot) => (
                                <li key={plot.index}>
                                    <strong>{plot.name}</strong>: {plot.description}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No plot lines added yet.</p>
                    )}
                </div>

                <div>
                    <h3 className="text-xl font-semibold mb-2">Plot Elements</h3>
                    {story.plotElementsGenerated && story.plotElementsGenerated.length > 0 ? (
                        <ul className="list-disc list-inside text-xs">
                            {story.plotElementsGenerated.map((plot) => (
                                <li key={plot._id}>
                                    <strong>{plot}</strong>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No plot elements added yet.</p>
                    )}
                </div>

            </div>
            <div className={'pt-4'}>
                <Button color={'red'} onClick={AbortStoryGeneration}>Abort</Button>
            </div>
        </div>
    );
};

export default StoryStatus;
