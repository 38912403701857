// src/components/LocationFormModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Label,
    Modal,
    TextInput,
    Textarea,
    Button,
    Checkbox,
    Spinner,
} from 'flowbite-react';
import PropertiesManager from "../../common/PropertiesManager";
import PropTypes from "prop-types";
import { TextAreaTheme, TextAreaThemeReadOnly } from "../../../themes/TextAreaTheme";
import { ModalTheme } from "../../../themes/ModalTheme";
import { ButtonTheme } from "../../../themes/ButtonTheme";
import { LabelTheme } from "../../../themes/LabelTheme";
import AddEditImage from "../../common/AddEditImage";
import { GiQuillInk } from "react-icons/gi";
import { GrEdit } from "react-icons/gr";
import { FaMapMarkerAlt, FaMagic } from "react-icons/fa";
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from "react-icons/md";
import { TextInputTheme, TextInputThemeReadOnly } from "../../../themes/TextInputTheme";

const LocationFormModal = ({
                               isOpen,
                               onClose,
                               locationToEdit,
                               story,
                               onSuccess,
                               viewOnly,
                           }) => {
    const [isViewOnly, setIsViewOnly] = useState(viewOnly);
    const [name, setName] = useState('');
    const [showInScene, setShowInScene] = useState(false);
    const [description, setDescription] = useState('');
    const [itemSpawns, setItemSpawns] = useState(''); // New state
    const [characterSpawns, setCharacterSpawns] = useState(''); // New state
    const [labels, setLabels] = useState([]);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const addEditImageRef = useRef();

    // New states for suggestion functionality
    const [suggestions, setSuggestions] = useState([]);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);
    const [newLocationId, setNewLocationId] = useState(null);

    useEffect(() => {
        if (locationToEdit) {
            setName(locationToEdit.name || '');
            setDescription(locationToEdit.description || '');
            setItemSpawns(locationToEdit.itemSpawns || ''); // Initialize
            setCharacterSpawns(locationToEdit.characterSpawns || ''); // Initialize
            setProperties(locationToEdit.properties || []);
            setLabels(locationToEdit.labels || []);
            setShowInScene(locationToEdit.showInScene || false);
        } else {
            setName('');
            setDescription('');
            setItemSpawns(''); // Reset
            setCharacterSpawns(''); // Reset
            setProperties([]);
            setLabels([]);
            setShowInScene(false);
        }
        setIsViewOnly(viewOnly);
        // Reset suggestions when modal is opened or closed, or when editing/viewing changes
        setSuggestions([]);
        setCurrentSuggestionIndex(0);
        // Reset newLocationId when modal is opened or closed
        if (!isOpen) {
            setNewLocationId(null);
        }
    }, [isOpen, locationToEdit, viewOnly]);

    const handleImageSave = async (locationId) => {
        try {
            if (addEditImageRef.current) {
                await addEditImageRef.current.commit(locationId);
            }
        } catch (err) {
            console.error('Error saving image:', err);
            setError('Failed to save image.');
            throw err; // Re-throw to handle in handleSubmit
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            const data = {
                name,
                description,
                itemSpawns, // Include new fields
                characterSpawns, // Include new fields
                labels,
                properties,
                showInScene,
                story: story._id,
                // Include other necessary fields
            };

            let locationId = locationToEdit ? locationToEdit._id : null;

            if (locationToEdit) {
                // Editing existing location
                await handleImageSave(locationId);
                // Update location details
                await axios.put(`${process.env.REACT_APP_API_URL}/location/${locationId}`, data);
            } else {
                // Creating new location without image
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/location`, data);
                const newLocation = response.data;
                locationId = newLocation._id;
                setNewLocationId(locationId); // Update state with new _id
                // Now, attach/upload the image using the new _id
                await handleImageSave(locationId);
            }

            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error saving location:', err);
            setError('Failed to save location.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!locationToEdit) return;
        if (!window.confirm('Are you sure you want to delete this location?')) return;

        setLoading(true);
        setError('');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/location/${locationToEdit._id}`);
            onSuccess();
            onClose();
        } catch (err) {
            console.error('Error deleting location:', err);
            setError('Failed to delete location.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsViewOnly(false);
    };

    const handlePropertiesUpdate = (newProperties) => {
        setProperties(newProperties);
    };

    // Handlers for suggestion functionality
    const handleGenerateSuggestions = async () => {
        setIsGenerating(true);
        setError('');
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/location/generate?storyId=${story._id}`
            );
            setSuggestions(response.data); // Assuming the API returns an array of suggestions in data
            setCurrentSuggestionIndex(0);
            // Load the first suggestion into the form fields
            if (response.data.length > 0) {
                loadSuggestion(response.data[0]);
            }
        } catch (error) {
            console.error('Error generating location suggestions:', error);
            setError('Failed to generate location suggestions.');
        } finally {
            setIsGenerating(false);
        }
    };

    const loadSuggestion = (suggestion) => {
        setName(suggestion.name || '');
        setDescription(suggestion.description || '');
        setItemSpawns(suggestion.itemSpawns || ''); // Load new fields
        setCharacterSpawns(suggestion.characterSpawns || ''); // Load new fields
        setLabels(suggestion.labels || []);
        setProperties(suggestion.properties || []);
        setShowInScene(suggestion.showInScene || false);
        // Handle image if any
        // If AddEditImage component supports setting the image, we can set it here
    };

    const handlePreviousSuggestion = () => {
        if (currentSuggestionIndex > 0) {
            const newIndex = currentSuggestionIndex - 1;
            setCurrentSuggestionIndex(newIndex);
            loadSuggestion(suggestions[newIndex]);
        }
    };

    const handleNextSuggestion = () => {
        if (currentSuggestionIndex < suggestions.length - 1) {
            const newIndex = currentSuggestionIndex + 1;
            setCurrentSuggestionIndex(newIndex);
            loadSuggestion(suggestions[newIndex]);
        }
    };

    return (
        <Modal theme={ModalTheme} show={isOpen} onClose={onClose} size="4xl">
            <Modal.Header>
                <div className="flex justify-between items-center w-full space-x-2">
                    <div className="flex items-center space-x-2">
                        <FaMapMarkerAlt />
                        <span
                            className={`text-2xl font-bold ${
                                isViewOnly ? '' : 'text-gray-900 dark:text-white'
                            }`}
                        >
                            {locationToEdit
                                ? isViewOnly
                                    ? 'View Location'
                                    : 'Edit Location'
                                : 'Add Location'}
                        </span>
                    </div>
                    <div className="flex items-center space-x-2">
                        {!isViewOnly && !locationToEdit && (
                            <>
                                {isGenerating ? (
                                    <Spinner size="sm" className="ml-2" />
                                ) : (
                                    <FaMagic
                                        className={`text-gray-300 dark:text-gray-500 hover:text-purple-700 dark:hover:text-purple-500 transition-all duration-100 transform hover:scale-110 ${
                                            isGenerating ? 'cursor-not-allowed' : 'cursor-pointer'
                                        }`}
                                        onClick={handleGenerateSuggestions}
                                        title="Generate Location Suggestions"
                                        aria-label="Generate Location Suggestions"
                                    />
                                )}
                            </>
                        )}
                        {locationToEdit && isViewOnly && (
                            <GrEdit
                                className="text-gray-300 dark:text-gray-500 hover:text-green-500 dark:hover:text-green-400 transition-all duration-100 transform hover:scale-110 cursor-pointer"
                                onClick={handleEdit}
                                title="Edit Location"
                                aria-label="Edit Location"
                            />
                        )}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <div className="mb-4">
                        <Label color="failure" value={error} />
                    </div>
                )}
                {suggestions.length > 0 && (
                    <div className="flex justify-between items-center mb-4">
                        <MdOutlineArrowBackIos
                            size={24}
                            onClick={handlePreviousSuggestion}
                            className={`transition-transform transform hover:scale-110 ${
                                currentSuggestionIndex === 0
                                    ? 'text-gray-400 dark:text-gray-500 cursor-not-allowed'
                                    : 'text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 cursor-pointer'
                            }`}
                        />
                        <span>
                            Suggestion {currentSuggestionIndex + 1} of {suggestions.length}
                        </span>
                        <MdOutlineArrowForwardIos
                            size={24}
                            onClick={handleNextSuggestion}
                            className={`transition-transform transform hover:scale-110 ${
                                currentSuggestionIndex === suggestions.length - 1
                                    ? 'text-gray-400 dark:text-gray-500 cursor-not-allowed'
                                    : 'text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 cursor-pointer'
                            }`}
                        />
                    </div>
                )}
                <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {/* Left Column */}
                    <div className="space-y-6">
                        {/* Name Field */}
                        <div>
                            <Label htmlFor="name" value="Name" />
                            <TextInput
                                id="name"
                                type="text"
                                placeholder="Enter location name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextInputThemeReadOnly : TextInputTheme}
                            />
                        </div>
                        {/* Show In Scene Field */}
                        <div className={'space-x-2 pt-2'}>
                            <Checkbox
                                disabled={isViewOnly}
                                checked={showInScene}
                                onChange={() => setShowInScene(!showInScene)}
                            />
                            <Label>Show image in scene view</Label>
                        </div>
                        {/* Image Field */}
                        <div>
                            <Label htmlFor="image" value="Image" />
                            <AddEditImage
                                parentData={{
                                    _id: locationToEdit?._id || newLocationId, // Use newLocationId if available
                                    name,
                                    description,
                                    itemSpawns, // Added
                                    characterSpawns, // Added
                                    labels,
                                    properties,
                                    showInScene,
                                    story: story._id,
                                    // Include image data if necessary
                                }}
                                ref={addEditImageRef}
                                placeholder={6}
                                draftImageSet={() => setShowInScene(true)} // Callback to auto-tick the showInScene checkbox
                                disabled={isViewOnly} // Disable if no _id available
                            />
                        </div>
                        {/* Properties Field */}
                        <div>
                            <Label htmlFor="properties" value="Properties" />
                            <PropertiesManager
                                worldId={story.world._id}
                                labels={labels}
                                objectProperties={properties}
                                setObjectProperties={handlePropertiesUpdate}
                                disabled={isViewOnly}
                            />
                        </div>
                    </div>
                    {/* Right Column */}
                    <div className="space-y-4">
                        {/* Description Field */}
                        <div>
                            <Label htmlFor="description" value="Description" />
                            <Textarea
                                id="description"
                                placeholder="Enter location description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                                rows={8}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Item Spawns Field */}
                        <div>
                            <Label htmlFor="itemSpawns" value="Item Spawns" />
                            <Textarea
                                id="itemSpawns"
                                placeholder="Enter items that spawn in this location"
                                value={itemSpawns}
                                onChange={(e) => setItemSpawns(e.target.value)}
                                required={!isViewOnly}
                                rows={4}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Character Spawns Field */}
                        <div>
                            <Label htmlFor="characterSpawns" value="Character Spawns" />
                            <Textarea
                                id="characterSpawns"
                                placeholder="Enter characters that spawn in this location"
                                value={characterSpawns}
                                onChange={(e) => setCharacterSpawns(e.target.value)}
                                required={!isViewOnly}
                                rows={4}
                                disabled={isViewOnly}
                                theme={isViewOnly ? TextAreaThemeReadOnly : TextAreaTheme}
                            />
                        </div>
                        {/* Labels Field */}
                        <div>
                            <Label htmlFor="labels" value="Labels" />
                            {/* Assuming there's a component or method to handle labels */}
                            {/* Implement label management as needed */}
                        </div>
                        {/* Additional Fields (if any) */}
                        {/* You can add more fields here as needed */}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {!isViewOnly ? (
                    <div className="flex justify-between w-full">
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading || !name || !description || !itemSpawns || !characterSpawns}
                            theme={ButtonTheme}
                            color="blue"
                        >
                            {loading ? 'Saving...' : locationToEdit ? 'Save' : 'Add'}
                            {!locationToEdit && (
                                <>
                                    <GiQuillInk size={16} className="ml-1 translate-x-1" />
                                </>
                            )}
                        </Button>
                        {locationToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div className="flex justify-between w-full">
                        {locationToEdit && (
                            <Button
                                color="red"
                                onClick={handleDelete}
                                disabled={loading}
                                theme={ButtonTheme}
                                className="mr-4"
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            color="gray"
                            onClick={onClose}
                            disabled={loading}
                            theme={ButtonTheme}
                        >
                            Close
                        </Button>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
};

LocationFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    locationToEdit: PropTypes.object,
    story: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool,
};

LocationFormModal.defaultProps = {
    locationToEdit: null,
    viewOnly: false,
};

export default LocationFormModal;
